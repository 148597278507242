import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    btn: {
      '& .MuiTypography-body1': {
        fontSize: 15,
      },

      '& .MuiSvgIcon-root': {
        height: 18,
        width: 18,
        fill: theme.palette.secondary.light,
      },

      '& .Mui-checked': {
        '& svg': {
          height: 15,
          width: 15,
        },
      },
    },
    btnSelected: {
      backgroundColor: theme.palette.frequent.chineseWhite,

      '@media (hover: none)': {
        '&:hover': {
          backgroundColor: theme.palette.frequent.chineseWhite,
        },
      },
    },
    yearBtn: {
      marginRight: 30,
    },
    monthBtn: {
      borderRadius: '0px 10px 10px 0px',
    },
  }),
);
