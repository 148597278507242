import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Grid, Paper } from '@material-ui/core';
import { useStyles } from './styles';
import { Notification } from '../../../../models';
import { useApiError, useLoading } from '../../../../providers';
import { updateNotificationStatus } from '../../NotificationService';

interface Props {
  data: Notification;
}

export const NotificationItem: FC<Props> = ({ data }) => {
  const classes = useStyles();
  const [notification, setNotification] = useState<Notification>();
  const { setIsLoading } = useLoading();
  const { addError } = useApiError();

  useEffect(() => {
    setNotification(data);
  }, [data]);

  const markAsRead = useCallback(async () => {
    if (notification) {
      setIsLoading(true);
      try {
        await updateNotificationStatus({
          notificationObjId: notification.id,
          isRead: true,
        });
        notification.isRead = true;
        setNotification(notification);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    }
  }, [addError, setIsLoading, notification]);

  return (
    <Paper className={classes.paper}>
      {notification && (
        <Grid container direction="column">
          <Grid item xs={12} className={classes.notificationSubject}>
            {notification.message.subject}
          </Grid>
          <Grid item xs={12} className={classes.notificationStatus}>
            <b>Status: </b>
            {notification.isRead ? 'Read' : 'New'}
          </Grid>
          <Grid item xs={12} className={classes.notificationBody} style={{ whiteSpace: 'pre-wrap' }}>
            {notification.message.body}
          </Grid>
          {!notification.isRead && (
            <Grid item xs={12}>
              <Button variant="outlined" onClick={markAsRead}>
                Dismiss
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Paper>
  );
};
