import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  button: {
    width: '152px !important',
    height: 40,
    backgroundColor: theme.palette.changeable.exportButtons,
    color: theme.palette.common.white,
    justifyContent: 'center',
    borderRadius: 20,

    '&:hover': {
      backgroundColor: theme.palette.changeable.hover,
      color: theme.palette.common.white,
    },

    '@media (max-width: 915px)': {
      width: '158px !important;',
      padding: '7px 9px',
    },
    '@media (max-width: 360px)': {
      width: '146px !important;',
      padding: '7px 9px',
    },
  },
}));
