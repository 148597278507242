/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, Paper } from '@material-ui/core';
import { CheckCircle, RadioButtonUncheckedOutlined } from '@material-ui/icons';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useStyles } from './styles';
import { ReportTitle } from '../../../components';
import { useApiError, useAppData, useLoading } from '../../../providers';
import { getCurrencies, getPartnerPartnerships } from '../../IBDashboard';
import { PartnerPartnership } from '../../../models';
import { ClientListEmailReportRequest, ClientListItem, reportEnColumns } from './ClientListModel';
import { ReportView, TopSelectPanel } from './components';
import { getClientsReport, sendEmailClientsOverviewReport } from './ClientListService';
import { getSelectedColumnsInEn, getTranslatedColumnsInCorrectOrder, getTranslation } from '../../../utils';

export const ClientsList: FC = () => {
  const classes = useStyles();
  const { setIsLoading } = useLoading();
  const [currencies, setCurrencies] = useState<string[]>([]);
  const [selectedCurrencies, setSelectedCurrencies] = useState<string[]>(['All']);
  const [partnerships, setPartnerships] = useState<PartnerPartnership[]>([]);
  const [selectedPartnerships, setSelectedPartnerships] = useState<string[]>(['All']);
  const { addError } = useApiError();
  const { translations, userData, locale } = useAppData();
  const [translation, setTranslation] = useState<any>([]);
  const [allReportData, setAllReportData] = useState<ClientListItem[]>([]);
  const [reportData, setReportData] = useState<ClientListItem[]>([]);
  const [groupBy, setGroupBy] = useState<string | undefined>(undefined);
  const columnNames = useMemo(
    () => [
      translation.text_6596,
      translation.text_6597,
      translation.text_6599,
      translation.text_6600,
      translation.text_6601,
      translation.text_6602,
      translation.text_6603,
      translation.text_6604,
      translation.text_6605,
      translation.text_6606,
      translation.text_6607,
      translation.text_6608,
      translation.text_6609,
      translation.text_6610,
      translation.text_6611,
      translation.text_6612,
      translation.text_6613,
    ],
    [translation],
  );
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnNames);
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userEmail, setUserEmail] = useState<string>();
  const [language, setLanguage] = useState<string>();

  const getReportFiltersData = useCallback(async () => {
    try {
      const currency = await getCurrencies();
      setCurrencies(currency.data);
      const response = await getPartnerPartnerships(true);
      setPartnerships(response.data);
    } catch (error) {
      addError(JSON.stringify(error.response));
    }
  }, [addError]);

  const getReportData = useCallback(
    async (partnershipList: number[], currencyList: string[]) => {
      try {
        const response = await getClientsReport(partnershipList, currencyList);
        setAllReportData(response.data);
        setReportData(response.data);
      } catch (error) {
        addError(JSON.stringify(error.response));
      }
    },
    [addError],
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getReportFiltersData();
      await getReportData([], []);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    const user = JSON.parse(userData);
    setUserEmail(user.userEmail);
    setLanguage(locale);
  }, [userData, locale]);

  useEffect(() => {
    if (columnNames.length === 17) {
      setSelectedColumns(columnNames.slice(0, 7));
    }
  }, [columnNames]);

  const onPartnershipChange = useCallback(
    async (partnershipIds: number[], currenciesList: string[]) => {
      await getReportData(partnershipIds, currenciesList);
    },
    [getReportData],
  );

  const handleSelectChange = useCallback(
    async (options, type) => {
      const partnershipIds: number[] = [];
      let currenciesList: string[];

      if (type === 'Partnership') {
        setSelectedPartnerships(options);
        options.forEach((option: any) => {
          if (option !== 'All') {
            const partnership = partnerships.find((p) => p.partnershipCode === option);
            if (partnership !== undefined && partnership.id != null) {
              partnershipIds.push(partnership.id);
            }
          }
        });

        currenciesList = selectedCurrencies.includes('All') ? [] : selectedCurrencies;
      } else {
        setSelectedCurrencies(options);
        currenciesList = options.includes('All') ? [] : options;

        selectedPartnerships.forEach((option: any) => {
          if (option !== 'All') {
            const partnership = partnerships.find((p) => p.partnershipCode === option);
            if (partnership !== undefined && partnership.id != null) {
              partnershipIds.push(partnership.id);
            }
          }
        });
      }
      await onPartnershipChange(partnershipIds, currenciesList);
    },
    [partnerships, selectedPartnerships, selectedCurrencies, onPartnershipChange, setSelectedCurrencies, setSelectedPartnerships],
  );

  const handleShowInactiveChanges = useCallback(
    (show: boolean) => {
      setShowInactive(show);

      if (show) {
        const data = cloneDeep(allReportData).filter((report) => report.partnershipEndDate);
        setReportData(data);
      } else {
        setReportData(allReportData);
      }
    },
    [allReportData],
  );

  const renderTableData = useCallback((): string[][] => {
    const rows: string[][] = [];
    reportData.forEach((row: any) => {
      const tableRow: string[] = [
        row.partnershipCode ? row.partnershipCode : '-',
        row.clientName ? row.clientName : '-',
        row.clientCountry ? getTranslation(translation, row.clientCountry) : '-',
        row.currency ? row.currency : '-',
        row.accountType ? getTranslation(translation, row.accountType) : '-',
        row.partnershipStartDate ? moment(row.partnershipStartDate).format('DD/MM/yyyy') : '-',
        row.clientLogin ? row.clientLogin : '-',
        row.partnershipEndDate ? moment(row.partnershipEndDate).format('DD/MM/yyyy') : '-',
        row.lastTradeDate ? moment(row.lastTradeDate).format('DD/MM/yyyy') : '-',
        row.leverage || row.leverage === 0 ? row.leverage : '-',
        row.volume || row.volume === '.00' ? Number(row.volume).toFixed(2) : '-',
        row.volumeLots || row.volumeLots === '.00' ? Number(row.volumeLots).toFixed(2) : '-',
        row.closedPositions || row.closedPositions === 0 ? row.closedPositions : '-',
        row.deposits || row.deposits === '.00' ? Number(row.deposits).toFixed(2) : '-',
        row.withDrawals || row.withDrawals === '.00' ? Math.abs(Number(row.withDrawals)) : '-',
        row.netDeposits || row.netDeposits === '.00' ? Number(row.netDeposits).toFixed(2) : '-',
        row.realEquity || row.realEquity === '.00' ? Number(row.realEquity).toFixed(2) : '-',
      ];
      rows.push(tableRow);
    });

    return rows;
  }, [reportData, translation]);

  const getIdsByPartnershipCode = (objects: any[], partnershipCodes: string[]): number[] => {
    if (partnershipCodes.includes('All')) {
      return [];
    }

    const idMap = new Map<string, number>();

    objects.forEach((obj) => {
      idMap.set(obj.partnershipCode, obj.id);
    });

    return partnershipCodes.map((code) => idMap.get(code)).filter((id) => id !== undefined) as number[];
  };

  const buildClientListEmailReportRequest = (): ClientListEmailReportRequest => ({
    partnersClientsFilters: {
      currencies: selectedCurrencies.includes('All') ? [] : selectedCurrencies,
      partnershipIds: getIdsByPartnershipCode(partnerships, selectedPartnerships),
    },
    reportEmailRequest: {
      email: null,
      includeTotal: false,
      tableColumns: getSelectedColumnsInEn(reportEnColumns, selectedColumns, columnNames),
      translatedTableColumns: getTranslatedColumnsInCorrectOrder(selectedColumns, columnNames),
      language,
      startDate: null,
      endDate: null,
    },
    showInactive,
  });

  const sendEmail = async () => {
    const clientListEmailReportRequest = buildClientListEmailReportRequest();

    try {
      await sendEmailClientsOverviewReport(clientListEmailReportRequest);
      enqueueSnackbar(`${translation.text_1640} ${userEmail}.`, {
        variant: 'success',
      });
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <ReportTitle
        sendEmail={sendEmail}
        title={translation.text_6429}
        exportFileName={translation.text_6430}
        reportColumns={columnNames}
        exportTableData={renderTableData()}
        printColumnNumber={selectedColumns.length}
        content={<ReportView key={Math.random()} reportData={reportData} groupBy={groupBy} translation={translation} columnNames={columnNames} selectedColumns={selectedColumns} />}
        enableButtons
        translation={translation}
      />
      <Paper className={classes.paper}>
        <TopSelectPanel
          partnerships={partnerships}
          currencies={currencies}
          selectedPartnerships={selectedPartnerships}
          selectedCurrencies={selectedCurrencies}
          selectedColumns={selectedColumns}
          columnNames={columnNames}
          onGroupByChange={(option: string) => setGroupBy(option)}
          onSelectedColumnsChange={setSelectedColumns}
          onPartnershipCurrenciesChange={handleSelectChange}
        />
        <Grid container direction="row" alignContent="flex-start" alignItems="center" className={classes.inactiveClients}>
          <FormControlLabel
            className={classes.checkboxLabel}
            control={
              <Checkbox
                checked={showInactive}
                onChange={() => handleShowInactiveChanges(!showInactive)}
                icon={<RadioButtonUncheckedOutlined className={classes.checkbox} />}
                checkedIcon={<CheckCircle className={classes.checkbox} />}
                name="showInactive"
              />
            }
            label={translation.text_6431}
          />
        </Grid>
        <ReportView key={Math.random()} reportData={reportData} groupBy={groupBy} translation={translation} columnNames={columnNames} selectedColumns={selectedColumns} />
      </Paper>
    </Grid>
  );
};
