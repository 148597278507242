import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Grid, MenuItem } from '@material-ui/core';
import {
  CheckIcon,
  ColumnsSelect,
  FieldLabel,
  MultiSelectControl,
} from '../../../../../components';
import { useStyles } from '../../styles';
import { PartnerPartnership } from '../../../../../models';
import { useCommonStyles } from '../../../../../styles';
import { useAppData } from '../../../../../providers';

interface Props {
  partnerships: PartnerPartnership[];
  currencies: string[];
  columnNames: string[];
  selectedPartnerships: string[];
  selectedCurrencies: string[];
  selectedColumns: string[];
  onGroupByChange: (newOption: string) => void;
  onSelectedColumnsChange: (columns: string[]) => void;
  onPartnershipCurrenciesChange: (newOptions: string[], type: string) => void;
}

export const TopSelectPanel: FC<Props> = ({
  partnerships,
  selectedPartnerships,
  selectedCurrencies,
  currencies,
  selectedColumns,
  onGroupByChange,
  onSelectedColumnsChange,
  onPartnershipCurrenciesChange,
  columnNames,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { translations } = useAppData();
  const [groupBy, setGroupBy] = useState<string | undefined>(undefined);
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const renderValueCustomCondition = useCallback(
    (value: string[]) => {
      if (value[0] === 'All') {
        return [translation.all];
      }

      return false;
    },
    [translation.all],
  );

  const handleSelectChange = useCallback(
    async (event, type) => {
      let options = event.target.value as string[];
      if (options.length === 0) {
        options = ['All'];
      }

      if (options.length > 1) {
        if (options.includes('All')) {
          if (options[options.length - 1] === 'All') {
            options = options.filter((option) => option === 'All');
          } else {
            options = options.filter((option) => option !== 'All');
          }
        }
      }

      onPartnershipCurrenciesChange(options, type);
    },
    [onPartnershipCurrenciesChange],
  );

  const selectGroupBy = useCallback(
    (option) => {
      let newValue = option;
      if (option === groupBy) {
        newValue = undefined;
      }
      setGroupBy(newValue);
      onGroupByChange(newValue);
    },
    [groupBy, onGroupByChange],
  );

  const handleSelectColumns = (value: string[]) => {
    onSelectedColumnsChange(value);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        alignItems="center"
        justify="space-between"
        className={classes.topSelect}
      >
        <Grid item xs={10}>
          <Grid
            container
            direction="row"
            alignContent="flex-start"
            alignItems="center"
          >
            <Grid item className={classes.itemMargin}>
              {partnerships.length > 0 && (
                <Grid container direction="column">
                  <FieldLabel label={translation.text_6432} />
                  <MultiSelectControl
                    value={selectedPartnerships}
                    onChange={(event: any) =>
                      handleSelectChange(event, 'Partnership')
                    }
                    renderValueCustomCondition={renderValueCustomCondition}
                    className={classes.multiSelect}
                  >
                    <MenuItem
                      className={
                        selectedPartnerships.includes('All')
                          ? classes.selectedOption
                          : classes.option
                      }
                      value="All"
                    >
                      <CheckIcon />
                      <span>{translation.all}</span>
                    </MenuItem>
                    {partnerships.map((partnership) => (
                      <MenuItem
                        className={
                          selectedPartnerships.includes(
                            partnership.partnershipCode as string,
                          )
                            ? classes.selectedOption
                            : classes.option
                        }
                        key={partnership.partnershipCode}
                        value={
                          partnership.partnershipCode
                            ? partnership.partnershipCode
                            : ''
                        }
                      >
                        <CheckIcon />
                        <span>{partnership.partnershipCode}</span>
                      </MenuItem>
                    ))}
                  </MultiSelectControl>
                </Grid>
              )}
            </Grid>
            <Grid item className={classes.itemMargin}>
              {currencies.length > 0 && (
                <Grid container direction="column">
                  <FieldLabel label={translation.text_6433} />
                  <MultiSelectControl
                    renderValueCustomCondition={renderValueCustomCondition}
                    value={selectedCurrencies}
                    onChange={(event: any) =>
                      handleSelectChange(event, 'Currency')
                    }
                    className={classes.multiSelect}
                  >
                    <MenuItem
                      key="All"
                      className={
                        selectedCurrencies.includes('All')
                          ? classes.selectedOption
                          : classes.option
                      }
                      value="All"
                    >
                      <CheckIcon />
                      <span>{translation.all}</span>
                    </MenuItem>
                    {currencies.map((currency) => (
                      <MenuItem
                        key={currency}
                        className={
                          selectedCurrencies.includes(currency)
                            ? classes.selectedOption
                            : classes.option
                        }
                        value={currency}
                      >
                        <CheckIcon />
                        <span>{currency}</span>
                      </MenuItem>
                    ))}
                  </MultiSelectControl>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <FieldLabel label={translation.text_5718} />
              <Grid
                container
                direction="row"
                alignContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    className={
                      groupBy === 'partnershipCode' ? classes.selectedGroup : ''
                    }
                    classes={{
                      root: `${commonClasses.buttonContained} ${classes.partnership}`,
                    }}
                    onClick={() => selectGroupBy('partnershipCode')}
                  >
                    {translation.text_6432}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    className={
                      groupBy === 'currency' ? classes.selectedGroup : ''
                    }
                    classes={{
                      root: `${commonClasses.buttonContained} ${classes.currency}`,
                    }}
                    onClick={() => selectGroupBy('currency')}
                  >
                    {translation.text_6433}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    className={
                      groupBy === 'clientCountry' ? classes.selectedGroup : ''
                    }
                    classes={{
                      root: `${commonClasses.buttonContained} ${classes.country}`,
                    }}
                    onClick={() => selectGroupBy('clientCountry')}
                  >
                    {translation.text_6434}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container justify="flex-end">
            <Grid item style={{ marginRight: 26 }}>
              <ColumnsSelect
                reportColumns={columnNames}
                selectedColumns={selectedColumns}
                handleSelectColumns={handleSelectColumns}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignContent="flex-start"
        alignItems="center"
        className={classes.topSelectMobile}
      >
        {partnerships.length > 0 && (
          <Grid container direction="column">
            <FieldLabel label={translation.text_6432} />
            <MultiSelectControl
              renderValueCustomCondition={renderValueCustomCondition}
              value={selectedPartnerships}
              onChange={(event: any) =>
                handleSelectChange(event, 'Partnership')
              }
              className={classes.multiSelect}
            >
              <MenuItem
                className={
                  selectedPartnerships.includes('All')
                    ? classes.selectedOption
                    : classes.option
                }
                value="All"
              >
                <CheckIcon />
                <span>{translation.all}</span>
              </MenuItem>
              {partnerships.map((partnership) => (
                <MenuItem
                  className={
                    selectedPartnerships.includes(
                      partnership.partnershipCode as string,
                    )
                      ? classes.selectedOption
                      : classes.option
                  }
                  key={partnership.partnershipCode}
                  value={
                    partnership.partnershipCode
                      ? partnership.partnershipCode
                      : ''
                  }
                >
                  <CheckIcon />
                  <span>{partnership.partnershipCode}</span>
                </MenuItem>
              ))}
            </MultiSelectControl>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          alignContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            {currencies.length > 0 && (
              <Grid container direction="column">
                <FieldLabel label={translation.text_6433} />
                <MultiSelectControl
                  renderValueCustomCondition={renderValueCustomCondition}
                  value={selectedCurrencies}
                  onChange={(event: any) =>
                    handleSelectChange(event, 'Currency')
                  }
                  className={classes.multiSelect}
                >
                  <MenuItem
                    key="All"
                    className={
                      selectedCurrencies.includes('All')
                        ? classes.selectedOption
                        : classes.option
                    }
                    value="All"
                  >
                    <CheckIcon />
                    <span>{translation.all}</span>
                  </MenuItem>
                  {currencies.map((currency) => (
                    <MenuItem
                      key={currency}
                      className={
                        selectedCurrencies.includes(currency)
                          ? classes.selectedOption
                          : classes.option
                      }
                      value={currency}
                    >
                      <CheckIcon />
                      <span>{currency}</span>
                    </MenuItem>
                  ))}
                </MultiSelectControl>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <ColumnsSelect
              reportColumns={columnNames}
              selectedColumns={selectedColumns}
              handleSelectColumns={handleSelectColumns}
            />
          </Grid>
        </Grid>
        <Grid container direction="column">
          <FieldLabel label={translation.text_5718} />
          <Grid
            container
            direction="row"
            alignContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="outlined"
                className={
                  groupBy === 'partnershipCode' ? classes.selectedGroup : ''
                }
                classes={{
                  root: `${commonClasses.buttonContained} ${classes.partnership}`,
                }}
                onClick={() => selectGroupBy('partnershipCode')}
              >
                {translation.text_6432}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                className={groupBy === 'currency' ? classes.selectedGroup : ''}
                classes={{
                  root: `${commonClasses.buttonContained} ${classes.currency}`,
                }}
                onClick={() => selectGroupBy('currency')}
              >
                {translation.text_6433}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                className={
                  groupBy === 'clientCountry' ? classes.selectedGroup : ''
                }
                classes={{
                  root: `${commonClasses.buttonContained} ${classes.country}`,
                }}
                onClick={() => selectGroupBy('clientCountry')}
              >
                {translation.text_6434}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
