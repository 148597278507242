import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: 'calc(100% - 55px) !important;',
      flex: 1,
      marginTop: 44,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: `${theme.spacing(0)} auto`,
    },
    loginForm: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 451,
    },
    loginBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,

      '&:hover': {
        backgroundColor: theme.palette.changeable.hover,
        color: theme.palette.common.white,
      },
    },
    header: {
      textAlign: 'center',
      color: theme.palette.frequent.davyGrey,
      fontWeight: 'normal',
      paddingBottom: 19,
    },
    systemName: {
      fontWeight: 500,
    },
    card: {
      marginTop: theme.spacing(10),
    },
    cardContent: {
      padding: '17px 30px 0px 30px',
    },
    padding: {
      paddingTop: 30,
    },
    label: {
      fontSize: '13pt',
      fontWeight: 500,
    },
    cardActions: {
      position: 'relative',
      padding: '31px 30px 21px 30px',
    },
    errorMessage: {
      margin: '25px 0',
      padding: '8px 18px 9px 12px',
      borderRadius: 5,
      backgroundColor: theme.palette.frequent.carminePink,
      fontSize: '13pt',
      color: theme.palette.common.white,
      alignContent: 'center',
      display: 'flex',
    },
    ddl: {
      boxShadow: 'none',
      padding: 5,
      borderRadius: '15px !important',
      margin: 0,
      border: '1px solid rgba(0, 0, 0, 0.23) !important',
      ' &:hover': {
        border: '1px solid black',
      },
    },
    formControlLabel: {
      margin: '30px 0 0 0',
      fontSize: '13pt',
      fontWeight: 500,
      color: theme.palette.common.black,
    },
    languageSelect: {
      '& svg': {
        top: 'auto',
      },
    },
    languageItem: {
      display: 'flex',
      color: theme.palette.text.primary,
      alignContent: 'center',
      textTransform: 'capitalize',
    },
    languageSelectedItem: {
      height: 14,
      marginRight: 5,
    },
    languageSelectItem: {
      height: 18,
      marginRight: 5,
    },
    errorCard: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 401,
      textAlign: 'center',
    },
    errorCardActions: {
      position: 'relative',
      padding: '66px 30px 21px 30px',
    },
  }),
);
