import React, { FC, useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useAppData } from '../../../providers';
import { ListComponent } from './components';
import {
  PATH_ALL_CLIENTS_AND_TRADING,
  PATH_CLIENTS_LIST,
  PATH_COMMISSIONS_BY_CLIENT,
  PATH_TRADING_SUMMARY,
  PATH_CLOSED_POSITIONS,
  PATH_MASTER_IB_COMMISSION,
  PATH_TRANSACTIONS_REPORT,
  PATH_OPEN_CLOSE_POSITIONS_SUMMARY,
  PATH_TRADING_ACTIVITIES_REPORT,
} from '../../../utils';

export const ReportsList: FC = () => {
  const classes = useStyles();
  const { userData, translations } = useAppData();
  const [reports, setReports] = useState<any>([]);
  const [translation, setTranslation] = useState<any>([]);
  const [reportList, setReportList] = useState<any>(undefined);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    setReports([]);
    const allReports: any = {
      allClientsAndTradingReport: {
        name: translation.text_6652,
        link: PATH_ALL_CLIENTS_AND_TRADING,
      },
      closedPositionsReport: {
        name: translation.text_6653,
        link: PATH_CLOSED_POSITIONS,
      },
      tradingReport: {
        name: translation.text_6654,
        link: PATH_TRADING_SUMMARY,
      },
      clientsListReport: {
        name: translation.text_6655,
        link: PATH_CLIENTS_LIST,
      },
      allMasterIBCommissionReport: {
        name: translation.text_6656,
        link: PATH_MASTER_IB_COMMISSION,
      },
      summaryByClientReport: {
        name: translation.text_6657,
        link: PATH_COMMISSIONS_BY_CLIENT,
      },
      partnersClientsTransactionsReport: {
        name: translation.text_6658,
        link: PATH_TRANSACTIONS_REPORT,
      },
      partnersClientsOpenClosedPositionsReport: {
        name: translation.text_6659,
        link: PATH_OPEN_CLOSE_POSITIONS_SUMMARY,
      },
      partnersClientsTradingActivitiesReport: {
        name: translation.text_6660,
        link: PATH_TRADING_ACTIVITIES_REPORT,
      },
    };

    setReportList(allReports);
  }, [translation]);

  useEffect(() => {
    const user = JSON.parse(userData);
    if (reportList !== undefined) {
      if (user.accounts != null && user.accounts.length > 0) {
        // All accounts
        const accountCollection = user.accounts;

        const reportsList: any = [];

        // Loop each account
        accountCollection.forEach((account: any) => {
          const { routeCollection } = account.role;
          const routeCollectionArray = user.isMasterIB
            ? routeCollection.routeCollectionHasRoute
            : routeCollection.routeCollectionHasRoute.filter(
                (item: any) => item.route !== 'allMasterIBCommissionReport',
              );
          const reportsCollection = routeCollectionArray.filter((route: any) =>
            route.module ? route.module.name === 'Reports' : false,
          );

          reportsCollection.forEach((route: any) => {
            const item = reportList[route.route];
            if (item) {
              const report = {
                name: item.name,
                link: item.link,
              };

              reportsList.push(report);
            }
          });
        });

        setReports(reportsList);
      }
    }
  }, [userData, reportList]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <Typography className={classes.title}>
          {translation.text_6410}
        </Typography>
      </Grid>
      <Paper className={`${classes.paper} ${classes.paperNew}`}>
        <Grid container direction="column">
          <ListComponent list={reports} menuButtonClass={classes.menuButton} />
        </Grid>
      </Paper>
    </Grid>
  );
};
