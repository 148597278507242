import React, { FC } from 'react';
import { ReactComponent as ArrowRight } from '../../../assets/Dashboard/ArrowRight.svg';
import { useStyles } from '../styles';
import { CustomIconProps } from '../CustomIconModels';

export const ArrowRightIcon: FC<CustomIconProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <ArrowRight className={`${classes.icon} ${className || ''}`} />
  );
};
