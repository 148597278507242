export const reportEnColumnsRequestId = [
  'partnership',
  'rebate local',
  'total rebates',
  'payment',
  'profit',
  'partnership type',
  'scheme',
  'scheme type',
  'sub-ib commission',
];

export const reportEnColumnsReportId = [
  'login',
  'client name',
  'symbol category',
  'symbol',
  'rebate local',
  'volume',
  'volume lots',
  'payment',
];
