import React, { FC, useMemo } from 'react';
import { ReportDetailsDialog } from './ReportDetailsDialog';
import { DetailedTable } from './DetailedTable';
import { DetailsModel } from '../../MasteIBCommissionModel';

interface Props {
  translation: any;
  title: any;
  subTitle: any;
  data: DetailsModel[];
  fromDate: Date;
  toDate: Date;
  partnershipCode: string;
  trigger: number | undefined;
  handleCancel: () => void;
}

export const MasterIbCommissionDialog: FC<Props> = ({
  title,
  subTitle,
  data,
  trigger,
  handleCancel,
  translation,
}) => {
  const columnNames = useMemo(
    () => [
      translation.text_1666,
      translation.text_983,
      translation.text_984,
      translation.text_1665,
    ],
    [translation],
  );

  const renderTableData = (): string[][] => {
    const rows: string[][] = [];
    if (data.length > 0) {
      data.forEach((row) => {
        const tableRow: string[] = [
          row.subPartnershipCode ? row.subPartnershipCode : '-',
          row.depositUSD || row.depositUSD === 0
            ? row.depositUSD.toFixed(2)
            : '-',
          row.withdrawalUSD || row.withdrawalUSD === 0
            ? Math.abs(row.withdrawalUSD).toFixed(2)
            : '-',
          row.lastBalanceUSD || row.lastBalanceUSD === 0
            ? row.lastBalanceUSD.toFixed(2)
            : '-',
        ];
        rows.push(tableRow);
      });
    }
    return rows;
  };

  return (
    <ReportDetailsDialog
      trigger={trigger}
      title={title}
      subTitle={subTitle}
      reportColumns={columnNames}
      exportTableData={renderTableData()}
      exportReportName={translation.text_6035}
      handleCancel={handleCancel}
    >
      <DetailedTable
        rows={data}
        reportColumns={columnNames}
        translation={translation}
      />
    </ReportDetailsDialog>
  );
};
