import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, IconButton, TableCell, TableRow } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { ReportModel } from 'src/models';
import { CustomTable } from '../../../../components';
import { useStyles } from '../styles';
import { useAppData, useLoading } from '../../../../providers';
import { useCommonStyles } from '../../../../styles';
import useGetReportDetails from '../hooks/useGetReportDetails';
import { MasterIbCommissionDialog } from './MasterIbCommissionsDialog';
import { DetailsModel } from '../MasteIBCommissionModel';

interface Props {
  fromDate: Date;
  toDate: Date;
  partnerName?: string;
  date: string;
  columnNames: string[];
  selectedColumns: string[];
  rows: ReportModel[];
}

export const PartnershipReports: FC<Props> = ({
  date,
  columnNames,
  selectedColumns,
  rows,
  toDate,
  fromDate,
  partnerName,
}) => {
  const timestamp = new Date().getTime();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { setIsLoading } = useLoading();
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);
  const [dialogTrigger, setDialogTrigger] =
    useState<number | undefined>(undefined);
  const [selectedPartnership, setSelectedPartnership] = useState<string>('');
  const [summaryData, setSummaryData] = useState<DetailsModel[]>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const renderHeader = useMemo((): any[] => {
    const result: any[] = [];
    columnNames.forEach((columnName) => {
      const isSelected = selectedColumns.includes(columnName);
      if (isSelected) {
        result.push(
          <TableCell
            key={columnName}
            align={columnName === columnNames[0] ? 'left' : 'center'}
          >
            {columnName}
          </TableCell>,
        );
      } else if (columnName === columnNames[0]) {
        result.push(<TableCell key={`first_${timestamp}`} />);
      }
    });
    result.push(<TableCell key={`last_${timestamp}`} />);
    return result;
  }, [columnNames, selectedColumns, timestamp]);

  const renderTotalRow = useMemo((): any => {
    let subIbCommissionSum = 0;
    let depositUSDSum = 0;
    let withdrawalUSDSum = 0;

    rows.forEach((row) => {
      subIbCommissionSum += row.subPartnershipsRebateSum
        ? row.subPartnershipsRebateSum
        : 0;
      depositUSDSum += row.depositUSD ? row.depositUSD : 0;
      withdrawalUSDSum += row.withdrawalUSD ? row.withdrawalUSD : 0;
    });

    return (
      <TableRow className={classes.tableTotals} key={`totals_${timestamp}`}>
        <TableCell align="right">{translation.text_951}</TableCell>
        {selectedColumns.includes(columnNames[1]) && (
          <TableCell align="center" />
        )}
        {selectedColumns.includes(columnNames[2]) && (
          <TableCell align="center" />
        )}
        {selectedColumns.includes(columnNames[3]) && (
          <TableCell align="center">{`$${subIbCommissionSum.toFixed(
            2,
          )}`}</TableCell>
        )}
        {selectedColumns.includes(columnNames[4]) && (
          <TableCell align="center">{`$${depositUSDSum.toFixed(2)}`}</TableCell>
        )}
        {selectedColumns.includes(columnNames[5]) && (
          <TableCell align="center">{`$${Math.abs(withdrawalUSDSum).toFixed(
            2,
          )}`}</TableCell>
        )}
        {selectedColumns.includes(columnNames[6]) && (
          <TableCell align="center" />
        )}
        <TableCell align="center" />
      </TableRow>
    );
  }, [rows, columnNames, selectedColumns, classes, timestamp]);

  const getSummaryData = useGetReportDetails({
    toDate,
    fromDate,
    setSummaryData,
    setIsLoading,
  });

  const openDetailsDialog = useCallback(
    async (partnershipCode: string) => {
      await getSummaryData(partnershipCode);
      setSelectedPartnership(partnershipCode);
      setDialogTrigger(Math.random());
    },
    [getSummaryData],
  );

  const renderRows = useMemo((): any[] => {
    const result: any[] = [];
    rows.forEach((row) => {
      result.push(
        <TableRow className={commonClasses.tableRow} key={row.id}>
          {selectedColumns.includes(columnNames[0]) ? (
            <TableCell align="left">
              {row.partnershipCode ? row.partnershipCode : '-'}
            </TableCell>
          ) : (
            <TableCell />
          )}
          {selectedColumns.includes(columnNames[1]) && (
            <TableCell align="center">
              {row.partnershipType ? row.partnershipType : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[2]) && (
            <TableCell align="center">
              {row.schemeName ? row.schemeName : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[3]) && (
            <TableCell align="center">
              {row.subPartnershipsRebateSum ||
              row.subPartnershipsRebateSum === 0
                ? `$${row.subPartnershipsRebateSum.toFixed(2)}`
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[4]) && (
            <TableCell align="center">
              {row.depositUSD || row.depositUSD === 0
                ? `$${row.depositUSD.toFixed(2)}`
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[5]) && (
            <TableCell align="center">
              {row.withdrawalUSD || row.withdrawalUSD === 0
                ? `$${Math.abs(row.withdrawalUSD).toFixed(2)}`
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[6]) && (
            <TableCell align="center">
              {row.schemeType ? row.schemeType : '-'}
            </TableCell>
          )}
          <TableCell align="center">
            <IconButton onClick={() => openDetailsDialog(row.partnershipCode)}>
              <ArrowForwardIos className={classes.arrowIcon} fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>,
      );
    });

    return result;
  }, [
    rows,
    columnNames,
    selectedColumns,
    openDetailsDialog,
    classes,
    commonClasses,
  ]);

  const renderTitle = useCallback(
    (): any => <Grid style={{ fontWeight: 'normal' }}>{date}</Grid>,
    [date],
  );

  return (
    <>
      <CustomTable
        headerCells={renderHeader}
        rows={renderRows}
        totalRow={renderTotalRow}
        columnsNumber={selectedColumns.length}
        emptyDataMessage={translation.text_833}
      />
      <MasterIbCommissionDialog
        key={`com_dialog${timestamp}`}
        title={`${translation.text_993} - ${partnerName} (${selectedPartnership})`}
        subTitle={renderTitle()}
        data={summaryData}
        fromDate={fromDate}
        toDate={toDate}
        partnershipCode={selectedPartnership}
        translation={translation}
        trigger={dialogTrigger}
        handleCancel={() => setDialogTrigger(undefined)}
      />
    </>
  );
};
