import { Grid } from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
import { useStyles } from './styles';
import { Dialog, ExportButtons } from '../../../../../../components';
import { useAppData } from '../../../../../../providers';

interface Props {
  title: any;
  subTitle: any;
  exportTableData: string[][];
  reportColumns: string[];
  exportReportName: string;
  trigger: number | undefined;
  handleCancel: () => void;
  children: JSX.Element;
}

export const ReportDetailsDialog: FC<Props> = ({
  title,
  subTitle,
  exportTableData,
  trigger,
  handleCancel,
  reportColumns,
  exportReportName,
  children,
}) => {
  const classes = useStyles();
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  return (
    <Dialog
      trigger={trigger}
      title={title}
      subTitle={subTitle}
      actions={[
        {
          key: '1',
          title: translation.text_1551,
          onClick: () => handleCancel(),
          isPrimary: false,
        },
      ]}
    >
      <Grid
        container
        direction="column"
        spacing={2}
        className={`${classes.root} ${classes.dialog}`}
      >
        <Grid item>
          <Grid container justify="flex-end">
            <ExportButtons
              exportFileName={exportReportName}
              columns={reportColumns}
              exportTableData={exportTableData}
              isEnabled
            />
          </Grid>
        </Grid>
        {children}
      </Grid>
    </Dialog>
  );
};
