import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  selectIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.frequent.cadetBlue
  },
  selectControl: {
    color: theme.palette.common.black,
    borderRadius: 3,
    fontSize: '13pt',
    '&::before': {
      display: 'none',
    },
  },
  selectContainer: {
    width: '100%',
    height: 40,
    fontSize: '13pt',
    borderRadius: 20,
    boxShadow: '0 5px 10px 0 rgb(103 126 172 / 16%)',
    backgroundColor: theme.palette.common.white,
    margin: '10px 15px 0 0',
    outline: 0,
    border: 0,
    textAlign: 'left',
    '&::before': {
      display: 'none',
    },

    '&:hover': {
      border: 0,
    },

    '& $selectRoot': {
      padding: theme.spacing(2, 3),
    },
    '& fieldset': {
      border: 'none'
    },
    '& select': {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1.5, 4)
    },
    '& .MuiList-root': {
      borderColor: theme.palette.frequent.selectorGrey,
      border: 1
    },
    '& .MuiSelect-root:focus': {
      backgroundColor: 'unset'
    },

    '@media (max-width: 1180px)': {
      fontSize: 12,
      height: 26,
    },

    '@media (max-width: 424px)': {
      fontSize: 10,
      height: 20,
      minWidth: 'unset !important',
      marginTop: 8
    }
  },
  inputField: {
    width: '100%',
    borderRadius: '10px'
  },
  errorDescription: {
    display: 'flex',
    color: theme.palette.frequent.red,
    margin: theme.spacing(1, 0, 0, 0.5),
  }
}));
