import React, { FC } from 'react';
import { useStyles } from '../styles';
import { ReactComponent as ArrowBack } from '../../../assets/Common/ArrowBack.svg';

export const ArrowBackIcon: FC = () => {
  const classes = useStyles();
  return (
    <ArrowBack className={classes.icon} />
  );
};
