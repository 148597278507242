import React, { FC } from 'react';
import { useStyles } from '../styles';
import { ReactComponent as Circle } from '../../../assets/Common/CircleIcon.svg';

interface Props {
  height?: number;
}

export const CircleIcon: FC<Props> = ({ height }) => {
  const classes = useStyles();
  return (
    <Circle className={classes.icon} height={height} />
  );
};
