import React, { FC } from 'react';
import { Divider, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { GreenArrowIcon, GreyLineIcon, RedArrowIcon } from '../../../../../../components';

interface Props {
  partnership?: string;
  value: string;
  netValue: string;
  increased?: number;
  decreased?: number;
  increasedNet?: number;
  decreasedNet?: number;
  translation: any;
}

export const DepositTitlePanel: FC<Props> = ({ value, netValue, increased, decreased, increasedNet, decreasedNet, translation, partnership }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid container direction="column" alignContent="flex-start">
        <Grid container direction="row" alignItems="baseline" justify="space-between" className={classes.dataRow}>
          <Grid item xs={partnership && partnership !== 'All' ? 9 : 12}>
            <Grid container direction="row">
              <Grid item xs={6}>
                <Grid container direction="column" alignItems="flex-start" style={{ marginRight: 20 }}>
                  <Grid item className={classes.mainTitle}>
                    {translation.text_6346}
                  </Grid>
                  {value !== '0' ? (
                    <>
                      <Grid item className={classes.valueTitle}>
                        {value}
                      </Grid>
                      <Grid item className={classes.changeTitle}>
                        {increased ? (
                          <>
                            <GreenArrowIcon className={classes.changeTitleIcon} /> {translation.text_6347} <b>{increased} %</b> {translation.text_6348}
                          </>
                        ) : decreased ? (
                          <>
                            <RedArrowIcon className={classes.changeTitleIcon} /> {translation.text_6349}
                            <b>{decreased} %</b> {translation.text_6348}
                          </>
                        ) : (
                          <>
                            <GreyLineIcon className={classes.noChangeTitleIcon} /> {translation.text_6350}
                          </>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <Grid item className={classes.emptyMessage} style={{ paddingRight: 10 }}>
                      {translation.text_6351}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="column" alignItems="flex-start" alignContent="flex-start">
                  <Grid item className={classes.mainTitle}>
                    {translation.text_6353}
                  </Grid>
                  {netValue !== '0' ? (
                    <>
                      <Grid item className={classes.valueTitle}>
                        {netValue}
                      </Grid>
                      <Grid item className={classes.changeTitle}>
                        {increasedNet ? (
                          <>
                            <GreenArrowIcon className={classes.changeTitleIcon} /> {translation.text_6347} <b>{increasedNet} %</b> {translation.text_6348}
                          </>
                        ) : decreasedNet ? (
                          <>
                            <RedArrowIcon className={classes.changeTitleIcon} /> {translation.text_6349} <b>{decreasedNet} %</b> {translation.text_6348}
                          </>
                        ) : (
                          <>
                            <GreyLineIcon className={classes.noChangeTitleIcon} /> {translation.text_6350}
                          </>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <Grid item className={classes.emptyMessage}>
                      {translation.text_6352}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            {partnership && partnership !== 'All' && (
              <Grid item className={classes.partnershipTitle}>
                <b>{translation.text_6330}:</b> {partnership}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center" className={classes.mobileDataRow}>
          {partnership && partnership !== 'All' && (
            <Grid item className={classes.partnershipTitle}>
              <b>{translation.text_6330}:</b> {partnership}
            </Grid>
          )}
          <Grid container direction="row" justify="space-between">
            <Grid item xs={6}>
              <Grid container direction="column" alignItems="flex-start">
                <Grid item className={classes.mainTitle}>
                  {translation.text_6346}
                </Grid>
                {value !== '0' ? (
                  <>
                    <Grid item className={classes.valueTitle}>
                      {value}
                    </Grid>
                    <Grid item className={classes.changeTitle}>
                      {increased ? (
                        <>
                          <GreenArrowIcon className={classes.changeTitleIcon} /> {translation.text_6347} <b>{increased} %</b> {translation.text_6348}
                        </>
                      ) : decreased ? (
                        <>
                          <RedArrowIcon className={classes.changeTitleIcon} /> {translation.text_6349} <b>{decreased} %</b> {translation.text_6348}
                        </>
                      ) : (
                        <>
                          <GreyLineIcon className={classes.noChangeTitleIcon} /> {translation.text_6350}
                        </>
                      )}
                    </Grid>
                  </>
                ) : (
                  <Grid item className={classes.emptyMessage} style={{ paddingRight: 10 }}>
                    {translation.text_6351}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" alignItems="flex-start" alignContent="flex-start">
                <Grid item className={classes.mainTitle}>
                  {translation.text_6353}
                </Grid>
                {netValue !== '0' ? (
                  <>
                    <Grid item className={classes.valueTitle}>
                      {netValue}
                    </Grid>
                    <Grid item className={classes.changeTitle}>
                      {increasedNet ? (
                        <>
                          <GreenArrowIcon className={classes.changeTitleIcon} /> {translation.text_6347} <b>{increasedNet} %</b> {translation.text_6348}
                        </>
                      ) : decreasedNet ? (
                        <>
                          <RedArrowIcon className={classes.changeTitleIcon} /> {translation.text_6349} <b>{decreasedNet} %</b> {translation.text_6348}
                        </>
                      ) : (
                        <>
                          <GreyLineIcon className={classes.noChangeTitleIcon} /> {translation.text_6350}
                        </>
                      )}
                    </Grid>
                  </>
                ) : (
                  <Grid item className={classes.emptyMessage}>
                    {translation.text_6352}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" className={classes.divider} />
        </Grid>
      </Grid>
    </Grid>
  );
};
