import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  expandedContainer: {
    paddingLeft: 15,
  },
  link: {
    userSelect: 'text',
    alignSelf: 'center',
    display: 'inline-block',
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 3,
    color: theme.palette.frequent.fossilGrey,
  },
  rightContainer: {
    float: 'right',
  },
  accordionDetails: {
    flexDirection: 'column',
    padding: 0,
    border: 'none',
  },
  accordion: {
    borderBottom: `1px solid ${theme.palette.frequent.chineseWhite}`,
    boxShadow: 'none',
    '&&&:before': {
      background: 'transparent',
    },
  },
  accordionHeader: {
    backgroundColor: theme.palette.frequent.pearlGrey,
    borderRadius: 10,
    boxShadow: 'none',
  },
  accordionContent: {
    margin: 0,
    padding: 0,
    backgroundColor: theme.palette.common.white,
    display: 'inline-block',
  },
  accordionContainer: {
    border: 0,
    boxShadow: 'none',
  },
  accordionHeaderTitle: {
    fontWeight: 600,
    padding: 5,
  },
  selectGridContaner: {
    paddingBottom: 15,
  },
  copyButtons: {
    height: 36,
    backgroundColor: theme.palette.frequent.abalonGrey,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.frequent.grey,
    float: 'right',
    padding: '10px 0px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  expandButtons: {
    height: 36,
    backgroundColor: theme.palette.frequent.abalonGrey,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.frequent.grey,
    float: 'right',
    padding: '10px 0px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  copyCodeButtons: {
    height: 26,
    backgroundColor: theme.palette.frequent.abalonGrey,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.frequent.grey,
    marginLeft: 15,
  },
  shortCode: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
