import React, { FC } from 'react';
import { ReactComponent as AngleDown } from '../../../assets/Header/AngleDown.svg';
import { useStyles } from '../styles';
import { CustomIconProps } from '../CustomIconModels';

export const AngleDownIcon: FC<CustomIconProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <AngleDown className={`${classes.icon} ${className || ''}`} />
  );
};
