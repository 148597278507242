export interface AllClientsAndTradingEmailReport {
  partnerId: number;
  month: number;
  year: number;
  day: number;
  endMonth: number;
  endYear: number;
  endDay: number;
  partnershipCode: string;
  convertToCurrency: string;
  requesterAccountGuid: string;
  reportEmailRequest: {
    email: string;
    includeTotal: boolean;
    tableColumns: string[];
    translatedTableColumns: string[];
    language: string;
    startDate: Date;
    endDate: Date;
  };
}

export interface AllClientsAndTradingEmailRequest {
  partnerId: number;
  month: number;
  year: number;
  day: number;
  endMonth: number;
  endYear: number;
  endDay: number;
  convertToCurrency: string;
  requesterAccountGuid: string;
  reportEmailRequest: {
    email: string;
    includeTotal: boolean;
    tableColumns: string[];
    translatedTableColumns: string[];
    language: string;
    startDate: Date;
    endDate: Date;
  };
}

export const reportEnColumnsRequestId = ['partnership', 'rebate local', 'total rebates', 'payment', 'profit', 'partnership type', 'scheme', 'scheme type', 'sub-ib commission'];

export const reportEnColumnsReportId = ['login', 'symbol category', 'symbol', 'rebate local', 'volume', 'volume lots', 'payment'];
