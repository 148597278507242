import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    width: 'calc(100% - 55px) !important;',
    flex: 1,
    marginTop: 44
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: `${theme.spacing(0)} auto`,
  },
  signUpForm: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 451
  },
  signUpBtn: {
    height: 45,
    width: 180,
    backgroundColor: theme.palette.frequent.greenButton,
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: theme.palette.frequent.greenHoverButton,
    }
  },
  required: {
    color: theme.palette.frequent.red,
  },
  logo: {
    margin: 'auto',
    textAlign: 'center',
    marginBottom: 100,
    marginTop: 40
  },
  logoImg: {
    width: 200,
    '@media (max-width: 1024px)': {
      width: 84,
    }
  },
  header: {
    textAlign: 'center',
    color: theme.palette.common.black,
    paddingBottom: 30
  },
  systemName: {
    fontWeight: 500
  },
  card: {
    marginTop: theme.spacing(10),
  },
  cardContent: {
    padding: '17px 30px 0px 30px'
  },
  padding: {
    paddingTop: 30
  },
  label: {
    fontSize: '13pt',
    fontWeight: 500
  },
  cardActions: {
    marginTop: 30,
    marginBottom: 40,
    justifyContent: 'center'
  },
  languageSelect: {
    '& svg': {
      top: 'auto'
    },
  },
  languageItem: {
    display: 'flex',
    color: theme.palette.text.primary,
    alignContent: 'center',
    textTransform: 'capitalize'
  },
  languageSelectedItem: {
    height: 14,
    marginRight: 5
  },
  languageSelectItem: {
    height: 18,
    marginRight: 5
  }
}));
