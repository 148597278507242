import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      color: theme.palette.text.primary,
      textAlign: 'left',
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      marginTop: 10,
      padding: 15,
      minHeight: '100vh',
      width: '100%',

      '@media (max-width: 768px)': {
        padding: '15px 10px',
      },
    },
    partnership: {
      fontSize: 13,
      borderRadius: '10px 0 0 10px',
      height: 40,

      '@media (max-width: 360px)': {
        padding: '7px 9px',
      },
    },
    currency: {
      fontSize: 13,
      borderRadius: 0,
      height: 40,

      '@media (max-width: 360px)': {
        padding: '7px 9px',
      },
    },
    country: {
      fontSize: 13,
      borderRadius: '0 10px 10px 0',
      height: 40,

      '@media (max-width: 360px)': {
        padding: '7px 9px',
      },
    },
    selectedGroup: {
      backgroundColor: theme.palette.frequent.abalonGrey,
    },
    multiSelect: {
      width: 178,
      margin: '0 5px 0 0',
      borderRadius: 10,
      boxShadow: 'none',
      fontSize: 13,

      '& fieldset': {
        border: '1px solid #0000003b',
      },

      '@media (max-width: 1180px)': {
        fontSize: 13,
        height: 40,
        borderRadius: 6,
      },

      '@media (max-width: 424px)': {
        fontSize: 13,
        height: 40,
        minWidth: 'unset !important',
        borderRadius: 6,
      },
    },
    option: {
      '& span': {
        marginLeft: 20,
      },
    },
    selectedOption: {
      '& svg path': {
        fill: theme.palette.frequent.logOutButtonBlue,
      },
      '& span': {
        marginLeft: 20,
      },
    },
    tableTotals: {
      backgroundColor: theme.palette.changeable.tableHeaderBgColor,
      borderRadius: 5,

      '& .MuiTableCell-root': {
        fontWeight: 'bold',
        padding: 16,
      },
      '& td:first-child': {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
      },

      '& td:last-child': {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
      },
    },
    groupedByCell: {
      fontWeight: 'bold',
    },
    emptyReportMessageContainer: {
      marginTop: 193,
    },
    inactiveClients: {
      marginTop: 26,
      marginBottom: 32,
      marginLeft: 8,
    },
    checkboxLabel: {
      '& .MuiTypography-body1': {
        fontSize: 14,
      },
    },
    checkbox: {
      fontSize: 17,
      fill: theme.palette.changeable.checkIconColor,
    },
    topSelect: {
      '@media (max-width: 915px)': {
        display: 'none',
      },
      '@media print': {
        display: 'block',
      },
    },
    topSelectMobile: {
      '@media (min-width: 916px)': {
        display: 'none',
      },
      '@media print': {
        display: 'none',
      },
    },
    itemMargin: {
      marginRight: 15,
    },
    cell: {
      minWidth: 153,
      maxWidth: 153,
    },
    emptyCell: {
      minWidth: 65,
      maxWidth: 65,
      width: 65,
    },
  }),
);
