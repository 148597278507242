import React, { FC, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { getTranslation } from 'src/utils';
import { useStyles } from './styles';
import { Dialog } from '../../../../components';

interface Props {
  data: any;
  trigger: number | undefined;
  handleCancel: () => void;
  translation: any;
}

export const DetailsDialog: FC<Props> = ({ trigger, handleCancel, data, translation }) => {
  const classes = useStyles();

  const translatedTradingAccounts = useMemo(() => {
    if (data?.tradingAccounts && data.tradingAccounts.length > 0) {
      return data.tradingAccounts.map((tradingAccount: string) => getTranslation(translation, tradingAccount)).join(', ');
    }

    return '-';
  }, [data?.tradingAccounts, translation]);

  return (
    <>
      {data !== null && (
        <Dialog
          trigger={trigger}
          title={`${translation.partner}: ${data.partnershipCode.toUpperCase()}`}
          onClose={() => handleCancel()}
          actions={[
            {
              key: '1',
              title: `${translation.text_6694}`,
              onClick: () => handleCancel(),
              isPrimary: false,
            },
          ]}
        >
          <Grid container direction="column" className={classes.root}>
            <Grid item>
              <b>{translation.text_6695}:</b>
            </Grid>
            <Grid item>{data.name}</Grid>
            <Grid item className={classes.margin}>
              <b>{translation.text_6696}:</b>
            </Grid>
            <Grid item>{data.email}</Grid>
            <Grid item className={classes.margin}>
              <b>{translation.text_6698}:</b>
            </Grid>
            <Grid item>{translatedTradingAccounts}</Grid>
            <Grid item className={classes.margin}>
              <b>{translation.text_1639}:</b>
            </Grid>
            <Grid item>{data.preferredCurrency ? data.preferredCurrency : '-'}</Grid>
          </Grid>
        </Dialog>
      )}
    </>
  );
};
