import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    fontSize: 22,
    color: theme.palette.frequent.davyGrey,
    paddingTop: 15,
    paddingBottom: 15
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

export default useStyles;
