import React, { FC, useCallback } from 'react';
import { FormControlLabel, Grid, Radio } from '@material-ui/core';
import { useStyles } from './styles';
import { RadioBtnCheckedIcon } from '../../CustomIcons';

interface Props {
  selectedPeriod: string;
  translation: any
  onChange: (period: string) => void
}

export const PeriodSelection: FC<Props> = ({
  selectedPeriod, onChange, translation
}) => {
  const classes = useStyles();

  const handlePeriodSelect = useCallback((period: string) => {
    onChange(period);
  }, [onChange]);

  return (
    <Grid container direction="row-reverse" style={{ marginTop: 5 }}>
      <FormControlLabel
        value="Year"
        label={translation.text_1598}
        className={`${classes.btn} ${classes.yearBtn}`}
        control={(
          <Radio
            checked={selectedPeriod === 'Year'}
            checkedIcon={<RadioBtnCheckedIcon />}
            onChange={() => handlePeriodSelect('Year')}
            value="Year"
          />
        )}
      />
      <FormControlLabel
        value="Month"
        label={translation.text_1599}
        className={classes.btn}
        control={(
          <Radio
            checked={selectedPeriod === 'Month'}
            checkedIcon={<RadioBtnCheckedIcon />}
            onChange={() => handlePeriodSelect('Month')}
            value="Month"
          />
        )}
      />
    </Grid>
  );
};
