import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  muiInput: {
    display: 'block',
    color: theme.palette.frequent.fossilGrey,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.frequent.abalonGrey}`,
    borderRadius: 10,
    fontSize: '13pt',
    padding: '7px 12px 7px 18px',

    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
    },

    '&::before': {
      display: 'none',
    },
  },
  input: {
    padding: theme.spacing(0),
    caretColor: theme.palette.secondary.main,
  },
  inputFocused: {
    border: `1px solid ${theme.palette.primary.light}`,
  },
  inputError: {
    borderColor: theme.palette.frequent.red,

    '&:hover': {
      borderColor: theme.palette.frequent.red,
    },
  },
  errorDescription: {
    display: 'flex',
    color: theme.palette.frequent.red,
    margin: theme.spacing(1, 0, 0, 0.5),
  }
}));

export default useStyles;
