import React, { FC } from 'react';
import { ReactComponent as Hierarchy } from '../../../assets/MainMenu/Hierarchy.svg';
import { useStyles } from '../styles';

export const HierarchyIcon: FC = () => {
  const classes = useStyles();
  return (
    <Hierarchy className={classes.icon} />
  );
};
