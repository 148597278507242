import { LoginForm } from './LoginModels';
import { instanceApi } from '../../services';

export const login = (data: LoginForm) : Promise<any> => instanceApi.post(
  'auth/Authenticate/Login', data
);
export const getUserLoginData = (): Promise<any> => instanceApi.get('api/user/getuserlogindata');

export const getTranslations = (locale: string) : Promise<any> => instanceApi.get(
  `api/settings/gettranslations?locale=${locale}`
);

export const getTranslationsByBrokerId = (locale: string, brokerId?: string) : Promise<any> => instanceApi.get(
  `/api/settings/gettranslationsbybroker?locale=${locale}&brokerId=${brokerId}`
);

export const getBrokers = (): Promise<any> => instanceApi.get('api/brokersmanagement/getbrokers');

export const loginPartner = (): Promise<any> => instanceApi.get('/auth/External/ChallengeNep');

export const loginRepresentative = (data: LoginForm): Promise<any> => instanceApi.post('auth/Authenticate/Login', data);
