import React, { FC } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useStyles } from './styles';

interface PanelProps {
  title: string;
  panelName: string;
  translation: any;
  selectedPanel: string;
  callBackFunction: (clickedTitle: string) => void;
  value?: string;
  dailyTotalValue?: string;
}

export const AccountingPanel: FC<PanelProps> = ({ title, selectedPanel, callBackFunction, value, dailyTotalValue, panelName, translation }) => {
  const classes = useStyles();

  return (
    <Paper className={`${classes.paper} ${selectedPanel === panelName ? classes.paperSelected : ''}`} onClick={() => callBackFunction(panelName)}>
      <div className={classes.rectangle} />
      <Grid container direction="column" justify="center" style={{ height: '90%' }}>
        <Grid className={classes.paperTitles}>{title}</Grid>
        <Grid className={classes.values}>{value}</Grid>
        {dailyTotalValue && (
          <Grid item className={classes.dailyValues}>
            {translation.text_6278}: {dailyTotalValue}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
