import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: 18,
      color: theme.palette.frequent.davyGrey,
      marginBottom: 20,
      textAlign: 'left',
      fontWeight: 'bold',

      '@media (max-width: 768px)': {
        fontSize: 14,
      },
    },
    content: {
      height: 121,
      padding: '19px 8px 18px',
      borderRadius: 10,
      backgroundColor: theme.palette.changeable.insightBgColor,
      boxShadow: 'none',
    },
    nameItem: {
      color: theme.palette.frequent.fossilGrey,
      fontSize: 16,

      '@media (max-width: 768px)': {
        fontSize: 14,
      },
    },
    valueItem: {
      color: theme.palette.changeable.insightValueTextColor,
      fontWeight: 500,
      fontSize: 24,
      paddingTop: 15,
      cursor: 'pointer',

      '@media (max-width: 768px)': {
        fontSize: 17,
      },

      '@media (max-width: 427px)': {
        fontSize: 15,
      },
    },
    infoIcon: {
      transform: 'scale(0.9)',
    },
  }),
);
