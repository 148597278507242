import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  dialog: {
    width: 692,
    height: 547
  },
  inputRoot: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 10,
    '&:hover, &.Mui-focused': {
      border: `1px solid ${theme.palette.common.black}`,
    },
    paddingBottom: 7,
    paddingTop: 7,
    width: 302
  },
  margin: {
    marginTop: 22
  },
  multiSelect: {
    width: '302px !important',
    margin: '0 5px 0 0',
    borderRadius: 10,
    boxShadow: 'none',

    '& fieldset': {
      border: '1px solid #0000003b !important'
    },

    '@media (max-width: 1180px)': {
      fontSize: 13,
      height: 40,
      borderRadius: 6
    },

    '@media (max-width: 424px)': {
      fontSize: 13,
      height: 40,
      minWidth: 'unset !important',
      marginTop: 8,
      borderRadius: 6
    }
  },
  option: {
    '& span': {
      marginLeft: 20
    }
  },
  selectedOption: {
    '& svg path': {
      fill: theme.palette.frequent.logOutButtonBlue,
    },
    '& span': {
      marginLeft: 20
    }
  },
  chip: {
    borderRadius: 3,
    backgroundColor: theme.palette.secondary.light,
    marginRight: 5,
    padding: '6px 8px 5px',
    textTransform: 'uppercase',
  }
}));
