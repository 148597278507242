import React, { FC, useCallback } from 'react';
import { mapValues, groupBy } from 'lodash';
import moment from 'moment';
import { getTranslation } from 'src/utils';
import { MobileTableView } from '../../../../../components';
import { ClientListItem } from '../../ClientListModel';
import { ValueLabel } from '../../../../../models';

interface Props {
  translation: any;
  groupByOption?: string;
  columnNames: string[];
  selectedColumns: string[];
  rows: ClientListItem[];
}

export const MobileReportTable: FC<Props> = ({
  translation,
  groupByOption,
  columnNames,
  selectedColumns,
  rows,
}) => {
  const renderRows = useCallback(
    (rowsData: ClientListItem[]): any[] => {
      const result: any[] = [];

      if (rowsData.length === 0) {
        const rowData: ValueLabel[] = [];
        columnNames.forEach((columnName) => {
          const isSelected = selectedColumns.includes(columnName);
          if (isSelected) {
            rowData.push({
              value: '',
              label: columnName,
            });
          }
        });
        result.push(rowData);
      }

      rowsData.forEach((row) => {
        const rowData: ValueLabel[] = [];

        if (selectedColumns.includes(columnNames[0])) {
          rowData.push({
            value: row.partnershipCode ? row.partnershipCode : '-',
            label: columnNames[0],
          });
        }
        if (selectedColumns.includes(columnNames[1])) {
          rowData.push({
            value: row.clientName ? row.clientName : '-',
            label: columnNames[1],
          });
        }
        if (selectedColumns.includes(columnNames[2])) {
          rowData.push({
            value: row.clientCountry ? getTranslation(translation, row.clientCountry) : '-',
            label: columnNames[2],
          });
        }
        if (selectedColumns.includes(columnNames[3])) {
          rowData.push({
            value: row.currency ? row.currency : '-',
            label: columnNames[3],
          });
        }
        if (selectedColumns.includes(columnNames[4])) {
          rowData.push({
            value: row.accountType ? getTranslation(translation, row.accountType) : '-',
            label: columnNames[4],
          });
        }
        if (selectedColumns.includes(columnNames[5])) {
          rowData.push({
            value: row.partnershipStartDate
              ? moment(row.partnershipStartDate).format('DD/MM/yyyy')
              : '-',
            label: columnNames[5],
          });
        }
        if (selectedColumns.includes(columnNames[6])) {
          rowData.push({
            value: row.clientLogin ? row.clientLogin : '-',
            label: columnNames[6],
          });
        }
        if (selectedColumns.includes(columnNames[7])) {
          rowData.push({
            value: row.partnershipEndDate
              ? moment(row.partnershipEndDate).format('DD/MM/yyyy')
              : '-',
            label: columnNames[7],
          });
        }
        if (selectedColumns.includes(columnNames[8])) {
          rowData.push({
            value: row.lastTradeDate
              ? moment(row.lastTradeDate).format('DD/MM/yyyy')
              : '-',
            label: columnNames[8],
          });
        }
        if (selectedColumns.includes(columnNames[9])) {
          rowData.push({
            value: row.leverage || row.leverage === 0 ? row.leverage : '-',
            label: columnNames[9],
          });
        }
        if (selectedColumns.includes(columnNames[10])) {
          rowData.push({
            value:
              row.volume || row.volume === '.00'
                ? Number(row.volume).toFixed(2)
                : '-',
            label: columnNames[10],
          });
        }
        if (selectedColumns.includes(columnNames[11])) {
          rowData.push({
            value:
              row.volumeLots || row.volumeLots === '.00'
                ? Number(row.volumeLots).toFixed(2)
                : '-',
            label: columnNames[11],
          });
        }
        if (selectedColumns.includes(columnNames[12])) {
          rowData.push({
            value:
              row.closedPositions || row.closedPositions === 0
                ? row.closedPositions
                : '-',
            label: columnNames[12],
          });
        }
        if (selectedColumns.includes(columnNames[13])) {
          rowData.push({
            value:
              row.deposits || row.deposits === '.00'
                ? Number(row.deposits).toFixed(2)
                : '-',
            label: columnNames[13],
          });
        }
        if (selectedColumns.includes(columnNames[14])) {
          rowData.push({
            value:
              row.withDrawals || row.withDrawals === '.00'
                ? Math.abs(Number(row.withDrawals))
                : '-',
            label: columnNames[14],
          });
        }
        if (selectedColumns.includes(columnNames[15])) {
          rowData.push({
            value:
              row.netDeposits || row.netDeposits === '.00'
                ? Number(row.netDeposits).toFixed(2)
                : '-',
            label: columnNames[15],
          });
        }
        if (selectedColumns.includes(columnNames[16])) {
          rowData.push({
            value:
              row.realEquity || row.realEquity === '.00'
                ? Number(row.realEquity).toFixed(2)
                : '-',
            label: columnNames[16],
          });
        }

        result.push(rowData);
      });

      return result;
    },
    [columnNames, selectedColumns],
  );

  const renderGroupedRows = useCallback(
    (array: any[]): any[] => {
      const result: any[] = [];

      array.forEach((row: any) => {
        let subRows: any[] = [];
        subRows = renderRows(row.subRows);

        let groupByValue = '';
        let groupByLabel = '';

        if (groupByOption === 'partnershipCode') {
          groupByLabel = 'partnership';
          groupByValue = row.partnershipCode;
        }

        if (groupByOption === 'clientCountry') {
          groupByLabel = 'country';
          groupByValue = getTranslation(translation, row.clientCountry);
        }

        if (groupByOption === 'currency') {
          groupByLabel = 'currency';
          groupByValue = row.currency;
        }

        result.push({
          groupByLabel,
          groupByValue,
          rows: subRows,
        });
      });

      return result;
    },
    [renderRows, groupByOption],
  );

  const checkGroupByAndRenderRows = useCallback((): any[] => {
    let result: any[] = [];

    if (groupByOption) {
      const grouped = mapValues(groupBy(rows, groupByOption));

      const resultArray = Object.keys(grouped).map((index) => ({
        partnershipCode: groupByOption === 'partnershipCode' ? index : '',
        clientCountry: groupByOption === 'clientCountry' ? index : '',
        currency: groupByOption === 'currency' ? index : '',
        subRows: grouped[index],
      }));
      result = renderGroupedRows(resultArray);
    } else {
      result = renderRows(rows);
    }

    return result;
  }, [rows, groupByOption, renderRows, renderGroupedRows]);

  return (
    <MobileTableView
      list={checkGroupByAndRenderRows()}
      grouped={groupByOption !== undefined}
      hasNextButton={false}
    />
  );
};
