import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    success: {
      backgroundColor: theme.palette.frequent.greenButton,

      '& svg': {
        marginRight: 11,
      },

      '& svg path': {
        fill: theme.palette.common.white,
      },
    },
    error: {
      backgroundColor: theme.palette.frequent.carminePink,

      '& svg': {
        marginRight: 11,
      },
    },
    warning: {
      '& svg': {
        marginRight: 11,
      },
    },
    info: {
      '& svg path': {
        fill: theme.palette.common.white,
      },

      '& svg': {
        marginRight: 11,
      },
    },
  }),
);
