/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC, useCallback, useEffect, useMemo, useState
} from 'react';
import { Button, Grid, useTheme } from '@material-ui/core';
import { cloneDeep, groupBy } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { usePrevious } from 'src/hooks/usePrevious';
import { useStyles } from '../styles';
import { useCommonStyles } from '../../../../styles';
import { useApiError, useLoading } from '../../../../providers';
import { getHighlightData } from '../../IBDashboardService';
import { HighlightTypes } from '../../IBDashboardModels';
import { PATH_COMMISSIONS_BY_CLIENT, formatNumber, PATH_ALL_CLIENTS_AND_TRADING, getTranslation } from '../../../../utils';
import { GraphArea, YearMonthList, TitlePanel } from './components';

  interface Props {
    partnership: string;
    currency?: string;
    translation: any
  }

const order = { Profit: 1, Loss: 2 };

export const ProfitLossPanel: FC<Props> = ({
  currency, partnership, translation
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonClasses = useCommonStyles();
  const currentDate = useMemo(() => new Date(), []);
  const [fromDate, setFromDate] = useState<Date>(moment().startOf('year').toDate());
  const [toDate, setToDate] = useState<Date>(currentDate);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('Year');
  const [tableData, setTableData] = useState<any>([]);
  const [selectedYear, setSelectedYear] = useState<any>();
  const [chartData, setChartData] = useState<any>([]);
  const [yearlyChartData, setYearlyChartData] = useState<any>([]);
  const [monthlyChartData, setMonthlyChartData] = useState<any>([]);
  const checkboxes = useMemo(() => [translation.text_1603, translation.text_1604], [translation]);
  const previousCheckboxes = usePrevious(checkboxes);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([translation.text_1603, translation.text_1604]);
  const [increasedProfit, setIncreasedProfit] = useState<number | undefined>();
  const [decreasedProfit, setDecreasedProfit] = useState<number | undefined>();
  const [sumProfit, setSumProfit] = useState<number>(0);
  const [increasedLoss, setIncreasedLoss] = useState<number | undefined>();
  const [decreasedLoss, setDecreasedLoss] = useState<number | undefined>();
  const [sumLoss, setSumLoss] = useState<number>(0);
  const { addError } = useApiError();
  const { setIsLoading } = useLoading();
  const [colors, setColors] = useState<any>([theme.palette.lineGraph.first, theme.palette.lineGraph.second]);

  const modifyData = useCallback((values: any[]) => {
    const profitData: any = [];
    const profitMonthYearArr: any = [];
    const lossData: any = [];
    const lossMonthYearArr: any = [];
    if (values.find((item) => item.highlightType === 'Profit')) {
      const currentMonth = currentDate.getMonth() + 1;
      const profit = values.find((item) => item.highlightType === 'Profit');
      const data = profit ? cloneDeep(profit.yearData) : [];
      data.sort((a: any, b: any) => b.period.localeCompare(a.period));

      const monthData = profit ? cloneDeep(profit.monthData) : [];

      monthData.forEach((item: any) => {
        const arr = item.period.split('-');
        let itemIncreased = 0;
        let itemDecreased = 0;

        if (item.increased < 0) {
          itemDecreased = Math.abs(item.increased);
        } else {
          itemIncreased = item.increased;
        }

        if (currentDate.getFullYear().toString() === arr[0] && currentMonth.toString() === arr[1]) {
          setIncreasedProfit(itemIncreased);
          setDecreasedProfit(itemDecreased);
        }

        const newObj = {
          year: arr[0],
          month: arr[1],
          period: item.period,
          profitAmount: item.amount,
          profitIncreased: itemIncreased,
          profitDecreased: itemDecreased
        };

        profitMonthYearArr.push(newObj);
      });

      data.forEach((item: any) => {
        let itemIncreased = 0;
        let itemDecreased = 0;

        if (item.increased < 0) {
          itemDecreased = Math.abs(item.increased);
        } else {
          itemIncreased = item.increased;
        }
        item.decreased = itemDecreased;
        item.increased = itemIncreased;

        const newObj = {
          period: item.period,
          profitAmount: item.amount,
          profitIncreased: itemIncreased,
          profitDecreased: itemDecreased,
        };

        profitData.push(newObj);
      });

      let result = 0;
      data.forEach((item: any) => {
        result += item.amount;
      });

      if (result !== 0) {
        if (sumProfit !== 0) {
          if (sumProfit < result) {
            setIncreasedProfit(Math.abs(Math.round((((result - sumProfit) / sumProfit) * 100))));
            setDecreasedProfit(0);
          } else {
            setDecreasedProfit(Math.abs(Math.round((((sumProfit - result) / sumProfit) * 100))));
            setIncreasedProfit(0);
          }
        }
        setSumProfit(result);
      }
    } if (values.find((item) => item.highlightType === 'Loss')) {
      const currentMonth = currentDate.getMonth() + 1;
      const loss = values.find((item) => item.highlightType === 'Loss');
      const data = loss ? cloneDeep(loss.yearData) : [];
      data.sort((a: any, b: any) => b.period.localeCompare(a.period));

      const monthData = loss ? cloneDeep(loss.monthData) : [];

      monthData.forEach((item: any) => {
        const arr = item.period.split('-');
        let itemIncreased = 0;
        let itemDecreased = 0;

        if (item.increased < 0) {
          itemDecreased = Math.abs(item.increased);
        } else {
          itemIncreased = item.increased;
        }

        if (currentDate.getFullYear().toString() === arr[0] && currentMonth.toString() === arr[1]) {
          setIncreasedLoss(itemIncreased);
          setDecreasedLoss(itemDecreased);
        }

        const newObj = {
          year: arr[0],
          month: arr[1],
          period: item.period,
          lossAmount: item.amount,
          lossIncreased: itemIncreased,
          lossDecreased: itemDecreased
        };

        lossMonthYearArr.push(newObj);
      });

      data.forEach((item: any) => {
        let itemIncreased = 0;
        let itemDecreased = 0;

        if (item.increased < 0) {
          itemDecreased = Math.abs(item.increased);
        } else {
          itemIncreased = item.increased;
        }
        item.decreased = itemDecreased;
        item.increased = itemIncreased;

        const newObj = {
          period: item.period,
          lossAmount: item.amount,
          lossIncreased: itemIncreased,
          lossDecreased: itemDecreased,
        };

        lossData.push(newObj);
      });

      let result = 0;
      data.forEach((item: any) => {
        item.amount = Math.abs(item.amount);
        result += item.amount;
      });

      if (result !== 0) {
        if (sumLoss !== 0) {
          if (sumProfit < result) {
            setIncreasedLoss(Math.abs(Math.round((((result - sumLoss) / sumLoss) * 100))));
            setDecreasedLoss(0);
          } else {
            setDecreasedLoss(Math.abs(Math.round((((sumLoss - result) / sumLoss) * 100))));
            setIncreasedLoss(0);
          }
        }
        setSumLoss(result);
      }
    }

    const profitLoss = profitData.map((item: any, i: number) => ({ ...item, ...lossData[i] }));
    const profitLossMonth = profitMonthYearArr.map((item: any, i: number) => ({ ...item, ...lossMonthYearArr[i] }));
    const grouped = groupBy(profitLossMonth, 'year');
    profitLoss.map((obj: any) => {
      if (grouped[obj.period]) {
        obj.monthData = grouped[obj.period];
      }
      return obj;
    });

    setTableData(profitLoss);
  }, [currentDate, sumProfit, sumLoss]);

  const modifyChartData = useCallback((values: any[], selected: string[]) => {
    const yearlyChart: any = [];
    const monthlyChart: any = [];

    values.forEach((item: any) => {
      if (selected.filter((s) => s === getTranslation(translation, item.highlightType)).length > 0) {
        if (item.highlightType === 'Loss') {
          item.yearData.map((year: any) => year.amount = Math.abs(year.amount));
        }
        yearlyChart.push({
          id: item.highlightType,
          data: item.yearData
        });
      }
    });

    values.forEach((item: any) => {
      if (selected.filter((s) => s === getTranslation(translation, item.highlightType)).length > 0) {
        if (item.highlightType === 'Loss') {
          item.monthData.map((month: any) => month.amount = Math.abs(month.amount));
        }
        monthlyChart.push({
          id: item.highlightType,
          data: item.monthData
        });
      }
    });

    yearlyChart.sort((a: any, b: any) => (order as any)[a.id] - (order as any)[b.id]);
    monthlyChart.sort((a: any, b: any) => (order as any)[a.id] - (order as any)[b.id]);

    setYearlyChartData(yearlyChart);
    setMonthlyChartData(monthlyChart);
    setChartData(selectedPeriod === 'Year' ? cloneDeep(yearlyChart) : cloneDeep(monthlyChart));
  }, [translation, selectedPeriod]);

  const getHighLightData = useCallback(async (partnershipCode: string, from: Date, to: Date, selected: string[]) => {
    setIsLoading(true);
    try {
      const data: any = [];
      await Promise.all(checkboxes.map(async (checkbox) => {
        const highlightType = checkbox === translation.text_1603 ? HighlightTypes.Profit : HighlightTypes.Loss;
        const response = await getHighlightData(from, to, partnershipCode === 'All' ? '' : partnershipCode, highlightType);
        data.push(response.data);
      }));
      modifyData(data);
      modifyChartData(data, selected);
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  }, [addError, modifyData, translation]);

  useEffect(() => {
    if (fromDate <= toDate && selectedCheckboxes.length > 0) {
      (async () => {
        await getHighLightData(partnership, fromDate, toDate, selectedCheckboxes);
      })();
    }
  }, [partnership, fromDate, toDate, selectedCheckboxes, currency]);

  const handleSelectPeriod = useCallback((year: string, data: any) => {
    if (selectedYear !== year) {
      setSelectedYear(year);
      setSelectedPeriod('Month');
      if (selectedCheckboxes.length > 0) {
        const newChartData: any = [];
        cloneDeep(monthlyChartData).forEach((item: any) => {
          const isSelected = selectedCheckboxes.filter((checkbox) => checkbox === getTranslation(translation, item.id)).length > 0;
          if (isSelected) {
            const monthData: any = [];
            item.data.forEach((month: any) => {
              const arr = month.period.split('-');
              if (arr[0] === year) {
                monthData.push(month);
              }
            });
            newChartData.push({ id: item.id, data: monthData });
          }
        });
        setChartData(newChartData);
      } else {
        setChartData(cloneDeep([{
          id: 'Profit',
          data: data || []
        }]));
      }
    } else {
      setSelectedYear('');
      setSelectedPeriod('Year');
      setChartData(cloneDeep(yearlyChartData));
    }
  }, [selectedYear, yearlyChartData, monthlyChartData, selectedCheckboxes]);

  const handlePeriodSelect = useCallback((period: string) => {
    if (period === 'Year') {
      setChartData(cloneDeep(yearlyChartData));
    } else {
      setChartData(cloneDeep(monthlyChartData));
    }
    setSelectedPeriod(period);
  }, [yearlyChartData, monthlyChartData]);

  const handleCheckboxesChanges = useCallback((selected: string[]) => {
    setSelectedYear('');
    setSelectedPeriod('Year');
    const graphColors: string[] = [];
    selected.forEach((s) => {
      if (s === checkboxes[0]) {
        graphColors[0] = theme.palette.lineGraph.first;
      }
      if (s === checkboxes[1]) {
        graphColors[1] = theme.palette.lineGraph.second;
      }
    });
    setColors(graphColors.filter((value) => value));
    setSelectedCheckboxes(selected);
  }, [checkboxes, theme.palette.lineGraph.first, theme.palette.lineGraph.second]);

  useEffect(() => {
    if (translation.text_1603 && translation.text_1604) {
      setSelectedCheckboxes((prevValues) => {
        const prevValuesIndexes = prevValues.map((value) => previousCheckboxes?.findIndex((item) => item === value));

        if (prevValuesIndexes.length === 0) {
          return [translation.text_1603, translation.text_1604];
        }

        const newValues = [];

        if (prevValuesIndexes.includes(0)) {
          newValues.push(translation.text_1603);
        }

        if (prevValuesIndexes.includes(1)) {
          newValues.push(translation.text_1604);
        }

        return newValues;
      });
    }
  }, [previousCheckboxes, translation.text_1603, translation.text_1604]);

  return (
    <>
      <TitlePanel
        subTitle={translation.text_6329}
        partnership={partnership}
        profit={formatNumber(sumProfit, currency ?? 'USD')}
        loss={formatNumber(sumLoss, currency ?? 'USD')}
        increasedProfit={increasedProfit}
        decreasedProfit={decreasedProfit}
        increasedLoss={increasedLoss}
        decreasedLoss={decreasedLoss}
        fromDate={fromDate}
        toDate={toDate}
        translation={translation}
        handleFromDateChange={setFromDate}
        handleToDateChange={setToDate}
      />
      <Grid container direction="row" className={classes.content} justify="space-between">
        {tableData.length > 0 ? (
          <>
            <Grid item xs={12} className={classes.mobileView}>
              <GraphArea
                currency={currency}
                checkboxes={checkboxes}
                chartData={chartData}
                colors={colors}
                selectedPeriod={selectedPeriod}
                handlePeriodSelect={handlePeriodSelect}
                selectedCheckboxes={selectedCheckboxes}
                setSelectedCheckboxes={handleCheckboxesChanges}
                translation={translation}
              />
              <div style={{ marginTop: 31 }} />
              <YearMonthList
                translation={translation}
                selectedYear={selectedYear}
                tableData={tableData}
                currency={currency}
                handleSelectPeriod={handleSelectPeriod}
              />
            </Grid>
            <Grid item xs={4} className={classes.desktopView}>
              <YearMonthList
                translation={translation}
                selectedYear={selectedYear}
                tableData={tableData}
                currency={currency}
                handleSelectPeriod={handleSelectPeriod}
              />
            </Grid>
            <Grid item xs={7} className={classes.desktopView}>
              <GraphArea
                currency={currency}
                checkboxes={checkboxes}
                chartData={chartData}
                colors={colors}
                selectedPeriod={selectedPeriod}
                handlePeriodSelect={handlePeriodSelect}
                selectedCheckboxes={selectedCheckboxes}
                setSelectedCheckboxes={handleCheckboxesChanges}
                translation={translation}
              />
            </Grid>
          </>
        ) : (
          <Grid container direction="column" alignContent="center" alignItems="center" className={classes.emptyMessage}>
            <Grid item>{translation.text_6340}</Grid>
          </Grid>
        )}
        <Grid container className={classes.buttons}>
          <Grid item className={classes.firstButton}>
            <Button
              variant="contained"
              color="primary"
              className={`${commonClasses.linkButtons} ${classes.button}`}
              component={Link}
              to={PATH_COMMISSIONS_BY_CLIENT}
            >{translation.text_6341}
            </Button>
          </Grid>
          <Grid item className={classes.secondButton}>
            <Button
              variant="contained"
              color="primary"
              className={`${commonClasses.linkButtons} ${classes.button}`}
              component={Link}
              to={PATH_ALL_CLIENTS_AND_TRADING}
            >{translation.text_6342}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
