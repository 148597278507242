import React, { createContext, FC, useState } from 'react';
import { errorInitFunction } from '../../utils';

export const ApiErrorContext = createContext<{
  error: any
  addError:(message: string) => void
  removeError: () => void}>({
      error: null,
      addError: errorInitFunction,
      removeError: errorInitFunction
    });

export const ApiErrorProvider: FC = ({ children }) => {
  const [error, setError] = useState<any>(null);

  const removeError = () => {
    setError(null);
  };

  const addError = (message: string) => {
    setError(message);
  };

  return (
    <ApiErrorContext.Provider value={{
      error,
      addError,
      removeError
    }}
    >{children}
    </ApiErrorContext.Provider>
  );
};
