import React, { FC } from 'react';
import { useStyles } from '../styles';
import { ReactComponent as ClientLinks } from '../../../assets/QuickLinks/ClientLinks.svg';

export const ClientLinksIcon: FC = () => {
  const classes = useStyles();
  return (
    <ClientLinks className={classes.icon} />
  );
};
