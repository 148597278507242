/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC, useCallback, useEffect, useState
} from 'react';
import { List, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { useStyles } from './styles';
import { useAppData } from '../../providers';
import { MainMenuItem } from './components';
import {
  PATH_ALL_CLIENTS_AND_TRADING,
  PATH_CLIENTS_LIST,
  PATH_CLOSED_POSITIONS,
  PATH_COMMISSIONS_BY_CLIENT,
  PATH_IB_DASHBOARD,
  PATH_MASTER_IB_COMMISSION,
  PATH_PARTNERS_HIERARCHY,
  PATH_REPORTS_LIST,
  PATH_TRADING_SUMMARY,
  PATH_PARTNERS_REPRESENTATIVES
} from '../../utils';
import {
  HierarchyIcon, HighlightsIcon, ReportsIcon, ClientPortalIcon
} from '../CustomIcons';

interface MenuItem {
  name: string;
  Icon?: OverridableComponent<SvgIconTypeMap>;
  link?: string;
  items?: MenuItem[];
  subItemsLinks?: string[]
}

export const MainMenu: FC = () => {
  const classes = useStyles();
  const { userData, translations } = useAppData();
  const [menuItems, setMainMenuItems] = useState<MenuItem[]>([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');
  const [translation, setTranslation] = useState<any>([]);
  const [mainMenu, setMainMenu] = useState<any>();

  const customSort = (array: any[], name: string) => array.filter((x) => x.name !== name).concat(array.find((x) => x.name === name));

  const setMenuCategories = useCallback((routeArray: any[]): any[] => {
    const seen: any = [];

    routeArray.filter((n) => n.groupID !== null && seen.indexOf(n.groupName) === -1 && seen.push(n.groupName));

    return seen;
  }, []);

  const filteredSideMenuData = useCallback((routeArray: any[]) => {
    let result: any = [];

    if (routeArray !== null && routeArray.length > 0) {
      const homeRoute = routeArray.filter((route) => route.groupID === null);
      homeRoute.forEach((route) => {
        const menuItem = mainMenu[route.route];
        if (menuItem) {
          const data: MenuItem = {
            name: menuItem.name,
            link: menuItem.link,
            Icon: menuItem.Icon,
            subItemsLinks: menuItem.subItemsLinks
          };
          result.push(data);
        }
      });
      const menuCategories = setMenuCategories(routeArray);

      menuCategories.forEach((category: any) => {
        const categoryItems = routeArray.filter((item: any) => item.groupName === category);
        const subItems: any = [];

        categoryItems.forEach((item) => {
          const itemName = Object.keys(mainMenu).find((key) => key.indexOf(item.route) > -1);
          const menuItem = itemName ? mainMenu[itemName] : null;
          if (menuItem) {
            const data: MenuItem = {
              name: menuItem.name,
              link: menuItem.link,
            };
            subItems.push(data);
          }
        });

        const menuItem = mainMenu[category];
        if (menuItem && menuItem.link) {
          const data: MenuItem = {
            name: menuItem.name,
            Icon: menuItem.Icon,
            link: menuItem.link,
            subItemsLinks: menuItem.subItemsLinks
          };

          result.push(data);
        } else {
          const data: MenuItem = {
            name: menuItem.name,
            Icon: menuItem.Icon,
            items: subItems
          };

          result.push(data);
        }
      });
    }

    result = result.find((item: any) => item.name === 'Reports') ? customSort(result, 'Reports') : result;
    result = result.find((item: any) => item.name === 'Representatives') ? customSort(result, 'Representatives') : result;
    result = result.find((item: any) => item.name === 'Settings') ? customSort(result, 'Settings') : result;

    // Making sure we only return unique routes, since each route can be duplicated in different roles.
    for (let idxMenuRoot = 0; idxMenuRoot < result.length; idxMenuRoot++) {
      const rootMenu = result[idxMenuRoot];
      const unique: any = [];

      if (rootMenu.items) {
        rootMenu.items.forEach((el: any) => {
          let found = false;
          unique.forEach((el2: any) => {
            if (el2.name === el.name) found = true;
          });

          if (!found) unique.push(el);
        });

        rootMenu.items = unique;
      }
    }
    setMainMenuItems(result);
  }, [setMenuCategories, mainMenu]);

  useEffect(() => {
    const userLoginData = JSON.parse(userData);
    const routeArray: any = [];

    if (userLoginData.accounts != null && userLoginData.accounts.length > 0 && mainMenu !== undefined) {
      // All accounts
      const accountCollection = userLoginData.accounts;

      // Loop each account
      accountCollection.forEach((account: any) => {
        const { routeCollection } = account.role;
        const routeCollectionArray = routeCollection.routeCollectionHasRoute;

        routeCollectionArray.forEach((routeIndex: any) => {
          const newArrayItem = {
            route: routeIndex.route,
            groupName: (routeIndex.module) ? routeIndex.module.moduleId : null,
            groupID: (routeIndex.module) ? routeIndex.module.moduleId : null
          };

          routeArray.push(newArrayItem);
        });

        const newArrayItem = { // TODO: temporary, needs backend changes for correct filtering
          route: 'Settings',
          groupName: null,
          groupID: null
        };
        routeArray.push(newArrayItem);
      });
    }
    filteredSideMenuData(routeArray);
  }, [userData, filteredSideMenuData]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    setMenuCategories([]);
    setMainMenuItems([]);
    const MainMenuItemsObj: any = {
      partnerDashboard: {
        name: translation.text_6661,
        link: PATH_IB_DASHBOARD,
        Icon: HighlightsIcon,
      },
      Hierarchy: {
        name: translation.text_6662,
        Icon: HierarchyIcon,
        link: PATH_PARTNERS_HIERARCHY
      },
      Reports: {
        name: translation.text_6663,
        Icon: ReportsIcon,
        link: PATH_REPORTS_LIST,
        subItemsLinks: [
          PATH_COMMISSIONS_BY_CLIENT,
          PATH_CLIENTS_LIST,
          PATH_ALL_CLIENTS_AND_TRADING,
          PATH_CLOSED_POSITIONS,
          PATH_MASTER_IB_COMMISSION,
          PATH_TRADING_SUMMARY
        ]
      },
      Representatives: {
        name: translation.text_6664,
        Icon: ClientPortalIcon,
        link: PATH_PARTNERS_REPRESENTATIVES
      },
    };

    setMainMenu(MainMenuItemsObj);
  }, [translation]);

  return (
    <List component="nav" className={classes.mainMenu} disablePadding>
      {menuItems.map((item) => (
        <MainMenuItem key={Math.random()} {...item} selectedItem={selectedMenuItem} handleSelectedItem={setSelectedMenuItem} />
      ))}
    </List>
  );
};
