/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { useStyles } from './styles';
import { useApiError, useAppData, useLoading } from '../../../providers';
import { ExportButtons, ReportsGeneralToolbar } from '../../../components';
import { ClosedPositionItem } from './ClosedPositionsModel';
import { getClosedPositions } from './ClosedPositionsService';

export const ClosedPositions: FC = () => {
  const currentDate = useMemo(() => new Date(), []);
  const classes = useStyles();
  const { userData, translations } = useAppData();
  const { addError } = useApiError();
  const { setIsLoading } = useLoading();
  const [fromDate, setFromDate] = useState<Date>(
    new Date(
      `${
        currentDate.getMonth() + 1
      } 1 ${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`,
    ),
  );
  const [toDate, setToDate] = useState<Date>(currentDate);
  const [reportData, setReportData] = useState<ClosedPositionItem[]>([]);
  const [isReportReady, setIsReportReady] = useState<boolean>(false);
  const [translation, setTranslation] = useState<any>([]);
  const columnNames = useMemo(
    () => [
      translation.text_6584,
      translation.text_6585,
      translation.text_6586,
      translation.text_6587,
      translation.text_6589,
      translation.text_6588,
      translation.text_6590,
      translation.text_6591,
      translation.text_6592,
      translation.text_6593,
      translation.text_6594,
      translation.text_6595,
    ],
    [translation],
  );

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const getReportData = useCallback(
    async (email: string) => {
      setIsLoading(true);
      try {
        const response = await getClosedPositions(
          email,
          moment(fromDate).format('YYYY-MM-DD'),
          moment(toDate).format('YYYY-MM-DD'),
        );
        setReportData(response.data.reportClosedPositions);
        setIsReportReady(true);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    },
    [addError, fromDate, toDate],
  );

  const handleNewReportRequest = useCallback(async () => {
    setIsReportReady(false);
    const userEmail: string = JSON.parse(userData)?.userEmail;
    await getReportData(userEmail);
  }, [userData, getReportData]);

  const renderTableData = useCallback((): string[][] => {
    const rows: string[][] = [];
    reportData.forEach((row) => {
      const tableRow: string[] = [
        row.partnershipCode ? row.partnershipCode : '-',
        row.openTime ? moment(row.openTime).format('DD/MM/yyyy HH:mm') : '-',
        row.positionType ? row.positionType : '-',
        row.symbol ? row.symbol : '-',
        row.closeTime ? moment(row.closeTime).format('DD/MM/yyyy HH:mm') : '-',
        row.openPrice || row.openPrice === 0 ? row.openPrice.toFixed(2) : '-',
        row.closePrice || row.closePrice === 0
          ? row.closePrice.toFixed(2)
          : '-',
        row.volumeUSD || row.volumeUSD === 0 ? row.volumeUSD.toFixed(2) : '-',
        row.broker ? row.broker : '-',
        row.regulator ? row.regulator : '-',
        row.accountType ? row.accountType : '-',
        row.login ? row.login : '-',
      ];
      rows.push(tableRow);
    });

    return rows;
  }, [reportData]);

  const changeFromDate = useCallback((newValue) => {
    setFromDate(newValue);
  }, []);

  const changeToDate = useCallback((newValue) => {
    setToDate(newValue);
  }, []);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        container
        direction="row"
        spacing={1}
        alignContent="flex-start"
        alignItems="center"
        className={classes.title}
      >
        <Grid item xs={9}>
          <Typography className={classes.title}>
            {translation.text_6425}
          </Typography>
        </Grid>
        {isReportReady && (
          <Grid item xs={3} className={classes.desktop}>
            <Grid container direction="row-reverse" spacing={1}>
              <ExportButtons
                exportFileName={translation.text_6425}
                columns={columnNames}
                exportTableData={renderTableData()}
                fontSize={6}
                isEnabled={isReportReady}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Paper className={classes.paper}>
        <Grid container direction="column" className={classes.root}>
          <ReportsGeneralToolbar
            fromDate={fromDate}
            toDate={toDate}
            handleFromDateChange={changeFromDate}
            handleToDateChange={changeToDate}
            showButton
            handleButtonClick={handleNewReportRequest}
          />
          <Grid item xs={12}>
            {isReportReady ? (
              <Alert
                icon={false}
                variant="outlined"
                severity="success"
                className={classes.reportRequested}
              >
                {translation.text_6426}
              </Alert>
            ) : (
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.emptyReportMessageContainer}
              >
                <Grid item className={classes.boldText}>
                  {translation.text_6427}
                </Grid>
                <Grid item>{translation.text_6428}</Grid>
              </Grid>
            )}
          </Grid>
          {isReportReady && (
            <Grid item xs={12} className={classes.mobile}>
                <ExportButtons
                  exportFileName={translation.text_6425}
                  columns={columnNames}
                  exportTableData={renderTableData()}
                  fontSize={6}
                  className={classes.exportBtn}
                  isEnabled={isReportReady}
                />
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};
