import { TableCell, TableRow } from '@material-ui/core';
import React, { FC, useCallback, useMemo } from 'react';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { ReportData } from '../../TradingSummarytModel';
import { CustomTable } from '../../../../../components';
import { useCommonStyles } from '../../../../../styles';
import { useStyles } from '../../styles';

interface Props {
  rows: ReportData[];
  translation: any;
  reportColumns: string[];
  selectedColumns: string[];
}

export const TradingReports: FC<Props> = ({
  rows,
  reportColumns,
  selectedColumns,
  translation,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const countTotals = useCallback((data: any[]): any => {
    let tradesSum = 0;
    let tradingAccountsTradedSum = 0;
    let depositsSum = 0;
    let withdrawalsSum = 0;
    let valueSum = 0;
    let lotsSum = 0;
    let volumeLotsSum = 0;
    let fundedAccounts = 0;
    let netDeposits = 0;

    data.forEach((row: any) => {
      tradesSum += row.trades ? row.trades : 0;
      tradingAccountsTradedSum += row.tradingAccountsTraded
        ? row.tradingAccountsTraded
        : 0;
      depositsSum += row.depositsUSD ? Number(row.depositsUSD) : 0;
      withdrawalsSum += row.withdrawalsUSD ? Number(row.withdrawalsUSD) : 0;
      valueSum += row.valueUSD ? Number(row.valueUSD) : 0;
      lotsSum += row.lots ? row.lots : 0;
      volumeLotsSum += row.volumeLots ? row.volumeLots : 0;
      fundedAccounts += row.tradingAccountsFunded
        ? row.tradingAccountsFunded
        : 0;
      netDeposits += row.netDepositsUSD ? Number(row.netDepositsUSD) : 0;
    });
    return {
      tradesSum,
      tradingAccountsTradedSum,
      depositsSum,
      withdrawalsSum,
      valueSum,
      lotsSum,
      volumeLotsSum,
      fundedAccounts,
      netDeposits,
    };
  }, []);

  const renderTotalRow = useMemo((): any => {
    const totals = countTotals(rows);
    return (
      <TableRow key={`totals${uuid()}`} className={classes.tableTotals}>
        <TableCell align="right">{translation.text_951}</TableCell>
        {selectedColumns.includes(reportColumns[1]) && (
          <TableCell align="center">{totals.tradesSum.toFixed(2)}</TableCell>
        )}
        {selectedColumns.includes(reportColumns[2]) && (
          <TableCell align="center">
            {totals.tradingAccountsTradedSum.toFixed(2)}
          </TableCell>
        )}
        {selectedColumns.includes(reportColumns[3]) && (
          <TableCell align="center">{totals.depositsSum.toFixed(2)}</TableCell>
        )}
        {selectedColumns.includes(reportColumns[4]) && (
          <TableCell align="center">
            {Math.abs(totals.withdrawalsSum).toFixed(2)}
          </TableCell>
        )}
        {selectedColumns.includes(reportColumns[5]) && (
          <TableCell align="center">{totals.valueSum.toFixed(2)}</TableCell>
        )}
        {selectedColumns.includes(reportColumns[6]) && (
          <TableCell align="center">{totals.lotsSum.toFixed(2)}</TableCell>
        )}
        {selectedColumns.includes(reportColumns[7]) && (
          <TableCell align="center">
            {totals.volumeLotsSum.toFixed(2)}
          </TableCell>
        )}
        {selectedColumns.includes(reportColumns[8]) && <TableCell />}
        {selectedColumns.includes(reportColumns[9]) && (
          <TableCell align="center">
            {totals.fundedAccounts.toFixed(2)}
          </TableCell>
        )}
        {selectedColumns.includes(reportColumns[10]) && (
          <TableCell align="center">{totals.netDeposits.toFixed(2)}</TableCell>
        )}
      </TableRow>
    );
  }, [rows, countTotals, classes, selectedColumns, reportColumns]);

  const renderHeader = useMemo((): any[] => {
    const result: any[] = [];
    reportColumns.forEach((columnName) => {
      const isSelected = selectedColumns.includes(columnName);
      if (isSelected) {
        result.push(
          <TableCell
            key={columnName}
            align={columnName === reportColumns[0] ? 'left' : 'center'}
          >
            {columnName}
          </TableCell>,
        );
      } else if (columnName === reportColumns[0]) {
        result.push(<TableCell key={Math.random()} />);
      }
    });
    return result;
  }, [selectedColumns, reportColumns]);

  const renderRows = useMemo((): any[] => {
    const result: any[] = [];
    rows.forEach((row) => {
      result.push(
        <TableRow className={commonClasses.tableRow} key={Math.random()}>
          {selectedColumns.includes(reportColumns[0]) && (
            <TableCell align="left">
              {row.date ? moment(row.date).format('DD/MM/YYYY') : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[1]) && (
            <TableCell align="center">
              {row.trades ? row.trades.toFixed(2) : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[2]) && (
            <TableCell align="center">
              {row.tradingAccountsTraded
                ? row.tradingAccountsTraded.toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[3]) && (
            <TableCell align="center">
              {row.depositsUSD ? Number(row.depositsUSD).toFixed(2) : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[4]) && (
            <TableCell align="center">
              {row.withdrawalsUSD
                ? Math.abs(Number(row.withdrawalsUSD)).toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[5]) && (
            <TableCell align="center">
              {row.valueUSD ? Number(row.valueUSD).toFixed(2) : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[6]) && (
            <TableCell align="center">
              {row.lots ? row.lots.toFixed(2) : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[7]) && (
            <TableCell align="center">
              {row.volumeLots ? row.volumeLots.toFixed(2) : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[8]) && (
            <TableCell align="center">
              {row.emails ? row.emails : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[9]) && (
            <TableCell align="center">
              {row.tradingAccountsFunded ? row.tradingAccountsFunded : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[10]) && (
            <TableCell align="center">
              {row.netDepositsUSD ? Number(row.netDepositsUSD).toFixed(2) : '-'}
            </TableCell>
          )}
        </TableRow>,
      );
    });
    return result;
  }, [rows, commonClasses, selectedColumns, reportColumns]);

  return (
    <CustomTable
      headerCells={renderHeader}
      totalRow={renderTotalRow}
      columnsNumber={reportColumns.length}
      rows={renderRows}
      emptyDataMessage={translation.reports_EmptyTableMessage}
    />
  );
};
