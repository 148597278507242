import React, { FC, useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, InputAdornment } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useAppData } from '../../../../../providers';
import { getLocale } from '../../../../../utils';

interface Props {
  fromDate: Date | null;
  toDate: Date | null;
  translation: any;
  handleFromDateChange?: (date: Date) => void;
  handleToDateChange?: (date: Date) => void;
}
export const DateRangeSelector: FC<Props> = ({
  fromDate,
  toDate,
  handleToDateChange,
  handleFromDateChange,
  translation,
}) => {
  const classes = useStyles();
  const { locale } = useAppData();
  const [showDateError, setShowDateError] = useState<boolean>(false);
  const [localeString, setLocaleString] = useState<string>('en');

  useEffect(() => {
    if (locale) {
      setLocaleString(locale);
    }
  }, [locale]);

  const setToDate = (newValue: Date) => {
    if (handleToDateChange) {
      if (fromDate !== null && fromDate > newValue) {
        setShowDateError(true);
      } else {
        setShowDateError(false);
      }
      handleToDateChange(newValue);
    }
  };

  const setFromDate = (newValue: Date) => {
    if (handleFromDateChange) {
      if (toDate !== null && newValue > toDate) {
        setShowDateError(true);
      } else {
        setShowDateError(false);
      }
      handleFromDateChange(newValue);
    }
  };

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={getLocale(localeString)}
    >
      <Grid item className={classes.dates}>
        {handleFromDateChange && handleToDateChange && (
          <>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <DatePicker
                  autoOk
                  className={classes.fromDate}
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  disableFuture
                  value={fromDate}
                  okLabel={translation.text_903}
                  cancelLabel={translation.text_28}
                  InputProps={{
                    margin: 'dense',
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.dateLabel}
                      >
                        {translation.text_6303}:
                      </InputAdornment>
                    ),
                  }}
                  onChange={(dateValue: any) => setFromDate(dateValue)}
                />
              </Grid>
              <Grid item>
                <DatePicker
                  autoOk
                  className={classes.toDate}
                  value={toDate}
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  okLabel={translation.text_903}
                  cancelLabel={translation.text_28}
                  onChange={(dateValue: any) => setToDate(dateValue)}
                  disableFuture
                  InputProps={{
                    margin: 'dense',
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.dateLabel}
                      >
                        {translation.text_6304}:
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            {showDateError && (
              <Grid item className={classes.errorMessage}>
                {translation.text_6305}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
