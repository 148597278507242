import React, { FC } from 'react';
import { ReactComponent as GreyLine } from '../../../assets/Dashboard/GreyLine.svg';
import { useStyles } from '../styles';
import { CustomIconProps } from '../CustomIconModels';

export const GreyLineIcon: FC<CustomIconProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <GreyLine className={`${classes.icon} ${className || ''}`} />
  );
};
