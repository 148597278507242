import React, { createContext, FC, useState } from 'react';
import { errorInitFunction } from '../../utils';

export const LoadingContext = createContext<{
    isLoading: boolean
    setIsLoading:(loading: boolean) => void
      }>({
        isLoading: false,
        setIsLoading: errorInitFunction
      });

export const LoadingProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const setIsLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  return (
    <LoadingContext.Provider value={{
      isLoading: loading,
      setIsLoading
    }}
    >{children}
    </LoadingContext.Provider>
  );
};
