import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      paddingLeft: 25,
      paddingRigth: 20,
      height: 40,
      backgroundColor: theme.palette.changeable.exportButtons,
      color: theme.palette.common.white,
      justifyContent: 'center',
      borderRadius: 20,

      '&:hover': {
        backgroundColor: theme.palette.changeable.hover,
        color: theme.palette.common.white,
      },

      '@media (max-width: 915px)': {
        width: 158,
        padding: '7px 9px',
      },
      '@media (max-width: 360px)': {
        width: 140,
        padding: '7px 9px',
      },
    },
    popupPaper: {
      borderRadius: 15,
    },
    menuItem: {
      marginLeft: 10,
      fontSize: 14,
    },
  }),
);
