import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      marginTop: 10,
      padding: 15,
      minHeight: '100vh',
      width: '100%',

      '@media (max-width: 768px)': {
        padding: '15px 10px',
      },
    },
    boldText: {
      fontWeight: 'bold',
    },
    emptyReportMessageContainer: {
      marginTop: 193,
    },
    title: {
      color: theme.palette.text.primary,
      textAlign: 'left',
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '1.334',
      letterSpacing: '0em',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    printButton: {
      width: '100px !important',
      marginRight: 13,
      padding: '10px 20px 10px 20px',
      backgroundColor: theme.palette.changeable.exportButtons,
      color: theme.palette.common.white,
      justifyContent: 'center',
      borderRadius: 20,

      '&:hover': {
        backgroundColor: theme.palette.changeable.hover,
        color: theme.palette.common.white,
      },

      '@media (max-width: 1023px)': {
        height: 35,
        borderRadius: 10,
        marginTop: 10,
        width: '376px !important',
      },

      '@media (max-width: 413px)': {
        width: '310px !important',
      },
    },
    reportRequested: {
      '& .MuiAlert-message': {
        width: '100%',
        padding: 0,
      },
      borderRadius: 10,
      border: `1px solid ${theme.palette.frequent.green}`,
      color: theme.palette.text.secondary,
      padding: '12px 14px 10px 13px',
      marginTop: 23,
    },
    desktop: {
      '@media (max-width: 840px)': {
        display: 'none',
      },
    },
    mobile: {
      marginTop: 32,

      '@media (min-width: 840px)': {
        display: 'none',
      },
    },
    exportBtn: {
      '@media (max-width: 1023px)': {
        height: 35,
        borderRadius: 10,
        marginTop: 10,
        width: '376px !important',
      },

      '@media (max-width: 413px)': {
        width: '310px !important',
      },
    },
  }),
);
