import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';
import { useApiError, useLoading, useAppData } from '../../../providers';
import { ReportGeneralToolbarWithFilters, ReportTitle } from '../../../components';
import { PositionsReport, reportEnColumns, OpenClosedPositionsEmailReportRequest } from './OpenClosedPositionsSummaryModel';
import { ReportTable } from './components';
import { getPositionsReport, sendEmailOpenClosedPositions } from './OpenClosedPositionsSummaryService';
import { getSelectedColumnsInEn, getTranslatedColumnsInCorrectOrder } from '../../../utils';

export const OpenClosedPositionsSummary: FC = () => {
  const currentDate = useMemo(() => new Date(), []);
  const classes = useStyles();
  const { setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const { addError } = useApiError();
  const { translations, userData, locale } = useAppData();
  const [selectedPartnerships, setSelectedPartnerships] = useState<string[]>(['All']);
  const [selectedLogins, setSelectedLogins] = useState<string[]>(['All']);
  const [fromDate, setFromDate] = useState<Date>(new Date(`${currentDate.getMonth() + 1} 1 ${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`));
  const [toDate, setToDate] = useState<Date>(currentDate);
  const [showReport, setShowReport] = useState<boolean>(false);
  const [reportData, setReportData] = useState<PositionsReport[]>([]);
  const [translation, setTranslation] = useState<any>([]);
  const columnNames = useMemo(
    () => [translation.text_6642, translation.text_6643, translation.text_6644, translation.text_6645, translation.text_6646, translation.text_6647, translation.text_6648, translation.text_6649, translation.text_6650, translation.text_6651],
    [translation],
  );
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnNames);
  const [language, setLanguage] = useState<string>();
  const [userEmail, setUserEmail] = useState<string>();

  useEffect(() => {
    if (columnNames.length === 10) {
      setSelectedColumns(columnNames.slice(0, 5));
    }
  }, [columnNames]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    const user = JSON.parse(userData);
    setLanguage(locale);
    setUserEmail(user.userEmail);
  }, [userData, locale]);

  const getReportData = useCallback(
    async (partnerships: string[], logins: string[]) => {
      setIsLoading(true);
      try {
        const response = await getPositionsReport({
          partnershipCodes: partnerships.includes('All') ? [] : partnerships,
          fromDate: moment(fromDate).format('YYYY-MM-DD'),
          toDate: moment(toDate).format('YYYY-MM-DD'),
          pageNumber: 0,
          login: logins.includes('All') ? [] : logins,
        });
        setReportData(response.data);
        setShowReport(true);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    },
    [fromDate, toDate, addError, setIsLoading],
  );

  const selectPartnershipChangeHandler = useCallback(
    async (options: any) => {
      setSelectedPartnerships(options);
      await getReportData(options, selectedLogins);
    },
    [getReportData, selectedLogins],
  );

  const selectLoginChangeHandler = useCallback(
    async (options: any) => {
      setSelectedLogins(options);
      await getReportData(selectedPartnerships, options);
    },
    [getReportData, selectedPartnerships],
  );

  const changeFromDate = useCallback((newValue) => {
    setFromDate(newValue);
    setShowReport(false);
  }, []);

  const changeToDate = useCallback((newValue) => {
    setToDate(newValue);
    setShowReport(false);
  }, []);

  const handleNewReportRequest = useCallback(async () => {
    await getReportData(selectedPartnerships, selectedLogins);
  }, [selectedPartnerships, getReportData, selectedLogins]);

  const renderTableData = useCallback((): string[][] => {
    const rows: string[][] = [];
    reportData.forEach((row) => {
      const tableRow: string[] = [
        row.partnershipCode || row.partnershipCode === '' ? row.partnershipCode : '-',
        row.login || row.login === 0 ? row.login.toString() : '-',
        row.openedVolumeUSD || row.openedVolumeUSD === 0 ? row.openedVolumeUSD.toFixed(2) : '-',
        row.openLots || row.openLots === 0 ? row.openLots.toFixed(2) : '-',
        row.openTrades || row.openTrades === 0 ? row.openTrades.toFixed(2) : '-',
        row.openPnL || row.openPnL === 0 ? row.openPnL.toFixed(2) : '-',
        row.closedVolumeUSD || row.closedVolumeUSD === 0 ? row.closedVolumeUSD.toFixed(2) : '-',
        row.closedLots || row.closedLots === 0 ? row.closedLots.toFixed(2) : '-',
        row.closedTrades || row.closedTrades === 0 ? row.closedTrades.toFixed(2) : '-',
        row.closedPnL || row.closedPnL === 0 ? row.closedPnL.toFixed(2) : '-',
      ];
      rows.push(tableRow);
    });

    return rows;
  }, [reportData]);

  const buildOpenClosedPositionsEmailReportRequest = (): OpenClosedPositionsEmailReportRequest => ({
    reportPartnershipClientsTradingRequest: {
      fromDate: moment(fromDate).format('YYYY-MM-DD'),
      toDate: moment(toDate).format('YYYY-MM-DD'),
      partnershipCodes: selectedPartnerships.includes('All') ? [] : selectedPartnerships,
      pageNumber: 1,
      login: selectedLogins.includes('All') ? [] : selectedLogins,
    },
    reportEmailRequest: {
      email: null,
      includeTotal: false,
      tableColumns: getSelectedColumnsInEn(reportEnColumns, selectedColumns, columnNames),
      translatedTableColumns: getTranslatedColumnsInCorrectOrder(selectedColumns, columnNames),
      language,
      startDate: moment(fromDate).format('YYYY-MM-DD'),
      endDate: moment(toDate).format('YYYY-MM-DD'),
    },
  });

  const sendEmail = async () => {
    const openClosedPositionsEmailReportRequest = buildOpenClosedPositionsEmailReportRequest();
    console.log(openClosedPositionsEmailReportRequest);
    try {
      await sendEmailOpenClosedPositions(openClosedPositionsEmailReportRequest);
      enqueueSnackbar(`${translation.text_1640} ${userEmail}.`, {
        variant: 'success',
      });
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <ReportTitle
        translation={translation}
        title={translation.text_6481}
        exportFileName={translation.text_6481}
        reportColumns={columnNames}
        exportTableData={renderTableData()}
        content={<ReportTable rows={reportData} selectedColumns={selectedColumns} columnNames={columnNames} translation={translation} />}
        printColumnNumber={columnNames.length}
        enableButtons={showReport}
        sendEmail={sendEmail}
      />
      <Paper className={classes.paper}>
        <ReportGeneralToolbarWithFilters
          fromDate={fromDate}
          toDate={toDate}
          columns={columnNames}
          selectedColumns={selectedColumns}
          handleFromDateChange={changeFromDate}
          handleToDateChange={changeToDate}
          handlePartnershipsChange={selectPartnershipChangeHandler}
          handleButtonClick={handleNewReportRequest}
          handleLoginsChange={selectLoginChangeHandler}
          handleSelectedColumnsChange={setSelectedColumns}
        />
        {showReport ? (
          <Grid item xs={12} className={classes.margin}>
            <ReportTable rows={reportData} selectedColumns={selectedColumns} columnNames={columnNames} translation={translation} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container direction="column" justify="center" alignItems="center" className={classes.emptyReportMessageContainer}>
              <Grid item className={classes.bold}>
                {translation.text_6482}
              </Grid>
              <Grid item>{translation.text_6483}</Grid>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};
