import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'inline-flex',
  },
  accordionContent: {
    margin: 0,
  },
  selectField: {
    borderColor: theme.palette.frequent.abalonGrey,
    borderRadius: 10,
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.frequent.davyGrey,
    },
    '& .MuiSelect-select': {
      paddingTop: 8,
      paddingBottom: 8,
    },
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  selectIconClass: {
    color: theme.palette.frequent.cadetBlue,
  },
  inputRoot: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 10,
    '&:hover, &.Mui-focused': {
      border: `1px solid ${theme.palette.common.black}`,
    },
    paddingBottom: 7,
    paddingTop: 7,
  },
  container: {
    marginTop: 10,
  },
  button: {
    height: 45,
    width: 180,
    borderRadius: 25,
    padding: '10px 20px 10px 20px',
    color: theme.palette.common.white,
  },
  generateButton: {
    backgroundColor: theme.palette.changeable.secondaryColor,
    color: theme.palette.changeable.secondaryBtnTextColor,
    '&:hover': {
      backgroundColor: theme.palette.changeable.hover,
      color: theme.palette.common.white,
    },
  },
  generateButtonContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: 'center',
  },
  generatedLinkContainer: {
    backgroundColor: theme.palette.common.white,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.frequent.abalonGrey,
    borderRadius: 10,
    paddingTop: 15,
    paddingBottom: 18,
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.frequent.greenButton,
    },
  },
  generetedLinkTittle: {
    fontWeight: 600,
    paddingTop: 8,
    paddingRight: 8,
  },
  generatedLink: {
    paddingTop: 8,
    paddingRight: 8,
  },
  generetedLinkDefaultText: {
    fontStyle: 'italic',
    color: theme.palette.frequent.lightLinkGrey,
  },
  copyText: {
    paddingLeft: 10,
  },
  copyLinkButton: {
    backgroundColor: theme.palette.common.white,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.frequent.abalonGrey,
    borderRadius: 20,
    width: 140,
    marginLeft: 15,
    float: 'right',
    '&:hover, &:focus, &.Mui-focused': {
      border: 1,
      borderColor: theme.palette.frequent.grey,
      backgroundColor: theme.palette.frequent.lightGreenButton,
    },
  },
  rightContainer: {
    float: 'right',
  },
  fieldContainer: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    '& legend': {
      width: 0,
      display: 'none',
    },
    '& .MuiSelect-root': {
      background: theme.palette.common.white,
    },
  },
  shortcodeContainer: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 16,
    paddingBottom: 10,
  },
  input: {
    width: 17,
    height: 15,
    color: theme.palette.frequent.fossilGrey,
  },
}));

export default useStyles;
