import React, { FC, memo } from 'react';
import {
  Card, IconButton
} from '@material-ui/core';
import useStyles from './styles';
import { CloseIconDark } from '../../../../components';

import { RepresentativeAssignedPartnerships, Representative } from '../../RepresentativesModels';

interface PartnershipCardProps {
  partnership: RepresentativeAssignedPartnerships
  representative : Representative
  onDelete: (representative?: Representative, partnershipcode?:string) => void
}

export const PartnershipCard: FC<PartnershipCardProps> = memo(({ partnership, onDelete, representative }) => {
  const classes = useStyles();

  return (
    <Card className={classes.partnershipCard}>
      <span>{partnership.partnershipCode}</span>
      <IconButton className={classes.iconButton} onClick={() => onDelete(representative, partnership.partnershipCode)}>
        <CloseIconDark width={10} />
      </IconButton>
    </Card>

  );
});
