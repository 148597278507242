import React, { FC } from 'react';
import { ReactComponent as User } from '../../../assets/Header/User.svg';
import { useStyles } from '../styles';
import { CustomIconProps } from '../CustomIconModels';

export const UserIcon: FC<CustomIconProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <User className={`${classes.icon} ${classes.user} ${className || ''}`} />
  );
};
