import React, { FC, useState } from 'react';
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowUp } from '@material-ui/icons';
import { useCommonStyles } from '../../../styles';

interface Props {
  rowCells: any[];
  subRows: any[];
  columnsNumber: number;
}

export const ReportsCollapsibleRow: FC<Props> = ({
  rowCells,
  subRows,
  columnsNumber,
}) => {
  const [open, setOpen] = useState(false);
  const commonClasses = useCommonStyles();

  return (
    <>
      <TableRow className={commonClasses.collapsibleRow}>
        <TableCell style={{ width: 70 }}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        {rowCells}
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={columnsNumber}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <TableBody>{subRows}</TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
