import React, { FC } from 'react';
import { useStyles } from '../styles';
import { ReactComponent as Reports } from '../../../assets/MainMenu/Reports.svg';

export const ReportsIcon: FC = () => {
  const classes = useStyles();
  return (
    <Reports className={classes.icon} width={12.7} height={11.1} />
  );
};
