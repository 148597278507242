import { instanceApi } from '../../../services';

export const getReports = (params: any): Promise<{ data: any[] }> =>
  instanceApi.get(`rebate/report/getcommissionbyclientreport`, { params });

export const getSummaryByClient = (params: any): Promise<any> =>
  instanceApi.get(`rebate/report/summarybyclientreport`, { params });

export const sendEmailByReportId = (data: any): Promise<any> =>
  instanceApi.post('rebate/report/sendemailsummarybyclientreport', data);

export const sendEmail = (data: any): Promise<any> =>
  instanceApi.post('rebate/report/sendemailcommissionsclientbyrequestid', data);
