import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: 'none',
  },
  formControl: {
    width: '100%',
    marginTop: 3,
    minWidth: 175,
    maxWidth: 673,
    marginBottom: 10,
    '&:hover, & .Mui-focused, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.frequent.davyGrey,
      borderWidth: 1
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 10
    }
  },
  modalWindow: {
    maxWidth: 675,
  },
  tab: {
    color: theme.palette.frequent.fossilGrey,
    backgroundColor: 'transparent',
    paddingTop: 3,
    paddingBottom: 3,
    textTransform: 'capitalize',
    '&.Mui-selected': {
      color: theme.palette.text.primary,
      background: theme.palette.secondary.light,
      borderRadius: 10
    },
    '&.Mui-focusVisible': {
      color: theme.palette.text.primary,
      background: theme.palette.secondary.light
    },
  },
  tabs: {
    boxShadow: 'none',
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
    marginTop: 15,
    backgroundColor: theme.palette.frequent.background,
    color: theme.palette.text.primary,
    borderRadius: 10,
  },
  closeBtn: {
    paddingTop: 6,
    paddingBottom: 6,
    width: 122,
    borderRadius: 50,
    borderColor: theme.palette.frequent.fossilGrey
  },
  dialogWidth: {
    width: 600
  },
  selectField: {
    borderColor: theme.palette.frequent.abalonGrey,
    borderRadius: 10,
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.frequent.davyGrey,
    },
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  },
  selectIconClass: {
    color: theme.palette.frequent.cadetBlue
  },
  partnershipSelect: {
    width: '100%',
    paddingBottom: 8,
    paddingTop: 8,
    backgroundColor: 'transparent',
    borderRadius: 10,
    overflow: 'hidden'
  },
  tabContainer: {
    width: '100%',
    padding: '18px 0 0 0'
  }
}));

export default useStyles;
