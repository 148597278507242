import React, { FC, useCallback, useMemo } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { mapValues, groupBy, omit } from 'lodash';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { getTranslation } from 'src/utils';
import {
  CustomTable,
  OverflowTip,
  ReportsCollapsibleRow,
} from '../../../../../components';
import { ClientListItem } from '../../ClientListModel';
import { useStyles } from '../../styles';

interface Props {
  translation: any;
  groupByOption?: string;
  columnNames: string[];
  selectedColumns: string[];
  rows: ClientListItem[];
}

export const ReportTable: FC<Props> = ({
  translation,
  groupByOption,
  columnNames,
  selectedColumns,
  rows,
}) => {
  const classes = useStyles();
  const renderHeader = useMemo((): any[] => {
    const result: any[] = [];
    if (groupByOption) {
      result.push(<TableCell className={classes.emptyCell} />);

      columnNames.forEach((columnName) => {
        const isSelected = selectedColumns.includes(columnName);
        if (isSelected) {
          if (
            groupByOption === 'partnershipCode' &&
            columnName === columnNames[0]
          ) {
            result.push(
              <TableCell key={columnName} align="left">
                {columnName}
              </TableCell>,
            );
          }

          if (
            groupByOption === 'clientCountry' &&
            columnName === columnNames[2]
          ) {
            result.push(
              <TableCell key={columnName} align="left">
                {columnName}
              </TableCell>,
            );
          }

          if (groupByOption === 'currency' && columnName === columnNames[3]) {
            result.push(
              <TableCell key={columnName} align="left">
                {columnName}
              </TableCell>,
            );
          }
        }
      });
    }

    columnNames.forEach((columnName) => {
      const isSelected = selectedColumns.includes(columnName);
      if (isSelected) {
        if (!groupByOption) {
          result.push(
            <TableCell
              key={columnName}
              align={columnName === columnNames[0] ? 'left' : 'center'}
            >
              {columnName}
            </TableCell>,
          );
        } else if (
          (groupByOption === 'partnershipCode' &&
            columnName !== columnNames[0]) ||
          (groupByOption === 'clientCountry' &&
            columnName !== columnNames[2]) ||
          (groupByOption === 'currency' && columnName !== columnNames[3])
        ) {
          result.push(
            <TableCell key={columnName} align="center">
              {columnName}
            </TableCell>,
          );
        }
      }
    });
    return result;
  }, [selectedColumns, columnNames, groupByOption]);

  const renderRows = useCallback(
    (rowsData: ClientListItem[]): any[] => {
      const result: any[] = [];

      rowsData.forEach((row) => {
        result.push(
          <TableRow key={uuid()}>
            {groupByOption && <TableCell className={classes.emptyCell} />}

            {!groupByOption ||
            (groupByOption && groupByOption === 'partnershipCode') ? (
              <>
                {selectedColumns.includes(columnNames[0]) && (
                  <TableCell align="left" className={classes.cell}>
                    {row.partnershipCode ? row.partnershipCode : '-'}
                  </TableCell>
                )}
                {selectedColumns.includes(columnNames[1]) && (
                  <TableCell align="center" className={classes.cell}>
                    {row.clientName ? row.clientName : '-'}
                  </TableCell>
                )}
                {selectedColumns.includes(columnNames[2]) && (
                  <TableCell align="center" className={classes.cell}>
                    {row.clientCountry ? getTranslation(translation, row.clientCountry) : '-'}
                  </TableCell>
                )}
                {selectedColumns.includes(columnNames[3]) && (
                  <TableCell align="center" className={classes.cell}>
                    {row.currency ? row.currency : '-'}
                  </TableCell>
                )}
              </>
            ) : (
              <>
                {groupByOption === 'clientCountry' && (
                  <>
                    {selectedColumns.includes(columnNames[2]) && (
                      <TableCell align="left" className={classes.cell}>
                        {row.clientCountry ? getTranslation(translation, row.clientCountry) : '-'}
                      </TableCell>
                    )}

                    {selectedColumns.includes(columnNames[0]) && (
                      <TableCell align="center" className={classes.cell}>
                        {row.partnershipCode ? row.partnershipCode : '-'}
                      </TableCell>
                    )}
                    {selectedColumns.includes(columnNames[1]) && (
                      <TableCell align="center" className={classes.cell}>
                        {row.clientName ? row.clientName : '-'}
                      </TableCell>
                    )}
                    {selectedColumns.includes(columnNames[3]) && (
                      <TableCell align="center" className={classes.cell}>
                        {row.currency ? row.currency : '-'}
                      </TableCell>
                    )}
                  </>
                )}
                {groupByOption === 'currency' && (
                  <>
                    {selectedColumns.includes(columnNames[3]) && (
                      <TableCell align="left" className={classes.cell}>
                        {row.currency ? row.currency : '-'}
                      </TableCell>
                    )}
                    {selectedColumns.includes(columnNames[0]) && (
                      <TableCell align="center" className={classes.cell}>
                        {row.partnershipCode ? row.partnershipCode : '-'}
                      </TableCell>
                    )}
                    {selectedColumns.includes(columnNames[1]) && (
                      <TableCell align="center" className={classes.cell}>
                        {row.clientName ? row.clientName : '-'}
                      </TableCell>
                    )}
                    {selectedColumns.includes(columnNames[2]) && (
                      <TableCell align="center" className={classes.cell}>
                        {row.clientCountry ? getTranslation(translation, row.clientCountry) : '-'}
                      </TableCell>
                    )}
                  </>
                )}
              </>
            )}

            {selectedColumns.includes(columnNames[4]) && (
              <>
                {row.accountType ? (
                  <OverflowTip
                    interactive
                    align="center"
                    component={TableCell}
                    title={getTranslation(translation, row.accountType)}
                    className={classes.cell}
                  >
                    {getTranslation(translation, row.accountType)}
                  </OverflowTip>
                ) : (
                  <TableCell align="center" className={classes.cell}>
                    -
                  </TableCell>
                )}
              </>
            )}
            {selectedColumns.includes(columnNames[5]) && (
              <TableCell align="center" className={classes.cell}>
                {row.partnershipStartDate
                  ? moment(row.partnershipStartDate).format('DD/MM/yyyy')
                  : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[6]) && (
              <TableCell align="center" className={classes.cell}>
                {row.clientLogin ? row.clientLogin : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[7]) && (
              <TableCell align="center" className={classes.cell}>
                {row.partnershipEndDate
                  ? moment(row.partnershipEndDate).format('DD/MM/yyyy')
                  : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[8]) && (
              <TableCell align="center" className={classes.cell}>
                {row.lastTradeDate
                  ? moment(row.lastTradeDate).format('DD/MM/yyyy')
                  : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[9]) && (
              <TableCell align="center" className={classes.cell}>
                {row.leverage || row.leverage === 0 ? row.leverage : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[10]) && (
              <TableCell align="center" className={classes.cell}>
                {row.volume || row.volume === '.00'
                  ? Number(row.volume).toFixed(2)
                  : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[11]) && (
              <TableCell align="center" className={classes.cell}>
                {row.volumeLots || row.volumeLots === '.00'
                  ? Number(row.volumeLots).toFixed(2)
                  : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[12]) && (
              <TableCell align="center" className={classes.cell}>
                {row.closedPositions || row.closedPositions === 0
                  ? row.closedPositions
                  : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[13]) && (
              <TableCell align="center" className={classes.cell}>
                {row.deposits || row.deposits === '.00'
                  ? Number(row.deposits).toFixed(2)
                  : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[14]) && (
              <TableCell align="center" className={classes.cell}>
                {row.withDrawals || row.withDrawals === '.00'
                  ? Math.abs(Number(row.withDrawals))
                  : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[15]) && (
              <TableCell align="center" className={classes.cell}>
                {row.netDeposits || row.netDeposits === '.00'
                  ? Number(row.netDeposits).toFixed(2)
                  : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[16]) && (
              <TableCell align="center" className={classes.cell}>
                {row.realEquity || row.realEquity === '.00'
                  ? Number(row.realEquity).toFixed(2)
                  : '-'}
              </TableCell>
            )}
          </TableRow>,
        );
      });

      return result;
    },
    [columnNames, selectedColumns, groupByOption],
  );

  const renderCollapsibleRows = useCallback(
    (array: any[]): any[] => {
      const result: any[] = [];

      array.forEach((row: any) => {
        const key = uuid();
        const cells: any[] = [];
        let subRows: any[] = [];

        if (
          !groupByOption ||
          (groupByOption && groupByOption === 'partnershipCode')
        ) {
          if (selectedColumns.includes(columnNames[0])) {
            cells.push(
              <TableCell align="left" className={classes.cell}>
                {row.partnershipCode !== null ? row.partnershipCode : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[1])) {
            cells.push(
              <TableCell align="center" className={classes.cell}>
                {row.clientName ? row.clientName : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[2])) {
            cells.push(
              <TableCell align="center" className={classes.cell}>
                {row.clientCountry ? getTranslation(translation, row.clientCountry) : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[3])) {
            cells.push(
              <TableCell align="center" className={classes.cell}>
                {row.currency ? row.currency : '-'}
              </TableCell>,
            );
          }
        } else if (groupByOption === 'clientCountry') {
          if (selectedColumns.includes(columnNames[2])) {
            cells.push(
              <TableCell align="left" className={classes.cell}>
                {row.clientCountry !== 'null' ? getTranslation(translation, row.clientCountry) : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[0])) {
            cells.push(
              <TableCell align="center" className={classes.cell}>
                {row.partnershipCode !== null ? row.partnershipCode : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[1])) {
            cells.push(
              <TableCell align="center" className={classes.cell}>
                {row.clientName ? row.clientName : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[3])) {
            cells.push(
              <TableCell align="center" className={classes.cell}>
                {row.currency ? row.currency : '-'}
              </TableCell>,
            );
          }
        } else {
          if (selectedColumns.includes(columnNames[3])) {
            cells.push(
              <TableCell
                align="left"
                key={`currency_${key}`}
                style={{ width: 182 }}
              >
                {row.currency ? row.currency : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[0])) {
            cells.push(
              <TableCell align="center" className={classes.cell}>
                {row.partnershipCode !== null ? row.partnershipCode : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[1])) {
            cells.push(
              <TableCell align="center" className={classes.cell}>
                {row.clientName ? row.clientName : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[2])) {
            cells.push(
              <TableCell
                align="center"
                key={`country_${key}`}
                style={{ width: 182 }}
              >
                {row.clientCountry ? getTranslation(translation, row.clientCountry) : '-'}
              </TableCell>,
            );
          }
        }

        if (selectedColumns.includes(columnNames[4])) {
          cells.push(
            <TableCell align="center" className={classes.cell}>
              {row.accountType ? getTranslation(translation, row.accountType) : '-'}
            </TableCell>,
          );
        }
        if (selectedColumns.includes(columnNames[5])) {
          cells.push(
            <TableCell align="center" className={classes.cell}>
              {row.partnershipStartDate ? row.partershipStartDate : '-'}
            </TableCell>,
          );
        }
        if (selectedColumns.includes(columnNames[6])) {
          cells.push(
            <TableCell align="center" className={classes.cell}>
              {row.clientLogin ? row.clientLogin : '-'}
            </TableCell>,
          );
        }
        if (selectedColumns.includes(columnNames[7])) {
          cells.push(
            <TableCell align="center" className={classes.cell}>
              {row.partnershipEndDate ? row.partnershipEndDate : '-'}
            </TableCell>,
          );
        }
        if (selectedColumns.includes(columnNames[8])) {
          cells.push(
            <TableCell
              align="center"
              key={`lastTradeDate_${key}`}
              style={{ width: 120 }}
            >
              {row.lastTradeDate ? row.lastTradeDate : '-'}
            </TableCell>,
          );
        }
        if (selectedColumns.includes(columnNames[9])) {
          cells.push(
            <TableCell align="center" className={classes.cell}>
              {row.leverage}
            </TableCell>,
          );
        }
        if (selectedColumns.includes(columnNames[10])) {
          cells.push(
            <TableCell align="center" className={classes.cell}>
              {row.volume}
            </TableCell>,
          );
        }
        if (selectedColumns.includes(columnNames[11])) {
          cells.push(
            <TableCell align="center" className={classes.cell}>
              {row.volumeLots}
            </TableCell>,
          );
        }
        if (selectedColumns.includes(columnNames[12])) {
          cells.push(
            <TableCell align="center" className={classes.cell}>
              {row.closedPositions}
            </TableCell>,
          );
        }
        if (selectedColumns.includes(columnNames[13])) {
          cells.push(
            <TableCell align="center" className={classes.cell}>
              {row.deposits}
            </TableCell>,
          );
        }
        if (selectedColumns.includes(columnNames[14])) {
          cells.push(
            <TableCell align="center" className={classes.cell}>
              {row.withDrawals}
            </TableCell>,
          );
        }
        if (selectedColumns.includes(columnNames[15])) {
          cells.push(
            <TableCell
              align="center"
              key={`netDeposit_${key}`}
              style={{ width: 108 }}
            >
              {row.netDeposits}
            </TableCell>,
          );
        }
        if (selectedColumns.includes(columnNames[16])) {
          cells.push(
            <TableCell align="center" className={classes.cell}>
              {row.realEquity}
            </TableCell>,
          );
        }

        subRows = renderRows(row.subRows);

        result.push(
          <ReportsCollapsibleRow
            key={key}
            rowCells={cells}
            subRows={subRows}
            columnsNumber={selectedColumns.length + 1}
          />,
        );
      });

      return result;
    },
    [selectedColumns, columnNames, renderRows, groupByOption],
  );

  const checkGroupByAndRenderRows = useCallback((): any[] => {
    let result: any[] = [];

    if (groupByOption) {
      const grouped = mapValues(groupBy(rows, groupByOption), (clist) =>
        clist.map((row) => omit(row, groupByOption)),
      );

      const resultArray = Object.keys(grouped).map((index) => ({
        partnershipCode: groupByOption === 'partnershipCode' ? index : '',
        clientCountry: groupByOption === 'clientCountry' ? index : '',
        currency: groupByOption === 'currency' ? index : '',
        subRows: grouped[index],
      }));
      result = renderCollapsibleRows(resultArray);
    } else {
      result = renderRows(rows);
    }

    return result;
  }, [rows, groupByOption, renderRows, renderCollapsibleRows]);

  return (
    <CustomTable
      headerCells={renderHeader}
      rows={checkGroupByAndRenderRows()}
      columnsNumber={selectedColumns.length}
      emptyDataMessage={translation.text_6448}
    />
  );
};
