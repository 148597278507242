import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    mainMenu: {
      width: 'inherit',
    },
    listItem: {
      padding: '4px 0',
    },
    menuButton: {
      borderRadius: 6,
      fontSize: 14,
      boxShadow: '0 10px 20px 0 rgba(103, 126, 172, 0.16)',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.changeable.menuFontColor,
      width: '100%',
      height: 40,
      textAlign: 'left',
      justifyContent: 'flex-start',
      verticalAlign: 'middle',
      '& .MuiButton-startIcon': {
        marginBottom: 2,

        '& svg path': {
          fill: theme.palette.frequent.nickel,
        },

        '& .MuiSvgIcon-root': {
          fontSize: 17,
        },
      },

      '& .MuiButton-endIcon': {
        position: 'absolute',
        right: '1rem',
        marginRight: '-10px',
        color: theme.palette.frequent.wildBlue,

        '& svg': {
          fontSize: 28,
        },
      },

      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          backgroundColor: theme.palette.changeable.hover,
          color: theme.palette.common.white,
          '& .MuiButton-startIcon': {
            '& svg path': {
              fill: theme.palette.common.white,
            },
          },
        },
      },

      '@media (hover: none)': {
        '&:hover': {
          backgroundColor: theme.palette.common.white,
          boxShadow: '0 10px 20px 0 rgba(103, 126, 172, 0.16)',
        },
      },
    },
    menuButtonSelected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.changeable.selectedMenuFontColor,
      verticalAlign: 'middle',
      '& .MuiButton-startIcon': {
        marginBottom: 2,

        '& svg path': {
          fill: theme.palette.changeable.selectedMenuFontColor,
        },
      },
      '& svg path': {
        fill: theme.palette.changeable.selectedMenuFontColor,
      },
    },
    subList: {
      paddingTop: 6,
      paddingBottom: 10,
    },
    subListItem: {
      padding: '4px 0 0 0',
    },
    subMenuButton: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.frequent.wildBlue,
      paddingLeft: 30,
      width: '100%',
      height: 40,
      fontSize: 13,
      textAlign: 'left',
      justifyContent: 'flex-start',

      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          backgroundColor: theme.palette.changeable.hover,
          color: theme.palette.common.white,
        },
      },
    },
    subMenuButtonSelected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  }),
);
