import { instanceApi } from '../../services';

// export const logOut = (): Promise<any> => instanceApi.get('api/Authenticate/Logout');

export const getTranslations = (locale: string) : Promise<any> => instanceApi.get(
  `api/settings/gettranslations?locale=${locale}`
);
export const getTheme = () : Promise<any> => instanceApi.get('api/settings/gettheme');

export const getThemeByBrokerId = (brokerId: string) : Promise<any> => instanceApi.get(`/api/settings/getthemebybrokerid?brokerId=${brokerId}`);

export const clearThemeCache = (): Promise<any> => instanceApi.post('api/settings/clearthemescache');
