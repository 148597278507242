import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    borderRadius: 3,
    fontSize: '13pt',
    marginTop: 6,
    '&.MuiInput-root': {
      border: 'none'
    },
    '&::before': {
      display: 'none',
    },
    '& $selectRoot:hover, & $selectRoot:focus, &.Mui-focused $selectRoot': {
      border: `1px solid ${theme.palette.frequent.wildBlue}`,
    },
    '& $selectRoot': {
      padding: '7px 10px',
      textAlign: 'left',
      border: `1px solid ${theme.palette.frequent.abalonGrey}`,
      borderRadius: 10,
      height: 'auto'
    },
    '& .MuiSelect-root': {
      background: theme.palette.common.white
    }
  },
  selectIcon: {
    color: theme.palette.primary.light,
    marginRight: theme.spacing(2),
  },
  selectRoot: {
  },
  selectDropdown: {
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  inputError: {
    borderColor: theme.palette.frequent.red,
  },
  errorDescription: {
    display: 'flex',
    color: theme.palette.frequent.red,
    margin: theme.spacing(1, 0, 0, 0.5),
  },
}));

export default useStyles;
