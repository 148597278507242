import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  header: {
    fontWeight: 500,
    fontSize: 14,
    paddingLeft: 5,
    paddingRight: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.frequent.background,

    '& .MuiButton-root': {
      fontSize: 14,
    },
  },
  container: {
    '& .MuiMenu-paper': {
      width: 386,
      borderRadius: 15,

      '& .MuiList-root': {
        paddingTop: 0,
      },
    },
  },
  noNotifications: {
    textAlign: 'center',
    paddingTop: 85,
    paddingBottom: 85,
  },
  notificationIcon: {
    '& circle': {
      fill: theme.palette.changeable.notification,
    },
  },
  newNotificationIcon: {
    '& circle': {
      fill: theme.palette.changeable.newNotification,
    },
  },
  userIcon: {
    '@media (max-width: 1180px)': {
      height: 30,
      width: 30,
    },
  },
  btn: {
    '&:hover': {
      '& .MuiBadge-root': {
        '& svg path': {
          fill: theme.palette.changeable.userIconHover,
        },
        '& .MuiBadge-badge': {
          '& svg path': {
            fill: theme.palette.common.white,
          },
        },
      },
    },
  },
}));
