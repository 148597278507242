import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      marginTop: 10,
      padding: 15,
      minHeight: '100vh',
      width: 'inherit',

      '@media (max-width: 768px)': {
        padding: '15px 10px',
      },
    },
    radioButtonContainer: {
      padding: '27px 7px 18px',
    },
    dailyLabel: {
      '& .MuiTypography-body1': {
        fontSize: 15,
      },

      '& .MuiSvgIcon-root': {
        height: 18,
        width: 18,
        fill: theme.palette.secondary.light,
      },

      '& .Mui-checked': {
        '& svg': {
          height: 15,
          width: 15,
        },
      },
    },
    dailyInput: {
      marginRight: 8,
    },
    tableTotals: {
      backgroundColor: theme.palette.changeable.tableHeaderBgColor,
      borderRadius: 5,

      '& .MuiTableCell-root': {
        fontWeight: 'bold',
        padding: 16,
      },
      '& td:first-child': {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
      },

      '& td:last-child': {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
      },
    },
    groupedByCell: {
      fontWeight: 'bold',
    },
    emptyReportMessageContainer: {
      marginTop: 193,
    },
    desktop: {
      '@media (max-width: 840px)': {
        display: 'none',
      },
    },
    desktopTable: {
      '@media (max-width: 840px)': {
        display: 'none',
      },
      '@media print': {
        display: 'block',
      },
    },
    mobile: {
      '@media (min-width: 840px)': {
        display: 'none',
      },
      '@media print': {
        display: 'none',
      },
    },
    radioButtons: {
      textAlign: 'left',

      '@media (max-width: 840px)': {
        marginTop: 35,
        textAlign: 'right',
      },
    },
  }),
);
