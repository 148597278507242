import React, { FC } from 'react';
import {
  Collapse, Grid, List, ListItem, ListItemIcon
} from '@material-ui/core';
import { useStyles } from '../../../styles';
import { formatNumber } from '../../../../../../utils';
import {
  ArrowDropDownGraphIcon, ArrowRightIcon, GreenArrowIcon, GreyLineIcon, RedArrowIcon
} from '../../../../../../components';
import { useAppData } from '../../../../../../providers';

interface Props {
  selectedYear: any
  tableData: any
  currency?: string
  translation: any
  handleSelectPeriod: (year: string, data: any) => void
}

export const YearMonthList: FC<Props> = ({
  tableData, currency, selectedYear, handleSelectPeriod, translation
}) => {
  const classes = useStyles();
  const { locale } = useAppData();

  const getMonthName = (month: string, language: string): string => new Date(new Date().setMonth(Number(month) - 1)).toLocaleString(language, { month: 'long' });

  return (
    <Grid container direction="column">
      <Grid container direction="row" justify="space-between" className={classes.tableHeader}>
        <Grid item>{translation.text_6301}</Grid>
        <Grid item>{translation.text_6302}</Grid>
      </Grid>
      <Grid item>
        <List component="div">
          {tableData.map((item: any) => (
            <div key={item.period}>
              <ListItem button className={classes.listItem} onClick={() => handleSelectPeriod(item.period, item.monthData)}>
                <ListItemIcon className={classes.listIcon}>
                  {selectedYear === item.period
                    ? (<ArrowDropDownGraphIcon />) : (<ArrowRightIcon />)}
                </ListItemIcon>
                <Grid container direction="row" justify="space-between">
                  <Grid item>{item.period}</Grid>
                  <Grid item>
                    {formatNumber(item.amount, currency || 'USD')}
                  </Grid>
                </Grid>
              </ListItem>
              <Collapse in={item.period === selectedYear} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.monthData && item.monthData.map((monthItem: any) => (
                    <ListItem component="div" className={classes.listSubItem} key={`${monthItem.month}_${item.period}`}>
                      <Grid container direction="row" justify="space-between">
                        <Grid item>{getMonthName(monthItem.month, locale ?? 'en')}</Grid>
                        <Grid item>
                          {formatNumber(monthItem.amount, currency || 'USD')}&nbsp;
                          {monthItem.increased !== 0 ? (<GreenArrowIcon className={classes.subListItemChangeIcon} />)
                            : (monthItem.decreased !== 0 ? (<RedArrowIcon className={classes.subListItemChangeIcon} />)
                              : (<GreyLineIcon className={classes.subListItemIconNoChange} />))}
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};
