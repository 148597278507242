import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { GreenArrowIcon, GreyLineIcon, RedArrowIcon } from '../../../../CustomIcons';

interface Props {
  title: string
  subTitle: string
  value: string
  increased?: number
  decreased?: number
  translation: any
}

export const ValueChangesTitle: FC<Props> = ({
  title, subTitle, value, increased, decreased, translation
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="center" className={classes.values}>
      {value !== '0' ? (
        <>
          <Grid item className={classes.valueTitle}>{value}</Grid>
          <Grid item className={classes.changeTitle}>
            {increased && increased !== 0
              ? (<><GreenArrowIcon className={classes.changeTitleIcon} /> {translation.text_6296} <b>{increased} %</b> {subTitle}</>)
              : (decreased && decreased !== 0
                ? (<><RedArrowIcon className={classes.changeTitleIcon} /> {translation.text_6297} <b>{decreased} %</b> {subTitle}</>)
                : (<><GreyLineIcon className={classes.noChangeTitleIcon} /> {translation.text_6298} {subTitle}</>)
              )}
          </Grid>
        </>
      ) : (
        <Grid item className={classes.emptyMessage}>{title} {translation.text_6299}</Grid>
      )}
    </Grid>
  );
};
