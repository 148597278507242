import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  titleContainer: {
    color: theme.palette.text.primary,
    marginBottom: 18,
  },
  title: {
    textAlign: 'left',
    marginBottom: 0,

    '@media (max-width: 768px)': {
      fontSize: 20
    }
  },
  tabs: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.frequent.fossilGrey,
    boxShadow: 'none',

    '& .MuiTab-root': {
      padding: 0,
      backgroundColor: theme.palette.common.white,
      border: `solid 1px ${theme.palette.frequent.abalonGrey}`,
    },

    '& .MuiTab-wrapper': {
      textTransform: 'none',
      padding: 9,
      fontWeight: 'normal'
    },

    '& .Mui-selected': {
      backgroundColor: theme.palette.frequent.cultured,
      textTransform: 'none',
      fontWeight: 'normal'
    },

    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.frequent.cultured,
      height: 0
    }
  },
  clientTab: {
    borderRadius: '10px 0 0 10px',
  },
  accountTab: {
    borderRadius: '0 10px 10px 0',
  },
  cell: {
    verticalAlign: 'top'
  },
  paper: {
    marginTop: 20,
    padding: '15px 10px',
  },
}));
