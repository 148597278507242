import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  partnershipCard: {
    padding: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.5),
    display: 'inline-flex',
    borderRadius: '1px',
    height: theme.spacing(3.5),
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0.9),
    backgroundColor: theme.palette.frequent.water,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    fontSize: '14px',
  },
  iconButton: {
    marginLeft: theme.spacing(1.2),
    padding: 0,
  },
}),);

export default useStyles;
