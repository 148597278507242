import React, { FC, useCallback } from 'react';
import { Checkbox, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { CheckBoxOutlined, FiberManualRecord } from '@material-ui/icons';
import { useStyles } from '../../../styles';
import { LineChart, PeriodSelection } from '../../../../../../components';

interface Props {
  selectedPeriod: string;
  chartData: any;
  currency?: string;
  checkboxes: string[];
  colors: any;
  translation: any;
  selectedCheckboxes: string[];
  handlePeriodSelect: (period: string) => void;
  setSelectedCheckboxes: (checkboxes: string[]) => void;
}

export const GraphArea: FC<Props> = ({ selectedPeriod, currency, checkboxes, chartData, handlePeriodSelect, setSelectedCheckboxes, selectedCheckboxes, colors, translation }) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (item: string) => {
      const newValues = selectedCheckboxes.includes(item) ? selectedCheckboxes.filter((f) => f !== item) : [...selectedCheckboxes, item];

      setSelectedCheckboxes(newValues);
    },
    [selectedCheckboxes, setSelectedCheckboxes],
  );

  return (
    <Grid container direction="column">
      <PeriodSelection selectedPeriod={selectedPeriod} onChange={handlePeriodSelect} translation={translation} />
      <Grid item className={classes.chartSection}>
        {selectedCheckboxes.length > 0 && chartData.length > 0 && <LineChart key={Math.random()} period={selectedPeriod} data={chartData} currency={currency} colors={colors} translation={translation} />}
      </Grid>
      <Grid item xs={12}>
        <List component="div" dense className={classes.flexContainer}>
          {checkboxes.map((item) => (
            <ListItem key={item} button className={classes.checkboxListItem}>
              <ListItemIcon
                className={`${classes.checkboxListIcon} 
                      ${item === translation.text_1603 ? classes.checkBoxListIconProfits : classes.checkBoxListIconLosses}`}
              >
                <FiberManualRecord className={classes.icon} />
              </ListItemIcon>
              <ListItemText id={item} primary={item} classes={{ primary: classes.checkboxText }} />
              <ListItemSecondaryAction className={classes.action}>
                <Checkbox
                  size="small"
                  edge="end"
                  title={item}
                  checked={selectedCheckboxes.includes(item)}
                  classes={{
                    root: classes.checkbox,
                  }}
                  checkedIcon={<CheckBoxOutlined />}
                  onChange={() => handleChange(item)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};
