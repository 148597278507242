import React, { FC, ReactElement } from 'react';
import { Button } from '@material-ui/core';

import { useCommonStyles } from '../../styles';
import { useStyles } from './styles';

interface Props {
  title: string;
  content: ReactElement;
  isEnabled: boolean;
  translation: any;
  sendEmail?: () => void;
}

export const SendByEmail: FC<Props> = ({
  isEnabled,
  translation,
  sendEmail,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <Button
      size="small"
      variant="contained"
      className={`${commonClasses.buttonContained} ${classes.button}`}
      onClick={sendEmail}
      disabled={!isEnabled}
    >
      {translation.text_6412}
    </Button>
  );
};
