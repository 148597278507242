import React, { FC, useCallback, useMemo, useEffect, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { CustomTable } from '../../../../../components';
import { TransactionsReportData } from '../../TransactionsReportModel';
import { useAppData } from '../../../../../providers';

interface Props {
  rows: TransactionsReportData[];
  reportColumns: string[];
  selectedColumns: string[];
}

export const ReportTable: FC<Props> = ({
  rows,
  reportColumns,
  selectedColumns,
}) => {
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const renderHeader = useMemo((): any[] => {
    const result: any[] = [];

    reportColumns.forEach((columnName) => {
      const isSelected = selectedColumns.includes(columnName);
      if (isSelected) {
        result.push(
          <TableCell
            key={columnName}
            align={columnName === reportColumns[0] ? 'left' : 'center'}
          >
            {columnName}
          </TableCell>,
        );
      }
    });
    return result;
  }, [selectedColumns, reportColumns]);

  const renderRows = useCallback((): any[] => {
    const result: any[] = [];

    rows.forEach((row) => {
      result.push(
        <TableRow key={uuid()}>
          {selectedColumns.includes(reportColumns[0]) && (
            <TableCell align="left">
              {row.accountPartnership || row.accountPartnership === ''
                ? row.accountPartnership
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[1]) && (
            <TableCell align="center">
              {row.accountName || row.accountName === ''
                ? row.accountName
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[2]) && (
            <TableCell align="center">
              {row.login || row.login === 0 ? row.login : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[3]) && (
            <TableCell align="center">
              {row.deposits || row.deposits === ''
                ? Number(row.deposits).toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[4]) && (
            <TableCell align="center">
              {row.withdrawals || row.withdrawals === ''
                ? Number(row.withdrawals).toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[5]) && (
            <TableCell align="center">
              {row.netDeposit || row.netDeposit === ''
                ? Number(row.netDeposit).toFixed(2)
                : '-'}
            </TableCell>
          )}
        </TableRow>,
      );
    });

    return result;
  }, [rows, selectedColumns, reportColumns]);

  return (
    <CustomTable
      headerCells={renderHeader}
      rows={renderRows()}
      columnsNumber={reportColumns.length}
      emptyDataMessage={translation.text_6448}
    />
  );
};
