import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { Divider, FormControl, Grid, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { useStyles } from './styles';
import { DateRangeSelector, ValueChangesTitle } from './components';
import { SelectControl } from '../../Controls';

interface Props {
  title: string;
  partnership?: string;
  value: string;
  increased?: number;
  decreased?: number;
  fromDate?: Date;
  toDate?: Date;
  handleFromDateChange?: (date: Date) => void;
  handleToDateChange?: (date: Date) => void;
  translation: any;
}

export const PanelTitle: FC<Props> = ({ title, partnership, value, increased, decreased, fromDate, toDate, handleFromDateChange, handleToDateChange, translation }) => {
  const classes = useStyles();
  const defaultFilter = 'placeholder';
  const [selectedFilter, setSelectedFilter] = useState<string>(defaultFilter);
  const [from, setFrom] = useState<Date | null>(fromDate || null);
  const [to, setTo] = useState<Date | null>(toDate || null);
  const quickFilter = useMemo(
    () => [translation.text_1606, translation.text_1607, translation.text_1608, translation.text_1609, translation.text_1610, translation.text_1611, translation.text_1612, translation.text_1613, translation.text_1614],
    [translation],
  );

  const handleFilterChange = useCallback(
    async (event: ChangeEvent<{ value: any }>) => {
      const filter = event.target.value;
      setSelectedFilter(filter);

      let startDate = new Date();
      let endDate = new Date();

      if (filter === quickFilter[0]) {
        startDate = moment().startOf('isoWeek').toDate();
        endDate = moment().endOf('isoWeek').toDate() > new Date() ? new Date() : moment().endOf('isoWeek').toDate();
      }

      if (filter === quickFilter[1]) {
        startDate = moment().subtract(1, 'weeks').startOf('isoWeek').toDate();
        endDate = moment().subtract(1, 'weeks').endOf('isoWeek').toDate();
      }

      if (filter === quickFilter[2]) {
        startDate = moment().startOf('month').toDate();
        endDate = moment().endOf('month').toDate() > new Date() ? new Date() : moment().endOf('month').toDate();
      }

      if (filter === quickFilter[3]) {
        startDate = moment().subtract(1, 'month').startOf('month').toDate();
        endDate = moment().subtract(1, 'month').endOf('month').toDate();
      }

      if (filter === quickFilter[4]) {
        startDate = moment().subtract(6, 'months').toDate();
      }

      if (filter === quickFilter[5]) {
        startDate = moment().subtract(12, 'months').toDate();
      }

      if (filter === quickFilter[6]) {
        startDate = moment().startOf('year').toDate();
      }

      if (filter === quickFilter[7]) {
        startDate = moment().subtract(1, 'year').startOf('year').toDate();
        endDate = moment().subtract(1, 'year').endOf('year').toDate();
      }

      if (filter === quickFilter[8]) {
        startDate = moment([2010]).startOf('year').toDate();
      }

      if (handleToDateChange) {
        handleToDateChange(endDate);
      }

      if (handleFromDateChange) {
        handleFromDateChange(startDate);
      }

      setFrom(null);
      setTo(null);
    },
    [handleToDateChange, handleFromDateChange],
  );

  return (
    <Grid item xs={12}>
      <Grid container direction="column" alignContent="flex-start">
        <Grid container direction="row" alignItems="center" justify="space-between" className={classes.dataRow}>
          <Grid item xs={6}>
            <Grid container direction="column" className={classes.title} justify="space-between">
              <Grid item className={classes.mainTitle}>
                {title}
              </Grid>
              <ValueChangesTitle title={title} value={value} increased={increased} decreased={decreased} subTitle={translation.text_6300} translation={translation} />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column" alignItems="flex-end">
              {partnership && partnership !== 'All' && (
                <Grid item className={classes.partnershipTitle}>
                  {translation.text_6306}: {partnership}
                </Grid>
              )}
              <Grid container direction="row" justify="flex-end" alignItems="center" spacing={3}>
                <Grid item>
                  <DateRangeSelector
                    translation={translation}
                    fromDate={from}
                    toDate={to}
                    handleFromDateChange={(date) => {
                      setFrom(date);
                      if (handleFromDateChange) {
                        handleFromDateChange(date);
                      }
                      setSelectedFilter(defaultFilter);
                    }}
                    handleToDateChange={(date) => {
                      setTo(date);
                      if (handleToDateChange) {
                        handleToDateChange(date);
                      }
                      setSelectedFilter(defaultFilter);
                    }}
                  />
                </Grid>
                {partnership && partnership !== 'All' && (
                  <Grid item>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <SelectControl value={selectedFilter} onChange={handleFilterChange} className={classes.selectBox}>
                        <MenuItem value={defaultFilter} className={classes.optionSelector} disabled>
                          {translation.text_1605}
                        </MenuItem>
                        {quickFilter.map((filter) => (
                          <MenuItem className={classes.optionSelector} key={filter} value={filter}>
                            {filter}
                          </MenuItem>
                        ))}
                      </SelectControl>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.mobileTitle}>
          {partnership && partnership !== 'All' && (
            <Grid className={classes.partnershipTitle}>
              <b>{translation.text_6306}:</b> {partnership}
            </Grid>
          )}
          <Grid item className={classes.mainTitle}>
            {title}
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center" className={classes.mobileDataRow}>
          {fromDate && toDate && (
            <DateRangeSelector
              translation={translation}
              fromDate={fromDate}
              toDate={toDate}
              handleFromDateChange={(date) => {
                setFrom(date);
                if (handleFromDateChange) {
                  handleFromDateChange(date);
                }
                setSelectedFilter(defaultFilter);
              }}
              handleToDateChange={(date) => {
                setTo(date);
                if (handleToDateChange) {
                  handleToDateChange(date);
                }
                setSelectedFilter(defaultFilter);
              }}
            />
          )}
          {partnership && partnership !== 'All' && (
            <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                <SelectControl value={selectedFilter} onChange={handleFilterChange} className={classes.selectBox}>
                  <MenuItem value={defaultFilter} className={classes.optionSelector} disabled>
                    {translation.text_1605}
                  </MenuItem>
                  {quickFilter.map((filter) => (
                    <MenuItem className={classes.optionSelector} key={filter} value={filter}>
                      {filter}
                    </MenuItem>
                  ))}
                </SelectControl>
              </FormControl>
            </Grid>
          )}
          <ValueChangesTitle title={title} value={value} increased={increased} decreased={decreased} subTitle={translation.text_6300} translation={translation} />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" className={classes.divider} />
        </Grid>
      </Grid>
    </Grid>
  );
};
