import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    margin: {
      marginTop: 10,
    },
    emptyMessage: {
      paddingTop: 20,
      paddingBottom: 20,
      fontStyle: 'italic',
    },
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0px 4px',

      '& .MuiTableCell-root': {
        fontSize: '13pt',
      },
    },
  }),
);
