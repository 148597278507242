import { useCallback, useContext } from 'react';
import { AppStateContext } from './AppStateContext';

interface AppDataResult {
  saveToken: (token: string | undefined) => void;
  removeToken: () => void;
  saveCurrentPage: (path: string | undefined) => void;
  pagePath: string | undefined;
  saveUserData: (userData: string | undefined) => void;
  userData: any;
  removeUserData: () => void;
  authToken: any;
  isUserLoggedIn: boolean;
  translations: any;
  saveTranslations: (translations: string | undefined) => void;
  removeTranslations: () => void;
  logoUrl: any;
  saveLogoUrl: (logoUrl: string | undefined) => void;
  removeLogoUrl: () => void;
  currencyList: any;
  saveCurrencyList: (currencies: string | undefined) => void;
  removeCurrencyList: () => void;
  partnershipList: any;
  savePartnershipList: (partnerships: string | undefined) => void;
  removePartnershipList: () => void;
  clientPortalRedirectUrl: string | undefined;
  saveClientPortalRedirectUrl: (url: string | undefined) => void;
  removeClientPortalRedirectUrl: () => void;
  commissionsByClientReportRequestMsgId: string | undefined;
  saveCommissionsByClientReportRequestMsgId: (
    msgId: string | undefined,
  ) => void;
  removeCommissionsByClientReportRequestMsgId: () => void;
  masterIbReportRequestMsgId: string | undefined;
  saveMasterIbReportRequestMsgId: (msgId: string | undefined) => void;
  removeMasterIbReportRequestMsgId: () => void;
  saveLocale: (locale: string) => void;
  locale: string | undefined;
}

export const useAppData: () => AppDataResult = () => {
  const {
    setTokenToStore,
    removeTokenFromStore,
    setCurrentPageInStore,
    getCurrentPage,
    isLoggedIn,
    setUserDataInStore,
    getUserData,
    removeUserDataFromStore,
    getTranslations,
    setTranslationInStore,
    removeTranslationFromStore,
    getLogoUrl,
    setLogoUrlInStore,
    removeLogoUrlFromStore,
    getToken,
    getCurrencyList,
    setCurrenciesInStore,
    removeCurrenciesFromStore,
    getPartnershipList,
    removePartnershipsFromStore,
    setPartnershipsInStore,
    getClientPortalRedirectUrl,
    setClientPortalRedirectUrlInStore,
    removeClientPortalRedirectUrlFromStore,
    getCommissionsByClientReportRequestMsgIdFromStore,
    setCommissionsByClientReportRequestMsgIdInStore,
    removeCommissionsByClientReportRequestMsgIdFromStore,
    getMasterIbReportRequestMsgIdFromStore,
    setMasterIbReportRequestMsgIdInStore,
    removeMasterIbReportRequestMsgIdFromStore,
    saveLocaleInStore,
    getLocale,
  } = useContext(AppStateContext);

  const saveToken = useCallback(
    (token: string | undefined) => setTokenToStore(token),
    [setTokenToStore],
  );
  const removeToken = useCallback(
    () => removeTokenFromStore(),
    [removeTokenFromStore],
  );

  const saveCurrentPage = useCallback(
    (path: string | undefined) => setCurrentPageInStore(path),
    [setCurrentPageInStore],
  );

  const saveUserData = useCallback(
    (userData: any | undefined) => setUserDataInStore(userData),
    [setUserDataInStore],
  );
  const removeUserData = useCallback(
    () => removeUserDataFromStore(),
    [removeUserDataFromStore],
  );

  const saveTranslations = useCallback(
    (translations: string | undefined) => setTranslationInStore(translations),
    [setTranslationInStore],
  );
  const removeTranslations = useCallback(
    () => removeTranslationFromStore(),
    [removeTranslationFromStore],
  );

  const saveLogoUrl = useCallback(
    (logoUrl: string | undefined) => setLogoUrlInStore(logoUrl),
    [setLogoUrlInStore],
  );
  const removeLogoUrl = useCallback(
    () => removeLogoUrlFromStore(),
    [removeLogoUrlFromStore],
  );

  const saveCurrencyList = useCallback(
    (currencies: string | undefined) => setCurrenciesInStore(currencies),
    [setCurrenciesInStore],
  );
  const removeCurrencyList = useCallback(
    () => removeCurrenciesFromStore(),
    [removeCurrenciesFromStore],
  );

  const savePartnershipList = useCallback(
    (partnerships: string | undefined) => setPartnershipsInStore(partnerships),
    [setPartnershipsInStore],
  );
  const removePartnershipList = useCallback(
    () => removePartnershipsFromStore(),
    [removePartnershipsFromStore],
  );

  const saveClientPortalRedirectUrl = useCallback(
    (url: string | undefined) => setClientPortalRedirectUrlInStore(url),
    [setClientPortalRedirectUrlInStore],
  );
  const removeClientPortalRedirectUrl = useCallback(
    () => removeClientPortalRedirectUrlFromStore(),
    [removeClientPortalRedirectUrlFromStore],
  );

  const saveLocale = useCallback(
    (locale: string) => saveLocaleInStore(locale),
    [saveLocaleInStore],
  );
  const locale = getLocale();

  const saveCommissionsByClientReportRequestMsgId = useCallback(
    (msgId: string | undefined) =>
      setCommissionsByClientReportRequestMsgIdInStore(msgId),
    [setCommissionsByClientReportRequestMsgIdInStore],
  );
  const removeCommissionsByClientReportRequestMsgId = useCallback(
    () => removeCommissionsByClientReportRequestMsgIdFromStore(),
    [removeCommissionsByClientReportRequestMsgIdFromStore],
  );
  const saveMasterIbReportRequestMsgId = useCallback(
    (msgId: string | undefined) => setMasterIbReportRequestMsgIdInStore(msgId),
    [setMasterIbReportRequestMsgIdInStore],
  );
  const removeMasterIbReportRequestMsgId = useCallback(
    () => removeMasterIbReportRequestMsgIdFromStore(),
    [removeMasterIbReportRequestMsgIdFromStore],
  );

  const pagePath = getCurrentPage();

  const isUserLoggedIn = isLoggedIn;

  const authToken = getToken();

  const userData = getUserData();

  const translations = getTranslations();

  const logoUrl = getLogoUrl;

  const currencyList = getCurrencyList();

  const partnershipList = getPartnershipList();

  const clientPortalRedirectUrl = getClientPortalRedirectUrl();

  const commissionsByClientReportRequestMsgId =
    getCommissionsByClientReportRequestMsgIdFromStore();
  const masterIbReportRequestMsgId = getMasterIbReportRequestMsgIdFromStore();

  return {
    saveToken,
    removeToken,
    saveCurrentPage,
    pagePath,
    saveUserData,
    removeUserData,
    userData,
    authToken,
    isUserLoggedIn,
    translations,
    saveTranslations,
    removeTranslations,
    logoUrl,
    saveLogoUrl,
    removeLogoUrl,
    currencyList,
    saveCurrencyList,
    removeCurrencyList,
    partnershipList,
    savePartnershipList,
    removePartnershipList,
    clientPortalRedirectUrl,
    saveClientPortalRedirectUrl,
    removeClientPortalRedirectUrl,
    commissionsByClientReportRequestMsgId,
    saveCommissionsByClientReportRequestMsgId,
    removeCommissionsByClientReportRequestMsgId,
    masterIbReportRequestMsgId,
    saveMasterIbReportRequestMsgId,
    removeMasterIbReportRequestMsgId,
    saveLocale,
    locale,
  };
};
