import { instanceApi } from '../../../services';
import { AllClientsAndTradingEmailReport, AllClientsAndTradingEmailRequest } from './AllClientsAndTradingModel';

export const getAllClientsAndTradingReportDetail = (partnershipCode: string, partnerID: number, month: number, year: number, day: number, toMonth: number, toYear: number, toDay: number, currency: string, accountGuid: string): Promise<any> =>
  instanceApi.get(
    `rebate/report/GetAllClientTradingReportDetails?PartnershipCode=${partnershipCode}&PartnerId=${partnerID}&Month=${month}&Year=${year}
    &Day=${day}&EndMonth=${toMonth}&EndYear=${toYear}&EndDay=${toDay}&ConvertToCurrency=${currency}&RequesterAccountGuid=${accountGuid}`,
  );

export const getAllClientsAndTradingReports = (partnerID: number, month: number, year: number, day: number, toMonth: number, toYear: number, toDay: number, currency: string, accountGuid: string): Promise<any> =>
  instanceApi.get(`rebate/report/GetAllClientAndTradingReport?PartnerId=${partnerID}&Month=${month}&Year=${year}&Day=${day}&EndMonth=${toMonth}&EndYear=${toYear}&EndDay=${toDay}&ConvertToCurrency=${currency}&RequesterAccountGuid=${accountGuid}`);

export const sendEmailClientsAndTradingByRequestId = (data: AllClientsAndTradingEmailRequest): Promise<any> => instanceApi.post('rebate/report/SendEmailAllClientAndTradingReportByRequestId', data);

export const sendEmailClientsAndTradingDetails = (data: AllClientsAndTradingEmailReport): Promise<any> => instanceApi.post('rebate/report/SendEmailAllClientTradingReportByReportId', data);
