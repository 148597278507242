import React, { FC, memo, useCallback } from 'react';
import { Grid, Typography } from '@material-ui/core';

import useStyles from './styles';

interface FieldLabelProps {
  label: string | JSX.Element;
  isRequired?: boolean;
  description?: JSX.Element | string;
  icon?: JSX.Element | string;
  style?: any
  classLabel? : string
}

export const FieldLabel: FC<FieldLabelProps> = memo((
  {
    label, isRequired, icon, description = undefined, style, classLabel
  }
) => {
  const classes = useStyles();

  const getLabel = useCallback(() => (
    <Typography className={`${classes.formLabel} ${classLabel}`} align="left" style={style}>
      {label}
      {isRequired ? <span className={classes.formRequired}>*</span> : <></>}
      <span className={classes.icon}>{icon}</span>
    </Typography>
  ), [classes, isRequired, label, icon, style, classLabel]);

  return description
    ? (
      <Grid container justify="space-between">
        <Grid item>{getLabel()}</Grid>
        <Grid item>{description}</Grid>
      </Grid>
    )
    : (
      <>
        {getLabel()}
      </>
    );
});
