import React, { FC } from 'react';
import { Grid, IconButton, List } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { useStyles } from './styles';
import { ValueLabel } from '../../../models';
import { OverflowTip } from '../../OverflowTip';

interface Props {
  list: ValueLabel[],
  onNextButtonClick?: () => void
}

export const MobileTableRow: FC<Props> = ({ list, onNextButtonClick }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row">
      <Grid item className={classes.dataColumn} style={{ marginRight: '8%' }}>
        <List>
          {list.slice(0, Math.ceil(list.length / 2)).map((item: any) => (
            <Grid key={Math.random()} className={classes.row}>
              <Grid item className={classes.label}>{item.label}</Grid>
              <Grid item className={classes.value}>
                <OverflowTip title={item.value}>{item.value}</OverflowTip>
              </Grid>
            </Grid>
          ))}
        </List>
      </Grid>
      <Grid item className={classes.dataColumn}>
        <List>
          {list.slice(Math.ceil(list.length / 2)).map((item: any) => (
            <Grid key={Math.random()} className={classes.row}>
              <Grid item className={classes.label}>{item.label}</Grid>
              <Grid item className={classes.value}>
                <OverflowTip title={item.value}>{item.value}</OverflowTip>
              </Grid>
            </Grid>
          ))}
        </List>
      </Grid>
      {onNextButtonClick && (
      <Grid container alignContent="center" alignItems="center" className={classes.arrowColumn}>
        <IconButton onClick={onNextButtonClick}>
          <ArrowForwardIos className={classes.arrowIcon} fontSize="small" />
        </IconButton>
      </Grid>
      )}
    </Grid>
  );
};
