import { useContext } from 'react';
import { ApiErrorContext } from './ApiErrorContext';

interface ApiErrorResult {
  error: any,
  addError: (message: string) => void
  removeError: () => void
}

export const useApiError: () => ApiErrorResult = () => {
  const { error, addError, removeError } = useContext((ApiErrorContext));

  return {
    error,
    addError,
    removeError
  };
};
