import { makeStyles } from '@material-ui/core/styles';

const useInputStyles = makeStyles((theme) => ({
  root: {
    padding: '8px 13px',
    width: '100%',
    borderRadius: 10,
    border: `solid 1px ${theme.palette.frequent.abalonGrey}`,

    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
    },

    '&.Mui-focused': {
      border: `1px solid ${theme.palette.primary.light}`,
    }
  },
  input: {
    padding: 0,
    '&::placeholder': {
      color: theme.palette.frequent.grey,
      opacity: 1,
    },
  },
}));

const useIconStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  label: {
    color: theme.palette.frequent.grey,
  },
}));

export { useInputStyles, useIconStyles };
