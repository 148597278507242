import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  content: {
    width: '100%',
    flexGrow: 1
  },
  chartSection: {
    margin: '53px 0 0 0',
    position: 'relative',
    height: 300,
    '@media (max-width: 500px)': {
      margin: '10px 0 0 0',
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    justifyContent: 'center'
  },
  checkboxListItem: {
    border: `1px solid ${theme.palette.frequent.chineseWhite}`,
    color: theme.palette.lineGraph.text,
  },
  checkbox: {
    color: theme.palette.frequent.chineseWhite,

    '&.Mui-checked': {
      color: theme.palette.lineGraph.text,
    }
  },
  checkboxListIcon: {
    minWidth: 30
  },
  checkboxText: {
    fontSize: 12
  },
  checkBoxListIconDeposits: {
    color: theme.palette.lineGraph.first
  },
  checkBoxListIconWithdrawals: {
    color: theme.palette.lineGraph.second
  },
  checkBoxListIconNetMi: {
    color: theme.palette.lineGraph.fourth
  },
  checkBoxListIconNetItrs: {
    color: theme.palette.lineGraph.third
  }
}));
