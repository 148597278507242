import React, {
  FC,
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button,
} from '@material-ui/core';

import useStyles from './styles';
import { DialogTitle } from './DialogTitle';
import { DialogProps } from '.';

export const Dialog: FC<DialogProps> = memo(({
  title, subTitle, children, actions, trigger, onClose, className, classTitleContainer, classClose
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!trigger);
  }, [trigger]);

  const handleClose = useCallback(() => {
    setOpen(false);
    if (onClose) { onClose(); }
  }, [onClose]);

  return (
    <MuiDialog
      onClose={onClose ? handleClose : () => {}}
      open={open}
      classes={{
        paper: `${classes.dialogPaper} ${className}`,
        root: classes.dialogContentRoot,
      }}
    >
      <DialogTitle
        onClose={onClose ? handleClose : undefined}
        subChildren={subTitle}
        className={classTitleContainer}
      >
        {title}
      </DialogTitle>

      <MuiDialogContent
        dividers
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        {children}
      </MuiDialogContent>

      {actions
        ? (
          <MuiDialogActions
            classes={{
              root: classes.dialogActionsRoot,
            }}
          >
            {actions.map((action) => (
              <Button
                key={action.key}
                variant="outlined"
                className={classes.btn}
                classes={{
                  root: `${(action.isPrimary ? classes.primaryActionButton : '')} ${classClose}`,
                  label: (action.isPrimary ? classes.primaryActionButtonLabel : classes.secondaryActionButtonLabel),
                }}
                onClick={action.onClick}
                disableRipple
              >
                {action.title}
              </Button>
            ))}
          </MuiDialogActions>
        )
        : <></>}
    </MuiDialog>
  );
});
