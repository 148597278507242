import React, { FC, useCallback } from 'react';
import { ReportModel, ValueLabel } from '../../../../models';
import { MobileTableView } from '../../../../components';

interface Props {
  columnNames: string[];
  selectedColumns: string[];
  rows: ReportModel[];
  onNextClick: (row: any) => void;
}

export const MobilePartnershipReports: FC<Props> = ({
  columnNames,
  selectedColumns,
  rows,
  onNextClick,
}) => {
  const renderRows = useCallback((): any[] => {
    const result: any[] = [];

    if (rows.length === 0) {
      const rowData: ValueLabel[] = [];
      columnNames.forEach((columnName) => {
        const isSelected = selectedColumns.includes(columnName);
        if (isSelected) {
          rowData.push({
            value: '',
            label: columnName,
          });
        }
      });
      result.push({ row: null, list: rowData });
    }

    rows.forEach((row) => {
      const rowData: ValueLabel[] = [];

      if (selectedColumns.includes(columnNames[0])) {
        rowData.push({
          value: row.partnershipCode ? row.partnershipCode : '-',
          label: columnNames[0],
        });
      }

      if (selectedColumns.includes(columnNames[1])) {
        rowData.push({
          value:
            row.reportTotal.rebateUSD || row.reportTotal.rebateUSD === 0
              ? `$${row.reportTotal.rebateUSD.toFixed(2)}`
              : '-',
          label: columnNames[1],
        });
      }

      if (selectedColumns.includes(columnNames[2])) {
        rowData.push({
          value:
            row.totalRebatesUSD || row.totalRebatesUSD === 0
              ? `$${row.totalRebatesUSD.toFixed(2)}`
              : '-',
          label: columnNames[2],
        });
      }

      if (selectedColumns.includes(columnNames[3])) {
        rowData.push({
          value:
            row.paymentUSD || row.paymentUSD === 0
              ? `$${row.paymentUSD.toFixed(2)}`
              : '-',
          label: columnNames[3],
        });
      }

      if (selectedColumns.includes(columnNames[4])) {
        rowData.push({
          value:
            row.reportTotal.profitUSD || row.reportTotal.profitUSD === 0
              ? `$${row.reportTotal.profitUSD.toFixed(2)}`
              : '-',
          label: columnNames[4],
        });
      }

      if (selectedColumns.includes(columnNames[5])) {
        rowData.push({
          value: row.partnershipType ? row.partnershipType : '-',
          label: columnNames[5],
        });
      }

      if (selectedColumns.includes(columnNames[6])) {
        rowData.push({
          value: row.schemeName ? row.schemeName : '-',
          label: columnNames[6],
        });
      }

      if (selectedColumns.includes(columnNames[7])) {
        rowData.push({
          value: row.schemeType ? row.schemeType : '-',
          label: columnNames[7],
        });
      }

      if (selectedColumns.includes(columnNames[8])) {
        rowData.push({
          value: row.subPartnershipsRebateSum
            ? `$${row.subPartnershipsRebateSum.toFixed(2)}`
            : '-',
          label: columnNames[8],
        });
      }

      result.push({ row, list: rowData });
    });

    return result;
  }, [columnNames, selectedColumns, rows]);

  return (
    <MobileTableView
      list={renderRows()}
      grouped={false}
      hasNextButton
      onNextButtonClick={onNextClick}
    />
  );
};
