import React, { FC } from 'react';
import { useStyles } from '../styles';
import { ReactComponent as Close } from '../../../assets/Common/CloseIcon.svg';

export const CloseIcon: FC = () => {
  const classes = useStyles();
  return (
    <Close className={classes.icon} />
  );
};
