import React, { FC } from 'react';
import { ReactComponent as RedArrow } from '../../../assets/Dashboard/RedArrow.svg';
import { useStyles } from '../styles';
import { CustomIconProps } from '../CustomIconModels';

export const RedArrowIcon: FC<CustomIconProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <RedArrow className={`${classes.icon} ${className || ''}`} />
  );
};
