import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: 'left'
  },
  bold: {
    fontWeight: 'bold'
  },
  emptyReportMessageContainer: {
    marginTop: 193
  },
  paper: {
    marginTop: 15,
    padding: 15,
    minHeight: '100vh',
    width: '100%',

    '@media (max-width: 768px)': {
      padding: '15px 10px',
    }
  },
  margin: {
    marginTop: 63
  },
  reportTable: {
    '@media (max-width: 1023px)': {
      display: 'none'
    },
  },
  reportTableMobile: {
    '@media (min-width: 1024px)': {
      display: 'none'
    },
  }
}));
