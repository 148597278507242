import React, { FC } from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { ArrowBackIcon } from '../../CustomIcons';

interface Props {
  title: string
  onBackClick: () => void
}

export const MobileReportTitleWithBackButton: FC<Props> = ({ title, onBackClick }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" alignContent="flex-start" alignItems="center" className={classes.titleContainer}>
      <Typography variant="h5" className={classes.title}><IconButton onClick={onBackClick}><ArrowBackIcon /></IconButton> {title}</Typography>
    </Grid>
  );
};
