import { useContext } from 'react';
import { LoadingContext } from './LoadingContext';

interface LoadingResult {
    isLoading: boolean
    setIsLoading: (loading: boolean) => void
}

export const useLoading: () => LoadingResult = () => {
  const { isLoading, setIsLoading } = useContext((LoadingContext));

  return {
    isLoading,
    setIsLoading
  };
};
