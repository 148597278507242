import { makeStyles } from '@material-ui/core/styles';
import { variables } from '../../../styles';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    color: theme.palette.frequent.fossilGrey,
    fontSize: '13pt',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  formRequired: {
    color: theme.palette.frequent.red,
  },
  icon: {
    color: variables.grey
  }
}));

export default useStyles;
