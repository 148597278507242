import React, {
  FC, useCallback, useEffect, useState
} from 'react';
import MonoStackedBar from 'mono-stacked-bar';
import 'mono-stacked-bar/dist/index.css';
import { useTheme } from '@material-ui/core';

interface Props {
  data: number[]
}

export const StackedLineChart: FC<Props> = ({ data }) => {
  const theme = useTheme();
  const [chartData, setChartData] = useState<number[]>([]);
  const colors = [theme.palette.frequent.pinkLace, theme.palette.frequent.flax, theme.palette.frequent.platinum,
    theme.palette.frequent.aeroBlue, theme.palette.frequent.cornflowerBlue];

  const formatData = useCallback(() => {
    const formattedData: number[] = [];
    const values = data.map((d: any) => Number(d.toFixed(2)));
    const total = values.reduce((v1: number, v2: number) => v1 + v2, 0);
    data.forEach((item) => {
      const percentage = item === 0 ? 0 : (Number(item.toFixed(2)) / total) * 100;
      formattedData.push(percentage);
    });
    setChartData(formattedData);
  }, [data]);

  useEffect(() => {
    formatData();
  }, [data, formatData]);

  return (
    <MonoStackedBar data={chartData} unit="%" colors={colors} radius={6} height={15} luminosity="light" />
  );
};
