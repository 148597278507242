/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { unstable_createMuiStrictModeTheme as createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { variables } from './styles';
import { AppRoute } from './AppRoute';
import { NotificationsProvider, useApiError, useAppData, useLoading } from './providers';
import { getTheme, getThemeByBrokerId } from './layouts';
import { getURLParam } from './utils';
import { ScrollToTop } from './components';

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteOptions {
    frequent: {
      active: string;
      dimGrey: string;
      grey: string;
      red: string;
      green: string;
      background: string;
      cadetBlue: string;
      cultured: string;
      ghostWhite: string;
      blue: string;
      pastelPink: string;
      blackOlive: string;
      wildBlue: string;
      nickel: string;
      boxShadow: string;
      slateGrey: string;
      davyGrey: string;
      chineseWhite: string;
      buttonGrey: string;
      lightWater: string;
      water: string;
      abalonGrey: string;
      greenButton: string;
      greenHoverButton: string;
      lightGreenButton: string;
      pearlGrey: string;
      fossilGrey: string;
      selectorGrey: string;
      selectorOptionGrey: string;
      logOutButtonBlue: string;
      reportMenuButton: string;
      reportMenuButtonCircle: string;
      lightLinkGrey: string;
      darkPastelRed: string;
      pinkLace: string;
      aeroBlue: string;
      platinum: string;
      flax: string;
      cornflowerBlue: string;
      carminePink: string;
      lightSilver: string;
      glaucous: string;
      lightWildBlue: string;
      lavander: string;
    };

    changeable: {
      exportButtons: string;
      hover: string;
      layoutBackground: string;
      insightBgColor: string;
      insightValueTextColor: string;
      reportItemBgColor: string;
      secondaryColor: string;
      secondaryBtnTextColor: string;
      tableHeaderBgColor: string;
      topPanelBorderColorSelected: string;
      topPanelBorderColor: string;
      notification: string;
      newNotification: string;
      userIcon: string;
      userIconHover: string;
      showMoreBtnColor: string;
      showMoreBtnTextColor: string;
      quickLinks: string;
      quickLinksText: string;
      quickLinksBorder: string;
      hierarchyMainColor: string;
      hierarchyPartnershipColor: string;
      hierarchySubPartnershipColor: string;
      hierarchyTerminatedColor: string;
      hierarchyClientsColor: string;
      menuFontColor: string;
      selectedMenuFontColor: string;
      dashboardTopPanelTextColor: string;
      checkIconColor: string;
    };

    lineGraph: {
      first: string;
      second: string;
      third: string;
      fourth: string;
      text: string;
      tooltipText: string;
      tooltipBlue: string;
      tooltipOrange: string;
      tooltipGreen: string;
      tooltipRed: string;
    };
  }

  interface Palette {
    frequent: {
      active: string;
      dimGrey: string;
      grey: string;
      red: string;
      green: string;
      background: string;
      cadetBlue: string;
      cultured: string;
      ghostWhite: string;
      blue: string;
      pastelPink: string;
      blackOlive: string;
      wildBlue: string;
      nickel: string;
      boxShadow: string;
      slateGrey: string;
      davyGrey: string;
      chineseWhite: string;
      buttonGrey: string;
      lightWater: string;
      water: string;
      abalonGrey: string;
      greenButton: string;
      greenHoverButton: string;
      lightGreenButton: string;
      pearlGrey: string;
      fossilGrey: string;
      selectorGrey: string;
      selectorOptionGrey: string;
      logOutButtonBlue: string;
      reportMenuButton: string;
      reportMenuButtonCircle: string;
      lightLinkGrey: string;
      darkPastelRed: string;
      pinkLace: string;
      aeroBlue: string;
      platinum: string;
      flax: string;
      cornflowerBlue: string;
      carminePink: string;
      lightSilver: string;
      glaucous: string;
      lightWildBlue: string;
      lavander: string;
    };

    changeable: {
      exportButtons: string;
      hover: string;
      layoutBackground: string;
      insightBgColor: string;
      insightValueTextColor: string;
      reportItemBgColor: string;
      secondaryColor: string;
      secondaryBtnTextColor: string;
      tableHeaderBgColor: string;
      topPanelBorderColorSelected: string;
      topPanelBorderColor: string;
      notification: string;
      newNotification: string;
      userIcon: string;
      userIconHover: string;
      showMoreBtnColor: string;
      showMoreBtnTextColor: string;
      quickLinks: string;
      quickLinksText: string;
      quickLinksBorder: string;
      hierarchyMainColor: string;
      hierarchyPartnershipColor: string;
      hierarchySubPartnershipColor: string;
      hierarchyTerminatedColor: string;
      hierarchyClientsColor: string;
      menuFontColor: string;
      selectedMenuFontColor: string;
      dashboardTopPanelTextColor: string;
      checkIconColor: string;
    };

    lineGraph: {
      first: string;
      second: string;
      third: string;
      fourth: string;
      text: string;
      tooltipText: string;
      tooltipBlue: string;
      tooltipOrange: string;
      tooltipGreen: string;
      tooltipRed: string;
    };
  }
}

export const Theme: FC = () => {
  const [primary, setPrimary] = useState(variables.main);
  const [hover, setHover] = useState(variables.cobaltBlue);
  const [layoutBackground, setLayoutBackground] = useState(variables.background);
  const [quickLinks, setQuickLinks] = useState(variables.white);
  const [quickLinksText, setQuickLinksText] = useState(variables.font);
  const [quickLinksBorder, setQuickLinksBorder] = useState(variables.font);
  const [exportButtons, setExportButtons] = useState(variables.weldonBlue);
  const [insightBgColor, setInsightBgColor] = useState(variables.dimGrey);
  const [insightValueTextColor, setInsightValueTextColor] = useState(variables.slateGrey);
  const [reportItemBgColor, setReportItemBgColor] = useState(variables.grey);
  const [secondaryColor, setSecondaryColor] = useState(variables.main);
  const [secondaryBtnTextColor, setSecondaryBtnTextColor] = useState(variables.white);
  const [showMoreBtnColor, setShowMoreBtnColor] = useState(variables.main);
  const [showMoreBtnTextColor, setShowMoreBtnTextColor] = useState(variables.white);
  const [tableHeaderBgColor, setTableHeaderBgColor] = useState(variables.cultured);
  const [topPanelBorderColorSelected, setTopPanelBorderColorSelected] = useState(variables.green);
  const [topPanelBorderColor, setTopPanelBorderColor] = useState(variables.grey);
  const [notification, setNotification] = useState(variables.babyBlue);
  const [newNotification, setNewNotification] = useState(variables.logOutButtonBlue);
  const [userIcon, setUserIcon] = useState(variables.blueYounder);
  const [userIconHover, setUserIconHover] = useState(variables.glaucous);
  const [hierarchyMainColor, setHierarchyMainColor] = useState(variables.main);
  const [hierarchyPartnershipColor, setHierarchyPartnershipColor] = useState(variables.green);
  const [hierarchySubPartnershipColor, setHierarchySubPartnershipColor] = useState(variables.lightWildBlue);
  const [hierarchyTerminatedColor, setHierarchyTerminatedColor] = useState(variables.lineGraphOrange);
  const [hierarchyClientsColor, setHierarchyClientsColor] = useState(variables.lavander);
  const [menuFontColor, setMenuFontColor] = useState(variables.wildBlue);
  const [selectedMenuFontColor, setSelectedMenuFontColor] = useState(variables.white);
  const [dashboardTopPanelTextColor, setDashboardTopPanelTextColor] = useState(variables.font);
  const [checkIconColor, setCheckIconColor] = useState(variables.silverFoil);

  const { saveLogoUrl, authToken, removeToken, removeUserData, removeTranslations, removePartnershipList, removeCurrencyList } = useAppData();
  const { addError } = useApiError();
  const [light, setLight] = useState(variables.wildBlue);
  const { setIsLoading } = useLoading();

  const brokerId = getURLParam('BrokerId') || process.env.REACT_APP_BROKER_ID;

  const theme = useMemo(
    () =>
      createMuiTheme({
        spacing: variables.spacing,
        palette: {
          primary: {
            light,
            main: primary,
            dark: variables.gunMetal,
            contrastText: variables.white,
          },
          secondary: {
            light: variables.lightGrey,
            main: variables.darkGrey,
            dark: variables.darkBlue,
            contrastText: variables.black,
          },
          error: {
            main: variables.error,
          },
          common: {
            black: variables.black,
            white: variables.white,
          },
          frequent: {
            active: variables.active,
            dimGrey: variables.dimGrey,
            grey: variables.grey,
            red: variables.red,
            green: variables.green,
            background: variables.background,
            cadetBlue: variables.cadetBlue,
            cultured: variables.cultured,
            ghostWhite: variables.ghostWhite,
            blackOlive: variables.blackOlive,
            blue: variables.blue,
            pastelPink: variables.pastelPink,
            wildBlue: variables.wildBlue,
            nickel: variables.nickel,
            boxShadow: variables.boxShadow,
            slateGrey: variables.slateGrey,
            davyGrey: variables.davyGrey,
            chineseWhite: variables.chineseWhite,
            buttonGrey: variables.buttonGrey,
            lightWater: variables.lightWater,
            water: variables.water,
            abalonGrey: variables.abalonGrey,
            greenButton: variables.greenButton,
            greenHoverButton: variables.greenHoverButton,
            lightGreenButton: variables.lightGreenButton,
            pearlGrey: variables.pearlGrey,
            fossilGrey: variables.fossilGrey,
            selectorGrey: variables.selectorGrey,
            selectorOptionGrey: variables.selectorOptionGrey,
            logOutButtonBlue: variables.logOutButtonBlue,
            reportMenuButton: variables.reportMenuButton,
            reportMenuButtonCircle: variables.reportMenuButtonCircle,
            lightLinkGrey: variables.lightLinkGrey,
            darkPastelRed: variables.darkPastelRed,
            pinkLace: variables.pinkLace,
            aeroBlue: variables.aeroBlue,
            platinum: variables.platinum,
            flax: variables.flax,
            cornflowerBlue: variables.cornflowerBlue,
            carminePink: variables.carminePink,
            lightSilver: variables.lightSilver,
            glaucous: variables.glaucous,
            lightWildBlue: variables.lightWildBlue,
            lavander: variables.lavander,
          },
          changeable: {
            exportButtons,
            layoutBackground,
            hover,
            insightBgColor,
            insightValueTextColor,
            reportItemBgColor,
            secondaryColor,
            secondaryBtnTextColor,
            tableHeaderBgColor,
            topPanelBorderColorSelected,
            topPanelBorderColor,
            notification,
            newNotification,
            userIcon,
            userIconHover,
            showMoreBtnColor,
            showMoreBtnTextColor,
            quickLinks,
            quickLinksText,
            quickLinksBorder,
            hierarchyMainColor,
            hierarchyPartnershipColor,
            hierarchySubPartnershipColor,
            hierarchyTerminatedColor,
            hierarchyClientsColor,
            menuFontColor,
            selectedMenuFontColor,
            dashboardTopPanelTextColor,
            checkIconColor,
          },
          text: {
            primary: variables.font,
            secondary: variables.black,
          },
          lineGraph: {
            first: variables.lineGraphBlue,
            second: variables.lineGraphOrange,
            third: variables.lineGraphGreen,
            fourth: variables.error,
            text: variables.lineGraphText,
            tooltipText: variables.lineGraphTooltipText,
            tooltipBlue: variables.lineGraphTooltipBlue,
            tooltipOrange: variables.lineGraphTooltipOrange,
            tooltipGreen: variables.lineGraphTooltipGreen,
            tooltipRed: variables.lineGraphTooltipRed,
          },
        },
        breakpoints: {
          values: {
            xl: variables.xl,
            lg: variables.lg,
            md: variables.md,
            sm: variables.sm,
            xs: variables.xs,
          },
        },
        overrides: {
          MuiOutlinedInput: {
            root: {
              borderRadius: 10,
            },
          },
          MuiPaper: {
            rounded: {
              borderRadius: 6,
            },
          },
          MuiButton: {
            root: {
              textTransform: 'none',
              borderRadius: 10,
            },
          },
          MuiButtonGroup: {
            root: {
              borderRadius: 10,
            },
          },
        },
        props: {
          MuiSelect: {
            MenuProps: {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            },
          },
        },
        typography: {
          fontSize: 14,
        },
      }),
    [
      primary,
      hover,
      light,
      exportButtons,
      insightBgColor,
      reportItemBgColor,
      secondaryColor,
      secondaryBtnTextColor,
      tableHeaderBgColor,
      topPanelBorderColor,
      insightValueTextColor,
      notification,
      newNotification,
      userIcon,
      userIconHover,
      showMoreBtnColor,
      showMoreBtnTextColor,
      layoutBackground,
      quickLinks,
      quickLinksText,
      quickLinksBorder,
      hierarchyMainColor,
      hierarchyPartnershipColor,
      hierarchySubPartnershipColor,
      hierarchyTerminatedColor,
      hierarchyClientsColor,
      topPanelBorderColorSelected,
      menuFontColor,
      selectedMenuFontColor,
      dashboardTopPanelTextColor,
      checkIconColor,
    ],
  );

  useEffect(() => {
    if (brokerId || authToken) {
      (async () => {
        setIsLoading(true);
        try {
          let response;
          if (brokerId) {
            response = await getThemeByBrokerId(brokerId);
          }
          if (authToken) {
            response = await getTheme();
          }
          if (response) {
            const headlessCmsTheme = response.data;
            setPrimary(headlessCmsTheme.primaryColor);
            setHover(variables.black);
            setLight(variables.nickel);
            setExportButtons(headlessCmsTheme.exportButtons);
            setInsightBgColor(headlessCmsTheme.insightBgColor);
            setInsightValueTextColor(headlessCmsTheme.insightValueTextColor);
            setReportItemBgColor(headlessCmsTheme.reportItemBgColor);
            setSecondaryColor(headlessCmsTheme.secondaryColor);
            setSecondaryBtnTextColor(headlessCmsTheme.secondaryBtnTextColor);
            setTableHeaderBgColor(headlessCmsTheme.tableHeaderBgColor);
            setTopPanelBorderColorSelected(headlessCmsTheme.topPanelBorderColorSelected);
            setTopPanelBorderColor(headlessCmsTheme.topPanelBorderColor);
            setNotification(headlessCmsTheme.notificationBell);
            setNewNotification(headlessCmsTheme.primeColor);
            setUserIcon(headlessCmsTheme.userIcon);
            setUserIconHover(headlessCmsTheme.userIconHover);
            setShowMoreBtnColor(headlessCmsTheme.showMoreBtnColor);
            setShowMoreBtnTextColor(headlessCmsTheme.showMoreBtnTextColor);
            setLayoutBackground(headlessCmsTheme.bgColor);
            setQuickLinks(headlessCmsTheme.quickLinksColor);
            setQuickLinksText(headlessCmsTheme.quickLinksTextColor);
            setQuickLinksBorder(headlessCmsTheme.quickLinksBroderColor);
            saveLogoUrl(headlessCmsTheme.logoUrl);
            setHierarchyMainColor(headlessCmsTheme.hierarchyMainColor);
            setHierarchyPartnershipColor(headlessCmsTheme.hierarchyPartnershipColor);
            setHierarchySubPartnershipColor(headlessCmsTheme.hierarchySubPartnershipColor);
            setHierarchyTerminatedColor(headlessCmsTheme.hierarchyTerminatedColor);
            setHierarchyClientsColor(headlessCmsTheme.hierarchyClientsColor);
            setMenuFontColor(headlessCmsTheme.menuFontColor);
            setSelectedMenuFontColor(headlessCmsTheme.selectedMenuItemTextColor);
            setDashboardTopPanelTextColor(headlessCmsTheme.dashboardTopPanelTextColor);
            setCheckIconColor(headlessCmsTheme.checkIconColor);
          }
        } catch (e) {
          if (e.response.status === 401) {
            removeTranslations();
            removePartnershipList();
            removeCurrencyList();
            removeToken();
            removeUserData();
          }
          addError(JSON.stringify(e.response));
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [saveLogoUrl, authToken, brokerId]);

  useEffect(() => {
    theme.typography.body1 = {
      fontSize: '13pt',
    };
  }, [theme]);

  return (
    <BrowserRouter basename="/#">
      <MuiThemeProvider theme={theme}>
        <ScrollToTop>
          <NotificationsProvider>
            <AppRoute />
          </NotificationsProvider>
        </ScrollToTop>
      </MuiThemeProvider>
    </BrowserRouter>
  );
};
