import { CircleFlag } from 'react-circle-flags';
import React, { ReactNode } from 'react';
import { supportedLocales } from './Constants';

export type Language = {
  value: string;
  label: string;
  icon: ReactNode;
};

export const allLanguages: Language[] = [
  {
    value: 'en',
    label: 'English',
    icon: <CircleFlag countryCode="gb" style={{ height: 'inherit' }} />,
  },
  {
    value: 'ja',
    label: '日本語',
    icon: <CircleFlag countryCode="jp" style={{ height: 'inherit' }} />,
  },
  {
    value: 'fr',
    label: 'Français',
    icon: <CircleFlag countryCode="fr" style={{ height: 'inherit' }} />,
  },
  {
    value: 'de',
    label: 'Deutsch',
    icon: <CircleFlag countryCode="de" style={{ height: 'inherit' }} />,
  },
  {
    value: 'nl',
    label: 'Néerlandais',
    icon: <CircleFlag countryCode="nl" style={{ height: 'inherit' }} />,
  },
  {
    value: 'es',
    label: 'Español',
    icon: <CircleFlag countryCode="es" style={{ height: 'inherit' }} />,
  },
  {
    value: 'pt',
    label: 'Portuguese',
    icon: <CircleFlag countryCode="pt" style={{ height: 'inherit' }} />,
  },
  {
    value: 'ar',
    label: 'العربية',
    icon: <CircleFlag countryCode="ae" style={{ height: 'inherit' }} />,
  },
  {
    value: 'it',
    label: 'Italiano',
    icon: <CircleFlag countryCode="it" style={{ height: 'inherit' }} />,
  },
];

export const languages = allLanguages.filter(({ value }) => supportedLocales.includes(value));
