import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: 98.5,
      width: '100%',

      '@media (max-width: 1180px)': {
        marginTop: 40.5,
      },
    },
    divider: {
      margin: '0 14px 39.5px 14px',
      backgroundColor: theme.palette.frequent.wildBlue,

      '@media (max-width: 1180px)': {
        margin: '0 14px 18.5px 14px',
      },
    },
    listItem: {
      padding: '4px 0',
      height: 48,
      '&.MuiLink-underlineHover:hover': {
        textDecoration: 'none',
      },
    },
    list: {
      height: 192,
      padding: '12px 0',
      marginBottom: 50,
    },
    linkButtons: {
      width: '100%',
      backgroundColor: theme.palette.changeable.quickLinks,
      borderRadius: 20,
      fontSize: 14,
      borderColor: theme.palette.changeable.quickLinksBorder,
      height: 40,
      color: theme.palette.changeable.quickLinksText,

      '& svg path': {
        fill: theme.palette.changeable.quickLinksText,
      },

      '&:hover': {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,

        '& svg path': {
          fill: theme.palette.common.white,
        },
      },
    },
  }),
);
