import { instanceApi } from '../../services';
import { NewRepresentative } from './RepresentativesModels';

export const addRepresentatives = (representative: NewRepresentative): Promise<any> => instanceApi.post('api/RepresentativeManagement/AddRepresentative', representative);

export const getRepresentativesData = (): Promise<any> => instanceApi.get('api/RepresentativeManagement/GetRepresentatives');

export const revokeRepresentative = (id: number | string): Promise<any> => instanceApi.post(`api/RepresentativeManagement/RevokeRepresentativeAccess?rerpesentativeID=${id}`);

export const reSendRepresentativelink = (representative: NewRepresentative): Promise<any> => instanceApi.post('api/RepresentativeManagement/resendinvitation', representative);

export const removePartnershipFromRepresentative = (id: number | string, partnership: string): Promise<any> => instanceApi.post(
  `api/RepresentativeManagement/RemovePartnershipFromRepresentative?rerpesentativeID=${id}&assignPartnershipCode=${partnership}`
);

export const assignPartnershipsToRepresentative = (id: number | string, partnerships: string[]): Promise<any> => instanceApi.post(
  `api/RepresentativeManagement/AssignPartnershipsToRepresentative?rerpesentativeID=${id}`, partnerships
);
