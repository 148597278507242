import React, {
  FC, SyntheticEvent, useCallback
} from 'react';
import { Grid, Link } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useStyles } from './styles';
import { PATH_NOTIFICATIONS } from '../../../utils';
import { NotificationIcon } from '../../CustomIcons';

interface Props {
  notification: any
  closeMenu: () => void
}

export const NotificationMenuItem: FC<Props> = ({ notification, closeMenu }) => {
  const classes = useStyles();
  const history = useHistory();

  const redirectToNotificationsPage = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
    closeMenu();
    history.push(PATH_NOTIFICATIONS, [notification.notification]);
  }, [history, notification, closeMenu]);

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={`${classes.card} ${!notification.notification.isRead && classes.new}`}
    >
      <Grid item xs={10}>
        <Link href="" underline="none" onClick={redirectToNotificationsPage}>
          <Grid container direction="row" alignItems="flex-end">
            <NotificationIcon className={classes.image} />
            <Grid item xs={9} className={classes.message}>
              <Grid item className={classes.title}>{notification.message}</Grid>
              <Grid item className={classes.time}>{notification.receivedTime}</Grid>
            </Grid>
          </Grid>
        </Link>
      </Grid>
    </Grid>
  );
};
