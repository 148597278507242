import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  formLabel: {
    marginBottom: theme.spacing(1),
    color: theme.palette.common.black,
    fontSize: '13pt',
    marginTop: theme.spacing(1.66),
  },
  input: {
    padding: theme.spacing(0),
    display: 'inline-block',
  },
}));

export default useStyles;
