import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    dialog: {
      minWidth: 1300,
    },
    login: {
      borderRadius: '15px 0 0 15px',
    },
    symbolCategory: {
      borderRadius: 0,
    },
    symbol: {
      borderRadius: '0 15px 15px 0',
    },
    selectedGroup: {
      backgroundColor: theme.palette.frequent.cultured,
    },
    margin: {
      marginBottom: 30,
    },
  }),
);
