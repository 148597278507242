import React, { FC } from 'react';
import { useStyles } from '../styles';
import { ReactComponent as Close } from '../../../assets/Common/CloseIconRedFilled.svg';

interface Props {
  height?: number;
  width?: number;
}

export const CloseIconRedFilled: FC<Props> = ({ height, width }) => {
  const classes = useStyles();
  return (
    <Close className={classes.icon} height={height} width={width} />
  );
};
