import React, { FC } from 'react';
import { useStyles } from '../styles';
import { ReactComponent as PDFFile } from '../../../assets/ExportButtons/PDFFile.svg';

interface Props {
  height?: number;
  width?: number;
}

export const PDFFileIcon: FC<Props> = ({ height, width }) => {
  const classes = useStyles();
  return (
    <PDFFile className={classes.icon} height={height} width={width} />
  );
};
