export interface IMessage {
  messageId:string;
  messageContent:any;
  messagetype:MessageType;
}

export enum MessageType {
    REBATES_REPORT_REQUEST_REPLY,
    PARTNERS_DASHBOARD_REBATES_REQUEST,
    REBATES_REPORT_REQUEST_SELECTED_PSHIPS_REPLY
}

export interface ReportMessageContent {
  PartnerId: number | string | null,
  Month: number,
  Year: number,
  Day: number,
  EndMonth: number,
  EndYear: number,
  EndDay: number,
  ConvertToCurrency: string,
  RequestorAccountGuid: string
}

export enum MessageMethod {
  SEND = 'Send',
  RECEIVE = 'Receive'
}
