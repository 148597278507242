import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      transform: 'scale(1.2)',
    },
    user: {
      '& path': {
        fill: theme.palette.changeable.userIcon,
      },
    },
  }),
);
