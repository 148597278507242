import React, {
  forwardRef, PropsWithChildren, useEffect, useState
} from 'react';
import { useStyles } from './styles';

interface Props {
  columnNumber: number
  title: string
}

export const ComponentToPrint = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (props, ref) => {
    const classes = useStyles();
    const [fontSize, setFontSize] = useState<string>(classes.defaultFontSize);

    useEffect(() => {
      let fontSizeClass = classes.defaultFontSize;
      if (props.columnNumber === 10) {
        fontSizeClass = classes.fontSize10Columns;
      }

      if (props.columnNumber === 11) {
        fontSizeClass = classes.fontSize11Columns;
      }

      if (props.columnNumber === 12) {
        fontSizeClass = classes.fontSize12Columns;
      }

      if (props.columnNumber === 13) {
        fontSizeClass = classes.fontSize13Columns;
      }

      if (props.columnNumber === 14) {
        fontSizeClass = classes.fontSize14Columns;
      }

      if (props.columnNumber === 15 || props.columnNumber > 15) {
        fontSizeClass = classes.fontSize15Columns;
      }

      if (props.columnNumber === 18 || props.columnNumber > 18) {
        fontSizeClass = classes.fontSize18Columns;
      }

      setFontSize(fontSizeClass);
    }, [props, classes]);

    return (
      <div className={`${classes.content} ${fontSize}`} ref={ref}>
        <p className={classes.title}>{props.title}</p>
        {props.children}
      </div>
    );
  }
);
