import React, { FC, memo } from 'react';
import { useStyles } from '../styles';
import { ReactComponent as LinkDetails } from '../../../assets/PartnershipLinkDialog/LinkDetails.svg';

interface LinkDetailsIconProps {
  width?: number
}

export const LinkDetailsIcon: FC<LinkDetailsIconProps> = memo(({
  width
}) => {
  const classes = useStyles();
  return (
    <LinkDetails className={classes.icon} width={width} height={width} />
  );
});
