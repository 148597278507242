import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  title: {
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  mobileTitle: {
    '@media (min-width: 1025px)': {
      display: 'none'
    }
  },
  mainTitle: {
    color: theme.palette.frequent.davyGrey,
    fontSize: 26,
    textAlign: 'left',
    fontWeight: 400,

    '@media (max-width: 1024px)': {
      fontSize: 20,
      marginBottom: 20,
      textAlign: 'center',
    }
  },
  partnershipTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.frequent.green,

    '@media (max-width: 840px)': {
      textAlign: 'center',
      marginBottom: 10,
      fontSize: 14,
    }
  },
  // valueTitle: {
  //   fontSize: 28,
  //   fontWeight: 700,
  //   color: theme.palette.text.primary,
  //
  //   '@media (max-width: 427px)': {
  //     fontSize: 21,
  //   },
  // },
  // changeTitle: {
  //   paddingLeft: 15,
  //   color: theme.palette.lineGraph.text,
  //   fontSize: 16,
  //
  //   '@media (max-width: 427px)': {
  //     paddingLeft: 10,
  //     fontSize: 12,
  //   },
  // },
  // values: {
  //   maxWidth: '61%',
  //
  //   '@media (max-width: 1024px)': {
  //     maxWidth: 'unset',
  //     justifyContent: 'center'
  //   }
  // },
  // dates: {
  //
  //   '@media (max-width: 1024px)': {
  //     marginBottom: 28
  //   }
  // },
  // toDate: {
  //   maxWidth: 135,
  //
  //   '@media (max-width: 428px)': {
  //     maxWidth: 118,
  //   },
  //
  //   '& .MuiOutlinedInput-notchedOutline': {
  //     borderColor: theme.palette.secondary.light,
  //   },
  //
  //   '& .MuiOutlinedInput-root': {
  //     borderTopLeftRadius: 0,
  //     borderBottomLeftRadius: 0,
  //     fontSize: 14,
  //
  //     '& .MuiTypography-root': {
  //       fontSize: 14,
  //     },
  //
  //     '@media (max-width: 428px)': {
  //       fontSize: 12,
  //
  //       '& .MuiTypography-root': {
  //         fontSize: 12,
  //       }
  //     }
  //   }
  // },
  // fromDate: {
  //   maxWidth: 148,
  //
  //   '@media (max-width: 428px)': {
  //     maxWidth: 132,
  //   },
  //
  //   '& .MuiOutlinedInput-notchedOutline': {
  //     borderColor: theme.palette.secondary.light
  //   },
  //
  //   '& .MuiOutlinedInput-root': {
  //     borderTopRightRadius: 0,
  //     borderBottomRightRadius: 0,
  //     fontSize: 14,
  //
  //     '& .MuiTypography-root': {
  //       fontSize: 14,
  //     },
  //
  //     '@media (max-width: 428px)': {
  //       fontSize: 12,
  //
  //       '& .MuiTypography-root': {
  //         fontSize: 12,
  //       }
  //     }
  //   }
  // },
  dataRow: {
    justifyContent: 'space-between',
    // height: 61,

    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  mobileDataRow: {
    justifyContent: 'space-between',
    marginBottom: 24,

    '@media (min-width: 1025px)': {
      display: 'none'
    }
  },
  divider: {
    backgroundColor: theme.palette.frequent.chineseWhite,
    margin: '14px 0 16px 0',

    '@media (max-width: 840px)': {
      display: 'none'
    }
  },
  // changeTitleIcon: {
  //   width: 15,
  //   height: 17
  // },
  // noChangeTitleIcon: {
  //   height: 10
  // },
  emptyMessage: {
    color: theme.palette.lineGraph.text,
    fontStyle: 'italic'
  },
  // errorMessage: {
  //   fontSize: 10,
  //   textAlign: 'right',
  //   color: theme.palette.error.main,
  //   paddingTop: 5
  // },
  // dateLabel: {
  //   fontWeight: 500
  // },
  // formControl: {
  //   minWidth: 165,
  //   flexDirection: 'row',
  //
  //   '@media (max-width: 1180px)': {
  //     minWidth: 'unset'
  //   }
  // },
  // selectBox: {
  //   border: `1px solid ${theme.palette.secondary.light}`,
  //   boxShadow: 'none',
  //   fontSize: 14,
  //   margin: 0,
  //
  //   '&:hover': {
  //     border: `1px solid ${theme.palette.frequent.nickel}`,
  //   }
  // },
  // optionSelector: {
  //   fontSize: 14,
  //
  //   '&.MuiListItem-root.Mui-selected': {
  //     backgroundColor: theme.palette.frequent.selectorOptionGrey
  //   },
  //   '&.MuiListItem-root.Mui-selected:hover': {
  //     backgroundColor: theme.palette.frequent.selectorOptionGrey
  //   },
  //
  //   '@media (max-width: 1180px)': {
  //     fontSize: 12,
  //     padding: '0 8px'
  //   },
  // },
}));
