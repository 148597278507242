import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,

    '@media (max-width: 1180px)': {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  filterContainer: {
    paddingBottom: 5,
    justifyContent: 'space-between',
  },
  title: {
    display: 'inline-block',
    fontSize: 14,

    '@media (max-width: 1180px)': {
      fontSize: 13
    }
  },
  bigTitle: {
    display: 'inline-block',
    marginLeft: 7,
    marginRight: 5,
    marginBottom: 0,
    marginTop: 4,
    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.frequent.davyGrey,

    '@media (max-width: 1180px)': {
      fontSize: 20,
      marginLeft: 0
    }
  },
  titleIcon: {
    '@media (min-width: 1181px)': {
      display: 'none'
    },

    '& svg path': {
      fill: theme.palette.frequent.nickel,
      marginTop: 5
    },
  },
  formControlTitle: {
    display: 'inline',
    paddingRight: 10,
    fontWeight: 500,
    fontSize: 18,
    minWidth: 165,
    textAlign: 'right',

    '@media (max-width: 1180px)': {
      fontSize: 12
    },

    '@media (max-width: 745px)': {
      minWidth: 'unset',
      display: 'block',
      textAlign: 'left',
    },
    '@media (max-width: 380px)': {
      fontSize: 10
    },
  },
  currencyFormControlTitle: {
    display: 'inline',
    paddingRight: 10,
    fontWeight: 500,
    fontSize: 18,
    minWidth: 90,
    textAlign: 'right',

    '@media (max-width: 1180px)': {
      fontSize: 12
    },

    '@media (max-width: 745px)': {
      minWidth: 'unset',
      display: 'block',
      textAlign: 'left',
    },
    '@media (max-width: 380px)': {
      fontSize: 10
    }
  },
  container: {
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 100,
    width: '100%',
    margin: '0 auto',
  },
  desktopContainer: {
    '@media (max-width: 599px)': {
      display: 'none'
    },
  },
  mobileContainer: {
    padding: 9,
    '@media (min-width: 600px)': {
      display: 'none'
    }
  },
  formControl: {
    minWidth: 175,
    marginRight: 3,
    flexDirection: 'row',

    '@media (max-width: 1180px)': {
      marginTop: 8,
      minWidth: 'unset'
    }
  },
  paperWithoutHover: {
    padding: '19px 16px 32px 25px',
    textAlign: 'center',
    height: 'auto',
    marginBottom: 15,
    boxShadow: `0 10px 20px 0 ${theme.palette.frequent.boxShadow}`,
    cursor: 'context-menu',
    '@media (max-width: 840px)': {
      padding: '19px 13px 32px',
    }
  },
  divider: {
    backgroundColor: theme.palette.secondary.light,
    margin: '2px 0px 22px 0px',
    height: 1
  },
  bold: {
    fontWeight: 700
  },
  optionSelector: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.frequent.selectorOptionGrey
    },
    '&.MuiListItem-root.Mui-selected:hover': {
      backgroundColor: theme.palette.frequent.selectorOptionGrey
    },

    '@media (max-width: 1180px)': {
      fontSize: 12,
      padding: '0 8px'
    }
  },
  footNote: {
    textAlign: 'right',
    color: theme.palette.frequent.lightLinkGrey,
    fontSize: 13,
    // marginLeft: 9,
    // marginRight: 9,

    '@media (max-width: 768px)': {
      fontSize: 11,
    },

    '@media (max-width: 300px)': {
      fontSize: 8,
    },
  },
  selectControl: {

    '@media (max-width: 1180px)': {
      fontSize: 12,
      height: '28px'
    },
    '@media (max-width: 380px)': {
      fontSize: 10,
      height: '20px'
    }
  },

  formControlContainer: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'flex-start'
  }
}),);
