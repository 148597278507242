import React, { FC } from 'react';
import { useStyles } from '../styles';
import { ReactComponent as BrandedSpinner } from '../../../assets/Spinner/Branded_Spinner.svg';

interface Props {
  className: string
}

export const SpinnerIcon: FC<Props> = ({ className }) => {
  const classes = useStyles();
  return (
    <BrandedSpinner className={`${classes.icon} ${className}`} />
  );
};
