export interface LandingPage {
  id: string
  name: string
  url: string
}

export const emptyLandingPage = () : LandingPage => ({
  id: '',
  name: '',
  url: '',
});
