import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  card: {
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.frequent.background}`,
    fontSize: 14,
    paddingTop: 14,
    paddingBottom: 21,
    marginBottom: 0,

    '&:hover': {
      backgroundColor: theme.palette.frequent.background
    },
  },
  new: {
    backgroundColor: theme.palette.frequent.pearlGrey
  },
  image: {
    margin: '3px 8px 0px 18px',
  },
  message: {
    textAlign: 'start',
  },
  title: {
    fontWeight: 500,
    fontSize: 16
  },
  time: {
    color: theme.palette.frequent.fossilGrey,
    fontSize: 14
  }
}));
