import React, { createRef, FC } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { CssBaseline, IconButton } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import {
  PATH_IB_DASHBOARD,
  PATH_PARTNERS_REPRESENTATIVES,
  PATH_COMMISSIONS_BY_CLIENT,
  PATH_CLIENTS_LIST,
  PATH_TRADING_SUMMARY,
  PATH_ALL_CLIENTS_AND_TRADING,
  PATH_CLOSED_POSITIONS,
  PATH_MASTER_IB_COMMISSION,
  PATH_PARTNERS_HIERARCHY,
  PATH_REPORTS_LIST,
  PATH_NOTIFICATIONS,
  PATH_LOGIN_AS_PARTNER,
  PATH_LOGIN_AS_REPRESENTATIVE,
  PATH_SIGN_UP,
  PATH_TRANSACTIONS_REPORT,
  PATH_OPEN_CLOSE_POSITIONS_SUMMARY,
  PATH_TRADING_ACTIVITIES_REPORT,
  PATH_ERROR_PAGE,
  extractLocaleFromPath,
  PATH_WELCOME,
  pathWithOrWithoutLocale,
} from './utils';
import {
  WelcomePage,
  IBDashboard,
  CommissionsByClient,
  ReportsList,
  ClientsList,
  TradingSummary,
  AllClientsAndTrading,
  ClosedPositions,
  MasterIbCommission,
  PartnersHierarchy,
  Representatives,
  NotificationsPage,
  LoginAsPartner,
  LoginAsRepresentative,
  RepresentativeSignUp,
  TransactionsReport,
  OpenClosedPositionsSummary,
  TradingActivitiesReport,
  ErrorPage,
} from './pages';
import { AuthRoute, CheckIcon, CloseIcon, InfoIcon, SpinnerWithMessage } from './components';
import { GlobalStyles } from './styles';
import { useStyles } from './appRouteStyles';

export const AppRoute: FC = () => {
  const classes = useStyles();
  const notistackRef = createRef<any>();
  const location = useLocation();
  const lang = extractLocaleFromPath(location.pathname);

  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      maxSnack={4}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      iconVariant={{
        success: <CheckIcon />,
        error: <CloseIcon />,
        info: <InfoIcon />,
      }}
      ref={notistackRef}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon />
        </IconButton>
      )}
    >
      <CssBaseline />
      <GlobalStyles />
      <SpinnerWithMessage />
      <Route exact path={pathWithOrWithoutLocale(PATH_WELCOME, lang)} component={WelcomePage} />
      <Route exact path={PATH_LOGIN_AS_PARTNER} component={LoginAsPartner} />
      <Route exact path={PATH_LOGIN_AS_REPRESENTATIVE} component={LoginAsRepresentative} />
      <Route exact path={pathWithOrWithoutLocale(PATH_SIGN_UP, lang)} component={RepresentativeSignUp} />
      <Route exact path={pathWithOrWithoutLocale(PATH_ERROR_PAGE, lang)} component={ErrorPage} />
      <Switch>
        <AuthRoute path={PATH_IB_DASHBOARD} Component={IBDashboard} />
        <AuthRoute path={PATH_PARTNERS_HIERARCHY} Component={PartnersHierarchy} />
        <AuthRoute path={PATH_PARTNERS_REPRESENTATIVES} Component={Representatives} />
        <AuthRoute path={PATH_COMMISSIONS_BY_CLIENT} Component={CommissionsByClient} />
        <AuthRoute path={PATH_TRADING_SUMMARY} Component={TradingSummary} />
        <AuthRoute path={PATH_REPORTS_LIST} Component={ReportsList} />
        <AuthRoute path={PATH_CLIENTS_LIST} Component={ClientsList} />
        <AuthRoute path={PATH_ALL_CLIENTS_AND_TRADING} Component={AllClientsAndTrading} />
        <AuthRoute path={PATH_CLOSED_POSITIONS} Component={ClosedPositions} />
        <AuthRoute path={PATH_MASTER_IB_COMMISSION} Component={MasterIbCommission} />
        <AuthRoute path={PATH_NOTIFICATIONS} Component={NotificationsPage} />
        <AuthRoute path={PATH_TRANSACTIONS_REPORT} Component={TransactionsReport} />
        <AuthRoute path={PATH_OPEN_CLOSE_POSITIONS_SUMMARY} Component={OpenClosedPositionsSummary} />
        <AuthRoute path={PATH_TRADING_ACTIVITIES_REPORT} Component={TradingActivitiesReport} />
      </Switch>
    </SnackbarProvider>
  );
};
