import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: theme.palette.common.white,
  },

  rotateIcon: {
    fill: '#9ee2d1e6',
    height: 75,
    animation: '$spin 2s linear infinite'
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(-360deg)'
    },
    '100%': {
      transform: 'rotate(0deg)'
    }
  }
}));
