import React, { FC } from 'react';
import { Divider, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { ValueChangesTitle } from '../../../../../../components';

interface Props {
  title: string;
  partnership?: string;
  value: string;
  increased?: number;
  decreased?: number;
  translation: any
}

export const WithdrawalPanelTitle: FC<Props> = ({
  title, partnership, value, increased, decreased, translation
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid container direction="column" alignContent="flex-start">
        <Grid container direction="row" alignItems="center" className={classes.title} justify="space-between">
          <Grid item className={classes.mainTitle}>{title}</Grid>
          {partnership && partnership !== 'All' && (
            <Grid item className={classes.partnershipTitle}>{translation.text_6306}: {partnership}</Grid>)}
        </Grid>
        <Grid container direction="column" className={classes.mobileTitle}>
          {partnership && partnership !== 'All' && (
            <Grid className={classes.partnershipTitle}><b>{translation.text_6306}:</b> {partnership}</Grid>)}
          <Grid item className={classes.mainTitle}>{title}</Grid>
        </Grid>
        <Grid container direction="row" className={classes.dataRow}>
          <ValueChangesTitle title={title} value={value} increased={increased} decreased={decreased} subTitle={translation.text_6300} translation={translation} />
        </Grid>
        <Grid container direction="column" alignItems="center" className={classes.mobileDataRow}>
          <ValueChangesTitle title={title} value={value} increased={increased} decreased={decreased} subTitle={translation.text_6300} translation={translation} />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" className={classes.divider} />
        </Grid>
      </Grid>
    </Grid>
  );
};
