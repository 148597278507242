import { Observable } from 'rxjs';
import { IMessage, MessageMethod } from '../models';
import { useSignalr } from './signalr';

interface signalerResult {
    send: (methodName: string, arg?: unknown) => Promise<void>,
    on: <TMessage = unknown>(methodName: string) => Observable<TMessage>;
    notify: (message: IMessage, methodName?: string) => void
}

export const useSignalrService: () => signalerResult = () => {
  const signalrEndpoint = `${process.env.REACT_APP_SIGNALER_ENDPOINT}`;

  // //  preffereable  to have  an action
  // const storageString = sessionStorage.getItem('__STATE_MACHINE__');
  // let token = '';
  // if (storageString) {
  //   const storage = JSON.parse(storageString);
  //   token = storage.authToken;
  // }

  const { send, on } = useSignalr(signalrEndpoint);

  const notify = (message: IMessage, methodName?: string) => {
    message.messageContent = JSON.stringify(message.messageContent);

    send(methodName ?? MessageMethod.SEND, message);
  };

  return { send, on, notify };
};
