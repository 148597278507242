import moment from 'moment';
import { useCallback } from 'react';
import { getSelectedColumnsInEn, getTranslatedColumnsInCorrectOrder } from 'src/utils';
import { useApiError, useAppData, useLoading } from 'src/providers';
import { ReportMessageContent } from 'src/models';
import { useSnackbar } from 'notistack';
import { sendEmail } from '../CommissionsByClientService';
import { reportEnColumnsRequestId } from '../CommissionsByClientModel';

const useSendEmail = ({ toDate, fromDate, selected, reportColumns, selectedLanguage }: { toDate: Date; fromDate: Date; selected: string[]; reportColumns: any[]; selectedLanguage?: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setIsLoading } = useLoading();
  const { userData, translations } = useAppData();
  const { addError } = useApiError();

  return useCallback(async () => {
    const loginData = JSON.parse(userData);

    const messageContent: ReportMessageContent = {
      PartnerId: loginData.PartnerID,
      Month: fromDate.getMonth() + 1,
      Year: fromDate.getFullYear(),
      Day: fromDate.getDate(),
      EndMonth: toDate.getMonth() + 1,
      EndYear: toDate.getFullYear(),
      EndDay: toDate.getDate(),
      ConvertToCurrency: 'USD',
      RequestorAccountGuid: loginData.defaultAccount,
    };

    setIsLoading(true);
    try {
      const tableColumns = getSelectedColumnsInEn(reportEnColumnsRequestId, selected, reportColumns);
      await sendEmail({
        ...messageContent,
        reportEmailRequest: {
          email: '',
          startDate: moment(fromDate).format('YYYY-MM-DD'),
          endDate: moment(toDate).format('YYYY-MM-DD'),
          includeTotal: true,
          language: selectedLanguage || 'en',
          tableColumns,
          translatedTableColumns: getTranslatedColumnsInCorrectOrder(selected, reportColumns),
        },
      });

      enqueueSnackbar(`${JSON.parse(translations).text_1640} ${loginData.userEmail}.`, {
        variant: 'success',
      });
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  }, [userData, fromDate, toDate, setIsLoading, selected, reportColumns, selectedLanguage, enqueueSnackbar, translations, addError]);
};

export default useSendEmail;
