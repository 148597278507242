/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC, useCallback, useEffect, useMemo, useState
} from 'react';
import { Button, Grid, useTheme } from '@material-ui/core';
import { cloneDeep, groupBy } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { usePrevious } from 'src/hooks/usePrevious';
import { useStyles } from '../styles';
import { PanelTitle } from '../../../../components';
import { useCommonStyles } from '../../../../styles';
import { useApiError, useLoading } from '../../../../providers';
import { getHighlightData } from '../../IBDashboardService';
import { HighlightTypes } from '../../IBDashboardModels';
import { PATH_COMMISSIONS_BY_CLIENT, formatNumber, getTranslation } from '../../../../utils';
import { GraphArea, YearMonthList } from './components';

interface Props {
  partnership: string;
  currency?: string;
  value: number;
  translation: any
}

const order = { Commissions: 1, Withdrawals: 2, Deposits: 3 };

export const CommissionsPanel: FC<Props> = ({
  currency, value, partnership, translation
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonClasses = useCommonStyles();
  const currentDate = useMemo(() => new Date(), []);
  const [fromDate, setFromDate] = useState<Date>(moment().startOf('year').toDate());
  const [toDate, setToDate] = useState<Date>(currentDate);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('Year');
  const [tableData, setTableData] = useState<any>([]);
  const [selectedYear, setSelectedYear] = useState<any>();
  const [chartData, setChartData] = useState<any>([]);
  const [yearlyChartData, setYearlyChartData] = useState<any>([]);
  const [monthlyChartData, setMonthlyChartData] = useState<any>([]);
  const checkboxes = useMemo(() => [translation.text_1600, translation.text_1601, translation.text_1602], [translation]);
  const previousCheckboxes = usePrevious(checkboxes);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [increased, setIncreased] = useState<number | undefined>();
  const [decreased, setDecreased] = useState<number | undefined>();
  const [sum, setSum] = useState<number>(0);
  const { addError } = useApiError();
  const { setIsLoading } = useLoading();
  const [colors, setColors] = useState<any>([theme.palette.lineGraph.first, theme.palette.lineGraph.second, theme.palette.lineGraph.third]);

  const modifyData = useCallback((values: any[]) => {
    const currentMonth = currentDate.getMonth() + 1;
    const commissions = values.find((item) => item.highlightType === 'Commissions');
    const data = commissions ? cloneDeep(commissions.yearData) : [];
    data.sort((a: any, b: any) => b.period.localeCompare(a.period));

    const monthData = commissions ? cloneDeep(commissions.monthData) : [];
    const monthYearArr: any = [];

    monthData.forEach((item: any) => {
      const arr = item.period.split('-');
      let itemIncreased = 0;
      let itemDecreased = 0;

      if (item.increased < 0) {
        itemDecreased = Math.abs(item.increased);
      } else {
        itemIncreased = item.increased;
      }

      if (currentDate.getFullYear().toString() === arr[0] && currentMonth.toString() === arr[1]) {
        setIncreased(itemIncreased);
        setDecreased(itemDecreased);
      }

      const newObj = {
        year: arr[0],
        month: arr[1],
        period: item.period,
        amount: item.amount,
        increased: itemIncreased,
        decreased: itemDecreased
      };

      monthYearArr.push(newObj);
    });

    const grouped = groupBy(monthYearArr, 'year');

    data.forEach((item: any) => {
      let itemIncreased = 0;
      let itemDecreased = 0;

      if (item.increased < 0) {
        itemDecreased = Math.abs(item.increased);
      } else {
        itemIncreased = item.increased;
      }
      item.decreased = itemDecreased;
      item.increased = itemIncreased;

      if (grouped[item.period]) {
        item.monthData = grouped[item.period];
      }
    });

    setTableData(data);
    let result = 0;
    data.forEach((item: any) => {
      result += item.amount;
    });

    if (result !== 0) {
      if (sum !== 0) {
        if (sum < result) {
          setIncreased(Math.abs(Math.round((((result - sum) / sum) * 100))));
          setDecreased(0);
        } else {
          setDecreased(Math.abs(Math.round((((sum - result) / sum) * 100))));
          setIncreased(0);
        }
      }
      setSum(result);
    }

    const yearlyChart: any = [];
    const monthlyChart: any = [];

    values.forEach((item: any) => {
      if (item.highlightType === 'Withdrawals') {
        item.yearData.map((year: any) => year.amount = Math.abs(year.amount));
      }
      const color = item.highlightType === 'Commissions' ? theme.palette.lineGraph.first : (item.highlightType === 'Withdrawals' ? theme.palette.lineGraph.second : theme.palette.lineGraph.third);
      yearlyChart.push({
        id: item.highlightType,
        color,
        data: item.yearData
      });
    });

    values.forEach((item: any) => {
      if (item.highlightType === 'Withdrawals') {
        item.monthData.map((month: any) => month.amount = Math.abs(month.amount));
      }
      const color = item.highlightType === 'Commissions' ? theme.palette.lineGraph.first : (item.highlightType === 'Withdrawals' ? theme.palette.lineGraph.second : theme.palette.lineGraph.third);
      monthlyChart.push({
        id: item.highlightType,
        color,
        data: item.monthData
      });
    });

    yearlyChart.sort((a: any, b: any) => (order as any)[a.id] - (order as any)[b.id]);
    monthlyChart.sort((a: any, b: any) => (order as any)[a.id] - (order as any)[b.id]);

    setYearlyChartData(yearlyChart);
    setMonthlyChartData(monthlyChart);
    setChartData(selectedPeriod === 'Year' ? cloneDeep(yearlyChart) : cloneDeep(monthlyChart));
  }, [currentDate, sum, value, selectedPeriod]);

  const getHighLightData = useCallback(async (partnershipCode: string, from: Date, to: Date, selected: string[]) => {
    setIsLoading(true);
    try {
      const data: any = [];
      await Promise.all(selected.map(async (checkbox) => {
        const highlightType = checkbox === translation.text_1600 ? HighlightTypes.Commissions
          : (checkbox === translation.text_1601 ? HighlightTypes.Withdrawals : HighlightTypes.Deposits);
        const response = await getHighlightData(from, to, partnershipCode === 'All' ? '' : partnershipCode, highlightType);
        data.push(response.data);
      }));
      modifyData(data);
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  }, [addError, modifyData, translation]);

  useEffect(() => {
    if (fromDate <= toDate) {
      (async () => {
        await getHighLightData(partnership, fromDate, toDate, selectedCheckboxes);
      })();
    }
  }, [partnership, fromDate, toDate, selectedCheckboxes, currency]);

  const handleSelectPeriod = useCallback((year: string, data: any) => {
    if (selectedYear !== year) {
      setSelectedYear(year);
      setSelectedPeriod('Month');
      if (selectedCheckboxes.length > 1) {
        const newChartData: any = [];
        cloneDeep(monthlyChartData).forEach((item: any) => {
          const isSelected = selectedCheckboxes.filter((checkbox) => checkbox === getTranslation(translation, item.id)).length > 0;
          if (isSelected) {
            const monthData: any = [];
            item.data.forEach((month: any) => {
              const arr = month.period.split('-');
              if (arr[0] === year) {
                monthData.push(month);
              }
            });
            newChartData.push({ id: item.id, data: monthData });
          }
        });
        setChartData(newChartData);
      } else {
        setChartData(cloneDeep([{
          id: 'Commissions',
          data: data || []
        }]));
      }
    } else {
      setSelectedYear('');
      setSelectedPeriod('Year');
      setChartData(cloneDeep(yearlyChartData));
    }
  }, [selectedYear, yearlyChartData, monthlyChartData, selectedCheckboxes]);

  const handlePeriodSelect = useCallback((period: string) => {
    if (period === 'Year') {
      setChartData(cloneDeep(yearlyChartData));
    } else {
      setChartData(cloneDeep(monthlyChartData));
    }
    setSelectedPeriod(period);
  }, [yearlyChartData, monthlyChartData]);

  const handleCheckboxesChanges = useCallback(
    (selected: string[]) => {
      setSelectedYear('');
      setSelectedPeriod('Year');
      const graphColors: string[] = [];
      selected.forEach((s) => {
        if (s === checkboxes[0]) {
          graphColors[0] = theme.palette.lineGraph.first;
        }
        if (s === checkboxes[1]) {
          graphColors[1] = theme.palette.lineGraph.second;
        }
        if (s === checkboxes[2]) {
          graphColors[2] = theme.palette.lineGraph.third;
        }
      });

      setColors(graphColors.filter((value) => value));
      setSelectedCheckboxes(selected);
    },
    [checkboxes, theme.palette.lineGraph.first, theme.palette.lineGraph.second, theme.palette.lineGraph.third],
  );

  const handleFromDateChange = useCallback((date: Date) => {
    setSelectedCheckboxes([translation.text_1600]);
    setSelectedPeriod('Year');
    setFromDate(date);
  }, [translation]);

  const handleToDateChange = useCallback((date: Date) => {
    setToDate(date);
    setSelectedCheckboxes([translation.text_1600]);
    setSelectedPeriod('Year');
  }, [translation]);

  useEffect(() => {
    if (translation.text_1600 && translation.text_1601 && translation.text_1602) {
      setSelectedCheckboxes((prevValues) => {
        const prevValuesIndexes = prevValues.map((value) => previousCheckboxes?.findIndex((item) => item === value));

        if (prevValuesIndexes.length === 0) {
          return [translation.text_1600];
        }

        const newValues = [];

        if (prevValuesIndexes.includes(0)) {
          newValues.push(translation.text_1600);
        }

        if (prevValuesIndexes.includes(1)) {
          newValues.push(translation.text_1601);
        }

        if (prevValuesIndexes.includes(2)) {
          newValues.push(translation.text_1602);
        }

        return newValues;
      });
    }
  }, [previousCheckboxes, translation.text_1600, translation.text_1601, translation.text_1602]);

  return (
    <>
      <PanelTitle
        key={translation}
        translation={translation}
        title={translation.text_6293}
        partnership={partnership}
        value={formatNumber(sum, currency ?? 'USD')}
        increased={increased}
        decreased={decreased}
        fromDate={fromDate}
        toDate={toDate}
        handleFromDateChange={handleFromDateChange}
        handleToDateChange={handleToDateChange}
      />
      <Grid container direction="row" className={classes.content} justify="space-between">
        {tableData.length > 0 ? (
          <>
            <Grid item xs={12} className={classes.mobileView}>
              <GraphArea
                currency={currency}
                checkboxes={checkboxes}
                chartData={chartData}
                colors={colors}
                selectedPeriod={selectedPeriod}
                translation={translation}
                selectedCheckboxes={selectedCheckboxes}
                handlePeriodSelect={handlePeriodSelect}
                selectCheckboxes={handleCheckboxesChanges}
              />
              <div style={{ marginTop: 31 }} />
              <YearMonthList
                selectedYear={selectedYear}
                translation={translation}
                tableData={tableData}
                currency={currency}
                handleSelectPeriod={handleSelectPeriod}
              />
            </Grid>
            <Grid item xs={4} className={classes.desktopView}>
              <YearMonthList translation={translation} selectedYear={selectedYear} tableData={tableData} currency={currency} handleSelectPeriod={handleSelectPeriod} />
            </Grid>
            <Grid item xs={7} className={classes.desktopView}>
              <GraphArea
                currency={currency}
                checkboxes={checkboxes}
                chartData={chartData}
                colors={colors}
                selectedPeriod={selectedPeriod}
                translation={translation}
                selectedCheckboxes={selectedCheckboxes}
                handlePeriodSelect={handlePeriodSelect}
                selectCheckboxes={handleCheckboxesChanges}
              />
            </Grid>
          </>
        ) : (
          <Grid container direction="column" alignContent="center" alignItems="center" className={classes.emptyMessage}>
            <Grid item>{translation.text_6294}</Grid>
          </Grid>
        )}
      </Grid>
      <Grid container direction="row" className={`${commonClasses.linkButtons} ${classes.button}`}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={PATH_COMMISSIONS_BY_CLIENT}
            className={`${commonClasses.linkButtons} ${classes.button}`}
          >{translation.text_6295}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
