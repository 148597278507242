import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: 'calc(100% - 55px) !important;',
    flex: 1,
    marginTop: 44,
  },
  content: {
    position: 'absolute',
    top: '30%',
    justifyContent: 'center',
  },
  container: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardActions: {
    position: 'relative',
  },
  languageSelect: {
    '& svg': {
      top: 'auto',
    },
  },
  languageItem: {
    display: 'flex',
    color: theme.palette.text.primary,
    alignContent: 'center',
    textTransform: 'capitalize',
  },
  languageSelectedItem: {
    height: 14,
    marginRight: 5,
  },
  languageSelectItem: {
    height: 18,
    marginRight: 5,
  },
  loginBtn: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: theme.palette.changeable.hover,
      color: theme.palette.common.white,
    },
  },
}));
