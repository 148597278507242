import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';
import { useApiError, useLoading, useAppData } from '../../../providers';
import { ReportGeneralToolbarWithFilters, ReportTitle } from '../../../components';
import { TransactionsReportData, TransactionsEmailReportRequest, reportEnColumns } from './TransactionsReportModel';
import { ReportTable } from './components';
import { getTransactionsReport, sendEmailTransactionsReport } from './TransactionsReportService';
import { getSelectedColumnsInEn, getTranslatedColumnsInCorrectOrder } from '../../../utils';

export const TransactionsReport: FC = () => {
  const currentDate = useMemo(() => new Date(), []);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { setIsLoading } = useLoading();
  const { addError } = useApiError();
  const { translations, userData, locale } = useAppData();
  const [selectedPartnerships, setSelectedPartnerships] = useState<string[]>(['All']);
  const [selectedLogins, setSelectedLogins] = useState<string[]>(['All']);
  const [fromDate, setFromDate] = useState<Date>(new Date(`${currentDate.getMonth() + 1} 1 ${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`));
  const [toDate, setToDate] = useState<Date>(currentDate);
  const [showReport, setShowReport] = useState<boolean>(false);
  const [reportData, setReportData] = useState<TransactionsReportData[]>([]);
  const [translation, setTranslation] = useState<any>([]);
  const reportColumns = useMemo(() => [translation.text_6616, translation.text_6617, translation.text_6618, translation.text_6619, translation.text_6620, translation.text_6621], [translation]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(reportColumns);
  const [language, setLanguage] = useState<string>();
  const [userEmail, setUserEmail] = useState<string>();

  useEffect(() => {
    if (reportColumns.length === 6) {
      setSelectedColumns(reportColumns.slice(0, 5));
    }
  }, [reportColumns]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    const user = JSON.parse(userData);
    setLanguage(locale);
    setUserEmail(user.userEmail);
  }, [userData, locale]);

  const getReportData = useCallback(
    async (partnerships: string[], logins: string[]) => {
      setIsLoading(true);
      try {
        const response = await getTransactionsReport({
          partnershipCodes: partnerships.includes('All') ? [] : partnerships,
          fromDate: moment(fromDate).format('YYYY-MM-DD'),
          toDate: moment(toDate).format('YYYY-MM-DD'),
          pageNumber: 0,
          login: logins.includes('All') ? [] : logins,
        });
        setReportData(response.data);
        setShowReport(true);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    },
    [fromDate, toDate, addError, setIsLoading],
  );

  const selectPartnershipChangeHandler = useCallback(
    async (options: any) => {
      setSelectedPartnerships(options);
      await getReportData(options, selectedLogins);
    },
    [getReportData, selectedLogins],
  );

  const selectLoginChangeHandler = useCallback(
    async (options: any) => {
      setSelectedLogins(options);
      await getReportData(selectedPartnerships, options);
    },
    [getReportData, selectedPartnerships],
  );

  const changeFromDate = useCallback((newValue) => {
    setFromDate(newValue);
  }, []);

  const changeToDate = useCallback((newValue) => {
    setToDate(newValue);
  }, []);

  const handleNewReportRequest = useCallback(async () => {
    await getReportData(selectedPartnerships, selectedLogins);
  }, [selectedPartnerships, getReportData, selectedLogins]);

  const renderTableData = useCallback((): string[][] => {
    const rows: string[][] = [];
    reportData.forEach((row) => {
      const tableRow: string[] = [
        row.accountPartnership || row.accountPartnership === '' ? row.accountPartnership : '-',
        row.accountName || row.accountName === '' ? row.accountName : '-',
        row.login || row.login === 0 ? row.login.toString() : '-',
        row.deposits || row.deposits === '' ? Number(row.deposits).toFixed(2) : '-',
        row.withdrawals || row.withdrawals === '' ? Number(row.withdrawals).toFixed(2) : '-',
        row.netDeposit || row.netDeposit === '' ? Number(row.netDeposit).toFixed(2) : '-',
      ];
      rows.push(tableRow);
    });

    return rows;
  }, [reportData]);

  const buildTransactionsEmailReportRequest = (): TransactionsEmailReportRequest => ({
    reportPartnershipClientsTradingRequest: {
      fromDate: moment(fromDate).format('YYYY-MM-DD'),
      toDate: moment(toDate).format('YYYY-MM-DD'),
      partnershipCodes: selectedPartnerships.includes('All') ? [] : selectedPartnerships,
      pageNumber: 1,
      login: selectedLogins.includes('All') ? [] : selectedLogins,
    },
    reportEmailRequest: {
      email: null,
      includeTotal: false,
      tableColumns: getSelectedColumnsInEn(reportEnColumns, selectedColumns, reportColumns),
      translatedTableColumns: getTranslatedColumnsInCorrectOrder(selectedColumns, reportColumns),
      language,
      startDate: moment(fromDate).format('YYYY-MM-DD'),
      endDate: moment(toDate).format('YYYY-MM-DD'),
    },
  });

  const sendEmail = async () => {
    const transactionsEmailReportRequest = buildTransactionsEmailReportRequest();
    try {
      await sendEmailTransactionsReport(transactionsEmailReportRequest);
      enqueueSnackbar(`${translation.text_1640} ${userEmail}.`, {
        variant: 'success',
      });
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <ReportTitle
        translation={translation}
        title={translation.text_6440}
        exportFileName={translation.text_6440}
        reportColumns={reportColumns}
        exportTableData={renderTableData()}
        content={<ReportTable rows={reportData} selectedColumns={selectedColumns} reportColumns={reportColumns} />}
        printColumnNumber={selectedColumns.length}
        enableButtons={showReport}
        sendEmail={sendEmail}
      />
      <Paper className={classes.paper}>
        <ReportGeneralToolbarWithFilters
          fromDate={fromDate}
          toDate={toDate}
          columns={reportColumns}
          selectedColumns={selectedColumns}
          handleFromDateChange={changeFromDate}
          handleToDateChange={changeToDate}
          handlePartnershipsChange={selectPartnershipChangeHandler}
          handleButtonClick={handleNewReportRequest}
          handleLoginsChange={selectLoginChangeHandler}
          handleSelectedColumnsChange={setSelectedColumns}
        />
        {showReport ? (
          <Grid item xs={12} className={classes.margin}>
            <ReportTable rows={reportData} selectedColumns={selectedColumns} reportColumns={reportColumns} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container direction="column" justify="center" alignItems="center" className={classes.emptyReportMessageContainer}>
              <Grid item className={classes.bold}>
                {translation.text_6441}
              </Grid>
              <Grid item>{translation.text_6442}</Grid>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};
