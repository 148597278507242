import React, { FC } from 'react';
import { ReactComponent as Info } from '../../../assets/Common/Info.svg';
import { useStyles } from '../styles';
import { CustomIconProps } from '../CustomIconModels';

export const InfoIcon: FC<CustomIconProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <Info className={`${classes.icon} ${className || ''}`} />
  );
};
