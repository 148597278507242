import React, { FC, useCallback, useMemo, useEffect, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { CustomTableWithPagination } from '../../../../../components';
import { TradingActivitiesReportData } from '../../TradingActivitiesReportModel';
import { useAppData } from '../../../../../providers';

interface Props {
  rows: TradingActivitiesReportData[];
  selectedColumns: string[];
  reportColumns: string[];
  onPageChange: (pageNumber: number) => void;
}

export const ReportTable: FC<Props> = ({
  rows,
  selectedColumns,
  reportColumns,
  onPageChange,
}) => {
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const renderHeader = useMemo((): any[] => {
    const result: any[] = [];

    reportColumns.forEach((columnName) => {
      const isSelected = selectedColumns.includes(columnName);
      if (isSelected) {
        result.push(
          <TableCell
            key={columnName}
            align={columnName === reportColumns[0] ? 'left' : 'center'}
          >
            {columnName}
          </TableCell>,
        );
      }
    });
    return result;
  }, [selectedColumns, reportColumns]);

  const renderRows = useCallback((): any[] => {
    const result: any[] = [];

    rows.forEach((row) => {
      result.push(
        <TableRow key={uuid()}>
          {selectedColumns.includes(reportColumns[0]) && (
            <TableCell align="left">
              {row.partnershipCode || row.partnershipCode === ''
                ? row.partnershipCode
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[1]) && (
            <TableCell align="center">
              {row.login || row.login === 0 ? row.login : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[2]) && (
            <TableCell align="center">
              {row.order || row.order === 0 ? row.order : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[3]) && (
            <TableCell align="center">
              {row.openTime || row.openTime === ''
                ? moment(row.openTime).format('DD/MM/YYYY')
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[4]) && (
            <TableCell align="center">{row.type ? row.type : '-'}</TableCell>
          )}
          {selectedColumns.includes(reportColumns[5]) && (
            <TableCell align="center">
              {row.symbol || row.symbol === '' ? row.symbol : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[6]) && (
            <TableCell align="center">
              {row.volumeUSD || row.volumeUSD === 0
                ? row.volumeUSD.toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[7]) && (
            <TableCell align="center">
              {row.openPrice || row.openPrice === 0
                ? row.openPrice.toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[8]) && (
            <TableCell align="center">
              {row.closeTime || row.closeTime === ''
                ? moment(row.closeTime).format('DD/MM/YYYY')
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[9]) && (
            <TableCell align="center">
              {row.closePrice || row.closePrice === 0
                ? row.closePrice.toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[10]) && (
            <TableCell align="center">
              {row.sl || row.sl === 0 ? row.sl.toFixed(2) : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[11]) && (
            <TableCell align="center">
              {row.tp || row.tp === 0 ? row.tp.toFixed(2) : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[12]) && (
            <TableCell align="center">
              {row.commissionUSD || row.commissionUSD === 0
                ? row.commissionUSD.toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[13]) && (
            <TableCell align="center">
              {row.swapUSD || row.swapUSD === 0 ? row.swapUSD.toFixed(2) : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(reportColumns[14]) && (
            <TableCell align="center">
              {row.profitUSD || row.profitUSD === 0
                ? row.profitUSD.toFixed(2)
                : '-'}
            </TableCell>
          )}
        </TableRow>,
      );
    });

    return result;
  }, [rows, selectedColumns, reportColumns]);

  return (
    <CustomTableWithPagination
      headerCells={renderHeader}
      rows={renderRows()}
      columnsNumber={reportColumns.length}
      emptyDataMessage={translation.text_6496}
      onPageChange={onPageChange}
    />
  );
};
