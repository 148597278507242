/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Divider, FormControl, Grid, Paper, Typography, MenuItem } from '@material-ui/core';
import { AggregatedValues, HighlightTypes, Period, Platforms } from './IBDashboardModels';
import { useStyles } from './styles';
import { SelectControl } from '../../components';
import { getAggregatedValues, getPartnerPartnerships, setPartnerSettings, getPartnerSettings, getGeneralSettings } from './IBDashboardService';
import { PartnerPartnership } from '../../models';
import { useApiError, useAppData, useLoading } from '../../providers';
import { CommissionsPanel, AccountsPanel, DepositsWithdrawalsPanel, VolumeVolumeLotsPanel, ClientsPanel, ProfitLossPanel, AccountingPanel, CensusPanel, DoubleInfoPanel } from './components';
import {
  // convertUTCDateToLocal,
  formatNumber,
} from '../../utils';

export const IBDashboard: FC = () => {
  const classes = useStyles();
  const key = 'report-currency';
  const [selectedPeriod] = useState<number>(Period.ALL_YEARS);
  const [currencies, setCurrencies] = useState<string[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<string>('USD');
  const [partnerships, setPartnerships] = useState<PartnerPartnership[]>([]);
  const [selectedPartnership, setSelectedPartnership] = useState<string>('All');
  const { translations, currencyList, partnershipList, saveCurrencyList, savePartnershipList, saveClientPortalRedirectUrl } = useAppData();
  const [aggregatedValues, setAggregatedValues] = useState<AggregatedValues>({
    activeClients: 0,
    activeTradingAccounts: 0,
    commissions: 0,
    deposits: 0,
    netDeposits: 0,
    inactiveClients: 0,
    inactiveTradingAccounts: 0,
    totalClients: 0,
    totalTradingAccounts: 0,
    volume: 0,
    volumeLots: 0,
    withdrawals: 0,
    profit: 0,
    loss: 0,
    dashboardAggregatedEstimatedValuesViewModel: {
      commissions: 0,
      deposits: 0,
      netDeposits: 0,
      withdrawals: 0,
      internalTransferIn: 0,
      internalTransferOut: 0,
      volume: 0,
      volumeLots: 0,
      profit: 0,
      loss: 0,
      lastUtcUpdateTime: '',
    },
  });
  const [selectedPanel, setSelectedPanel] = useState<string>('Commissions');
  const [translation, setTranslation] = useState<any>('');
  const { addError } = useApiError();
  const { setIsLoading } = useLoading();

  const getCurrencyData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getGeneralSettings();
      saveClientPortalRedirectUrl(response.data.clientPortalRedirectUrl);
      setCurrencies(response.data.currencies);
      saveCurrencyList(JSON.stringify(response.data.currencies));
      const settings = await getPartnerSettings(key);
      if (settings.data.value !== null) {
        setSelectedCurrency(settings.data.value);
      }
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  }, [addError, setIsLoading]);

  const getPartnershipData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getPartnerPartnerships(true);
      setPartnerships(response.data);
      savePartnershipList(JSON.stringify(response.data));
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  }, [addError, setIsLoading]);

  const getValues = useCallback(
    async (code, period) => {
      setIsLoading(true);
      try {
        const values = await getAggregatedValues(code === 'All' ? '' : code, period, Platforms.ALL_PLATFORMS);
        setAggregatedValues(values.data);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    },
    [addError, setIsLoading],
  );

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    if (currencyList !== undefined) {
      setCurrencies(JSON.parse(currencyList));
    } else {
      (async () => {
        await getCurrencyData();
      })();
    }
  }, [currencyList]);

  useEffect(() => {
    if (partnershipList !== undefined) {
      setPartnerships(JSON.parse(partnershipList));
    } else {
      (async () => {
        await getPartnershipData();
      })();
    }
  }, [partnershipList]);

  useEffect(() => {
    (async () => {
      await getValues(selectedPartnership, selectedPeriod);
    })();
  }, [selectedPeriod, selectedPartnership, translations]);

  const handleCurrencyChange = useCallback(
    async (event: ChangeEvent<{ value: any }>) => {
      const currency = event.target.value;
      setSelectedCurrency(currency);
      if (currency !== '') {
        setIsLoading(true);
        try {
          await setPartnerSettings(key, currency);
          await getValues(selectedPartnership, selectedPeriod);
        } catch (error) {
          addError(JSON.stringify(error.response));
        } finally {
          setIsLoading(false);
        }
      }
    },
    [selectedPartnership, selectedPeriod, getValues, addError, setIsLoading],
  );

  const handlePartnershipChange = useCallback(
    async (event: ChangeEvent<{ value: any }>) => {
      const partnership = event.target.value;
      setSelectedPartnership(partnership);
      await getValues(partnership, selectedPeriod);
    },
    [getValues, selectedPeriod],
  );

  return (
    <Grid container direction="column" spacing={3} className={classes.root}>
      <Grid container xs={12} item spacing={1}>
        <Grid container direction="row" className={classes.filterContainer}>
          <Grid item>
            <Typography className={classes.bigTitle}>{translation.text_6273}</Typography>
            <p className={classes.title}> | {translation.text_6274}</p>
          </Grid>
          <Grid item>
            {partnerships && partnerships.length !== 0 && (
              <FormControl variant="outlined" className={classes.formControl}>
                <p className={classes.formControlTitle}>{translation.text_6275}:</p>
                <SelectControl className={classes.selectControl} value={selectedPartnership} onChange={handlePartnershipChange} minWidth={97}>
                  <MenuItem className={classes.optionSelector} value="All">
                    {translation.all}
                  </MenuItem>
                  {partnerships.map((partnership) => (
                    <MenuItem className={classes.optionSelector} style={{ width: 'inherit' }} key={partnership.partnershipCode} value={partnership.partnershipCode}>
                      {partnership.partnershipCode}
                    </MenuItem>
                  ))}
                </SelectControl>
              </FormControl>
            )}
            {currencies.length !== 0 && (
              <FormControl variant="outlined" className={classes.formControl}>
                <p className={classes.currencyFormControlTitle}>{translation.text_6276}:</p>
                <SelectControl className={classes.selectControl} value={selectedCurrency} onChange={handleCurrencyChange}>
                  {currencies.map((currency) => (
                    <MenuItem className={classes.optionSelector} key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </SelectControl>
              </FormControl>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} item spacing={1} className={`${classes.container} ${classes.desktopContainer}`}>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={3}>
            <AccountingPanel
              translation={translation}
              title={translation.text_6277}
              panelName="Commissions"
              selectedPanel={selectedPanel}
              value={formatNumber(aggregatedValues.commissions ? aggregatedValues.commissions : 0.0, selectedCurrency)}
              dailyTotalValue={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.commissions ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.commissions : 0.0, selectedCurrency)}
              callBackFunction={() => setSelectedPanel('Commissions')}
            />
          </Grid>
          <Grid item xs={3}>
            <AccountingPanel
              translation={translation}
              title={translation.text_6279}
              panelName="Clients"
              selectedPanel={selectedPanel}
              value={aggregatedValues.totalClients ? aggregatedValues.totalClients.toString() : '0'}
              callBackFunction={() => setSelectedPanel('Clients')}
            />
          </Grid>
          <Grid item xs={3}>
            <CensusPanel
              translation={translation}
              title={translation.text_6282}
              panelName="Accounts"
              selectedPanel={selectedPanel}
              value={aggregatedValues.totalTradingAccounts ? aggregatedValues.totalTradingAccounts : 0}
              active={aggregatedValues.activeTradingAccounts ? aggregatedValues.activeTradingAccounts : 0}
              dormant={aggregatedValues.inactiveTradingAccounts ? aggregatedValues.inactiveTradingAccounts : 0}
              callBackFunction={() => setSelectedPanel('Accounts')}
            />
          </Grid>
          <Grid item xs={3}>
            <DoubleInfoPanel
              firstTitle={translation.text_6283}
              secondTitle={translation.text_6284}
              panelName="ProfitLoss"
              selectedPanel={selectedPanel}
              callBackFunction={() => setSelectedPanel('ProfitLoss')}
              translation={translation}
              firstValue={formatNumber(aggregatedValues.profit ? aggregatedValues.profit : 0.0, selectedCurrency)}
              secondValue={formatNumber(aggregatedValues.loss ? aggregatedValues.loss : 0.0, selectedCurrency)}
              firstDailyTotal={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.profit ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.profit : 0.0, selectedCurrency)}
              secondDailyTotal={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.loss ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.loss : 0.0, selectedCurrency)}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={4}>
            <DoubleInfoPanel
              firstTitle={translation.text_6287}
              secondTitle={translation.text_6288}
              panelName="Deposits"
              selectedPanel={selectedPanel}
              callBackFunction={() => setSelectedPanel('Deposits')}
              translation={translation}
              firstValue={formatNumber(aggregatedValues.deposits ? aggregatedValues.deposits : 0.0, selectedCurrency)}
              secondValue={formatNumber(aggregatedValues.netDeposits ? aggregatedValues.netDeposits : 0.0, selectedCurrency)}
              firstDailyTotal={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.deposits ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.deposits : 0.0, selectedCurrency)}
              secondDailyTotal={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.netDeposits ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.netDeposits : 0.0, selectedCurrency)}
            />
          </Grid>
          <Grid item xs={4}>
            <AccountingPanel
              translation={translation}
              title={translation.text_6289}
              panelName="Withdrawals"
              selectedPanel={selectedPanel}
              value={formatNumber(aggregatedValues.withdrawals ? aggregatedValues.withdrawals : 0.0, selectedCurrency)}
              dailyTotalValue={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.withdrawals ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.withdrawals : 0.0, selectedCurrency)}
              callBackFunction={() => setSelectedPanel('Withdrawals')}
            />
          </Grid>
          <Grid item xs={4}>
            <DoubleInfoPanel
              firstTitle={translation.text_6290}
              secondTitle={translation.text_6291}
              panelName="Volume"
              selectedPanel={selectedPanel}
              callBackFunction={() => setSelectedPanel('Volume')}
              translation={translation}
              firstValue={formatNumber(aggregatedValues.volume ? aggregatedValues.volume : 0.0, selectedCurrency)}
              secondValue={aggregatedValues.volumeLots.toFixed(2)}
              firstDailyTotal={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.volume ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.volume : 0.0, selectedCurrency)}
              secondDailyTotal={aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.volumeLots.toFixed(2)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1} className={`${classes.container} ${classes.mobileContainer}`}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6}>
            <AccountingPanel
              translation={translation}
              title={translation.text_6277}
              panelName="Commissions"
              selectedPanel={selectedPanel}
              value={formatNumber(aggregatedValues.commissions ? aggregatedValues.commissions : 0.0, selectedCurrency)}
              dailyTotalValue={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.commissions ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.commissions : 0.0, selectedCurrency)}
              callBackFunction={() => setSelectedPanel('Commissions')}
            />
          </Grid>
          <Grid item xs={6}>
            <AccountingPanel
              translation={translation}
              title={translation.text_6279}
              panelName="Clients"
              selectedPanel={selectedPanel}
              value={aggregatedValues.totalClients ? aggregatedValues.totalClients.toString() : '0'}
              callBackFunction={() => setSelectedPanel('Clients')}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6}>
            <CensusPanel
              translation={translation}
              title={translation.text_6282}
              panelName="Accounts"
              selectedPanel={selectedPanel}
              value={aggregatedValues.totalTradingAccounts ? aggregatedValues.totalTradingAccounts : 0}
              active={aggregatedValues.activeTradingAccounts ? aggregatedValues.activeTradingAccounts : 0}
              dormant={aggregatedValues.inactiveTradingAccounts ? aggregatedValues.inactiveTradingAccounts : 0}
              callBackFunction={() => setSelectedPanel('Accounts')}
            />
          </Grid>
          <Grid item xs={6}>
            <AccountingPanel
              translation={translation}
              title={translation.text_6289}
              panelName="Withdrawals"
              selectedPanel={selectedPanel}
              value={formatNumber(aggregatedValues.withdrawals ? aggregatedValues.withdrawals : 0.0, selectedCurrency)}
              dailyTotalValue={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.withdrawals ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.withdrawals : 0.0, selectedCurrency)}
              callBackFunction={() => setSelectedPanel('Withdrawals')}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <DoubleInfoPanel
              firstTitle={translation.text_6283}
              secondTitle={translation.text_6284}
              panelName="ProfitLoss"
              selectedPanel={selectedPanel}
              callBackFunction={() => setSelectedPanel('ProfitLoss')}
              translation={translation}
              firstValue={formatNumber(aggregatedValues.profit ? aggregatedValues.profit : 0.0, selectedCurrency)}
              secondValue={formatNumber(aggregatedValues.loss ? aggregatedValues.loss : 0.0, selectedCurrency)}
              firstDailyTotal={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.profit ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.profit : 0.0, selectedCurrency)}
              secondDailyTotal={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.loss ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.loss : 0.0, selectedCurrency)}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <DoubleInfoPanel
              firstTitle={translation.text_6287}
              secondTitle={translation.text_6288}
              panelName="Deposits"
              selectedPanel={selectedPanel}
              callBackFunction={() => setSelectedPanel('Deposits')}
              translation={translation}
              firstValue={formatNumber(aggregatedValues.deposits ? aggregatedValues.deposits : 0.0, selectedCurrency)}
              secondValue={formatNumber(aggregatedValues.netDeposits ? aggregatedValues.netDeposits : 0.0, selectedCurrency)}
              firstDailyTotal={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.deposits ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.deposits : 0.0, selectedCurrency)}
              secondDailyTotal={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.netDeposits ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.netDeposits : 0.0, selectedCurrency)}
            />
          </Grid>
          <Grid item xs={12}>
            <DoubleInfoPanel
              firstTitle={translation.text_6290}
              secondTitle={translation.text_6291}
              panelName="Volume"
              selectedPanel={selectedPanel}
              callBackFunction={() => setSelectedPanel('Volume')}
              translation={translation}
              firstValue={formatNumber(aggregatedValues.volume ? aggregatedValues.volume : 0.0, selectedCurrency)}
              secondValue={aggregatedValues.volumeLots.toFixed(2)}
              firstDailyTotal={formatNumber(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.volume ? aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.volume : 0.0, selectedCurrency)}
              secondDailyTotal={aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.volumeLots.toFixed(2)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {/* <Grid item className={classes.footNote}> */}
        {/*   {translation.text_6292}: */}
        {/*   {convertUTCDateToLocal(aggregatedValues.dashboardAggregatedEstimatedValuesViewModel.lastUtcUpdateTime, 'DD/MM/YYYY hh:mm')} */}
        {/* </Grid> */}
        <Divider variant="middle" className={classes.divider} />
      </Grid>
      <Grid item xs={12} className={classes.container}>
        <Paper className={classes.paperWithoutHover}>
          {selectedPanel === 'Commissions' && <CommissionsPanel translation={translation} currency={selectedCurrency} partnership={selectedPartnership} value={aggregatedValues.commissions ?? 0} />}
          {selectedPanel === 'Accounts' && <AccountsPanel translation={translation} partnership={selectedPartnership} value={aggregatedValues.totalTradingAccounts ? aggregatedValues.totalTradingAccounts : 0} />}
          {selectedPanel === 'Deposits' && (
            <DepositsWithdrawalsPanel
              title={translation.text_6343}
              currency={selectedCurrency}
              highlightType={HighlightTypes.Deposits}
              partnership={selectedPartnership}
              translation={translation}
              value={aggregatedValues.deposits ? formatNumber(aggregatedValues.deposits, selectedCurrency) : '0'}
              netValue={aggregatedValues.netDeposits ? formatNumber(aggregatedValues.netDeposits, selectedCurrency) : '0'}
            />
          )}
          {selectedPanel === 'Withdrawals' && (
            <DepositsWithdrawalsPanel
              title={translation.text_6344}
              currency={selectedCurrency}
              translation={translation}
              highlightType={HighlightTypes.Withdrawals}
              partnership={selectedPartnership}
              value={aggregatedValues.withdrawals ? formatNumber(aggregatedValues.withdrawals, selectedCurrency) : '0'}
            />
          )}
          {selectedPanel === 'Volume' && <VolumeVolumeLotsPanel partnership={selectedPartnership} currency={selectedCurrency} translation={translation} />}
          {selectedPanel === 'Clients' && <ClientsPanel translation={translation} partnership={selectedPartnership} value={aggregatedValues.totalClients ? aggregatedValues.totalClients : 0} />}
          {selectedPanel === 'ProfitLoss' && <ProfitLossPanel translation={translation} currency={selectedCurrency} partnership={selectedPartnership} />}
        </Paper>
      </Grid>
    </Grid>
  );
};
