import { useCallback } from 'react';
import { ReportMessageContent, ReportModel } from 'src/models';
import { useAppData } from 'src/providers';
import { useSnackbar } from 'notistack';
import { getReports } from '../MasterIbCommissionService';

const useGetReports = ({
  translation,
  toDate,
  fromDate,
  setReports,
  showReports,
  setIsLoading,
  setShowReports,
}: {
  translation: any;
  toDate: Date;
  fromDate: Date;
  showReports: boolean;
  setIsLoading: (data: boolean) => void;
  setShowReports: (data: boolean) => void;
  setReports: (data: ReportModel[]) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userData } = useAppData();

  return useCallback(async () => {
    const loginData = JSON.parse(userData);

    const messageContent: ReportMessageContent = {
      PartnerId: loginData.partnerID,
      Month: fromDate.getMonth() + 1,
      Year: fromDate.getFullYear(),
      Day: fromDate.getDate(),
      EndMonth: toDate.getMonth() + 1,
      EndYear: toDate.getFullYear(),
      EndDay: toDate.getDate(),
      ConvertToCurrency: 'USD',
      RequestorAccountGuid: loginData.defaultAccount,
    };

    setIsLoading(true);

    try {
      const response = await getReports(messageContent);

      setReports(response.data);

      if (!showReports) {
        setShowReports(true);
      }
    } catch (error) {
      setReports([]);
      enqueueSnackbar(translation.something_went_wrong, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [
    userData,
    fromDate,
    toDate,
    setIsLoading,
    setReports,
    showReports,
    setShowReports,
    enqueueSnackbar,
    translation.something_went_wrong,
  ]);
};

export default useGetReports;
