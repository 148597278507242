import React, { FC } from 'react';
import { Divider, Grid, Paper } from '@material-ui/core';
import { useStyles } from './styles';

interface PanelProps {
    firstTitle: string
    secondTitle: string
    panelName: string
    selectedPanel: string
    callBackFunction: ((clickedTitle: string) => void)
    firstValue?: string
    secondValue?: string
    firstDailyTotal?: string
    secondDailyTotal?: string
    translation: any
}

export const DoubleInfoPanel: FC<PanelProps> = ({
  firstTitle, secondTitle, selectedPanel, callBackFunction, panelName,
  firstValue, secondValue, firstDailyTotal, secondDailyTotal, translation
}) => {
  const classes = useStyles();

  return (
    <Paper
      className={`${classes.paper} ${selectedPanel === panelName ? classes.paperSelected : ''}`}
      onClick={() => callBackFunction(panelName)}
    >
      <div className={classes.rectangle} />
      <Grid container direction="column">
        <Grid item className={classes.paperTitles}>{firstTitle}</Grid>
        <Grid className={classes.values}>{firstValue}</Grid>
        <Grid item className={classes.dailyValues}>{translation.text_6285}: {firstDailyTotal}</Grid>
        <Divider variant="middle" className={classes.divider} />
        <Grid item className={classes.paperTitles}>{secondTitle}</Grid>
        <Grid className={classes.values}>{secondValue}</Grid>
        <Grid item className={classes.dailyValues}>{translation.text_6286}: {secondDailyTotal}</Grid>
      </Grid>
    </Paper>
  );
};
