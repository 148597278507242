import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    margin: {
      marginTop: 10,
    },
    emptyMessage: {
      paddingTop: 20,
      paddingBottom: 20,
      fontStyle: 'italic',
    },
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0px 4px',

      '& .MuiTableCell-root': {
        fontSize: '13pt',
      },
    },
    button: {
      backgroundColor: theme.palette.changeable.showMoreBtnColor,
      color: theme.palette.changeable.secondaryBtnTextColor,
      borderRadius: 25,
      padding: '10px 20px 10px 20px',

      '&:hover': {
        backgroundColor: theme.palette.changeable.hover,
        color: theme.palette.common.white,
      },
    },
  }),
);
