import React, { useEffect, useState, FC } from 'react';
import {
  AppBar,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { getTranslation } from 'src/utils';
import { Logo } from '../Logo';
import { useStyles } from './styles';
import { useAppData } from '../../providers';
import { NotificationsMenu } from '../NotificationsMenu';
import { AngleDownIcon } from '../CustomIcons';

interface Props {
  selectedLocale: any;
  translation: any;
  languages: any[];
  handleLogout: () => void;
  handleLocaleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleShowMenu: () => void;
}

export const Header: FC<Props> = ({
  translation,
  selectedLocale,
  languages,
  handleLogout,
  handleLocaleChange,
  handleShowMenu,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up('lg'));
  const { userData } = useAppData();
  const [userName, setUserName] = useState<string>('');
  const [userRole, setUserRole] = useState<string>('');

  useEffect(() => {
    const user = JSON.parse(userData);
    setUserName(user.userDisplayName);
    setUserRole(getTranslation(translation, user.accounts[0].userRoleName));
  }, [match, translation, userData]);

  return (
    <>
      <Grid container direction="row" className={classes.header}>
        <Logo />
        <AppBar position="sticky" className={classes.appBarShift}>
          <Toolbar className={classes.toolbar}>
            <Grid className={classes.headerSpace} />
            <div className={classes.languageInnerContainer}>
              <FormControl
                variant="standard"
                className={classes.languageSelect}
              >
                <Select
                  onChange={handleLocaleChange}
                  renderValue={(value: any) => (
                    <div className={classes.languageItem}>
                      <span className={classes.languageSelectedItem}>
                        {value.icon}
                      </span>
                      {value.value}
                    </div>
                  )}
                  autoWidth
                  inputProps={{
                    IconComponent: AngleDownIcon,
                  }}
                  value={selectedLocale}
                  disableUnderline
                >
                  {languages.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      <span className={classes.languageSelectItem}>
                        {item.icon}
                      </span>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <div className={classes.headerInnerContainer}>
              <Grid
                container
                direction="row-reverse"
                alignContent="center"
                alignItems="center"
              >
                <Grid item style={{ marginLeft: 6 }}>
                  <NotificationsMenu />
                </Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="flex-end">
                    <Grid item className={classes.headerListItem}>
                      <span className={classes.userName}>{userName}</span>
                    </Grid>
                    <Grid item className={classes.headerListItem}>
                      <span className={classes.userRole}>{userRole}</span>|
                      <Button
                        className={classes.headerListItemButton}
                        onClick={handleLogout}
                      >
                        {translation.text_6040}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid container direction="row" className={classes.mobileHeader}>
        <AppBar position="sticky" className={classes.appBar}>
          <Toolbar className={classes.mobileToolbar}>
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleShowMenu}
              >
                <Menu />
              </IconButton>
            </Grid>
            <Grid item>
              <Logo />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row-reverse"
                alignContent="center"
                alignItems="center"
              >
                <Grid item>
                  <NotificationsMenu />
                </Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="flex-end">
                    <Grid item className={classes.headerListItem}>
                      <span className={classes.userName}>{userName}</span>
                    </Grid>
                    <Grid item className={classes.headerListItem}>
                      <span className={classes.userRole}>{userRole}</span>|
                      <Button
                        className={classes.headerListItemButton}
                        onClick={handleLogout}
                      >
                        {translation.text_6040}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Grid>
    </>
  );
};
