/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router';
import { defaultLocale, languages } from 'src/utils';
import {
  MainMenu,
  QuickLinks,
  Header,
  MobileMenuPanel,
} from '../../components';
import { useStyles } from './styles';
import { getTranslations } from './defaultLayoutService';
import { useApiError, useAppData, useLoading } from '../../providers';

export const DefaultLayout = memo(({ children }) => {
  const classes = useStyles();
  const { setIsLoading } = useLoading();
  const { addError } = useApiError();
  const {
    removeToken,
    saveCurrentPage,
    removeUserData,
    saveTranslations,
    removeTranslations,
    removeCurrencyList,
    removePartnershipList,
    removeClientPortalRedirectUrl,
    saveLocale,
    locale,
  } = useAppData();
  const [translation, setTranslation] = useState<any>([]);
  const location = useLocation();
  const [selectedLocale, setSelectedLocale] = useState<any>(languages[0]);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const getTranslationList = useCallback(
    async (newLocale: string) => {
      try {
        setIsLoading(true);
        const translations = await getTranslations(newLocale);
        saveTranslations(JSON.stringify(translations.data));
        setTranslation(translations.data);
        saveLocale(newLocale);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    },
    [saveTranslations, saveLocale, addError, setIsLoading],
  );

  const handleLocaleChange = useCallback(
    async (event: React.ChangeEvent<{ value: unknown }>) => {
      const selectedLanguage =
        languages.find(
          (item) => item.value === (event.target.value as string),
        ) || languages[0];
      setSelectedLocale(selectedLanguage);
      saveLocale(selectedLanguage.value);
      await getTranslationList(selectedLanguage.value);
    },
    [getTranslationList],
  );

  useEffect(() => {
    if (locale) {
      const selectedLanguage =
        languages.find((item) => item.value === locale) || languages[0];
      setSelectedLocale(selectedLanguage);
    }
    (async () => {
      await getTranslationList(locale || defaultLocale);
    })();
  }, []);

  const handleLogout = useCallback(() => {
    saveCurrentPage(location.pathname.replace('/', ''));
    setIsLoading(true);
    removeToken();
    removeUserData();
    removeTranslations();
    removeCurrencyList();
    removePartnershipList();
    removeClientPortalRedirectUrl();
    setIsLoading(false);
    saveLocale('en');
  }, [
    removeToken,
    location,
    saveCurrentPage,
    removeUserData,
    removeTranslations,
    removeCurrencyList,
    removePartnershipList,
    removeClientPortalRedirectUrl,
    setIsLoading,
    saveLocale,
  ]);

  return (
    <div className={classes.root}>
      {!showMenu && (
        <>
          <Header
            translation={translation}
            selectedLocale={selectedLocale}
            languages={languages}
            handleLogout={handleLogout}
            handleLocaleChange={handleLocaleChange}
            handleShowMenu={() => setShowMenu(true)}
          />
          <div className={classes.container}>
            <div className={classes.drawer}>
              <MainMenu />
              <QuickLinks />
            </div>
            <main className={classes.main}>{children}</main>
          </div>
        </>
      )}
      {showMenu && (
        <div className={classes.mobileContainer}>
          <MobileMenuPanel
            selectedLocale={selectedLocale}
            languages={languages}
            handleLocaleChange={handleLocaleChange}
            handleCloseMenu={() => setShowMenu(false)}
          />
        </div>
      )}
    </div>
  );
});
