import React, { FC, useCallback, useMemo } from 'react';
import { Grid, TableCell, TableRow } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { CustomTable } from '../../../../../components';
import { PositionsReport } from '../../OpenClosedPositionsSummaryModel';
import { useStyles } from './styles';

interface Props {
  columnNames: string[];
  rows: PositionsReport[];
  selectedColumns: string[];
  translation: any;
}

export const ReportTable: FC<Props> = ({
  rows,
  selectedColumns,
  columnNames,
  translation,
}) => {
  const classes = useStyles();

  const renderHeader = useMemo((): any[] => {
    const result: any[] = [];
    const openPositions = translation.text_1219;
    const closedPositions = translation.text_1220;

    columnNames.forEach((columnName) => {
      if (columnName !== undefined) {
        const isSelected = selectedColumns.includes(columnName);
        if (isSelected) {
          if (
            columnName.indexOf(openPositions) !== -1 ||
            columnName.indexOf(closedPositions) !== -1
          ) {
            if (columnName.indexOf(openPositions) !== -1) {
              const nameArr = columnName?.split(openPositions);
              result.push(
                <TableCell
                  align={columnName === columnNames[0] ? 'left' : 'center'}
                >
                  {nameArr[0]}
                  <br />
                  <span className={classes.subColumnName}>{openPositions}</span>
                </TableCell>,
              );
            } else {
              const nameArr = columnName?.split(closedPositions);
              result.push(
                <TableCell
                  align={columnName === columnNames[0] ? 'left' : 'center'}
                >
                  {nameArr[0]}
                  <br />
                  <span className={classes.subColumnName}>
                    {closedPositions}
                  </span>
                </TableCell>,
              );
            }
          } else {
            result.push(
              <TableCell
                align={columnName === columnNames[0] ? 'left' : 'center'}
              >
                {columnName}
              </TableCell>,
            );
          }
        }
      }
    });
    return result;
  }, [selectedColumns, classes, columnNames, translation]);

  const renderRows = useCallback((): any[] => {
    const result: any[] = [];

    rows.forEach((row) => {
      result.push(
        <TableRow key={uuid()}>
          {selectedColumns.includes(columnNames[0]) && (
            <TableCell align="left">
              {row.partnershipCode || row.partnershipCode === ''
                ? row.partnershipCode
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[1]) && (
            <TableCell align="center">
              {row.login || row.login === 0 ? row.login : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[2]) && (
            <TableCell align="center">
              {row.openedVolumeUSD || row.openedVolumeUSD === 0
                ? row.openedVolumeUSD.toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[3]) && (
            <TableCell align="center">
              {row.openLots || row.openLots === 0
                ? row.openLots.toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[4]) && (
            <TableCell align="center">
              {row.openTrades || row.openTrades === 0
                ? row.openTrades.toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[5]) && (
            <TableCell align="center">
              {row.openPnL || row.openPnL === 0 ? row.openPnL.toFixed(2) : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[6]) && (
            <TableCell align="center">
              {row.closedVolumeUSD || row.closedVolumeUSD === 0
                ? row.closedVolumeUSD.toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[7]) && (
            <TableCell align="center">
              {row.closedLots || row.closedLots === 0
                ? row.closedLots.toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[8]) && (
            <TableCell align="center">
              {row.closedTrades || row.closedTrades === 0
                ? row.closedTrades.toFixed(2)
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[9]) && (
            <TableCell align="center">
              {row.closedPnL || row.closedPnL === 0
                ? row.closedPnL.toFixed(2)
                : '-'}
            </TableCell>
          )}
        </TableRow>,
      );
    });

    return result;
  }, [rows, selectedColumns, columnNames]);

  return (
    <Grid item style={{ marginTop: 62 }}>
      <CustomTable
        headerCells={renderHeader}
        rows={renderRows()}
        columnsNumber={selectedColumns.length}
        emptyDataMessage={translation.text_6448}
      />
    </Grid>
  );
};
