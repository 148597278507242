import { ApplicationStore } from '../states';

export const updateToken: (
  state: ApplicationStore,
  payload: string,
) => ApplicationStore = (state, payload) => ({ ...state, token: payload });

export const removeToken: (state: ApplicationStore) => ApplicationStore = (
  state,
) => {
  const newState: ApplicationStore = JSON.parse(JSON.stringify(state));

  delete newState.token;
  return { ...newState };
};

export const updateCurrentPage: (
  state: ApplicationStore,
  payload: string,
) => ApplicationStore = (state, payload) => ({
  ...state,
  currentPage: payload,
});

export const removeCurrentPage: (state: ApplicationStore) => ApplicationStore =
  (state) => {
    const newState: ApplicationStore = JSON.parse(JSON.stringify(state));

    delete newState.currentPage;
    return { ...newState };
  };

export const updateUserData: (
  state: ApplicationStore,
  payload: string,
) => ApplicationStore = (state, payload) => ({ ...state, userData: payload });

export const removeUserData: (state: ApplicationStore) => ApplicationStore = (
  state,
) => {
  const newState: ApplicationStore = state;

  delete newState.userData;
  return { ...newState };
};

export const updateTranslation: (
  state: ApplicationStore,
  payload: string,
) => ApplicationStore = (state, payload) => ({
  ...state,
  translations: payload,
});

export const deleteTranslation: (state: ApplicationStore) => ApplicationStore =
  (state) => {
    const newState: ApplicationStore = state;

    delete newState.translations;
    return { ...newState };
  };

export const updateLogoUrl: (
  state: ApplicationStore,
  payload: string,
) => ApplicationStore = (state, payload) => ({ ...state, logoUrl: payload });

export const removeLogoUrl: (state: ApplicationStore) => ApplicationStore = (
  state,
) => {
  const newState: ApplicationStore = state;

  delete newState.logoUrl;
  return { ...newState };
};

export const updateCurrencyList: (
  state: ApplicationStore,
  payload: string,
) => ApplicationStore = (state, payload) => ({
  ...state,
  currencyList: payload,
});

export const removeCurrencyList: (state: ApplicationStore) => ApplicationStore =
  (state) => {
    const newState: ApplicationStore = state;

    delete newState.currencyList;
    return { ...newState };
  };

export const updatePartnershipList: (
  state: ApplicationStore,
  payload: string,
) => ApplicationStore = (state, payload) => ({
  ...state,
  partnershipList: payload,
});

export const removePartnershipList: (
  state: ApplicationStore,
) => ApplicationStore = (state) => {
  const newState: ApplicationStore = state;

  delete newState.partnershipList;
  return { ...newState };
};

export const updateClientPortalRedirectUrl: (
  state: ApplicationStore,
  payload: string,
) => ApplicationStore = (state, payload) => ({
  ...state,
  clientPortalRedirectUrl: payload,
});

export const removeClientPortalRedirectUrl: (
  state: ApplicationStore,
) => ApplicationStore = (state) => {
  const newState: ApplicationStore = state;

  delete newState.clientPortalRedirectUrl;
  return { ...newState };
};

export const updateLocale: (
  state: ApplicationStore,
  payload: string,
) => ApplicationStore = (state, payload) => ({ ...state, locale: payload });

export const updateCommissionsByClientReportRequestMsgId: (
  state: ApplicationStore,
  payload: string,
) => ApplicationStore = (state, payload) => ({
  ...state,
  commissionsByClientReportRequestMsgId: payload,
});

export const removeCommissionsByClientReportRequestMsgId: (
  state: ApplicationStore,
) => ApplicationStore = (state) => {
  const newState: ApplicationStore = state;

  delete newState.commissionsByClientReportRequestMsgId;
  return { ...newState };
};

export const updateMasterIbReportRequestMsgId: (
  state: ApplicationStore,
  payload: string,
) => ApplicationStore = (state, payload) => ({
  ...state,
  masterIbReportRequestMsgId: payload,
});

export const removeMasterIbReportRequestMsgId: (
  state: ApplicationStore,
) => ApplicationStore = (state) => {
  const newState: ApplicationStore = state;

  delete newState.masterIbReportRequestMsgId;
  return { ...newState };
};
