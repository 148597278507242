import React, { useEffect, Fragment, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

function ScrollToTop({ history, children }: RouteComponentProps & { children?: ReactNode }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
}

export default withRouter(ScrollToTop);
