import React, { FC } from 'react';
import { useStyles } from '../styles';
import { ReactComponent as ClientPortal } from '../../../assets/QuickLinks/ClientPortal.svg';

export const ClientPortalIcon: FC = () => {
  const classes = useStyles();
  return (
    <ClientPortal className={classes.icon} />
  );
};
