import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  reportStatus: {
    color: theme.palette.common.white,
    padding: '6px 0 6px 0',
    borderRadius: 20,
    fontSize: 15,
    textAlign: 'center',

    '@media (max-width: 1024px)': {
      width: 90
    },
  },
  completedStatus: {
    backgroundColor: theme.palette.primary.main,
  },
  failedStatus: {
    backgroundColor: theme.palette.error.main,
  },
  pendingStatus: {
    backgroundColor: theme.palette.frequent.pastelPink
  },
  arrowIcon: {
    color: theme.palette.frequent.cadetBlue
  },
}));
