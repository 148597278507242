import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  titleContainer: {
    color: theme.palette.text.primary,
    marginBottom: 20,
    marginTop: 10,

    '@media (max-width: 1025px)': {
      marginTop: 19
    }
  },
  title: {
    marginBottom: 0,
    marginRight: 5,
    fontSize: 26,

    '@media (max-width: 768px)': {
      fontSize: 20
    }
  },
  titleButton: {
    minWidth: 20,
    padding: 0,
    border: 'none'
  },
  popper: {
    zIndex: 1,
    left: '12px !important',
    '&[x-placement*="right"] $arrow': {
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 10,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}));
