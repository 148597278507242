import React, { FC } from 'react';
import { Divider } from '@material-ui/core';
import { MobileTableRow } from './MobileTableRow';
import { useStyles } from '../../../styles';

interface Props {
    rows: any
    columnNames: string[]
    columns: string[]
    currency: string
}

export const MobileTable: FC<Props> = ({
  rows, columnNames, columns, currency
}) => {
  const classes = useStyles();

  return (
    <>
      {rows.map((row: any, index: number) => (
        <>
          <MobileTableRow
            key={columnNames.includes(columns[0]) ? row.symbol : row.platform}
            index={index}
            row={row}
            columnNames={columnNames}
            columns={columns}
            currency={currency}
          />
          <Divider variant="middle" className={classes.divider} />
        </>
      ))}
    </>
  );
};
