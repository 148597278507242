import { useCallback } from 'react';
import { useApiError, useAppData } from 'src/providers';
import { getSummaryByClient } from '../CommissionsByClientService';
import { ReportDetailsModel } from '../../../../models';

const useGetSummaryByClient = ({
  toDate,
  fromDate,
  setSummaryData,
  setIsLoading,
}: {
  toDate: Date;
  fromDate: Date;
  setIsLoading: (data: boolean) => void;
  setSummaryData: (data: ReportDetailsModel[]) => void;
}) => {
  const { addError } = useApiError();
  const { userData } = useAppData();

  return useCallback(
    async (partnershipCode: string) => {
      const loginData = JSON.parse(userData);
      setIsLoading(true);

      try {
        const response = await getSummaryByClient({
          partnershipCode,
          PartnerId: loginData.partnerID,
          Month: fromDate.getMonth() + 1,
          Year: fromDate.getFullYear(),
          Day: fromDate.getDate(),
          EndMonth: toDate.getMonth() + 1,
          EndYear: toDate.getFullYear(),
          EndDay: toDate.getDate(),
          ConvertToCurrency: 'USD',
          RequestorAccountGuid: loginData.defaultAccount,
        });

        setSummaryData(response.data.reportItems);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    },
    [userData, fromDate, toDate, setIsLoading, setSummaryData, addError],
  );
};

export default useGetSummaryByClient;
