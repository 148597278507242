import React, { FC, useEffect, useState } from 'react';
import { Backdrop, Grid } from '@material-ui/core';
import { useAppData } from 'src/providers';
import { useStyles } from './style';
import { SpinnerIcon } from '../CustomIcons';

interface Props {
  isLoading: boolean;
}

export const Spinner: FC<Props> = ({ isLoading }) => {
  const classes = useStyles();
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <Grid container direction="column" alignItems="center">
        <Grid item>{translation.loading_please_wait_a_moment}</Grid>
        <SpinnerIcon className={classes.rotateIcon} />
      </Grid>
    </Backdrop>
  );
};
