import React, { FC } from 'react';
import {
  DialogTitle as MuiDialogTitle,
  Typography,
  IconButton,
  Grid,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useStyles from './styles';

interface DialogTitleProps {
  children: JSX.Element | string | FC;
  subChildren?: JSX.Element | string | FC;
  className?: string;
  onClose?: () => void;
}

export const DialogTitle: FC<DialogTitleProps> = ({
  children, subChildren, onClose, className, ...other
}) => {
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={`${classes.root} ${className}`} {...other}>
      <Grid container justify="space-between" spacing={1} alignItems="center">
        <Grid item xs={8}>
          <Typography variant="h6">{children}</Typography>
        </Grid>

        {subChildren && (
        <Grid item>
          <Typography variant="h6">{subChildren}</Typography>
        </Grid>
        )}

        {onClose ? (
          <Grid item>
            <IconButton className={classes.closeButton} onClick={onClose}>
              <Close fontSize="large" />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
    </MuiDialogTitle>
  );
};
