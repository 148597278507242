import React, { FC, useEffect, useState } from 'react';
import { useStyles } from './styles';
import { useAppData } from '../../providers';

export const Logo: FC = () => {
  const classes = useStyles();
  const { logoUrl } = useAppData();
  const [logo, setLogo] = useState<string>('');

  useEffect(() => {
    setLogo(logoUrl);
  }, [logoUrl]);

  return (
    <div className={classes.LogoContainer}>
      <img src={logo} alt="" className={classes.logoImg} />
    </div>
  );
};
