/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Chip, Grid, MenuItem } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Close } from '@material-ui/icons';
import { PartnerPartnership } from '../../../../models';
import {
  CheckIcon,
  Dialog,
  FieldLabel,
  MultiSelectControl,
} from '../../../../components';
import { useStyles } from './styles';
import { Representative } from '../../RepresentativesModels';
import { useAppData } from '../../../../providers';

interface Props {
  trigger: number | undefined;
  representative?: Representative;
  partnerships: PartnerPartnership[];
  onSubmit: (values: any, { setSubmitting }: any) => void;
  onCancel: () => void;
}

export const AssignPartnershipDialog: FC<Props> = ({
  trigger,
  representative,
  partnerships,
  onCancel,
  onSubmit,
}) => {
  const classes = useStyles();
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);
  const [initialValues, setInitialValues] = useState<any>({
    assignedPartnershipCodes: [],
  });
  const [selectedPartnerships, setSelectedPartnerships] = useState<string[]>([
    'Select one or more partnerships...',
  ]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    if (representative) {
      const partnershipCodes: string[] = [];
      const activePartnerships =
        representative.representativeAssignedPartnerships?.filter(
          (partn: any) =>
            partn.representativePartnershipPeriods.some(
              (item: any) => item.endDate === null,
            ),
        );
      activePartnerships?.forEach((partnership) => {
        if (partnership.partnershipCode != null) {
          partnershipCodes.push(partnership.partnershipCode);
        }
      });
      const initValues = {
        assignedPartnershipCodes: partnershipCodes,
      };
      setInitialValues(initValues);
      setSelectedPartnerships(
        partnershipCodes.length > 0
          ? partnershipCodes
          : ['Select one or more partnerships...'],
      );
    }
  }, [representative]);

  const handleSelectChange = useCallback(
    async (event, setFieldValue) => {
      let options = event.target.value as string[];
      if (options.length === 0) {
        options = ['Select one or more partnerships...'];
        setFieldValue('assignedPartnershipCodes', []);
      }

      if (options.length > 0) {
        if (options.includes('All')) {
          if (options[options.length - 1] === 'All') {
            options = options.filter((option) => option === 'All');
            const selected: string[] = [];
            partnerships.forEach((partnership) => {
              selected.push(
                partnership.partnershipCode ? partnership.partnershipCode : '',
              );
            });
            setFieldValue('assignedPartnershipCodes', selected);
          } else {
            options = options.filter(
              (option) =>
                option !== 'All' &&
                option !== 'Select one or more partnerships...',
            );
            setFieldValue('assignedPartnershipCodes', options);
          }
        } else {
          setFieldValue('assignedPartnershipCodes', options);
        }

        if (options.includes('Select one or more partnerships...')) {
          if (
            options[options.length - 1] === 'Select one or more partnerships...'
          ) {
            options = options.filter(
              (option) => option === 'Select one or more partnerships...',
            );
            setFieldValue('assignedPartnershipCodes', []);
          } else {
            options = options.filter(
              (option) =>
                option !== 'All' &&
                option !== 'Select one or more partnerships...',
            );
            setFieldValue('assignedPartnershipCodes', options);
          }
        }
      }

      setSelectedPartnerships(options);
    },
    [partnerships],
  );

  const validationSchema = Yup.object().shape({
    assignedPartnershipCodes: Yup.mixed().test(
      'arraySize',
      'Must have at least one value',
      (value) => value.length > 0,
    ),
  });

  const handleClose = useCallback(
    (resetForm) => {
      onCancel();
      resetForm();
    },
    [onCancel],
  );

  const handleDelete = useCallback(
    (partnership: string, setFieldValue) => {
      let options = selectedPartnerships.filter(
        (option) => option !== partnership,
      );

      if (options.length === 0) {
        options = ['Select one or more partnerships...'];
        setFieldValue('assignedPartnershipCodes', []);
      } else {
        setFieldValue('assignedPartnershipCodes', options);
      }
      setSelectedPartnerships(options);
    },
    [selectedPartnerships],
  );

  const renderValueCustomCondition = useCallback(
    (value: string[]) => {
      if (value[0] === 'Select one or more partnerships...') {
        return [translation.text_6533];
      }

      if (value[0] === 'All') {
        return [translation.all];
      }

      return false;
    },
    [translation.all],
  );

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ submitForm, resetForm, touched, errors, setFieldValue }) => (
        <Dialog
          trigger={trigger}
          title={translation.text_6534}
          onClose={() => handleClose(resetForm)}
          className={classes.dialog}
          actions={[
            {
              key: '1',
              title: translation.text_6526,
              onClick: () => handleClose(resetForm),
              isPrimary: false,
            },
            {
              key: '2',
              title: translation.text_6528,
              onClick: submitForm,
              isPrimary: true,
            },
          ]}
        >
          <Form>
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              alignContent="flex-start"
              justify="space-between"
            >
              <Grid item className={classes.margin}>
                <FieldLabel isRequired label={translation.text_6532} />
                <MultiSelectControl
                  value={selectedPartnerships}
                  renderValueCustomCondition={renderValueCustomCondition}
                  errors={
                    touched.assignedPartnershipCodes
                      ? errors.assignedPartnershipCodes
                      : undefined
                  }
                  onChange={(event: any) =>
                    handleSelectChange(event, setFieldValue)
                  }
                  className={classes.multiSelect}
                >
                  <MenuItem
                    key="none"
                    className={
                      selectedPartnerships.includes(
                        'Select one or more partnerships...',
                      )
                        ? classes.selectedOption
                        : classes.option
                    }
                    value="Select one or more partnerships..."
                  >
                    <span>{translation.text_6533}</span>
                  </MenuItem>
                  <MenuItem
                    key="all"
                    className={
                      selectedPartnerships.includes('All')
                        ? classes.selectedOption
                        : classes.option &&
                          initialValues.assignedPartnershipCodes.length ===
                            partnerships.length
                        ? classes.selectedOptionDisabled
                        : classes.option
                    }
                    value="All"
                  >
                    <CheckIcon />
                    <span>{translation.all}</span>
                  </MenuItem>
                  {partnerships.map((partnership) => (
                    <MenuItem
                      className={
                        initialValues.assignedPartnershipCodes.includes(
                          partnership.partnershipCode,
                        )
                          ? classes.selectedOptionDisabled
                          : classes.option &&
                            selectedPartnerships.includes(
                              partnership.partnershipCode as string,
                            )
                          ? classes.selectedOption
                          : classes.option
                      }
                      key={partnership.partnershipCode}
                      value={
                        partnership.partnershipCode
                          ? partnership.partnershipCode
                          : ''
                      }
                    >
                      <CheckIcon />
                      <span>{partnership.partnershipCode}</span>
                    </MenuItem>
                  ))}
                </MultiSelectControl>
              </Grid>
              <Grid item className={classes.margin}>
                {selectedPartnerships.map(
                  (partnership) =>
                    partnership !== 'All' &&
                    partnership !== 'Select one or more partnerships...' && (
                      <Chip
                        key={partnership}
                        className={
                          initialValues.assignedPartnershipCodes.includes(
                            partnership,
                          )
                            ? classes.chipDisabled
                            : classes.chip
                        }
                        label={partnership}
                        onDelete={() =>
                          handleDelete(partnership, setFieldValue)
                        }
                        deleteIcon={<Close fontSize="small" />}
                      />
                    ),
                )}
              </Grid>
            </Grid>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
