import { useCallback } from 'react';
import { getTranslation } from 'src/utils';

const useGetTranslatedOptions = (translation: any) =>
  useCallback(
    (list: any[], labelName: string, valueName: string) =>
      list?.map((elem) => {
        const label: string = elem[labelName];

        return {
          value: elem[valueName],
          label: getTranslation(translation, label),
        };
      }),
    [translation],
  );

export default useGetTranslatedOptions;
