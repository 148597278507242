import axios from 'axios';
import { supportedLocales } from 'src/utils';

export const instanceApi = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}`,
  // withCredentials: true,
});

instanceApi.interceptors.request.use((config) => {
  const storageString = sessionStorage.getItem('__STATE_MACHINE__');
  let token;
  let locale = supportedLocales[0];

  if (storageString) {
    const storage = JSON.parse(storageString);
    token = storage.token;
    locale = storage.locale;
  }
  const newConfig = config;
  if (typeof window !== 'undefined') {
    newConfig.headers = {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
      Authorization: token ? `Bearer ${token}` : undefined,
      'Accept-Language': locale,
    };
  }
  return newConfig;
});
