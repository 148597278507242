import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import {
  AppBar,
  FormControl,
  Grid,
  Tab,
  MenuItem,
  Select,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import useStyles from './styles';
import { PartnerPartnership, PartnershipRank } from '../../models';
import {
  ClientLinkSetup,
  Link,
  SubPartnershipLinkSetup,
} from './PartnershipLinkDialogModel';
import {
  getClientLinkSetup,
  getClientLinks,
  getSubPartnershipLinkSetup,
  getSubPartnershipLinks,
} from '../../pages';
import { Dialog } from '../Dialog';
import { CreateClientLinkTab } from './CreateClientLinkTab';
import { ExistingLinksTab } from './ExistingLinksTab';
import { CreatePartnershipLinkTab } from './CreatePartnershipLinkTab';
import { FieldLabel } from '../Form';
import { useApiError, useAppData, useLoading } from '../../providers';

interface PartnershipLinkDialogProps {
  partnerships?: PartnerPartnership[];
  trigger?: number;
  handleClose: () => void;
}

export const PartnershipLinkDialog: FC<PartnershipLinkDialogProps> = memo(
  ({ partnerships, trigger, handleClose }) => {
    const classes = useStyles();
    const { addError } = useApiError();
    const { translations } = useAppData();
    const { setIsLoading } = useLoading();
    const [activeTab, setActiveTab] = useState<string>('1');
    const [clientLinks, setClientLinks] = useState<Link[]>([]);
    const [subPartnershipLinks, setSubPartnershipLinks] = useState<Link[]>([]);
    const [clientLinkSetup, setClientLinkSetup] = useState<ClientLinkSetup>();
    const [subPartnershipLinkSetup, setSubPartnershipLinkSetup] =
      useState<SubPartnershipLinkSetup>();

    const [selectedPartnership, setSelectedPartnership] =
      useState<PartnerPartnership | null>(
        partnerships && partnerships.length > 0 ? partnerships[0] : null,
      );
    const createClientLinkTabFormRef = useRef<any>();
    const subIBLinksTabFormRef = useRef<any>();
    const [translation, setTranslation] = useState<any>([]);

    useEffect(() => {
      setTranslation(JSON.parse(translations));
    }, [translations]);

    const dialogActions = () => {
      if (activeTab !== '3') {
        return [
          {
            key: '1',
            title: translation.text_6665,
            onClick: () => handleClose(),
            isPrimary: false,
          },
        ];
      }
      return [
        {
          key: '1',
          title: translation.text_6665,
          onClick: () => handleClose(),
          isPrimary: false,
        },
      ];
    };

    const getData = useCallback(
      async (id: number) => {
        setIsLoading(true);
        try {
          if (selectedPartnership) {
            const { partnershipRank } = selectedPartnership;

            if (
              partnershipRank === PartnershipRank.Normal ||
              partnershipRank === PartnershipRank.SubIB
            ) {
              const { links, linkSetup } = await Promise.all([
                getClientLinks(id),
                getClientLinkSetup(id),
              ]).then(([{ data: links }, { data: linkSetup }]) => ({
                links,
                linkSetup,
              }));

              setClientLinks(links.links);
              setClientLinkSetup(linkSetup);
              setSubPartnershipLinks([]);
              setSubPartnershipLinkSetup(undefined);
            } else {
              const { links, linkSetup } = await Promise.all([
                getSubPartnershipLinks(id),
                getSubPartnershipLinkSetup(id),
              ]).then(([{ data: links }, { data: linkSetup }]) => ({
                links,
                linkSetup,
              }));

              setSubPartnershipLinks(links.links);
              setSubPartnershipLinkSetup(linkSetup);
              setClientLinks([]);
              setClientLinkSetup(undefined);
            }
          }
        } catch (error) {
          addError(JSON.stringify(error.response));
        } finally {
          setIsLoading(false);
        }
      },
      // setIsLoading causes the problem of infinite rendering
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [addError, selectedPartnership],
    );

    useEffect(() => {
      if (
        selectedPartnership &&
        (selectedPartnership.partnershipRank === PartnershipRank.Normal ||
          selectedPartnership.partnershipRank === PartnershipRank.SubIB)
      ) {
        setActiveTab('1');
      } else {
        setActiveTab('2');
      }
    }, [selectedPartnership]);

    useEffect(() => {
      if (partnerships && partnerships.length > 0) {
        if (selectedPartnership === null) {
          setSelectedPartnership(partnerships[0]);
        }
      }
    }, [partnerships, selectedPartnership]);

    useEffect(() => {
      const partnershipId = selectedPartnership?.id;

      if (partnershipId && typeof partnershipId === 'number') {
        (async () => {
          await getData(partnershipId);
        })();
      }
    }, [getData, selectedPartnership?.id]);

    const handleTabChange = useCallback((event: any, newValue: string) => {
      setActiveTab(newValue);
    }, []);

    const handlePartnershipChange = useCallback(
      (event: ChangeEvent<{ value: any }>) => {
        const partnership = partnerships?.find(
          (p) => p.id === Number(event.target.value),
        );

        if (partnership) {
          setSelectedPartnership(partnership);
        }
      },
      [partnerships],
    );

    const getLinks = useCallback(
      async (id: number) => {
        if (selectedPartnership) {
          const { partnershipRank } = selectedPartnership;

          if (
            partnershipRank === PartnershipRank.Normal ||
            partnershipRank === PartnershipRank.SubIB
          ) {
            const links = await getClientLinks(id);

            setClientLinks(links.data.links);
          } else {
            const links = await getSubPartnershipLinks(id);

            setSubPartnershipLinks(links.data.links);
          }
        }
      },
      [selectedPartnership],
    );

    return (
      <Dialog
        trigger={trigger}
        title={translation.text_6235}
        onClose={() => handleClose()}
        actions={dialogActions()}
        classClose={classes.closeBtn}
        className={classes.dialogWidth}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FieldLabel label={translation.text_6242} isRequired />
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                displayEmpty
                value={selectedPartnership ? selectedPartnership.id : ''}
                onChange={handlePartnershipChange}
                inputProps={{
                  IconComponent: ExpandMore,
                }}
                classes={{
                  select: classes.partnershipSelect,
                }}
              >
                {partnerships?.map((partnership) => (
                  <MenuItem
                    key={partnership.partnershipCode}
                    value={partnership.id}
                  >
                    {partnership.partnershipCode}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {selectedPartnership !== null && selectedPartnership?.id && (
            <TabContext value={activeTab}>
              <AppBar position="static" className={classes.tabs}>
                <TabList variant="fullWidth" onChange={handleTabChange}>
                  {selectedPartnership.partnershipRank ===
                    PartnershipRank.Normal ||
                  selectedPartnership.partnershipRank ===
                    PartnershipRank.SubIB ? (
                    <Tab
                      className={classes.tab}
                      label={translation.text_6236}
                      value="1"
                    />
                  ) : (
                    <Tab
                      className={classes.tab}
                      label={translation.new_Sub_Partnership_Link}
                      value="2"
                    />
                  )}
                  <Tab
                    className={classes.tab}
                    label={translation.text_6239}
                    value="3"
                  />
                </TabList>
              </AppBar>
              <TabPanel className={classes.tabContainer} value="1">
                <CreateClientLinkTab
                  formRef={createClientLinkTabFormRef}
                  partnershipId={selectedPartnership.id as number}
                  tradingAccountTypes={
                    clientLinkSetup?.tradingAccountTypes || []
                  }
                  translation={translation}
                  brokers={clientLinkSetup?.brokers ?? []}
                  countries={clientLinkSetup?.countries ?? []}
                  setLoading={setIsLoading}
                  onLinkGeneration={getLinks}
                />
              </TabPanel>
              <TabPanel className={classes.tabContainer} value="2">
                <CreatePartnershipLinkTab
                  translation={translation}
                  formRef={subIBLinksTabFormRef}
                  partnershipId={selectedPartnership.id as number}
                  brokers={subPartnershipLinkSetup?.brokers ?? []}
                  linkConfigurations={
                    subPartnershipLinkSetup?.linkConfigurations ?? []
                  }
                  setLoading={setIsLoading}
                  onLinkGeneration={getLinks}
                />
              </TabPanel>
              <TabPanel className={classes.tabContainer} value="3">
                <ExistingLinksTab
                  clientLinks={clientLinks}
                  subPartnershipLinks={subPartnershipLinks}
                />
              </TabPanel>
              {/* New Partner link is temporary hidden */}
              {/* <TabPanel className={classes.tabContainer} value="3">
                <CreatePartnerLinkTab formRef={createPartnerLinkTabFormRef} partnershipId={partnershipId} brokers={directLinkSetup?.brokers ?? []} setLoading={setIsLoading} partnershipCode={partnershipCode} onLinkGeneration={getLinks} />
              </TabPanel> */}
            </TabContext>
          )}
        </Grid>
      </Dialog>
    );
  },
);
