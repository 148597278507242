export const PATH_IB_DASHBOARD = '/partnerDashboard';
export const PATH_WELCOME = '/';
export const PATH_LOGIN = '/login';
export const PATH_LOGIN_AS_PARTNER = '/loginAsPartner';
export const PATH_LOGIN_AS_REPRESENTATIVE = '/loginAsRepresentative';
export const PATH_PARTNERS_HIERARCHY = '/partnersHierarchy';
export const PATH_COMMISSIONS_BY_CLIENT = '/summaryByClientReport';
export const PATH_TRADING_SUMMARY = '/tradingSummary';
export const PATH_REPORTS_LIST = '/reportList';
export const PATH_CLIENTS_LIST = '/clientsList';
export const PATH_ALL_CLIENTS_AND_TRADING = '/allClientsAndTrading';
export const PATH_CLOSED_POSITIONS = '/closedPositions';
export const PATH_MASTER_IB_COMMISSION = '/masterIbCommission';
export const PATH_PARTNERS_REPRESENTATIVES = '/partnersRepresentatives';
export const PATH_NOTIFICATIONS = '/notifications';
export const PATH_SIGN_UP = '/resetPassword';
export const PATH_TRANSACTIONS_REPORT = '/transactions';
export const PATH_OPEN_CLOSE_POSITIONS_SUMMARY = '/openClosePositions';
export const PATH_TRADING_ACTIVITIES_REPORT = '/tradingActivities';
export const PATH_ERROR_PAGE = '/errorPage';
