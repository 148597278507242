import { Typography } from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
import { Dialog, DialogAction } from '../Dialog';
import { useAppData } from '../../providers';

interface Props {
  trigger: number | undefined
  title?: string
  content?: string;
  actions: Array<DialogAction>
}

export const ConfirmationDialog : FC<Props> = ({
  trigger, title, content, actions
}) => {
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  return (
    <Dialog title={title || translation.text_29} trigger={trigger} actions={actions}>
      <Typography>{content || translation.text_30}</Typography>
    </Dialog>
  );
};
