import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  title: {
    textAlign: 'left',
    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.frequent.davyGrey,

    '@media (max-width: 560px)': {
      fontSize: 20,
      marginBottom: 20,
      marginTop: 15
    },

    '@media (max-width: 300px)': {
      fontSize: 15
    }
  }
}));
