import React, { createContext, FC } from 'react';
import { useStateMachine } from 'little-state-machine';
import { ApplicationStore } from '../states';
import {
  removeCurrentPage,
  removeUserData,
  updateCurrentPage,
  updateUserData,
  updateTranslation,
  deleteTranslation,
  updateLogoUrl,
  removeLogoUrl,
  updateToken,
  removeToken,
  updateCurrencyList,
  removeCurrencyList,
  updatePartnershipList,
  removePartnershipList,
  updateClientPortalRedirectUrl,
  removeClientPortalRedirectUrl,
  updateLocale,
  updateCommissionsByClientReportRequestMsgId,
  removeCommissionsByClientReportRequestMsgId,
  updateMasterIbReportRequestMsgId,
  removeMasterIbReportRequestMsgId,
} from '../actions';
import { errorInitFunction } from '../../utils';

export const AppStateContext = createContext<{
  setTokenToStore: (token: string | undefined) => void;
  removeTokenFromStore: () => void;
  getCurrentPage: () => string | undefined;
  setCurrentPageInStore: (token: string | undefined) => void;
  removeCurrentPageFromStore: () => void;
  getUserData: () => string | undefined;
  getToken: () => string | undefined;
  setUserDataInStore: (userData: string | undefined) => void;
  removeUserDataFromStore: () => void;
  getTranslations: () => string | undefined;
  setTranslationInStore: (translations: string | undefined) => void;
  removeTranslationFromStore: () => void;
  getLogoUrl: () => string | undefined;
  setLogoUrlInStore: (logoUrl: string | undefined) => void;
  removeLogoUrlFromStore: () => void;
  getCurrencyList: () => string | undefined;
  setCurrenciesInStore: (translations: string | undefined) => void;
  removeCurrenciesFromStore: () => void;
  getPartnershipList: () => string | undefined;
  setPartnershipsInStore: (translations: string | undefined) => void;
  removePartnershipsFromStore: () => void;
  getClientPortalRedirectUrl: () => string | undefined;
  setClientPortalRedirectUrlInStore: (translations: string | undefined) => void;
  removeClientPortalRedirectUrlFromStore: () => void;
  getCommissionsByClientReportRequestMsgIdFromStore: () => string | undefined;
  setCommissionsByClientReportRequestMsgIdInStore: (
    msgId: string | undefined,
  ) => void;
  removeCommissionsByClientReportRequestMsgIdFromStore: () => void;
  getMasterIbReportRequestMsgIdFromStore: () => string | undefined;
  setMasterIbReportRequestMsgIdInStore: (msgId: string | undefined) => void;
  removeMasterIbReportRequestMsgIdFromStore: () => void;
  saveLocaleInStore: (locale: string) => void;
  getLocale: () => string | undefined;
  isLoggedIn: boolean;
}>({
  setTokenToStore: errorInitFunction,
  removeTokenFromStore: errorInitFunction,
  getCurrentPage: errorInitFunction,
  setCurrentPageInStore: errorInitFunction,
  removeCurrentPageFromStore: errorInitFunction,
  getUserData: errorInitFunction,
  getToken: errorInitFunction,
  setUserDataInStore: errorInitFunction,
  removeUserDataFromStore: errorInitFunction,
  getTranslations: errorInitFunction,
  setTranslationInStore: errorInitFunction,
  removeTranslationFromStore: errorInitFunction,
  getLogoUrl: errorInitFunction,
  setLogoUrlInStore: errorInitFunction,
  removeLogoUrlFromStore: errorInitFunction,
  getCurrencyList: errorInitFunction,
  setCurrenciesInStore: errorInitFunction,
  removeCurrenciesFromStore: errorInitFunction,
  getPartnershipList: errorInitFunction,
  setPartnershipsInStore: errorInitFunction,
  removePartnershipsFromStore: errorInitFunction,
  getClientPortalRedirectUrl: errorInitFunction,
  setClientPortalRedirectUrlInStore: errorInitFunction,
  removeClientPortalRedirectUrlFromStore: errorInitFunction,
  getCommissionsByClientReportRequestMsgIdFromStore: errorInitFunction,
  setCommissionsByClientReportRequestMsgIdInStore: errorInitFunction,
  removeCommissionsByClientReportRequestMsgIdFromStore: errorInitFunction,
  getMasterIbReportRequestMsgIdFromStore: errorInitFunction,
  setMasterIbReportRequestMsgIdInStore: errorInitFunction,
  removeMasterIbReportRequestMsgIdFromStore: errorInitFunction,
  saveLocaleInStore: errorInitFunction,
  getLocale: errorInitFunction,
  isLoggedIn: false,
});

export const AppStateProvider: FC = ({ children }) => {
  const { state, actions } = useStateMachine<ApplicationStore>({
    updateToken,
    removeToken,
    updateCurrentPage,
    removeCurrentPage,
    updateUserData,
    removeUserData,
    updateTranslation,
    deleteTranslation,
    updateLogoUrl,
    removeLogoUrl,
    updateCurrencyList,
    removeCurrencyList,
    updatePartnershipList,
    removePartnershipList,
    updateClientPortalRedirectUrl,
    removeClientPortalRedirectUrl,
    updateCommissionsByClientReportRequestMsgId,
    removeCommissionsByClientReportRequestMsgId,
    updateMasterIbReportRequestMsgId,
    removeMasterIbReportRequestMsgId,
    updateLocale,
  });

  const setTokenToStore = actions.updateToken as (
    isLoggedIn: string | undefined,
  ) => void;
  const removeTokenFromStore = actions.removeToken as () => void;

  const setCurrentPageInStore = actions.updateCurrentPage as (
    token: string | undefined,
  ) => void;
  const removeCurrentPageFromStore = actions.removeCurrentPage as () => void;

  const setUserDataInStore = actions.updateUserData as (
    userData: any | undefined,
  ) => void;
  const removeUserDataFromStore = actions.removeUserData as () => void;

  const setTranslationInStore = actions.updateTranslation as (
    translations: string | undefined,
  ) => void;
  const removeTranslationFromStore = actions.deleteTranslation as () => void;

  const setLogoUrlInStore = actions.updateLogoUrl as (
    logoUrl: string | undefined,
  ) => void;
  const removeLogoUrlFromStore = actions.removeLogoUrl as () => void;

  const setCurrenciesInStore = actions.updateCurrencyList as (
    currencies: string | undefined,
  ) => void;
  const removeCurrenciesFromStore = actions.removeCurrencyList as () => void;

  const setPartnershipsInStore = actions.updatePartnershipList as (
    partnerships: string | undefined,
  ) => void;
  const removePartnershipsFromStore =
    actions.removePartnershipList as () => void;

  const setClientPortalRedirectUrlInStore =
    actions.updateClientPortalRedirectUrl as (url: string | undefined) => void;
  const removeClientPortalRedirectUrlFromStore =
    actions.removeClientPortalRedirectUrl as () => void;

  const setCommissionsByClientReportRequestMsgIdInStore =
    actions.updateCommissionsByClientReportRequestMsgId as (
      msgId: string | undefined,
    ) => void;
  const removeCommissionsByClientReportRequestMsgIdFromStore =
    actions.removeCommissionsByClientReportRequestMsgId as () => void;

  const setMasterIbReportRequestMsgIdInStore =
    actions.updateMasterIbReportRequestMsgId as (
      msgId: string | undefined,
    ) => void;
  const removeMasterIbReportRequestMsgIdFromStore =
    actions.removeMasterIbReportRequestMsgId as () => void;

  const saveLocaleInStore = actions.updateLocale as (locale: string) => void;

  const getUserData = () => state.userData as string;

  const getTranslations = () => state.translations as string;

  const getCurrentPage = () => state.currentPage as string;

  const getLogoUrl = () => state.logoUrl as string;

  const getToken = () => state.token as string;

  const getCurrencyList = () => state.currencyList as string;

  const getPartnershipList = () => state.partnershipList as string;

  const getClientPortalRedirectUrl = () =>
    state.clientPortalRedirectUrl as string;

  const getLocale = () => state.locale as string;

  const getCommissionsByClientReportRequestMsgId = () =>
    state.commissionsByClientReportRequestMsgId as string;

  const getMasterIbReportRequestMsgId = () =>
    state.masterIbReportRequestMsgId as string;

  const isLoggedIn = (!!state.token && state.token.length >= 40) as boolean;

  return (
    <AppStateContext.Provider
      value={{
        setTokenToStore,
        removeTokenFromStore,
        getCurrentPage,
        setCurrentPageInStore,
        removeCurrentPageFromStore,
        getUserData,
        getToken,
        setUserDataInStore,
        removeUserDataFromStore,
        getTranslations,
        setTranslationInStore,
        removeTranslationFromStore,
        getLogoUrl,
        setLogoUrlInStore,
        removeLogoUrlFromStore,
        getCurrencyList,
        setCurrenciesInStore,
        removeCurrenciesFromStore,
        getPartnershipList,
        setPartnershipsInStore,
        removePartnershipsFromStore,
        getClientPortalRedirectUrl,
        setClientPortalRedirectUrlInStore,
        removeClientPortalRedirectUrlFromStore,
        getCommissionsByClientReportRequestMsgIdFromStore:
        getCommissionsByClientReportRequestMsgId,
        setCommissionsByClientReportRequestMsgIdInStore,
        removeCommissionsByClientReportRequestMsgIdFromStore,
        getMasterIbReportRequestMsgIdFromStore: getMasterIbReportRequestMsgId,
        setMasterIbReportRequestMsgIdInStore,
        removeMasterIbReportRequestMsgIdFromStore,
        saveLocaleInStore,
        getLocale,
        isLoggedIn,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
