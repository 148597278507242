export interface TransactionRequestModel {
  partnershipCodes: string[];
  fromDate: string;
  toDate: string;
  pageNumber: number;
  login?: string[];
}

export interface TransactionsReportData {
  accountName: string;
  deposits: string;
  internalTransferIn: string;
  internalTransferOut: string;
  login: number;
  netDeposit: string;
  netInternalTransfer: string;
  accountPartnership: string;
  withdrawals: string;
}

export interface TransactionsEmailReportRequest {
  reportPartnershipClientsTradingRequest: {
    fromDate: string;
    toDate: string;
    partnershipCodes: string[];
    pageNumber: number;
    login: string[];
  };
  reportEmailRequest: {
    email: null | string;
    includeTotal: boolean;
    tableColumns: string[];
    translatedTableColumns: string[];
    language: string | undefined;
    startDate: string | null;
    endDate: string | null;
  };
}

export const reportEnColumns = ['partnership', 'client name', 'login', 'deposits', 'withdrawals', 'net deposits', 'internal transfer in', 'internal transfer out', 'net internal transfer'];
