import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  languageSelectControl: {
    '& svg': {
      top: 'auto'
    },
  },
  languageItem: {
    display: 'flex',
    color: theme.palette.text.primary,
    alignContent: 'center',
    // textTransform: 'capitalize'
  },
  menu: {
    marginRight: 6
  },
  languageSelect: {
    marginTop: 105
  },
  languageSelectedItem: {
    height: 14,
    marginRight: 5
  },
  languageSelectItem: {
    height: 18,
    marginRight: 5
  },
}));
