import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  title: {
    color: theme.palette.frequent.davyGrey,
    marginBottom: 20,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 14
  },
  content: {
    padding: '16px 19px 14px 15px',
    borderRadius: 10,
    backgroundColor: `${theme.palette.frequent.dimGrey}0d`,
    boxShadow: 'none'
  },
  nameItem: {
    color: theme.palette.frequent.fossilGrey,
    fontSize: 14,
    margin: '9px 0px'
  },
  valueItem: {
    color: theme.palette.frequent.slateGrey,
    fontWeight: 500,
    fontSize: 17,
    paddingTop: 15,
    cursor: 'pointer',
    margin: '9px 21px 0'
  },
  infoIcon: {
    transform: 'scale(0.9)'
  }
}));
