import React, { FC, memo } from 'react';
import { useStyles } from '../styles';
import { ReactComponent as Copy } from '../../../assets/PartnershipLinkDialog/Copy.svg';

interface CopyIconProps {
  width?: number
}

export const CopyIcon: FC<CopyIconProps> = memo(({
  width
}) => {
  const classes = useStyles();
  return (
    <Copy className={classes.icon} width={width} />
  );
});
