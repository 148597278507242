import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    '@media screen': {
      display: 'none',
    },

    '& .MuiTableCell-sizeSmall': {
      '@media print': {
        padding: '2px 4px',
      },
    },
  },
  defaultFontSize: {
    '& .MuiTableCell-root': {
      '@media print': {
        fontSize: 14,
      },
    },
  },
  fontSize10Columns: {
    '& .MuiTableCell-root': {
      '@media print': {
        fontSize: 13,
      },
    },
  },
  fontSize11Columns: {
    '& .MuiTableCell-root': {
      '@media print': {
        fontSize: 12,
      },
    },
  },
  fontSize12Columns: {
    '& .MuiTableCell-root': {
      '@media print': {
        fontSize: 11,
      },
    },
  },
  fontSize13Columns: {
    '& .MuiTableCell-root': {
      '@media print': {
        fontSize: 10,
      },
    },
  },
  fontSize14Columns: {
    '& .MuiTableCell-root': {
      '@media print': {
        fontSize: 9,
      },
    },
  },
  fontSize15Columns: {
    '& .MuiTableCell-root': {
      '@media print': {
        fontSize: 8,
      },
    },
  },
  fontSize18Columns: {
    '& .MuiTableCell-root': {
      '@media print': {
        fontSize: 7,
      },
    },
  },
  printButton: {
    width: '100px !important',
    marginRight: 13,
    padding: '10px 20px 10px 20px',
    backgroundColor: theme.palette.changeable.exportButtons,
    color: theme.palette.common.white,
    justifyContent: 'center',
    borderRadius: 20,

    '&:hover': {
      backgroundColor: theme.palette.changeable.hover,
      color: theme.palette.common.white,
    },
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: 'left',
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '1.334',
    letterSpacing: '0em',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    marginBottom: 10,
  },
}));
