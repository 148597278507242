import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  headerSpace: {
    flexGrow: 1,
    boxShadow: 'none',
  },
  header: {
    '@media (max-width: 1180px)': {
      display: 'none',
    },
  },
  mobileHeader: {
    '@media (min-width: 1181px)': {
      display: 'none',
    },
  },
  mobileToolbar: {
    minHeight: 37,
    width: '100%',
    justifyContent: 'space-between',
  },
  appBar: {
    display: 'flex',
    background: theme.palette.frequent.background,
    color: theme.palette.secondary.contrastText,
    width: '100%',
    height: 74,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: 'none',
    alignItems: 'center',
    '@media (max-width: 1180px)': {
      paddingTop: 20,
    },
  },
  appBarShift: {
    display: 'flex',
    background: theme.palette.common.white,
    borderRadius: 10,
    color: theme.palette.secondary.contrastText,
    width: 'calc(100% - 363px - 52px)',
    height: 94,
    marginTop: 20,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: `0 10px 20px 0 ${theme.palette.frequent.boxShadow}`,
  },
  toolbar: {
    paddingRight: 0,
    height: '100%',
  },
  buttonTitle: {
    marginLeft: 5,
  },
  divider: {
    color: theme.palette.frequent.abalonGrey,
  },
  accountCircle: {
    height: 37,
    width: 37,
    color: theme.palette.frequent.wildBlue,
  },
  headerListItem: {
    width: 'fit-content',
    color: theme.palette.frequent.wildBlue,

    '@media (max-width: 1180px)': {
      fontSize: 12,
    },
  },
  headerListItemButton: {
    color: theme.palette.primary.main,
    padding: 0,
    fontWeight: 500,
    textAlign: 'right',
    minWidth: 'fit-content',
    marginLeft: 10,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    '& .MuiButton-label': {
      justifyContent: 'right',
    },

    '@media (max-width: 1180px)': {
      fontSize: 12,
      marginLeft: 5,
    },

    '@media (max-width: 420px)': {
      fontSize: 10,
      marginLeft: 2,
    },
  },
  languageInnerContainer: {
    marginRight: 39,
  },
  headerInnerContainer: {
    marginLeft: 32,
    marginRight: 32,
  },
  languageSelect: {
    '& svg': {
      top: 'auto',
    },
  },
  languageItem: {
    display: 'flex',
    color: theme.palette.text.primary,
    alignContent: 'center',
    textTransform: 'capitalize',
  },
  userName: {
    color: theme.palette.primary.light,
    fontWeight: 500,
    fontSize: 15,
    '@media (max-width: 640px)': {
      fontSize: 12,
    },
  },
  userRole: {
    fontWeight: 700,
    fontSize: 15,
    marginRight: 10,

    '@media (max-width: 1180px)': {
      marginRight: 5,
      fontSize: 12,
    },
    '@media (max-width: 420px)': {
      fontSize: 10,
      marginRight: 2,
    },
  },
  languageSelectedItem: {
    height: 14,
    marginRight: 5,
  },
  languageSelectItem: {
    height: 18,
    marginRight: 5,
  },
}));
