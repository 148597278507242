import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControl,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { FieldLabel } from '../../Form';
import { useStyles } from './styles';
import { useAppData } from '../../../providers';

interface Props {
  reportColumns: string[];
  selectedColumns: string[];
  className?: string;
  handleSelectColumns: (selectedColumns: string[]) => void;
}

export const ColumnsSelect: FC<Props> = ({
  reportColumns,
  selectedColumns,
  handleSelectColumns,
  className,
}) => {
  const classes = useStyles();
  const isAllSelected =
    reportColumns.length > 0 && selectedColumns.length === reportColumns.length;
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);
  const columnsSelectedValue = useMemo(
    () =>
      `${translation.text_6470}: ${selectedColumns.length}/${reportColumns.length}`,
    [translation, selectedColumns, reportColumns],
  );

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const handleSelectChange = (event: any) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      handleSelectColumns(
        selectedColumns.length === reportColumns.length ? [] : reportColumns,
      );
      return;
    }

    handleSelectColumns(value);
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <FieldLabel label={translation.text_6419} />
      <FormControl variant="outlined">
        <Select
          multiple
          value={selectedColumns}
          displayEmpty
          className={`${classes.columnsSelect} ${className}`}
          onChange={handleSelectChange}
          renderValue={() => columnsSelectedValue}
        >
          <MenuItem
            value="all"
            classes={{ root: isAllSelected ? classes.selectedAll : '' }}
          >
            <ListItemIcon>
              <Checkbox
                classes={{
                  checked: classes.checkboxColor,
                  indeterminate: classes.checkboxColor,
                }}
                checked={isAllSelected}
                indeterminate={
                  selectedColumns.length > 0 &&
                  selectedColumns.length < reportColumns.length
                }
                size="small"
              />
            </ListItemIcon>
            <ListItemText
              className={classes.selectText}
              classes={{ primary: classes.selectAllText }}
              primary={translation.text_1494}
            />
          </MenuItem>
          {reportColumns.map((option) => (
            <MenuItem key={Math.random()} value={option}>
              <ListItemIcon>
                <Checkbox
                  classes={{ checked: classes.checkboxColor }}
                  checked={selectedColumns.indexOf(option) > -1}
                  size="small"
                />
              </ListItemIcon>
              <ListItemText className={classes.selectText} primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};
