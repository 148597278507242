import React, { FC } from 'react';
import { Divider, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { MobileTableRow } from './MobileTableRow';
import { ValueLabel } from '../../../models';

interface Props {
  list: any[]
  grouped: boolean,
  hasNextButton: boolean
  onNextButtonClick?: (row: any) => void
}

export const MobileTableView: FC<Props> = ({
  list, grouped, hasNextButton, onNextButtonClick
}) => {
  const classes = useStyles();

  return (
    <>
      {grouped ? (
        <Grid container direction="row">
          {list.map((item) => (
            <Grid item xs={12} key={Math.random()}>
              <Grid container direction="row">
                <Grid item className={classes.groupByLabel}>{item.groupByLabel}:</Grid>
                <Grid item className={classes.value}>{item.groupByValue}</Grid>
              </Grid>
              <Grid container direction="row" className={classes.root}>
                {item.rows.map((row: ValueLabel[], index: number) => (
                  <Grid item xs={12} key={Math.random()}>
                    <MobileTableRow list={row} />
                    {(index < list.length - 1) && (
                      <Divider variant="middle" className={classes.divider} />)}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      )
        : (hasNextButton ? (
          <Grid container direction="row" className={classes.root}>
            {list.map((item, index) => (
              <Grid item xs={12} key={Math.random()}>
                {item.row !== null ? (
                  <MobileTableRow
                    list={item.list}
                    onNextButtonClick={() => {
                      if (onNextButtonClick) {
                        onNextButtonClick(item.row);
                      }
                    }}
                  />
                ) : (
                  <MobileTableRow
                    list={item.list}
                  />
                )}
                {(index < list.length - 1) && (
                <Divider variant="middle" className={classes.divider} />)}
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container direction="row" className={classes.root}>
            {list.map((item, index) => (
              <Grid item xs={12} key={Math.random()}>
                <MobileTableRow list={item} />
                {(index < list.length - 1) && (
                  <Divider variant="middle" className={classes.divider} />)}
              </Grid>
            ))}
          </Grid>
        ))}
    </>
  );
};
