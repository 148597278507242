import React, { createContext, Dispatch, ReactNode, useState } from 'react';

export type NotificationsContextValue = {
  notifications: Notification[];
  setNotifications: Dispatch<React.SetStateAction<Notification[]>>;
};

const notificationsContextDefaultValues: NotificationsContextValue = {
  notifications: [],
  setNotifications: () => null,
};

export const NotificationsContext = createContext<NotificationsContextValue>(notificationsContextDefaultValues);

export const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        setNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
