import React, { CSSProperties, FC, ReactNode } from 'react';
import { Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { FormikErrors } from 'formik';
import { useStyles } from '../styles';

interface Props {
  value: any;
  children: ReactNode;
  onChange: (value: any) => void;
  open?: boolean;
  style?: CSSProperties;
  errors?:
    | string
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[]
    | undefined;
  className?: string;
  onOpen?: () => void;
  renderValueCustomCondition?: (value: string[]) => false | string[];
}

export const MultiSelectControl: FC<Props> = ({
  value,
  onChange,
  children,
  style,
  open,
  onOpen,
  className,
  errors,
  renderValueCustomCondition,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Select
        open={open}
        onOpen={onOpen}
        multiple
        value={value}
        variant="outlined"
        IconComponent={ExpandMore}
        onChange={onChange}
        className={`${classes.selectContainer} ${className}`}
        style={style}
        renderValue={(newValue: any) => {
          if (renderValueCustomCondition?.(newValue)) {
            return renderValueCustomCondition(newValue);
          }

          return newValue.length > 1 ? newValue.join('; ') : newValue;
        }}
      >
        {children}
      </Select>
      {errors && <span className={classes.errorDescription}>{errors}</span>}
    </div>
  );
};
