import {
  Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow
} from '@material-ui/core';
import React, { FC, useCallback, useState } from 'react';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { useCommonStyles } from '../../styles';
import { useStyles } from './styles';

interface Props {
  headerCells: any,
  rows: any[]
  emptyDataMessage?: string
  totalRow?: any
  columnsNumber: number,
  className?: string,
  headerClassName?: string
  onPageChange: (pageNumber: number) => void
}

export const CustomTableWithPagination: FC<Props> = ({
  headerCells, rows, emptyDataMessage, totalRow, columnsNumber, className,
  headerClassName, onPageChange
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const rowsPerPage = 500;
  const [pageNumber, setPageNumber] = useState(0);

  const handleChangePage = useCallback((event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
    onPageChange(newPage + 1);
  }, [onPageChange]);

  return (
    <>
      <TableContainer className={className}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow className={`${commonClasses.tableHeader} ${headerClassName}`}>
              {headerCells}
            </TableRow>
          </TableHead>
          <TableBody>
            {totalRow}
            {rows.length === 0
              ? (
                <TableRow className={commonClasses.tableRow}>
                  <TableCell align="center" className={classes.emptyMessage} colSpan={columnsNumber}>{emptyDataMessage}</TableCell>
                </TableRow>
              )
              : rows.map((row) => (row))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {rows.length > 500 && (
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                count={rows.length === 0 ? 0 : -1}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                labelDisplayedRows={({
                  from,
                  to,
                  count,
                  page
                }) => (count === 0 && (page === 0 || page > 0) ? '' : `Displaying ${from}-${to} records`)}
                onChangePage={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
