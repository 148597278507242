import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import { getTranslation } from 'src/utils';
import { useAppData, useLoading } from '../../../providers';
import {
  ReportsGeneralToolbar,
  ReportTitle,
  ColumnsSelect,
} from '../../../components';
import { useStyles } from './styles';
import { PartnershipReports } from './components';
import useGetReports from './hooks/useGetReports';
import { ReportModel } from '../../../models';

export const MasterIbCommission: FC = () => {
  const currentDate = useMemo(() => new Date(), []);
  const classes = useStyles();
  const { userData, translations } = useAppData();
  const { setIsLoading } = useLoading();
  const [fromDate, setFromDate] = useState<Date>(
    new Date(
      `${
        currentDate.getMonth() + 1
      } 1 ${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`,
    ),
  );
  const [toDate, setToDate] = useState<Date>(currentDate);
  const [reports, setReports] = useState<ReportModel[]>([]);
  const [showReports, setShowReports] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<any>(null);
  const [translation, setTranslation] = useState<any>([]);
  const columnNames = useMemo(
    () => [
      translation.text_6009,
      translation.text_572,
      translation.text_579,
      translation.text_982,
      translation.text_983,
      translation.text_6344,
      translation.text_6010,
    ],
    [translation],
  );
  const [selected, setSelected] = useState<string[]>(columnNames);

  const dateRange = useMemo(
    () =>
      `
      ${getTranslation(translation, moment(fromDate).format('MMMM'))}  ${moment(
        fromDate,
      ).format('YYYY')} - 
      ${getTranslation(translation, moment(toDate).format('MMMM'))}  ${moment(
        toDate,
      ).format('YYYY')}      
      `,
    [fromDate, toDate, translation],
  );

  useEffect(() => {
    if (columnNames.length === 7) {
      setSelected(columnNames.slice(0, 6));
    }
  }, [columnNames]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    const userLoginData = JSON.parse(userData);
    setLoginData(userLoginData);
  }, [userData]);

  const handleNewReportRequest = useGetReports({
    translation,
    toDate,
    fromDate,
    setReports,
    showReports,
    setShowReports,
    setIsLoading,
  });

  const changeFromDate = useCallback((newValue) => {
    setFromDate(newValue);
  }, []);

  const changeToDate = useCallback((newValue) => {
    setToDate(newValue);
  }, []);

  const renderTableData = (): string[][] => {
    const rows: string[][] = [];
    reports.forEach((row) => {
      const tableRow: string[] = [
        row.partnershipCode ? row.partnershipCode : '-',
        row.partnershipType ? row.partnershipType : '-',
        row.schemeName ? row.schemeName : '-',
        row.subPartnershipsRebateSum || row.subPartnershipsRebateSum === 0
          ? row.subPartnershipsRebateSum.toFixed(2)
          : '-',
        row.depositUSD || row.depositUSD === 0
          ? row.depositUSD.toFixed(2)
          : '-',
        row.withdrawalUSD || row.withdrawalUSD === 0
          ? Math.abs(row.withdrawalUSD).toFixed(2)
          : '-',
        row.schemeType ? row.schemeType : '-',
      ];
      rows.push(tableRow);
    });

    return rows;
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <ReportTitle
        title={translation.text_6656}
        exportFileName={translation.text_6656}
        reportColumns={columnNames}
        exportTableData={renderTableData()}
        printColumnNumber={selected.length}
        content={
          <PartnershipReports
            toDate={toDate}
            fromDate={fromDate}
            partnerName={loginData?.userDisplayName}
            date={dateRange}
            columnNames={columnNames}
            selectedColumns={selected}
            rows={reports}
          />
        }
        enableButtons={showReports}
        translation={translation}
      />
      <Paper className={classes.paper}>
        <Grid container direction="column" className={classes.root}>
          <Grid container direction="row" className={classes.desktop}>
            <Grid item xs={10}>
              <ReportsGeneralToolbar
                fromDate={fromDate}
                toDate={toDate}
                handleFromDateChange={changeFromDate}
                handleToDateChange={changeToDate}
                showButton
                handleButtonClick={handleNewReportRequest}
              />
            </Grid>
            <Grid item xs={2}>
              {showReports && (
                <ColumnsSelect
                  reportColumns={columnNames}
                  selectedColumns={selected}
                  handleSelectColumns={setSelected}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.mobile}>
          <ReportsGeneralToolbar
            fromDate={fromDate}
            toDate={toDate}
            handleFromDateChange={changeFromDate}
            handleToDateChange={changeToDate}
            showButton
            handleButtonClick={handleNewReportRequest}
          />
        </Grid>
        {showReports ? (
          <Grid item xs={12}>
            <Grid item xs={6} className={classes.mobile}>
              <ColumnsSelect
                reportColumns={columnNames}
                selectedColumns={selected}
                handleSelectColumns={setSelected}
              />
            </Grid>
            <PartnershipReports
              toDate={toDate}
              fromDate={fromDate}
              partnerName={loginData?.userDisplayName}
              date={dateRange}
              columnNames={columnNames}
              selectedColumns={selected}
              rows={reports}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.emptyReportMessageContainer}
            >
              <Grid item className={classes.groupedByCell}>
                {translation.text_6455}
              </Grid>
              <Grid item>{translation.text_6456}</Grid>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};
