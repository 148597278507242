import { useCallback } from 'react';
import { useAppData } from 'src/providers';
import { useSnackbar } from 'notistack';
import { getAllClientsAndTradingReports } from '../AllClientsAndTradingService';
import { ReportModel } from '../../../../models';

const useGetReports = ({
  toDate,
  fromDate,
  setReports,
  showReports,
  setIsLoading,
  setShowReports,
  translation,
}: {
  translation: any;
  toDate: Date;
  fromDate: Date;
  showReports: boolean;
  setIsLoading: (data: boolean) => void;
  setShowReports: (data: boolean) => void;
  setReports: (data: ReportModel[]) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userData } = useAppData();

  return useCallback(async () => {
    const loginData = JSON.parse(userData);
    setIsLoading(true);

    try {
      const response = await getAllClientsAndTradingReports(
        loginData.partnerID,
        fromDate.getMonth() + 1,
        fromDate.getFullYear(),
        fromDate.getDate(),
        toDate.getMonth() + 1,
        toDate.getFullYear(),
        toDate.getDate(),
        'USD',
        loginData.defaultAccount,
      );

      setReports(response.data);

      if (!showReports) {
        setShowReports(true);
      }
    } catch (error) {
      setReports([]);
      enqueueSnackbar(translation.something_went_wrong, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [
    userData,
    setIsLoading,
    fromDate,
    toDate,
    setReports,
    showReports,
    setShowReports,
    enqueueSnackbar,
    translation.something_went_wrong,
  ]);
};

export default useGetReports;
