import React, { FC, ReactElement } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { ExportButtons } from '../../ExportButtons';
import { PrintButton } from '../../PrintButton';
import { SendByEmail } from '../..';

interface Props {
  title: string;
  exportFileName: string;
  exportTableData: string[][];
  reportColumns: string[];
  content: ReactElement;
  printColumnNumber: number;
  enableButtons: boolean;
  translation: any;
  sendEmail?: () => void;
}

export const ReportTitle: FC<Props> = ({ title, exportFileName, exportTableData, reportColumns, content, printColumnNumber, enableButtons, translation, sendEmail }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container direction="row" spacing={1} alignContent="flex-start" alignItems="center" className={classes.webTitle}>
        <Grid item xs={6}>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="row-reverse" spacing={2}>
            <Grid item>
              <ExportButtons exportFileName={exportFileName} columns={reportColumns} exportTableData={exportTableData} fontSize={6} isEnabled={enableButtons} />
            </Grid>
            <Grid item>{sendEmail && <SendByEmail sendEmail={sendEmail} translation={translation} title={title} content={content} isEnabled={enableButtons} />}</Grid>
            <PrintButton translation={translation} title={title} columnNumber={printColumnNumber || 0} isEnabled={enableButtons}>
              {content}
            </PrintButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.mobileTitle}>
        <Typography variant="h5">{title}</Typography>
        <Grid container direction="row-reverse" justify="center" style={{ width: 'calc(100% + 4px)' }} spacing={1}>
          <Grid item>
            <ExportButtons exportFileName={exportFileName} columns={reportColumns} exportTableData={exportTableData} fontSize={6} isEnabled={enableButtons} />
          </Grid>
          <Grid item>{sendEmail && <SendByEmail sendEmail={sendEmail} translation={translation} title={title} content={content} isEnabled={enableButtons} />}</Grid>
          <PrintButton translation={translation} title={title} columnNumber={printColumnNumber || 0} isEnabled={enableButtons}>
            {content}
          </PrintButton>
        </Grid>
      </Grid>
    </>
  );
};
