import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useApiError, useAppData } from '../../providers';
import { DefaultLayout } from '../../layouts';
import { PATH_WELCOME, getTranslation } from '../../utils';

interface Props {
  path: string;
  Component: FC<RouteComponentProps>;
  exact?: boolean;
}

export const AuthRoute: FC<Props> = ({ Component, path, exact = false }) => {
  const location = useLocation();
  const { isUserLoggedIn, removeToken, removeUserData, translations, saveCurrentPage, removeTranslations, removePartnershipList, removeCurrencyList } = useAppData();
  const { error, removeError } = useApiError();
  const { enqueueSnackbar } = useSnackbar();
  const [translation, setTranslation] = useState<any>('');
  const message = 'Please log in to view this page';

  useEffect(() => {
    if (translations) {
      setTranslation(JSON.parse(translations));
    }
  }, [translations]);

  useEffect(() => {
    if (error) {
      const errorObj = JSON.parse(error);
      if (errorObj.status === 401) {
        saveCurrentPage(location.pathname);
        removeTranslations();
        removePartnershipList();
        removeCurrencyList();
        removeToken();
        removeUserData();
      } else if (errorObj.status === 403 && errorObj.data.message === 'You do not have the required permissions to access this resource.') {
        removeTranslations();
        removePartnershipList();
        removeCurrencyList();
        removeToken();
        removeUserData();
        enqueueSnackbar(translation.text_1641, { variant: 'error' });
      } else {
        let errorData = '';
        if (errorObj.data.Message || errorObj.data.message) {
          errorData = errorObj.data.Message ? errorObj.data.Message : errorObj.data.message;
        } else if (errorObj.data.errors) {
          errorData = errorObj.data.title ? errorObj.data.title : errorObj.response.data;
        } else {
          errorData = errorObj.data;
        }
        enqueueSnackbar(getTranslation(translation, errorData), {
          variant: 'error',
        });
      }

      removeError();
    }
  }, [enqueueSnackbar, error, removeError, removeToken, removeUserData, location, translation, saveCurrentPage, removePartnershipList, removeCurrencyList, removeTranslations]);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) =>
        isUserLoggedIn ? (
          <DefaultLayout>
            <Component {...props} />
          </DefaultLayout>
        ) : (
          <Redirect
            to={{
              pathname: PATH_WELCOME,
              state: {
                message,
                requestedPath: path,
              },
            }}
          />
        )
      }
    />
  );
};
