import React, { memo, FC, useCallback, useState, Fragment, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Button, Typography } from '@material-ui/core';
import { ArrowDropDownSharp } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { getTranslation } from 'src/utils';
import useStyles from './styles';
import { useApiError, useAppData, useLoading } from '../../../providers';
import { Link } from '../PartnershipLinkDialogModel';
import { CopyIcon, LinkDetailsIcon } from '../../CustomIcons';
import { getPropertiesForClientSetupURL, getPropertiesForSubPartnershipSetupURL } from '../../../pages';

interface ExistingLinksTabProps {
  clientLinks: Link[];
  subPartnershipLinks: Link[];
}

export const ExistingLinksTab: FC<ExistingLinksTabProps> = memo(({ clientLinks, subPartnershipLinks }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { addError } = useApiError();
  const { setIsLoading } = useLoading();
  const [expandedClientLink, setExpandedClientLink] = useState<string | false>(false);
  const [expandedSubPartnershipLink, setExpandedSubPartnershipLink] = useState<string | false>(false);
  const [linkInfo, setLinkInfo] = useState<any | false>(false);
  const classes = useStyles();
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const handleLinkCopy = useCallback(
    async (event, link) => {
      event.stopPropagation();
      await navigator.clipboard.writeText(link);
      enqueueSnackbar(translation.text_1637, { variant: 'success' });
    },
    [enqueueSnackbar, translation.text_1637],
  );

  const handleExpandedClientAccordionChange = (link: string) => (event: any, isExpanded: boolean) => {
    setIsLoading(true);
    getPropertiesForClientSetupURL(link)
      .then(({ data }) => {
        if (isExpanded) {
          setLinkInfo({
            broker: data.brokerName,
            shortCode: data.shortCode !== undefined && data.shortCode !== null ? data.shortCode : '-',
            language: data.language,
            country: data.country,
          });
        }
        setExpandedClientLink(isExpanded ? link : false);
      })
      .catch((error) => {
        addError(JSON.stringify(error.response));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleExpandedSubPartnershipAccordionChange = (link: string, shortCode: string) => (event: any, isExpanded: boolean) => {
    setIsLoading(true);
    getPropertiesForSubPartnershipSetupURL(shortCode)
      .then(({ data }) => {
        if (isExpanded) {
          setLinkInfo({
            broker: data.brokerName,
            shortCode: data.shortCode !== undefined && data.shortCode !== null ? data.shortCode : '-',
            language: data.language,
            percentage: data.percentage,
          });
        }
        setExpandedSubPartnershipLink(isExpanded ? link : false);
      })
      .catch((error) => {
        addError(JSON.stringify(error.response));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        {clientLinks.length > 0 && (
          <Grid item xs={12} className={classes.selectGridContaner}>
            <Accordion className={classes.accordionContainer}>
              <AccordionSummary expandIcon={<ArrowDropDownSharp />} className={classes.accordionHeader}>
                <Typography className={classes.accordionHeaderTitle}>{translation.text_419}</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetails }}>
                {clientLinks.map((link) => (
                  <Fragment key={link.shortCode}>
                    <Accordion classes={{ root: classes.accordion }} expanded={expandedClientLink === link.link} onChange={handleExpandedClientAccordionChange(link.link)}>
                      <AccordionSummary classes={{ content: classes.accordionContent }}>
                        <Typography className={classes.link}>{link.link.substring(0, 47)}...</Typography>
                        <div className={classes.rightContainer}>
                          <Button
                            className={classes.expandButtons}
                            onClick={() => {
                              handleExpandedClientAccordionChange(link.link);
                            }}
                          >
                            <LinkDetailsIcon width={10} />
                          </Button>
                          <Button onClick={(event) => handleLinkCopy(event, link.link)} className={classes.copyButtons}>
                            <CopyIcon width={10} />
                          </Button>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_6270}</b>: {linkInfo.broker}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_6682}</b>: {linkInfo.language}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_432}</b>: {getTranslation(translation, linkInfo?.country || '')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.shortCode}>
                            <Typography component="span">
                              <b>{translation.text_6272}</b>: {linkInfo.shortCode}
                            </Typography>
                            <Button onClick={(event) => handleLinkCopy(event, linkInfo.shortCode)} className={classes.copyCodeButtons}>
                              <CopyIcon width={10} />
                            </Button>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Fragment>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        {subPartnershipLinks.length > 0 && (
          <Grid item xs={12} className={classes.selectGridContaner}>
            <Accordion className={classes.accordionContainer}>
              <AccordionSummary expandIcon={<ArrowDropDownSharp />} className={classes.accordionHeader}>
                <Typography className={classes.accordionHeaderTitle}>{translation.sub_Partnership_Links}</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetails }}>
                {subPartnershipLinks.map((link) => (
                  <Fragment key={link.shortCode}>
                    <Accordion classes={{ root: classes.accordion }} expanded={expandedSubPartnershipLink === link.link} onChange={handleExpandedSubPartnershipAccordionChange(link.link, link.shortCode)}>
                      <AccordionSummary classes={{ content: classes.accordionContent }}>
                        <Typography className={classes.link}>{link.link.substring(0, 47)}...</Typography>
                        <div className={classes.rightContainer}>
                          <Button className={classes.expandButtons} onClick={() => handleExpandedSubPartnershipAccordionChange(link.link, link.shortCode)}>
                            <LinkDetailsIcon width={10} />
                          </Button>
                          <Button onClick={(event) => handleLinkCopy(event, link.link)} className={classes.copyButtons}>
                            <CopyIcon width={10} />
                          </Button>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_435}</b>: {linkInfo.broker}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_436}</b>: {linkInfo.language}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_437}</b>: {linkInfo.shortCode}
                            </Typography>
                            <Button onClick={(event) => handleLinkCopy(event, linkInfo.shortCode)} className={classes.copyCodeButtons}>
                              <CopyIcon width={10} />
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_438}</b>: {linkInfo.percentage}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Fragment>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
    </>
  );
});
