import React, { FC } from 'react';
import { ReactComponent as ArrowDropDown } from '../../../assets/Dashboard/ArrowDropDown.svg';
import { useStyles } from '../styles';
import { CustomIconProps } from '../CustomIconModels';

export const ArrowDropDownGraphIcon: FC<CustomIconProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <ArrowDropDown className={`${classes.icon} ${className || ''}`} />
  );
};
