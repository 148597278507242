import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  reportRequested: {
    '& .MuiAlert-message': {
      width: '100%',
      padding: 0
    },
    borderRadius: 15,
    border: `1px solid ${theme.palette.frequent.green}`,
    color: theme.palette.text.secondary
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'center',

    '@media (max-width: 851px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  message: {
    maxWidth: '83%',
    flexBasis: '83%',
    textAlign: 'left',

    '@media (max-width: 851px)': {
      maxWidth: '100%',
      flexBasis: '100%',
    }
  },
  statusOrButton: {
    maxWidth: '16%',
    flexBasis: '16%',
    textAlign: 'right',

    '@media (max-width: 851px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      textAlign: 'left'
    }
  },
  reportsButton: {
    color: theme.palette.frequent.blue
  },
  reportRequestProgress: {
    color: theme.palette.frequent.pastelPink
  },
  reportRequestFailed: {
    color: theme.palette.frequent.red
  }
}));
