import React, { FC } from 'react';
import {
  Button, Grid, List, ListItem
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStyles } from '../styles';
import { ReportMenuButtonIcon } from '../../../../components';

interface Props {
  list: any[],
  menuButtonClass: string| undefined
}

export const ListComponent: FC<Props> = ({ list, menuButtonClass }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container direction="row" justify="space-between" className={classes.panelRow}>
        <Grid item xs={6}>
          <List>
            {list.slice(0, Math.ceil(list.length / 2)).map((item: any) => (
              <div key={Math.random()}>
                {item.link !== '' ? (
                  <ListItem component={Link} to={item.link}>
                    <Button
                      variant="contained"
                      className={menuButtonClass}
                      startIcon={<div className={classes.circleContainer}><ReportMenuButtonIcon /></div>}
                    >
                      <span className={classes.menuButtonText}>{item.name}</span>
                    </Button>
                  </ListItem>
                ) : (
                  <ListItem>
                    <Button
                      variant="contained"
                      className={menuButtonClass}
                      startIcon={<div className={classes.circleContainer}><ReportMenuButtonIcon /></div>}
                    >
                      <span className={classes.menuButtonText}>{item.name}</span>
                    </Button>
                  </ListItem>

                )}
              </div>
            ))}
          </List>
        </Grid>
        <Grid item xs={6}>
          <List>
            {list.slice(-Math.ceil((list.length - 1) / 2)).map((item: any) => (
              <div key={Math.random()}>
                {item.link !== '' ? (
                  <ListItem component={Link} to={item.link}>
                    <Button
                      variant="contained"
                      className={menuButtonClass}
                      startIcon={<div className={classes.circleContainer}><ReportMenuButtonIcon /></div>}
                    >
                      <span className={classes.menuButtonText}>{item.name}</span>
                    </Button>
                  </ListItem>
                ) : (
                  <ListItem>
                    <Button
                      variant="contained"
                      className={menuButtonClass}
                      startIcon={<div className={classes.circleContainer}><ReportMenuButtonIcon /></div>}
                    >
                      <span className={classes.menuButtonText}>{item.name}</span>
                    </Button>
                  </ListItem>

                )}
              </div>
            ))}
          </List>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="space-between" className={classes.mobilePanelRow}>
        <List style={{ width: '100%' }}>
          {list.map((item: any) => (
            <Grid key={Math.random()} item xs={12}>
              {item.link !== '' ? (
                <ListItem component={Link} to={item.link}>
                  <Button
                    variant="contained"
                    className={menuButtonClass}
                    startIcon={<div className={classes.circleContainer}><ReportMenuButtonIcon /></div>}
                  >
                    <span className={classes.menuButtonText}>{item.name}</span>
                  </Button>
                </ListItem>
              ) : (
                <ListItem>
                  <Button
                    variant="contained"
                    className={menuButtonClass}
                    startIcon={<div className={classes.circleContainer}><ReportMenuButtonIcon /></div>}
                  >
                    <span className={classes.menuButtonText}>{item.name}</span>
                  </Button>
                </ListItem>

              )}
            </Grid>
          ))}
        </List>
      </Grid>
    </>
  );
};
