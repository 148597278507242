import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  columnsSelect: {
    textAlign: 'left',
    height: 40,
    fontSize: '13pt',
    '@media (max-width: 1180px)': {
      fontSize: 13,
      borderRadius: 6
    },

    '@media (max-width: 424px)': {
      fontSize: 13,
      minWidth: 'unset !important',
      borderRadius: 6
    }
  },
  selectText: {
    textTransform: 'uppercase',
    fontSize: theme.typography.fontSize,
    marginTop: 0,
    marginBottom: 0,
    '& .MuiTypography-root': {
      fontSize: theme.typography.fontSize,
    }
  },
  checkboxColor: {
    color: `${theme.palette.frequent.green} !important`,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  },
  selectAllText: {
    fontWeight: 500
  },
}));
