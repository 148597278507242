export enum Period {
  MONTHLY = 1,
  QUARTERLY,
  YEARLY,
  DAILY,
  ALL_YEARS,
}

export enum HighlightTypes {
  Undefined,
  Commissions,
  Deposits,
  Withdrawals,
  Volume,
  VolumeLots,
  Profit,
  Clients,
  TradingAccounts,
  NetDeposits,
  Loss,
  InternalTransferIn,
  InternalTransferOut,
}

export enum Platforms {
  ALL_PLATFORMS = 1,
  MT4,
}

export interface DashboardAggregatedEstimatedValues {
  commissions: number;
  deposits: number;
  netDeposits: number;
  withdrawals: number;
  internalTransferIn: number;
  internalTransferOut: number;
  volume: number;
  volumeLots: number;
  profit: number;
  loss: number;
  lastUtcUpdateTime: string;
}

export interface AggregatedValues {
  activeClients: number;
  activeTradingAccounts: number;
  commissions: number;
  netDeposits: number;
  deposits: number;
  inactiveClients: number;
  inactiveTradingAccounts: number;
  totalClients: number;
  totalTradingAccounts: number;
  volume: number;
  volumeLots: number;
  withdrawals: number;
  profit: number;
  loss: number;
  dashboardAggregatedEstimatedValuesViewModel: DashboardAggregatedEstimatedValues;
}

export interface GeneratePartnerChildLinkRequestModel {
  shortCode?: string;
  parentPartnershipCode: string;
  schemeID: number | string;
  schemeName: string;
  brokerId: string;
  percentage?: number;
}

export interface GeneratePartnerLinkRequestModel {
  shortCode?: string;
  currency?: string;
  partnershipCode: number | string;
  landingPage?: string;
  language: string;
  linkType: string;
  platform?: string;
  brokerFID: number;
}

export interface GeneratePartnerMasterLink {
  brokerId: number;
  shortCode: string;
  linkConfigurationId: string;
}

export interface LinkType {
  linkType: number | string;
  linkTypeText: string;
  currencies: string[];
}

export interface PlatformForLink {
  id: number | string;
  name: string;
  directCode: string;
  linkTypes: LinkType[];
}
