import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { ReportTable } from '../ReportTable';
import { ClientListItem } from '../../ClientListModel';
import { useStyles } from '../../styles';
import { MobileReportTable } from '../MobileReportTable';

interface Props {
  translation: any;
  groupBy: string | undefined;
  columnNames: string[];
  selectedColumns: string[];
  reportData: ClientListItem[];
}

export const ReportView: FC<Props> = ({
  groupBy,
  selectedColumns,
  reportData,
  columnNames,
  translation,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid className={classes.topSelect}>
        <ReportTable
          translation={translation}
          groupByOption={groupBy}
          columnNames={columnNames}
          selectedColumns={selectedColumns}
          rows={reportData}
        />
      </Grid>
      <Grid className={classes.topSelectMobile}>
        <MobileReportTable
          translation={translation}
          groupByOption={groupBy}
          columnNames={columnNames}
          selectedColumns={selectedColumns}
          rows={reportData}
        />
      </Grid>
    </>
  );
};
