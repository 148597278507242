import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  link: {
    display: 'inline-flex',
    paddingTop: 6,
    color: theme.palette.frequent.fossilGrey,
  },
  accordionContent: {
    margin: 0,
  },
  selectField: {
    height: 19,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 10,
    fontSize: '13pt',

    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
    '&::before': {
      display: 'none',
    },

    '& $selectRoot': {
      padding: theme.spacing(2, 3),
      paddingRight: theme.spacing(2),
      textAlign: 'left',
    },
  },
  selectIconClass: {
    color: theme.palette.frequent.cadetBlue,
  },
  inputRoot: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 10,
    '&:hover, &.Mui-focused': {
      border: `1px solid ${theme.palette.common.black}`,
    },
    paddingBottom: 7,
    paddingTop: 7,
  },
  container: {
    padding: 15,
  },
  button: {
    height: 45,
    width: 180,
    borderRadius: 25,
    padding: '10px 20px 10px 20px',
    color: theme.palette.common.white,
  },
  generateButton: {
    backgroundColor: theme.palette.changeable.secondaryColor,
    color: theme.palette.changeable.secondaryBtnTextColor,
    '&:hover': {
      backgroundColor: theme.palette.changeable.hover,
      color: theme.palette.common.white,
    },
  },
  generateButtonContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: 'center',
  },
  generatedLinkContainer: {
    backgroundColor: theme.palette.common.white,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.frequent.abalonGrey,
    borderRadius: 10,
    paddingTop: 15,
    paddingBottom: 18,
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.frequent.greenButton,
    },
  },
  generetedLinkDefaultText: {
    fontStyle: 'italic',
    color: theme.palette.frequent.lightLinkGrey,
  },
  copyText: {
    paddingLeft: 10,
    fontSize: '0.875rem',
  },
  copyLinkButton: {
    backgroundColor: theme.palette.common.white,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.frequent.abalonGrey,
    borderRadius: 20,
    width: 140,
    marginLeft: 15,
    float: 'right',
    '&:hover, &:focus, &.Mui-focused': {
      border: 1,
      borderColor: theme.palette.frequent.grey,
      backgroundColor: theme.palette.frequent.lightGreenButton,
    },
  },
  rightContainer: {
    float: 'right',
  },
  realDemoButton: {
    backgroundColor: theme.palette.frequent.buttonGrey,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.frequent.chineseWhite,
    float: 'left',
    padding: '10px 0px',
    '&:hover, &:focus, &.Mui-focused': {
      backgroundColor: theme.palette.common.white,
    },
    borderRadius: 5,
    fontWeight: 400,
    fontSize: 12,
    width: 145.7,
    height: 40,
  },
  leftButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  rightButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  linkTypeSelected: {
    backgroundColor: theme.palette.common.white,
  },
  fieldContainer: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    '& legend': {
      width: 0,
      display: 'none',
    },
    '& .MuiSelect-root': {
      background: theme.palette.common.white,
    },
  },
  partnershipSelect: {
    width: '100%',
    paddingBottom: 8,
    paddingTop: 8,
    backgroundColor: 'transparent',
    borderRadius: 10,
    overflow: 'hidden',
  },
  formControl: {
    width: '100%',
    marginTop: 5,
    minWidth: 175,
    maxWidth: 673,
    '&:hover, & .Mui-focused, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: theme.palette.frequent.davyGrey,
        borderWidth: 1,
      },
    '& .MuiOutlinedInput-root': {
      borderRadius: 10,
    },
  },
  input: {
    width: 17,
    height: 15,
    color: theme.palette.frequent.fossilGrey,
  },
  shortcodeContainer: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 8,
    paddingBottom: 10,
  },
  generetedLinkTittle: {
    fontWeight: 600,
    paddingTop: 8,
    paddingRight: 8,
  },
  generatedLink: {
    paddingTop: 8,
    paddingRight: 8,
  },
}));
