import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    color: theme.palette.common.white,
    background: theme.palette.changeable.layoutBackground,
  },
  container: {
    display: 'flex',
    flex: 1,
    margin: '36px 52px 82px 33px',

    '@media (max-width: 768px)': {
      margin: '36px 16px 20px 15px',
    },
  },
  mobileContainer: {
    display: 'flex',
    flex: 1,
    padding: '6px 17px 21px 21px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
  },
  drawer: {
    position: 'static',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
    width: 269,

    '@media (max-width: 1180px)': {
      display: 'none',
    },
  },
  main: {
    backgroundColor: theme.palette.changeable.layoutBackground,
    color: theme.palette.text.primary,
    marginLeft: 61,
    width: 'calc(100% - 280px - 50px)',

    '@media (max-width: 1180px)': {
      width: '100%',
      marginLeft: 0,
    },
  },
}));
