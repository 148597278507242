import React, { FC } from 'react';
import { ReactComponent as GreenArrow } from '../../../assets/Dashboard/GreenArrow.svg';
import { useStyles } from '../styles';
import { CustomIconProps } from '../CustomIconModels';

export const GreenArrowIcon: FC<CustomIconProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <GreenArrow className={`${classes.icon} ${className || ''}`} />
  );
};
