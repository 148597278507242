import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { mapValues, groupBy, omit } from 'lodash';
import { v4 as uuid } from 'uuid';
import { useAppData } from 'src/providers';
import { CustomTable, ReportsCollapsibleRow } from '../../../../../components';
import { useStyles } from '../../styles';
import { ReportDetailsModel } from '../../../../../models';

interface Props {
  translation: any;
  groupByOption?: string;
  columnNames: string[];
  selectedColumns: string[];
  rows: ReportDetailsModel[];
}

export const DetailedTable: FC<Props> = ({ translation, groupByOption, columnNames, selectedColumns, rows }) => {
  const classes = useStyles();

  const renderHeader = useMemo((): any[] => {
    const result: any[] = [];

    if (groupByOption) {
      result.push(<TableCell key={`zero${uuid()}`} />);

      columnNames.forEach((columnName) => {
        const isSelected = selectedColumns.includes(columnName);
        if (isSelected) {
          if (groupByOption === 'Login' && columnName === columnNames[0]) {
            result.push(
              <TableCell key={columnName} align="left">
                {columnName}
              </TableCell>,
            );
          }

          if (groupByOption === 'Group' && columnName === columnNames[2]) {
            result.push(
              <TableCell key={columnName} align="left">
                {columnName}
              </TableCell>,
            );
          }

          if (groupByOption === 'Symbol' && columnName === columnNames[3]) {
            result.push(
              <TableCell key={columnName} align="left">
                {columnName}
              </TableCell>,
            );
          }
        }
      });
    }

    columnNames.forEach((columnName) => {
      const isSelected = selectedColumns.includes(columnName);

      if (isSelected) {
        if (!groupByOption) {
          result.push(
            <TableCell key={columnName} align={columnName === columnNames[0] ? 'left' : 'center'}>
              {columnName}
            </TableCell>,
          );
        } else if ((groupByOption === 'Login' && columnName !== columnNames[0]) || (groupByOption === 'Group' && columnName !== columnNames[2]) || (groupByOption === 'Symbol' && columnName !== columnNames[3])) {
          result.push(
            <TableCell key={columnName} align="center">
              {columnName}
            </TableCell>,
          );
        }
      } else if (columnName === columnNames[0] && selectedColumns.indexOf(columnNames[1]) === -1 && selectedColumns.indexOf(columnNames[2]) === -1) {
        result.push(<TableCell key={`first_${uuid()}`} />);
      }
    });

    return result;
  }, [selectedColumns, columnNames, groupByOption]);

  const countTotals = useCallback((data: any[]): any => {
    let rebateLocalSum = 0;
    let volumeSum = 0;
    let volumeLotSum = 0;
    let paymentSum = 0;

    data.forEach((row: any) => {
      rebateLocalSum += row.RebateUSDLocal ? row.RebateUSDLocal : 0;
      volumeSum += row.VolumeUSD ? row.VolumeUSD : 0;
      volumeLotSum += row.VolumeLots ? row.VolumeLots : 0;
      paymentSum += row.PaymentUSD ? row.PaymentUSD : 0;
    });

    return {
      rebateLocalSum,
      volumeSum,
      volumeLotSum,
      paymentSum,
    };
  }, []);

  const renderTotalRow = useMemo((): any => {
    const totals = countTotals(rows);

    let colSpan = 4;

    if (
      (selectedColumns.indexOf(columnNames[0]) === -1 && selectedColumns.includes(columnNames[1]) && selectedColumns.includes(columnNames[2]) && selectedColumns.includes(columnNames[3])) ||
      (selectedColumns.indexOf(columnNames[1]) === -1 && selectedColumns.includes(columnNames[0]) && selectedColumns.includes(columnNames[2]) && selectedColumns.includes(columnNames[3])) ||
      (selectedColumns.indexOf(columnNames[2]) === -1 && selectedColumns.includes(columnNames[1]) && selectedColumns.includes(columnNames[0]) && selectedColumns.includes(columnNames[3])) ||
      (selectedColumns.indexOf(columnNames[3]) === -1 && selectedColumns.includes(columnNames[1]) && selectedColumns.includes(columnNames[0]) && selectedColumns.includes(columnNames[2]))
    ) {
      colSpan = 3;
    }

    if (
      (selectedColumns.indexOf(columnNames[0]) === -1 && selectedColumns.indexOf(columnNames[1]) === -1 && selectedColumns.includes(columnNames[2]) && selectedColumns.includes(columnNames[3])) ||
      (selectedColumns.indexOf(columnNames[0]) === -1 && selectedColumns.indexOf(columnNames[2]) === -1 && selectedColumns.includes(columnNames[1]) && selectedColumns.includes(columnNames[3])) ||
      (selectedColumns.indexOf(columnNames[2]) === -1 && selectedColumns.indexOf(columnNames[1]) === -1 && selectedColumns.includes(columnNames[0]) && selectedColumns.includes(columnNames[3])) ||
      (selectedColumns.indexOf(columnNames[3]) === -1 && selectedColumns.indexOf(columnNames[1]) === -1 && selectedColumns.includes(columnNames[0]) && selectedColumns.includes(columnNames[2]))
    ) {
      colSpan = 2;
    }

    if (
      (selectedColumns.indexOf(columnNames[0]) === -1 && selectedColumns.indexOf(columnNames[1]) === -1 && selectedColumns.indexOf(columnNames[2]) === -1 && selectedColumns.includes(columnNames[3])) ||
      (selectedColumns.indexOf(columnNames[0]) === -1 && selectedColumns.indexOf(columnNames[2]) === -1 && selectedColumns.indexOf(columnNames[3]) === -1 && selectedColumns.includes(columnNames[1])) ||
      (selectedColumns.indexOf(columnNames[1]) === -1 && selectedColumns.indexOf(columnNames[2]) === -1 && selectedColumns.indexOf(columnNames[3]) === -1 && selectedColumns.includes(columnNames[0])) ||
      (selectedColumns.indexOf(columnNames[2]) === -1 && selectedColumns.indexOf(columnNames[3]) === -1 && selectedColumns.indexOf(columnNames[0]) === -1 && selectedColumns.includes(columnNames[2]))
    ) {
      colSpan = 1;
    }

    if (selectedColumns.indexOf(columnNames[0]) === -1 && selectedColumns.indexOf(columnNames[1]) === -1 && selectedColumns.indexOf(columnNames[2]) === -1 && selectedColumns.indexOf(columnNames[3]) === -1) {
      colSpan = 0;
    }
    return (
      <TableRow className={classes.tableTotals} key={`totals${uuid()}`}>
        {groupByOption && <TableCell />}
        <TableCell align="right" colSpan={colSpan}>
          {translation.text_951}
        </TableCell>
        {selectedColumns.includes(columnNames[4]) && <TableCell align="center">{`$${totals.rebateLocalSum.toFixed(2)}`}</TableCell>}
        {selectedColumns.includes(columnNames[5]) && <TableCell align="center">{totals.volumeSum.toFixed(2)}</TableCell>}
        {selectedColumns.includes(columnNames[6]) && <TableCell align="center">{totals.volumeLotSum.toFixed(2)}</TableCell>}
        {selectedColumns.includes(columnNames[7]) && <TableCell align="center">{`$${totals.paymentSum.toFixed(2)}`}</TableCell>}
      </TableRow>
    );
  }, [rows, columnNames, selectedColumns, classes, countTotals, groupByOption]);

  const renderRows = useCallback(
    (rowsData: any[]): any[] => {
      const result: any[] = [];

      rowsData.forEach((row: any) => {
        result.push(
          <TableRow key={uuid()}>
            {groupByOption && <TableCell style={{ width: '5%' }} />}
            {selectedColumns.indexOf(columnNames[0]) === -1 && selectedColumns.indexOf(columnNames[1]) === -1 && selectedColumns.indexOf(columnNames[2]) === -1 && selectedColumns.indexOf(columnNames[3]) === -1 && <TableCell style={{ width: 126 }} />}

            {!groupByOption || (groupByOption && groupByOption === 'Login') ? (
              <>
                {selectedColumns.includes(columnNames[0]) && (
                  <TableCell align="left" style={{ width: 126 }}>
                    {row.Login ? row.Login : '-'}
                  </TableCell>
                )}
                {selectedColumns.includes(columnNames[1]) && (
                  <TableCell align="center" style={{ width: 250 }}>
                    {row.ClientName ? row.ClientName : '-'}
                  </TableCell>
                )}
                {selectedColumns.includes(columnNames[2]) && (
                  <TableCell align="center" style={{ width: 213 }}>
                    {row.Group ? row.Group : '-'}
                  </TableCell>
                )}
                {selectedColumns.includes(columnNames[3]) && (
                  <TableCell align="center" style={{ width: 115 }}>
                    {row.Symbol ? row.Symbol : '-'}
                  </TableCell>
                )}
              </>
            ) : (
              <>
                {groupByOption === 'Group' && (
                  <>
                    {selectedColumns.includes(columnNames[2]) && (
                      <TableCell align="left" style={{ width: 213 }}>
                        {row.Group ? row.Group : '-'}
                      </TableCell>
                    )}
                    {selectedColumns.includes(columnNames[0]) && (
                      <TableCell align="center" style={{ width: 126 }}>
                        {row.Login ? row.Login : '-'}
                      </TableCell>
                    )}
                    {selectedColumns.includes(columnNames[1]) && (
                      <TableCell align="center" style={{ width: 250 }}>
                        {row.ClientName ? row.ClientName : '-'}
                      </TableCell>
                    )}
                    {selectedColumns.includes(columnNames[3]) && (
                      <TableCell align="center" style={{ width: 115 }}>
                        {row.Symbol ? row.Symbol : '-'}
                      </TableCell>
                    )}
                  </>
                )}
                {groupByOption === 'Symbol' && (
                  <>
                    {selectedColumns.includes(columnNames[3]) && (
                      <TableCell align="left" style={{ width: 115 }}>
                        {row.Symbol ? row.Symbol : '-'}
                      </TableCell>
                    )}
                    {selectedColumns.includes(columnNames[0]) && (
                      <TableCell align="center" style={{ width: 126 }}>
                        {row.Login ? row.Login : '-'}
                      </TableCell>
                    )}
                    {selectedColumns.includes(columnNames[1]) && (
                      <TableCell align="center" style={{ width: 250 }}>
                        {row.ClientName ? row.ClientName : '-'}
                      </TableCell>
                    )}
                    {selectedColumns.includes(columnNames[2]) && (
                      <TableCell align="center" style={{ width: 213 }}>
                        {row.Group ? row.Group : '-'}
                      </TableCell>
                    )}
                  </>
                )}
              </>
            )}

            {selectedColumns.includes(columnNames[4]) && (
              <TableCell align="center" style={{ width: 170 }}>
                {row.RebateUSDLocal || row.RebateUSDLocal === 0 ? `$${row.RebateUSDLocal.toFixed(2)}` : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[5]) && (
              <TableCell align="center" style={{ width: 170 }}>
                {row.VolumeUSD || row.VolumeUSD === 0 ? row.VolumeUSD.toFixed(2) : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[6]) && (
              <TableCell align="center" style={{ width: 170 }}>
                {row.VolumeLots || row.VolumeLots === 0 ? row.VolumeLots.toFixed(2) : '-'}
              </TableCell>
            )}
            {selectedColumns.includes(columnNames[7]) && (
              <TableCell align="center" style={{ width: 170 }}>
                {row.PaymentUSD || row.PaymentUSD === 0 ? `$${row.PaymentUSD.toFixed(2)}` : '-'}
              </TableCell>
            )}
          </TableRow>,
        );
      });

      return result;
    },
    [columnNames, selectedColumns, groupByOption],
  );

  const renderCollapsibleRows = useCallback(
    (array: any[]): any[] => {
      const result: any[] = [];

      array.forEach((row: any) => {
        const key = uuid();
        const cells: any[] = [];
        let columnsCoeff = 1;

        if (selectedColumns.indexOf(columnNames[0]) === -1 && selectedColumns.indexOf(columnNames[1]) === -1 && selectedColumns.indexOf(columnNames[2]) === -1 && selectedColumns.indexOf(columnNames[3]) === -1) {
          columnsCoeff = 2;
          cells.push(<TableCell key={`login_${key}`} style={{ width: 126 }} />);
        }

        if (!groupByOption || (groupByOption && groupByOption === 'Login')) {
          if (selectedColumns.includes(columnNames[0])) {
            cells.push(
              <TableCell align="left" key={`login_${key}`} style={{ width: 126 }} className={row.Login !== '' ? classes.groupedByCell : ''}>
                {row.Login ? row.Login : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[1])) {
            cells.push(
              <TableCell align="center" key={`clientName_${key}`} style={{ width: 250 }}>
                {row.ClientName}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[2])) {
            cells.push(
              <TableCell align="center" key={`group_${key}`} className={row.Group !== '' ? classes.groupedByCell : ''} style={{ width: 213 }}>
                {row.Group ? row.Group : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[3])) {
            cells.push(
              <TableCell align="center" key={`symbol_${key}`} className={row.Symbol !== '' ? classes.groupedByCell : ''} style={{ width: 115 }}>
                {row.Symbol ? row.Symbol : '-'}
              </TableCell>,
            );
          }
        } else if (groupByOption === 'Group') {
          if (selectedColumns.includes(columnNames[2])) {
            cells.push(
              <TableCell align="left" key={`group_${key}`} className={row.Group !== '' ? classes.groupedByCell : ''} style={{ width: 213 }}>
                {row.Group ? row.Group : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[0])) {
            cells.push(
              <TableCell align="center" key={`login_${key}`} style={{ width: 126 }} className={row.Login !== '' ? classes.groupedByCell : ''}>
                {row.Login ? row.Login : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[1])) {
            cells.push(
              <TableCell align="center" key={`clientName_${key}`} style={{ width: 250 }}>
                {row.ClientName}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[3])) {
            cells.push(
              <TableCell align="center" key={`symbol_${key}`} className={row.Symbol !== '' ? classes.groupedByCell : ''} style={{ width: 115 }}>
                {row.Symbol ? row.Symbol : '-'}
              </TableCell>,
            );
          }
        } else {
          if (selectedColumns.includes(columnNames[3])) {
            cells.push(
              <TableCell align="left" key={`symbol_${key}`} className={row.Symbol !== '' ? classes.groupedByCell : ''} style={{ width: 115 }}>
                {row.Symbol ? row.Symbol : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[0])) {
            cells.push(
              <TableCell align="center" key={`login_${key}`} style={{ width: 126 }} className={row.Login !== '' ? classes.groupedByCell : ''}>
                {row.Login ? row.Login : '-'}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[1])) {
            cells.push(
              <TableCell align="center" key={`clientName_${key}`} style={{ width: 250 }}>
                {row.ClientName}
              </TableCell>,
            );
          }
          if (selectedColumns.includes(columnNames[2])) {
            cells.push(
              <TableCell align="center" key={`group_${key}`} className={row.Group !== '' ? classes.groupedByCell : ''} style={{ width: 213 }}>
                {row.Group ? row.Group : '-'}
              </TableCell>,
            );
          }
        }

        if (selectedColumns.includes(columnNames[4])) {
          cells.push(<TableCell align="center" key={`rebateLocal_${key}`} style={{ width: 170 }}>{`$${row.RebateLocal.toFixed(2)}`}</TableCell>);
        }
        if (selectedColumns.includes(columnNames[5])) {
          cells.push(<TableCell align="center" key={`volume_${key}`} style={{ width: 170 }}>{`${row.Volume.toFixed(2)}`}</TableCell>);
        }
        if (selectedColumns.includes(columnNames[6])) {
          cells.push(<TableCell align="center" key={`volumeLot_${key}`} style={{ width: 170 }}>{`${row.VolumeLot.toFixed(2)}`}</TableCell>);
        }
        if (selectedColumns.includes(columnNames[7])) {
          cells.push(<TableCell align="center" key={`payment_${key}`} style={{ width: 170 }}>{`$${row.Payment.toFixed(2)}`}</TableCell>);
        }

        const subRows = renderRows(row.subRows);

        result.push(<ReportsCollapsibleRow key={key} rowCells={cells} subRows={subRows} columnsNumber={selectedColumns.length + columnsCoeff} />);
      });

      return result;
    },
    [selectedColumns, columnNames, renderRows, classes, groupByOption],
  );

  const checkGroupByAndRenderRows = useCallback((): any[] => {
    let result: any[];

    if (groupByOption && groupByOption !== 'Mt4 login') {
      const grouped = mapValues(groupBy(rows, groupByOption), (clist) => clist.map((row) => omit(row, groupByOption)));

      const resultArray = Object.keys(grouped).map((index) => {
        const totals = countTotals(grouped[index]);
        return {
          Login: groupByOption === 'Login' ? index : '',
          ClientName: '-',
          Group: groupByOption === 'Group' ? index : '',
          Symbol: groupByOption === 'Symbol' ? index : '',
          RebateLocal: totals.rebateLocalSum,
          Volume: totals.volumeSum,
          VolumeLot: totals.volumeLotSum,
          Payment: totals.paymentSum,
          subRows: grouped[index],
        };
      });
      result = renderCollapsibleRows(resultArray);
    } else {
      result = renderRows(rows);
    }

    return result;
  }, [rows, groupByOption, renderRows, renderCollapsibleRows, countTotals]);

  return <CustomTable headerCells={renderHeader} rows={checkGroupByAndRenderRows()} totalRow={renderTotalRow} columnsNumber={selectedColumns.length} emptyDataMessage={translation.text_6448} />;
};
