import { makeStyles } from '@material-ui/core/styles';
import { variables } from '../../styles';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: 'inherit',
    minWidth: variables.dialogMinWidth,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },

    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
      height: '100%',
      maxHeight: 'none',
    },
  },

  dialogContentRoot: {
    padding: theme.spacing(4),
    height: 'auto',
  },
  dialogActionsRoot: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(4),
  },

  primaryActionButton: {
    padding: theme.spacing(1, 2),
    border: 'none',
    backgroundColor: `${theme.palette.primary.main}`,

    '&:hover': {
      backgroundColor: `${theme.palette.changeable.hover}`,
    },
  },
  primaryActionButtonLabel: {
    color: `${theme.palette.common.white}`,
    fontWeight: 'normal',
  },
  secondaryActionButtonLabel: {
    color: theme.palette.secondary.main,
  },
  btn: {
    borderRadius: 20,
  },
}));

export default useStyles;
