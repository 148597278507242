import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Tree from 'react-d3-tree';
import { CustomNodeElementProps } from 'react-d3-tree/lib/types/common';
import { useStyles } from './styles';
import { ClientListDialog } from '../ClientListDialog';
import {
  PartnerHierarchicalViewModel,
  Partnership,
} from '../../PartnersHierarchyModels';
import { DetailsDialog } from '../DetailsDialog';

interface Child {
  name: string;
  attributes: any;
  children?: Child[];
}

interface ChartData {
  name: string;
  attributes: any;
  children: Child[];
}

interface Props {
  data: PartnerHierarchicalViewModel;
  handleShowMobileTable: (data: any) => void;
  translation: any;
}

function isEmail(name: string): boolean {
  return (
    name.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi) !== null
  );
}

export const TreeHierarchy: FC<Props> = ({
  data,
  handleShowMobileTable,
  translation,
}) => {
  const classes = useStyles();
  const treeContainer = useRef<HTMLDivElement>(null);
  const [dialogTrigger, setDialogTrigger] =
    useState<number | undefined>(undefined);
  const [detailsDialogTrigger, setDetailsDialogTrigger] =
    useState<number | undefined>(undefined);
  const [dialogData, setDialogData] = useState<any>(null);
  const [dialogDetailsData, setDialogDetailsData] = useState<any>(null);
  const [translate, setTranslate] = useState({
    x: 0,
    y: 0,
  });
  const [chartData, setChartData] = useState<ChartData>({
    name: '',
    attributes: {},
    children: [],
  });
  const [zoom, setZoom] = useState<number>(1);

  useEffect(() => {
    if (window.innerWidth <= 1180) {
      setZoom(0.5);
    }

    if (window.innerWidth <= 800) {
      setZoom(0.4);
    }

    if (window.innerWidth <= 700) {
      setZoom(0.3);
    }

    if (window.innerWidth <= 500) {
      setZoom(0.2);
    }

    window.addEventListener('resize', () => {
      setZoom(1);

      if (window.innerWidth <= 1180) {
        setZoom(0.5);
      }

      if (window.innerWidth <= 900) {
        setZoom(0.4);
      }

      if (window.innerWidth <= 700) {
        setZoom(0.3);
      }

      if (window.innerWidth <= 500) {
        setZoom(0.2);
      }
    });
  }, []);

  const formatPartners = useCallback(
    (partnerships: Partnership[], hasParentPartnership?: boolean): Child[] => {
      const children: Child[] = [];
      partnerships.forEach((p) => {
        const subChildren: Child[] = [];
        const clientChild: Child = {
          name: translation.text_6387,
          attributes: {
            type: 'clients',
            partnershipCode: p.partnershipCode,
            partnershipId: p.id,
            clientsNumber: p.clientsCount,
            accounts: translation.text_6388,
            accountsNumber: p.accountsCount,
          },
        };
        subChildren.push(clientChild);

        if (p.subPartnerships) {
          const subPartners = formatPartners(p.subPartnerships, true);
          subChildren.push(...subPartners);
        }

        const child = {
          name: p.partnershipCode ? p.partnershipCode : '',
          attributes: {
            type: 'partner',
            hasParentPartner: hasParentPartnership,
            partnershipEnded: !!p.endDate,
            scheme: p.schemeName,
            startDate: p.startDate,
            partnerName: p.partnerName,
            partnerEmail: p.partnerEmail,
            partnershipCode: p.partnershipCode,
            preferredPaymentCurrency: p.preferredPaymentCurrency,
            tradingAccountTypes: p.tradingAccountTypes,
          },
          children: subChildren,
        };
        children.push(child);
      });

      return children;
    },
    [translation],
  );

  const formatAndSetChartData = useCallback(() => {
    const children: Child[] = data.partnerships
      ? formatPartners(data.partnerships)
      : [];

    setChartData({
      name: data.name,
      attributes: {
        type: 'master',
      },
      children,
    });
  }, [setChartData, data, formatPartners]);

  useEffect(() => {
    formatAndSetChartData();

    const currentElement = treeContainer.current;
    if (currentElement) {
      const size = currentElement.getBoundingClientRect();
      const width = size.width / 2;

      setTranslate({
        x: width,
        y: 20,
      });
    }
  }, [setTranslate, treeContainer, formatAndSetChartData]);

  const renderCustomElement = ({
    nodeDatum,
    toggleNode,
  }: CustomNodeElementProps): JSX.Element => {
    let type = '';
    let cssClass = '';
    let hasParentPartner = 'false';
    let partnershipEnded = 'false';
    let attributes;
    let tradingAccounts: any = [];

    if (nodeDatum && nodeDatum.attributes) {
      attributes = nodeDatum.attributes;
      type = attributes.type;
      hasParentPartner = attributes.hasParentPartner;
      partnershipEnded = attributes.partnershipEnded;
      tradingAccounts = attributes.tradingAccountTypes
        ? (attributes.tradingAccountTypes as unknown as Array<any>)
        : [];

      if (type === 'partner' && partnershipEnded) {
        cssClass = classes.branchNodeTerminated;
      }

      if (type === 'master' && isEmail(nodeDatum.name)) {
        cssClass = classes.branchNodeMaster;
      }
    }

    const showClients = () => {
      if (nodeDatum.attributes) {
        setDialogData({
          partnershipId: nodeDatum.attributes.partnershipId,
          partnershipCode: nodeDatum.attributes.partnershipCode,
          clientsNumber: nodeDatum.attributes.clientsNumber,
          accountsNumber: nodeDatum.attributes.accountsNumber,
        });
        setDialogTrigger(Math.random());
      }
    };

    const showClientsMobile = () => {
      if (nodeDatum.attributes) {
        const details = {
          partnershipId: nodeDatum.attributes.partnershipId,
          partnershipCode: nodeDatum.attributes.partnershipCode,
          clientsNumber: nodeDatum.attributes.clientsNumber,
          accountsNumber: nodeDatum.attributes.accountsNumber,
        };
        handleShowMobileTable(details);
      }
    };

    const showPartnerData = () => {
      if (nodeDatum.attributes) {
        setDialogDetailsData({
          partnershipCode: nodeDatum.attributes.partnershipCode,
          email: nodeDatum.attributes.partnerEmail,
          name: nodeDatum.attributes.partnerName,
          preferredCurrency: nodeDatum.attributes.preferredPaymentCurrency,
          tradingAccounts,
        });
        setDetailsDialogTrigger(Math.random());
      }
    };

    const partnershipContent = (
      <div
        className={classes.partnershipContent}
        onClick={() => showPartnerData()}
        role="button"
        tabIndex={0}
      >
        <div className={classes.partnershipCode}>
          {nodeDatum.name.toUpperCase()}
        </div>
        <div className={classes.partnerName}>{attributes?.partnerName}</div>
        {attributes?.scheme ? (
          <div>
            <b>{translation.text_6390}: </b>
            {attributes.scheme}
          </div>
        ) : (
          ''
        )}
      </div>
    );

    const clientsAccountsContent = (
      <div>
        <div className={classes.clientsContent}>
          <div className={classes.padding} style={{ paddingRight: 6 }}>
            <div>{nodeDatum.name}</div>
            <div>
              <b>{attributes?.clientsNumber}</b>
            </div>
          </div>
          <div className={classes.border} />
          <div className={classes.padding}>
            <div>{attributes?.accounts}</div>
            <div>
              <b>{attributes?.accountsNumber}</b>
            </div>
          </div>
        </div>
        <button
          className={classes.viewAll}
          type="button"
          disabled={
            attributes &&
            Number(attributes.clientsNumber) === 0 &&
            Number(attributes.accountsNumber) === 0
          }
          onClick={() => showClients()}
        >
          {translation.text_6391}
        </button>
        <button
          className={classes.viewAllMobile}
          type="button"
          disabled={
            attributes &&
            Number(attributes.clientsNumber) === 0 &&
            Number(attributes.accountsNumber) === 0
          }
          onClick={() => showClientsMobile()}
        >
          {translation.text_6391}
        </button>
      </div>
    );

    const Content = (
      <div
        className={`${classes.branchNode} ${cssClass}`}
        onClick={() => {}}
        role="button"
        tabIndex={0}
        onKeyPress={() => {}}
      >
        {type !== 'clients' ? (
          <div
            className={`${classes.rectangle}
        ${
          type === 'master'
            ? ''
            : partnershipEnded
            ? classes.rectangleTerminated
            : hasParentPartner
            ? classes.rectangleSubPartner
            : classes.rectanglePartner
        }`}
          >
            {type === 'master' ? (
              <b>{translation.text_6392}</b>
            ) : hasParentPartner ? (
              `${translation.text_6409}`
            ) : (
              `${translation.text_6393}`
            )}
          </div>
        ) : (
          <div className={`${classes.rectangle} ${classes.rectangleClient}`} />
        )}
        <div className="node-master">
          {type === 'master' ? (
            <div className={`${classes.textDiv} ${classes.master}`}>
              {nodeDatum.name}
            </div>
          ) : type === 'clients' ? (
            clientsAccountsContent
          ) : (
            partnershipContent
          )}
        </div>
      </div>
    );

    return (
      <svg
        x={type === 'master' && isEmail(nodeDatum.name) ? '-150px' : '-100px'}
        y="-20"
      >
        <foreignObject
          className={classes.foreignObject}
          width="200"
          height={type === 'master' && isEmail(nodeDatum.name) ? '80' : '150'}
          onClick={toggleNode}
        >
          <div>{Content}</div>
        </foreignObject>
      </svg>
    );
  };

  const Legend = (
    <>
      <div className={classes.chartLegend}>
        <ul className={classes.legendList}>
          <li className={classes.legendItem}>
            <span
              className={`${classes.legendListIcon} ${classes.legendMaster}`}
            />
            {translation.partner}
          </li>
          <li className={classes.legendItem}>
            <span
              className={`${classes.legendListIcon} ${classes.legendPartner}`}
            />
            {translation.text_6009}
          </li>
          <li className={classes.legendItem}>
            <span
              className={`${classes.legendListIcon} ${classes.legendSubPartner}`}
            />
            {translation.text_6176}
          </li>
          <li className={classes.legendItem}>
            <span
              className={`${classes.legendListIcon} ${classes.legendTerminated}`}
            />
            <span style={{ display: 'inline-flex' }}>
              {translation.text_6177}
            </span>
          </li>
          <li className={classes.legendItem}>
            <span
              className={`${classes.legendListIcon} ${classes.legendClient}`}
            />
            {translation.text_5765}
          </li>
        </ul>
      </div>
      <div className={classes.mobileChartLegend}>
        <div className={classes.mobileLegendRow}>
          <div className={classes.mobileItem}>
            <span
              className={`${classes.legendListIcon} ${classes.legendMaster}`}
            />
            {translation.partner}
          </div>
          <div className={classes.mobileItem}>
            <span
              className={`${classes.legendListIcon} ${classes.legendPartner}`}
            />
            {translation.text_6009}
          </div>
          <div className={classes.mobileItem}>
            <span
              className={`${classes.legendListIcon} ${classes.legendSubPartner}`}
            />
            {translation.text_6176}
          </div>
        </div>
        <div className={classes.mobileLegendRow}>
          <div className={classes.mobileItem}>
            <span
              className={`${classes.legendListIcon} ${classes.legendTerminated}`}
            />
            <span style={{ display: 'inline-flex' }}>
              {translation.text_6177}
            </span>
          </div>
          <div className={classes.mobileItem}>
            <span
              className={`${classes.legendListIcon} ${classes.legendClient}`}
            />
            {translation.text_5765}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className={classes.chartRoot} ref={treeContainer}>
        {Legend}
        <Tree
          data={chartData}
          translate={translate}
          collapsible={false}
          transitionDuration={0}
          separation={{ siblings: 0.645, nonSiblings: 0.645 }}
          orientation="vertical"
          nodeSize={{ x: 325, y: 200 }}
          renderCustomNodeElement={renderCustomElement}
          pathFunc="step"
          zoom={zoom}
          pathClassFunc={() => classes.line}
        />
      </div>
      <ClientListDialog
        key={Math.random()}
        translation={translation}
        data={dialogData}
        trigger={dialogTrigger}
        handleCancel={() => setDialogTrigger(undefined)}
      />
      <DetailsDialog
        key={Math.random()}
        translation={translation}
        trigger={detailsDialogTrigger}
        data={dialogDetailsData}
        handleCancel={() => setDetailsDialogTrigger(undefined)}
      />
    </>
  );
};
