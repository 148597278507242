import React, {
  FC, useCallback, useEffect, useState
} from 'react';
import { cloneDeep } from 'lodash';
import {
  Checkbox, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText
} from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import { useStyles } from './styles';
import { PeriodSelection } from '../PeriodSelection';
import { LineChart } from '../../Charts';

interface Props {
  data: any
  checkboxes?: string[]
  colors: any
  translation: any
}

export const GraphWithPeriod: FC<Props> = ({
  data, checkboxes, colors, translation
}) => {
  const classes = useStyles();
  const [chartData, setChartData] = useState<any>([]);
  const [yearlyChartData, setYearlyChartData] = useState<any>([]);
  const [monthlyChartData, setMonthlyChartData] = useState<any>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('Year');

  const modifyData = useCallback(() => {
    if (data.adminYearData) {
      data.adminYearData.forEach((year: any) => year.amount = Math.abs(year.amount));
    } else {
      data.yearData.forEach((year: any) => year.amount = Math.abs(year.amount));
    }

    if (data.adminMonthData) {
      data.adminMonthData.forEach((month: any) => month.amount = Math.abs(month.amount));
    } else {
      data.monthData.map((month: any) => month.amount = Math.abs(month.amount));
    }

    const yearlyChart: any = {
      id: data.highlightType,
      data: data.adminYearData ? data.adminYearData : data.yearData
    };

    const monthlyChart: any = {
      id: data.highlightType,
      data: data.adminMonthData ? data.adminMonthData : data.monthData
    };

    setYearlyChartData(yearlyChart);
    setMonthlyChartData(monthlyChart);
    setChartData(cloneDeep(yearlyChart));
  }, [data]);

  useEffect(() => {
    modifyData();
  }, [modifyData]);

  const handlePeriodSelect = useCallback((period: string) => {
    if (period === 'Year') {
      setChartData(cloneDeep(yearlyChartData));
    } else {
      setChartData(cloneDeep(monthlyChartData));
    }
    setSelectedPeriod(period);
  }, [yearlyChartData, monthlyChartData]);

  return (
    <>
      {(chartData && chartData.data && chartData.data.length > 0)
        && (
        <>
          <PeriodSelection selectedPeriod={selectedPeriod} onChange={handlePeriodSelect} translation={translation} />
          <Grid container direction="row" className={classes.content} justify="space-between">
            <Grid item xs={12} className={classes.chartSection}>
              <LineChart key={Math.random()} period={selectedPeriod} data={[chartData]} colors={colors} translation={translation} />
            </Grid>
            {checkboxes && checkboxes.length > 0 && (
            <Grid item xs={12}>
              <List component="div" dense className={classes.flexContainer}>
                {checkboxes.map((item, index) => (
                  <ListItem key={item} button className={classes.checkboxListItem} disabled>
                    <ListItemIcon
                      className={`${classes.checkboxListIcon} 
                      ${index === 0 ? classes.checkBoxListIconDeposits
                        : (index === 1 ? classes.checkBoxListIconWithdrawals
                          : (index === 2 ? classes.checkBoxListIconNetMi : classes.checkBoxListIconNetItrs))}`}
                    >
                      <FiberManualRecord />
                    </ListItemIcon>
                    <ListItemText id={item} primary={item} classes={{ primary: classes.checkboxText }} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        disabled
                        size="small"
                        edge="end"
                        classes={{
                          root: classes.checkbox,
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
            )}
          </Grid>
        </>
        )}
    </>
  );
};
