import React, { FC, useCallback } from 'react';
import moment from 'moment';
import { ReportData } from '../../TradingSummarytModel';
import { MobileTableView } from '../../../../../components';
import { ValueLabel } from '../../../../../models';

interface Props {
  rows: ReportData[];
  reportColumns: string[];
  selectedColumns: string[];
}

export const MobileTradingReports: FC<Props> = ({
  rows,
  reportColumns,
  selectedColumns,
}) => {
  const renderMobileRows = useCallback((): any => {
    const result: any[] = [];

    if (rows.length === 0) {
      const rowData: ValueLabel[] = [];
      reportColumns.forEach((columnName) => {
        const isSelected = selectedColumns.includes(columnName);
        if (isSelected) {
          rowData.push({
            value: '',
            label: columnName,
          });
        }
      });
      result.push(rowData);
    }

    rows.forEach((row) => {
      const rowData: ValueLabel[] = [];
      if (selectedColumns.includes(reportColumns[0])) {
        rowData.push({
          value: row.date ? moment(row.date).format('DD/MM/YYYY') : '-',
          label: reportColumns[0],
        });
      }

      if (selectedColumns.includes(reportColumns[1])) {
        rowData.push({
          value: row.trades ? row.trades.toFixed(2) : '-',
          label: reportColumns[1],
        });
      }

      if (selectedColumns.includes(reportColumns[2])) {
        rowData.push({
          value: row.tradingAccountsTraded
            ? row.tradingAccountsTraded.toFixed(2)
            : '-',
          label: reportColumns[2],
        });
      }

      if (selectedColumns.includes(reportColumns[3])) {
        rowData.push({
          value: row.depositsUSD ? Number(row.depositsUSD).toFixed(2) : '-',
          label: reportColumns[3],
        });
      }

      if (selectedColumns.includes(reportColumns[4])) {
        rowData.push({
          value: row.withdrawalsUSD
            ? Math.abs(Number(row.withdrawalsUSD)).toFixed(2)
            : '-',
          label: reportColumns[4],
        });
      }

      if (selectedColumns.includes(reportColumns[5])) {
        rowData.push({
          value: row.valueUSD ? Number(row.valueUSD).toFixed(2) : '-',
          label: reportColumns[5],
        });
      }

      if (selectedColumns.includes(reportColumns[6])) {
        rowData.push({
          value: row.lots ? row.lots.toFixed(2) : '-',
          label: reportColumns[6],
        });
      }

      if (selectedColumns.includes(reportColumns[7])) {
        rowData.push({
          value: row.volumeLots ? row.volumeLots.toFixed(2) : '-',
          label: reportColumns[7],
        });
      }

      if (selectedColumns.includes(reportColumns[8])) {
        rowData.push({
          value: row.emails ? row.emails : '-',
          label: reportColumns[8],
        });
      }

      if (selectedColumns.includes(reportColumns[9])) {
        rowData.push({
          value: row.tradingAccountsFunded ? row.tradingAccountsFunded : '-',
          label: reportColumns[9],
        });
      }

      if (selectedColumns.includes(reportColumns[10])) {
        rowData.push({
          value: row.netDepositsUSD
            ? Number(row.netDepositsUSD).toFixed(2)
            : '-',
          label: reportColumns[10],
        });
      }

      result.push(rowData);
    });

    return result;
  }, [selectedColumns, rows, reportColumns]);

  return (
    <MobileTableView
      list={renderMobileRows()}
      grouped={false}
      hasNextButton={false}
    />
  );
};
