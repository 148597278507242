import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  margin: {
    marginTop: 5
  },
  label: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    marginRight: 24
  },
  reportStatus: {
    color: theme.palette.common.white,
    padding: '6px 0 6px 0',
    borderRadius: 4
  },
  completedStatus: {
    backgroundColor: theme.palette.frequent.green,
  },
  failedStatus: {
    backgroundColor: theme.palette.frequent.red,
  },
  pending: {
    backgroundColor: theme.palette.frequent.pastelPink
  },
  btn: {
    width: 104,
    height: 39,
    padding: '12px 28px 11px 27px',
    borderColor: theme.palette.frequent.fossilGrey
  }
}));
