import React, { FC, useEffect, useState } from 'react';
import { Backdrop, Grid } from '@material-ui/core';
import { useStyles } from './style';
import { SpinnerIcon } from '../CustomIcons';
import { useAppData, useLoading } from '../../providers';

export const SpinnerWithMessage: FC = () => {
  const classes = useStyles();
  const { isLoading } = useLoading();
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    if (translations) {
      setTranslation(JSON.parse(translations));
    }
  }, [translations]);

  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <Grid container direction="column" alignItems="center">
        <Grid item>{translation?.loading_please_wait_a_moment}</Grid>
        <SpinnerIcon className={classes.rotateIcon} />
      </Grid>
    </Backdrop>
  );
};
