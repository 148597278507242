import { useCallback } from 'react';
import { useApiError, useAppData } from 'src/providers';
import { getAllClientsAndTradingReportDetail } from '../AllClientsAndTradingService';
import { ReportDetailsModel } from '../../../../models';

const useGetSummaryByClient = ({
  toDate,
  fromDate,
  setSummaryData,
  setIsLoading,
}: {
  toDate: Date;
  fromDate: Date;
  setIsLoading: (data: boolean) => void;
  setSummaryData: (data: ReportDetailsModel[]) => void;
}) => {
  const { addError } = useApiError();
  const { userData } = useAppData();

  return useCallback(
    async (partnershipCode: string) => {
      const loginData = JSON.parse(userData);
      setIsLoading(true);

      try {
        const response = await getAllClientsAndTradingReportDetail(
          partnershipCode,
          loginData.partnerID,
          fromDate.getMonth() + 1,
          fromDate.getFullYear(),
          fromDate.getDate(),
          toDate.getMonth() + 1,
          toDate.getFullYear(),
          toDate.getDate(),
          'USD',
          loginData.defaultAccount,
        );

        setSummaryData(response.data.reportItems);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    },
    [userData, fromDate, toDate, setIsLoading, setSummaryData, addError],
  );
};

export default useGetSummaryByClient;
