import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useAppData } from '../../providers';
import { useCommonStyles } from '../../styles';
import { useStyles } from './styles';
import { PDFFileIcon, ExcelFileIcon } from '../CustomIcons/ExportButtons';

interface Props {
  exportFileName: string;
  columns: string[];
  exportTableData: string[][];
  fontSize?: number;
  className?: string;
  isEnabled: boolean;
}

export const ExportButtons: FC<Props> = ({
  exportFileName,
  exportTableData,
  columns,
  fontSize,
  className,
  isEnabled,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const anchorRef = useRef<HTMLDivElement>(null);
  const { translations, locale } = useAppData();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const capitalizedColumns = () =>
    columns.map((column) => column?.toUpperCase());

  const handleExportCSVClick = () => {
    const builder = new CsvBuilder(`${exportFileName}.csv`);
    builder
      .setDelimeter(',')
      .setColumns(capitalizedColumns())
      .addRows(exportTableData)
      .exportFile();
  };

  const createPDF = (): Promise<any> =>
    new Promise((resolve) => {
      if (jsPDF !== null) {
        const content: {
          startY: number;
          head: string[][];
          body: string[][];
          styles: {
            fontSize?: number;
            font?: string;
          };
        } = {
          startY: 50,
          head: [capitalizedColumns()],
          body: exportTableData,
          styles: { fontSize: fontSize || 10 },
        };

        const unit = 'pt';
        const size = 'A4';
        const orientation = 'landscape';

        // eslint-disable-next-line new-cap
        const doc = new jsPDF(orientation, unit, size);

        if (locale === 'ja') {
          doc.addFont(
            '/NotoSansJP-Regular.ttf',
            'NotoSansJP-Regular',
            'regular',
          );
          doc.setFont('NotoSansJP-Regular', 'regular');
          content.styles.font = 'NotoSansJP-Regular';
        }

        autoTable(doc, content);

        setOpen(false);
        doc.save(`${exportFileName}.pdf`);
        setTimeout(() => {
          resolve('resolved');
        }, 3000);
      }
    });

  const handleExportPDFClick = () => {
    setLoading(true);
    createPDF().then(() => {
      setOpen(false);
      setLoading(false);
    });
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        style={{ boxShadow: 'none' }}
      >
        <Button
          color="primary"
          size="small"
          className={`${commonClasses.buttonContained} ${classes.button} ${className}`}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleToggle}
          disabled={!isEnabled}
        >
          <span style={{ marginRight: 5 }}>{translation.text_6420}</span>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={{ zIndex: 6000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.popupPaper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem onClick={handleExportPDFClick} disabled={loading}>
                    <PDFFileIcon height={15} />
                    <span className={classes.menuItem}>
                      {translation.text_1062}
                    </span>
                  </MenuItem>
                  <MenuItem onClick={handleExportCSVClick}>
                    <ExcelFileIcon height={15} />
                    <span className={classes.menuItem}>
                      {translation.text_1061}
                    </span>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
