import React, { FC, useCallback, useMemo } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { CustomTable } from '../../../../../components';
import { DetailsModel } from '../../MasteIBCommissionModel';

interface Props {
  rows: DetailsModel[];
  reportColumns: string[];
  translation: any;
}

export const DetailedTable: FC<Props> = ({
  rows,
  reportColumns,
  translation,
}) => {
  const renderHeader = useMemo((): any[] => {
    const result: any[] = [];
    reportColumns.forEach((columnName) => {
      result.push(
        <TableCell
          key={columnName}
          align={columnName === reportColumns[0] ? 'left' : 'center'}
        >
          {columnName}
        </TableCell>,
      );
    });
    return result;
  }, [reportColumns]);

  const renderRows = useCallback((): any[] => {
    const result: any[] = [];
    rows.forEach((row: any) => {
      result.push(
        <TableRow key={uuid()}>
          <TableCell align="left">
            {row.subPartnershipCode ? row.subPartnershipCode : '-'}
          </TableCell>
          <TableCell align="center">
            {row.depositUSD || row.depositUSD === 0
              ? `$${row.depositUSD.toFixed(2)}`
              : '-'}
          </TableCell>
          <TableCell align="center">
            {row.withdrawalUSD || row.withdrawalUSD === 0
              ? `$${Math.abs(row.withdrawalUSD).toFixed(2)}`
              : '-'}
          </TableCell>
          <TableCell align="center">
            {row.lastBalanceUSD || row.lastBalanceUSD === 0
              ? `$${row.lastBalanceUSD.toFixed(2)}`
              : '-'}
          </TableCell>
        </TableRow>,
      );
    });

    return result;
  }, [rows]);

  return (
    <CustomTable
      headerCells={renderHeader}
      rows={renderRows()}
      columnsNumber={reportColumns.length}
      emptyDataMessage={translation.text_833}
    />
  );
};
