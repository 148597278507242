import React, { FC } from 'react';
import {
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';
import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import { useStyles } from '../../../styles';
import { formatNumber } from '../../../../../../utils';
import { useAppData } from '../../../../../../providers';

interface Props {
  selectedYear: any;
  tableData: any;
  currency?: string;
  translation: any;
  handleSelectPeriod: (year: string, data: any) => void;
}

export const YearMonthList: FC<Props> = ({
  tableData,
  currency,
  selectedYear,
  handleSelectPeriod,
  translation,
}) => {
  const classes = useStyles();
  const { locale } = useAppData();

  const getMonthName = (month: string, language: string): string =>
    new Date(new Date().setMonth(Number(month) - 1)).toLocaleString(language, {
      month: 'long',
    });

  return (
    <Grid container direction="column">
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.tableHeader}
      >
        <Grid item>{translation.year}</Grid>
        <Grid item>{translation.text_6339}</Grid>
      </Grid>
      <Grid item>
        <List component="div">
          {tableData.map((item: any) => (
            <div key={item.period}>
              <ListItem
                button
                className={classes.listItem}
                onClick={() => handleSelectPeriod(item.period, item.monthData)}
              >
                <ListItemIcon style={{ minWidth: 'auto' }}>
                  {selectedYear === item.period ? (
                    <ArrowDropDown classes={{ root: classes.listIcon }} />
                  ) : (
                    <ArrowRight classes={{ root: classes.listIcon }} />
                  )}
                </ListItemIcon>
                <Grid container direction="row" justify="space-between">
                  <Grid item>{item.period}</Grid>
                  <Grid item>
                    {formatNumber(item.profitAmount, currency || 'USD')} /{' '}
                    {formatNumber(Math.abs(item.lossAmount), currency || 'USD')}
                  </Grid>
                </Grid>
              </ListItem>
              <Collapse
                in={item.period === selectedYear}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {item.monthData &&
                    item.monthData.map((monthItem: any) => (
                      <ListItem
                        component="div"
                        className={classes.listSubItem}
                        key={`${monthItem.month}_${item.period}`}
                      >
                        <Grid container direction="row" justify="space-between">
                          <Grid item>
                            {getMonthName(monthItem.month, locale ?? 'en')}
                          </Grid>
                          <Grid item>
                            {formatNumber(
                              monthItem.profitAmount,
                              currency || 'USD',
                            )}{' '}
                            /{' '}
                            {formatNumber(
                              Math.abs(monthItem.lossAmount),
                              currency || 'USD',
                            )}
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};
