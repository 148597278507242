import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  chartRoot: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  toolTip: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    border: '1px solid',
    padding: 6,
    fontSize: 12,
    color: theme.palette.lineGraph.tooltipText,
    position: 'relative',
    zIndex: 1,
    boxShadow: `0 0 20px 0 ${theme.palette.frequent.boxShadow}`,

    '&::after': {
      content: '""',
      position: 'absolute',
      width: 25,
      height: 25,
      marginLeft: '-16px',
      marginTop: '-7px',
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.lineGraph.first}`,
      transformOrigin: '50% 50%',
      transform: 'rotate(315deg)',
      borderColor: `transparent transparent ${theme.palette.lineGraph.first} ${theme.palette.lineGraph.first}`,
    }
  },

  toolTipColorFirst: {
    borderColor: theme.palette.lineGraph.tooltipBlue,

    '&::after': {
      borderColor: `transparent transparent ${theme.palette.lineGraph.tooltipBlue} ${theme.palette.lineGraph.tooltipBlue}`,
    }
  },

  toolTipColorSecond: {
    borderColor: theme.palette.lineGraph.tooltipOrange,

    '&::after': {
      borderColor: `transparent transparent ${theme.palette.lineGraph.tooltipOrange} ${theme.palette.lineGraph.tooltipOrange}`,
    }
  },

  toolTipColorThird: {
    borderColor: theme.palette.lineGraph.tooltipGreen,

    '&::after': {
      borderColor: `transparent transparent ${theme.palette.lineGraph.tooltipGreen} ${theme.palette.lineGraph.tooltipGreen}`,
    }
  },

  toolTipColorFourth: {
    borderColor: theme.palette.lineGraph.tooltipRed,

    '&::after': {
      borderColor: `transparent transparent ${theme.palette.lineGraph.tooltipRed} ${theme.palette.lineGraph.tooltipRed}`,
    }
  },

  tooltipIcon: {
    height: 9,
    width: 10
  },

  tooltipNoChangeIcon: {
    width: 7
  }
}));
