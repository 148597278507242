import React, { FC } from 'react';
import './App.css';
import { createStore, StateMachineProvider } from 'little-state-machine';
import { Theme } from './Theme';
import {
  ApiErrorProvider,
  AppStateProvider,
  LoadingProvider,
} from './providers';

createStore({
  name: 'synergy-web',
});

export const App: FC = () => (
  <StateMachineProvider>
    <AppStateProvider>
      <LoadingProvider>
        <ApiErrorProvider>
          <Theme />
        </ApiErrorProvider>
      </LoadingProvider>
    </AppStateProvider>
  </StateMachineProvider>
);
