/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useApiError, useAppData, useLoading } from '../../providers';
import { getIBPartnerHierarchyModel } from './PartnersHierarchyService';
import { useStyles } from './styles';
import { TreeHierarchy } from './components/TreeHierarchy';
import { PartnerHierarchicalViewModel } from './PartnersHierarchyModels';
import { MobileClientList } from './components/MobileClientList';

export const PartnersHierarchy: FC = () => {
  const classes = useStyles();
  const { userData } = useAppData();
  const { setIsLoading } = useLoading();
  const [hierarchy, setHierarchy] = useState<PartnerHierarchicalViewModel>();
  const { addError } = useApiError();
  const [showTable, setShowTable] = useState<boolean>(false);
  const [details, setDetails] = useState<any>();
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);

  const getPartnerHierarchy = useCallback(
    (accountGuid: string) => {
      setIsLoading(true);
      getIBPartnerHierarchyModel(accountGuid)
        .then((response: any) => {
          setHierarchy(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          addError(JSON.stringify(error.response));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [setHierarchy],
  );

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    const userLoginData = JSON.parse(userData);
    const account = userLoginData.accounts[0];
    getPartnerHierarchy(account.accountGuid);
  }, [getPartnerHierarchy]);

  return (
    <>
      {showTable ? (
        <MobileClientList
          data={details}
          handleBack={() => setShowTable(false)}
          translation={translation}
        />
      ) : (
        <Grid container direction="column" className={classes.root}>
          {/* <Spinner isLoading={loading} /> */}
          <Grid container direction="row" spacing={1} alignContent="flex-start">
            <Grid item>
              <Typography className={classes.title}>
                {translation.text_6386}
              </Typography>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <Grid container className={classes.root}>
              {hierarchy && (
                <TreeHierarchy
                  translation={translation}
                  data={hierarchy}
                  handleShowMobileTable={(data: any) => {
                    setDetails(data);
                    setShowTable(true);
                  }}
                />
              )}
            </Grid>
          </Paper>
        </Grid>
      )}
    </>
  );
};
