import React, { FC } from 'react';
import { useStyles } from '../styles';
import { ReactComponent as Highlights } from '../../../assets/MainMenu/Highlights.svg';

interface Props {
  height?: number;
}

export const HighlightsIcon: FC<Props> = ({ height }) => {
  const classes = useStyles();
  return (
    <Highlights className={classes.icon} height={height || 13} width={height || 13} />
  );
};
