import React, { memo, FC, useCallback } from 'react';

import { InputBase } from 'formik-material-ui';
import { Field, FieldAttributes, FormikErrors } from 'formik';
import useStyles from './styles';
import { FieldLabel } from '../FieldLabel';

interface InputFieldProps extends FieldAttributes<any> {
  name: string;
  label: string | JSX.Element;
  isPositiveOnly?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  validate?: () => boolean;
  description?: JSX.Element | string;
  type?: string;
  errors?: string | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined;
  setFieldValue?: any;
  inputClass?: any;
  icon?: JSX.Element | string;
}

export const InputField : FC<InputFieldProps> = memo(({
  name, label, isRequired, disabled, description, errors, validate, type = 'text', setFieldValue, isPositiveOnly, inputClass, icon, ...other
}) => {
  const classes = useStyles();

  const onChange = useCallback((e) => {
    const { value } = e.target;
    e.preventDefault();
    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
    if (!value || regex.test(value.toString())) {
      setFieldValue(name, value);
    }
  }, [setFieldValue, name]);

  return (
    <div>
      { label && <FieldLabel label={label} isRequired={isRequired} description={description} icon={icon} /> }
      <Field
        autoComplete="off"
        id={name}
        name={name}
        component={InputBase}
        type={type}
        error={Boolean(errors)}
        disabled={disabled}
        classes={{
          root: `${classes.muiInput} ${inputClass}`,
          focused: classes.inputFocused,
          error: classes.inputError,
          input: classes.input
        }}
        validate={validate}
        {...Object.assign((type === 'number' && isPositiveOnly) ? { onChange } : {})}
        {...other}
      />
      {errors && (
        <span className={classes.errorDescription}>{errors}</span>
      )}
    </div>
  );
});
