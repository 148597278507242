/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Button, Grid, TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CustomTable, MobileTableView } from '../../../../components';
import { useCommonStyles } from '../../../../styles';
import { useApiError, useLoading } from '../../../../providers';
import { getDashboardInsights } from '../../IBDashboardService';
import { HighlightTypes } from '../../IBDashboardModels';
import { useStyles } from '../styles';
import { formatNumber, getTranslation, PATH_TRADING_SUMMARY } from '../../../../utils';
import { ValueLabel } from '../../../../models';
import { DepositTitlePanel, WithdrawalPanelTitle } from './components';

interface Props {
  highlightType: any;
  title: string;
  partnership: string;
  value: string;
  currency: string;
  netValue?: string;
  translation: any;
}

export const DepositsWithdrawalsPanel: FC<Props> = ({ value, partnership, highlightType, title, currency, netValue, translation }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [tableData, setTableData] = useState<any>([]);
  const [columnsNumber, setColumnsNumber] = useState<number>(5);
  const [increased, setIncreased] = useState<number | undefined>();
  const [decreased, setDecreased] = useState<number | undefined>();
  const [increasedNet, setIncreasedNet] = useState<number | undefined>();
  const [decreasedNet, setDecreasedNet] = useState<number | undefined>();
  const { addError } = useApiError();
  const { setIsLoading } = useLoading();

  const getData = useCallback(
    async (partnershipCode: string) => {
      setIsLoading(true);
      try {
        const date = new Date();
        const fromDate = new Date(new Date().setDate(date.getDate() - 30));
        const response = await getDashboardInsights(fromDate, date, partnershipCode === 'All' ? '' : partnershipCode, highlightType);
        setTableData(response.data.partnerInsightViewModel.clientDeposits ? response.data.partnerInsightViewModel.clientDeposits : response.data.partnerInsightViewModel.clientWithdrawals);

        let itemIncreased = 0;
        let itemDecreased = 0;

        if (highlightType === HighlightTypes.Withdrawals) {
          const { withdrawalIncrease } = response.data.partnerInsightViewModel;
          if (withdrawalIncrease < 0) {
            itemDecreased = Math.abs(withdrawalIncrease);
          } else {
            itemIncreased = withdrawalIncrease;
          }
        }

        if (highlightType === HighlightTypes.Deposits) {
          const { depositIncrease } = response.data.partnerInsightViewModel;
          if (depositIncrease < 0) {
            itemDecreased = Math.abs(depositIncrease);
          } else {
            itemIncreased = depositIncrease;
          }

          const netResponse = await getDashboardInsights(fromDate, date, partnershipCode === 'All' ? '' : partnershipCode, HighlightTypes.NetDeposits);
          const netDepositIncrease = netResponse.data.partnerInsightViewModel.depositIncrease;
          if (depositIncrease < 0) {
            setDecreasedNet(Math.abs(netDepositIncrease));
          } else {
            setIncreasedNet(netDepositIncrease);
          }
        }
        setIncreased(itemIncreased);
        setDecreased(itemDecreased);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    },
    [addError, highlightType],
  );

  useEffect(() => {
    (async () => {
      await getData(partnership);
    })();
    if (partnership !== 'All') {
      setColumnsNumber(4);
    }
  }, [partnership, currency]);

  const renderHeader = useMemo(
    () => (
      <>
        <TableCell align="left">{translation.text_6361}</TableCell>
        {partnership === 'All' && <TableCell align="left">{translation.text_6362}</TableCell>}
        <TableCell align="left">{translation.text_6363}</TableCell>
        <TableCell align="left">{highlightType === HighlightTypes.Deposits ? translation.text_6364 : translation.text_6365}</TableCell>
        <TableCell align="left">{highlightType === HighlightTypes.Deposits ? translation.text_6366 : translation.text_6367}</TableCell>
      </>
    ),
    [partnership, highlightType, translation],
  );

  const renderRows = useMemo((): any[] => {
    const result: any[] = [];
    tableData.forEach((row: any) => {
      result.push(
        <TableRow className={classes.tableRow} key={Math.random()}>
          <TableCell align="left">{row.client}</TableCell>
          {partnership === 'All' && <TableCell align="left">{row.partnership}</TableCell>}
          <TableCell align="left">{getTranslation(translation, row.accountType)}</TableCell>
          <TableCell align="left">{moment(highlightType === HighlightTypes.Deposits ? row.depositDate : row.withdrawalDate).format('DD/MM/YYYY')}</TableCell>
          <TableCell className={highlightType === HighlightTypes.Deposits ? classes.depositCell : classes.withdrawalCell} align="left">
            {formatNumber(highlightType === HighlightTypes.Deposits ? row.deposit : row.withdrawal, currency)}
          </TableCell>
        </TableRow>,
      );
    });
    return result;
  }, [tableData, classes, partnership, highlightType, translation]);

  const renderMobileRows = useCallback((): any[] => {
    const result: any[] = [];

    tableData.forEach((row: any) => {
      const rowData: ValueLabel[] = [];

      rowData.push({
        value: row.client,
        label: translation.text_6361,
      });

      if (partnership === 'All') {
        rowData.push({
          value: row.partnership,
          label: translation.text_6362,
        });
      }

      rowData.push({
        value: row.accountType,
        label: translation.text_6363,
      });
      rowData.push({
        value: moment(highlightType === HighlightTypes.Deposits ? row.depositDate : row.withdrawalDate).format('DD/MM/YYYY'),
        label: highlightType === HighlightTypes.Deposits ? translation.text_6364 : translation.text_6365,
      });
      rowData.push({
        value: formatNumber(highlightType === HighlightTypes.Deposits ? row.deposit : row.withdrawal, currency),
        label: highlightType === HighlightTypes.Deposits ? translation.text_6366 : translation.text_6367,
      });

      result.push(rowData);
    });

    return result;
  }, [tableData, translation]);

  return (
    <>
      {highlightType === HighlightTypes.Deposits ? (
        <DepositTitlePanel translation={translation} value={value} partnership={partnership} increased={increased} decreased={decreased} netValue={netValue || '0'} increasedNet={increasedNet} decreasedNet={decreasedNet} />
      ) : (
        <WithdrawalPanelTitle translation={translation} title={title} partnership={partnership} value={value} increased={increased} decreased={decreased} />
      )}
      <Grid container direction="column" alignContent="center" className={classes.content}>
        <Grid container direction="row" className={classes.content} justify="space-between">
          {tableData.length > 0 ? (
            <Grid item xs={12}>
              <Grid item className={classes.titleMessage}>
                {highlightType === HighlightTypes.Deposits ? translation.text_6366 : translation.text_6367} {translation.text_6354}
              </Grid>
              <Grid className={classes.desktopView}>
                <CustomTable headerCells={renderHeader} rows={renderRows} columnsNumber={columnsNumber} headerClassName={classes.tableHeader} hidePagination />
              </Grid>
              <Grid className={classes.mobileView}>
                <MobileTableView list={renderMobileRows()} grouped={false} hasNextButton={false} />
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column" alignContent="center" alignItems="center" className={classes.emptyMessage}>
              <Grid item>
                {partnership === 'All'
                  ? ` ${translation.text_6355} ${highlightType === HighlightTypes.Deposits ? `${translation.text_6356}` : `${translation.text_6357}`} ${translation.text_6358}`
                  : ` ${translation.text_6355} ${highlightType === HighlightTypes.Deposits ? `${translation.text_6356}` : `${translation.text_6357}`} ${translation.text_6359}`}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" style={{ paddingTop: 78 }} alignContent="center" alignItems="flex-start">
          <Grid item>
            <Button variant="contained" color="primary" className={`${commonClasses.linkButtons} ${classes.button}`} component={Link} to={PATH_TRADING_SUMMARY}>
              {translation.text_6360}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
