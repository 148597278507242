import React, { FC, memo } from 'react';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import { MenuItem } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { FieldLabel } from '..';

import useStyles from './styles';
import { SelectFieldProps } from './SelectFieldModels';

export const SelectField: FC<SelectFieldProps> = memo(({
  name, label, options, disabled, isRequired, description, errors, onChange, children, selectClass, selectIconClass, placeholder, labelClass, id, defaultValue
}) => {
  const classes = useStyles();
  return (
    <div>
      {label && <FieldLabel label={label} isRequired={isRequired} description={description} classLabel={labelClass} />}

      <Field
        defaultValue={defaultValue}
        displayEmpty
        component={Select}
        name={name}
        disabled={disabled}
        disableUnderline
        id={id}
        inputProps={{
          classes: {
            icon: `${classes.selectIcon} ${selectIconClass}`,
            select: classes.selectRoot,
          },
          IconComponent: ExpandMore,
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            classes: {
              paper: classes.selectDropdown,
            },
          },
        }}
        className={`${classes.inputContainer} ${selectClass} ${errors ? classes.inputError : ''}`}
        {...Object.assign(onChange ? { onChange } : {})}
      >
        <MenuItem disabled key="0" value="">{placeholder !== '' ? placeholder : 'Please select...'} </MenuItem>
        {(options && options.map((elem) => (
          <MenuItem key={`${name}-${elem.label}-${elem.value}`} value={elem.value}>{elem.label}</MenuItem>
        ))) || children}
      </Field>
      {errors && (
        <span className={classes.errorDescription}>{errors}</span>
      )}
    </div>
  );
});
