import { withStyles } from '@material-ui/core';

const styles = (theme : any) => ({
  '@global': {
    /**
         * Disable the focus outline, which is default on some browsers like
         * chrome when focusing elements
         */
    '*:focus': {
      outline: 0,
    },
    a: {
      textDecoration: 'none',
      color: 'inherit',
    },
    '.text-white': {
      color: theme.palette.common.white,
    },
    '.MuiTypography-h5': {
      fontWeight: 500,
      marginBottom: 20
    }
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
