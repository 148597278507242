import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
    position: 'relative'
  },
  title: {
    textAlign: 'left',
    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.frequent.davyGrey
  },
  paper: {
    // marginTop: 10,
    padding: '8px 11px',
    minHeight: '100vh'
  },
}));
