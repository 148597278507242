/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, IconButton, Paper, TableCell, TableRow, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { RadioButtonCheckedOutlined, RadioButtonUncheckedOutlined } from '@material-ui/icons';
import { useApiError, useAppData, useLoading } from '../../providers';
import { NewRepresentative, Representative, RepresentativeAssignedPartnerships } from './RepresentativesModels';
import { useStyles } from './styles';
import { AssignPartnershipDialog, InviteRepresentativeDialog, MenuItems, PartnershipCard } from './components';
import { CustomTable, ConfirmationDialog, AddCircleIcon } from '../../components';
import { useCommonStyles } from '../../styles';
import { AddItemResult, PartnerPartnership } from '../../models';
import { getPartnerPartnerships } from '../IBDashboard';
import { convertDate, getTranslation } from '../../utils';
import { addRepresentatives, getRepresentativesData, revokeRepresentative, reSendRepresentativelink, removePartnershipFromRepresentative, assignPartnershipsToRepresentative } from './RepresentativesService';

export const Representatives: FC = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { translations, locale } = useAppData();
  const [translation, setTranslation] = useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { setIsLoading } = useLoading();
  const { addError } = useApiError();
  const { userData } = useAppData();
  const [dialogTrigger, setDialogTrigger] = useState<number | undefined>(undefined);
  const [assignDialogTrigger, setAssignDialogTrigger] = useState<number | undefined>(undefined);
  const [partnerships, setPartnerships] = useState<PartnerPartnership[]>([]);
  const [showRevoked, setShowRevoked] = useState<boolean>(false);
  const [confirmRevokeDialogTrigger, setConfirmRevokeDialogTrigger] = useState<number | undefined>();
  const [confirmLinkDialogTrigger, setConfirmLinkDialogTrigger] = useState<number | undefined>();
  const [confirmDeletePartnershipDialogTrigger, setConfirmDeletePartnershipDialogTrigger] = useState<number | undefined>();
  const [representativeForActions, setRepresentativeForActions] = useState<Representative>();
  const [partnershipToRemove, setPrtnershipToRemove] = useState<string>('');
  const [representativesData, setRepresentativesData] = useState<Representative[]>([]);
  const [activeRepresentativesData, setActiveRepresentativesData] = useState<Representative[]>([]);
  const [allRepresentativesData, setAllRepresentativesData] = useState<Representative[]>([]);

  const columns = [translation.text_6535, translation.text_6536, translation.text_6537, translation.text_6538];

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const getPartnershipsData = useCallback(async () => {
    try {
      const response = await getPartnerPartnerships(true);
      setPartnerships(response.data);
    } catch (error) {
      addError(JSON.stringify(error.response));
    }
  }, [addError]);

  const getRepresentatives = useCallback(async () => {
    try {
      const data: any = [];
      const response = await getRepresentativesData();
      setShowRevoked(false);
      if (response.data.length > 0) {
        data.push(response.data);
        setRepresentativesData(data[0].filter((repres: any) => repres.accessRevokedDate === null));
        setActiveRepresentativesData(data[0].filter((repres: any) => repres.accessRevokedDate === null));
        setAllRepresentativesData(data[0]);
      }
    } catch (error) {
      addError(JSON.stringify(error.response));
    }
  }, [addError, userData]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getPartnershipsData();
      await getRepresentatives();
      setIsLoading(false);
    })();
  }, []);

  const reSendLinkCallback = useCallback((representative) => {
    setRepresentativeForActions(representative);
    setConfirmLinkDialogTrigger(Math.random());
  }, []);

  const revokeAccessCallback = useCallback((representative) => {
    setRepresentativeForActions(representative);
    setConfirmRevokeDialogTrigger(Math.random());
  }, []);

  const deletePartnershipCallback = useCallback((representative, partnership) => {
    setRepresentativeForActions(representative);
    setPrtnershipToRemove(partnership);
    setConfirmDeletePartnershipDialogTrigger(Math.random());
  }, []);

  const addPartnershipCallback = useCallback((representative) => {
    setRepresentativeForActions(representative);
    setAssignDialogTrigger(Math.random());
  }, []);

  const renderPartnershipsCallback = useCallback(
    (representative: Representative) => {
      const partnershipsArr = representative.representativeAssignedPartnerships?.filter((partn: any) => partn.representativePartnershipPeriods.some((item: any) => item.endDate === null)) as Array<RepresentativeAssignedPartnerships>;
      partnershipsArr.forEach((partner) => partner.representativePartnershipPeriods);
      return (
        <div className={`${representative.accessRevokedDate !== null ? classes.disabled : ''}`}>
          {partnershipsArr.map((partnership) => (
            <PartnershipCard representative={representative} key={partnership.id} partnership={partnership} onDelete={deletePartnershipCallback} />
          ))}
          {partnerships.length > partnershipsArr.length && (
            <IconButton onClick={() => addPartnershipCallback(representative)}>
              <AddCircleIcon height={25} />
            </IconButton>
          )}
        </div>
      );
    },
    [addPartnershipCallback, classes, deletePartnershipCallback, partnerships],
  );

  const renderHeader = useMemo((): any[] => {
    const result: any[] = [];
    columns.forEach((column, index) => {
      result.push(
        <TableCell key={column} align={index === 0 ? 'left' : 'center'}>
          {column}
        </TableCell>,
      );
    });
    return result;
  }, [classes, translation]);

  const renderRows = useMemo((): any[] => {
    const result: any[] = [];
    representativesData.forEach((row) => {
      result.push(
        <TableRow key={row.id}>
          <TableCell align="left">{row.email}</TableCell>
          <TableCell align={row.representativeAssignedPartnerships && row.representativeAssignedPartnerships.filter((partn: any) => partn.representativePartnershipPeriods.some((item: any) => item.endDate === null)).length > 0 ? 'left' : 'center'}>
            {renderPartnershipsCallback(row)}
          </TableCell>
          <TableCell align="center">
            <span className={`${row.accessRevokedDate !== null ? classes.disabled : ''}`}>{convertDate(row.invitationDate)}</span>
          </TableCell>
          <TableCell align="center" style={{ width: '15%' }}>
            <MenuItems representative={row} onResend={reSendLinkCallback} onRevoke={revokeAccessCallback} />
          </TableCell>
        </TableRow>,
      );
    });
    return result;
  }, [representativesData, classes, reSendLinkCallback, revokeAccessCallback, renderPartnershipsCallback]);

  const handleShowRevokedRepresentatives = useCallback(
    (show: boolean) => {
      setShowRevoked(show);
      if (show) {
        setRepresentativesData(allRepresentativesData);
      } else {
        setRepresentativesData(activeRepresentativesData);
      }
    },
    [allRepresentativesData, activeRepresentativesData],
  );

  const handleAddResponse = useCallback(
    async (response: any) => {
      switch (response.status) {
        case AddItemResult.Success:
          enqueueSnackbar(translation.text_6500, { variant: 'success' });
          await getRepresentatives();
          break;

        case AddItemResult.Failure:
          enqueueSnackbar(translation.text_6501, { variant: 'error' });
          break;

        case AddItemResult.PrimaryKeyViolation:
          enqueueSnackbar(translation.text_507, { variant: 'error' });
          break;

        case AddItemResult.ValidationError:
          enqueueSnackbar(translation.text_508, { variant: 'error' });
          break;
        case AddItemResult.DuplicatedValue:
          enqueueSnackbar(translation.text_6502, { variant: 'error' });
          break;
        default:
          break;
      }
    },
    [enqueueSnackbar, translation, addError],
  );

  const inviteNewRepresentative = useCallback(
    (values, { setSubmitting }) => {
      setIsLoading(true);
      const entity = { ...values, language: locale };

      addRepresentatives(entity)
        .then((response: any) => {
          setDialogTrigger(undefined);
          handleAddResponse(response.data);
        })
        .catch((error) => {
          addError(JSON.stringify(error.response));
        })
        .finally(() => {
          setIsLoading(false);
        });
      setSubmitting(false);
    },
    [addError, setIsLoading, handleAddResponse],
  );

  const representativeRevokeCallback = useCallback(async () => {
    setIsLoading(true);
    try {
      if (representativeForActions) {
        const response = await revokeRepresentative(representativeForActions.id);
        switch (response.data.status) {
          case AddItemResult.Success:
            enqueueSnackbar(response.message ? getTranslation(translation, response.message) : translation.text_6503, { variant: 'success' });
            break;
          case AddItemResult.Failure:
            enqueueSnackbar(response.message ? getTranslation(translation, response.message) : translation.text_6504, { variant: 'error' });
            break;

          case AddItemResult.ValidationError:
            enqueueSnackbar(response.message ? getTranslation(translation, response.message) : translation.text_508, { variant: 'error' });
            break;
          default:
            break;
        }
        setConfirmRevokeDialogTrigger(undefined);
        await getRepresentatives();
      }
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  }, [enqueueSnackbar, representativeForActions, addError, translation, setIsLoading, getRepresentatives]);

  const representativeResendLinkCallback = useCallback(async () => {
    setIsLoading(true);
    try {
      if (representativeForActions) {
        const assignedPartnershipCodes: string[] = [];
        if (representativeForActions.representativeAssignedPartnerships) {
          representativeForActions.representativeAssignedPartnerships.forEach((partnership) => {
            if (partnership.partnershipCode) {
              assignedPartnershipCodes.push(partnership.partnershipCode);
            }
          });
        }

        const representative: NewRepresentative = {
          email: representativeForActions.email,
          displayName: representativeForActions.displayName,
          password: '',
          partnerFID: representativeForActions.partnerFID,
          assignedPartnershipCodes,
        };
        const response = await reSendRepresentativelink(representative);
        switch (response.data.status) {
          case AddItemResult.Success:
            enqueueSnackbar(response.message ? getTranslation(translation, response.message) : translation.text_6505, { variant: 'success' });
            break;
          case AddItemResult.Failure:
            enqueueSnackbar(response.message ? getTranslation(translation, response.message) : translation.text_6506, { variant: 'error' });
            break;

          case AddItemResult.ValidationError:
            enqueueSnackbar(response.message ? getTranslation(translation, response.message) : translation.text_508, { variant: 'error' });
            break;
          default:
            break;
        }
        setConfirmLinkDialogTrigger(undefined);
        await getRepresentatives();
      }
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  }, [enqueueSnackbar, representativeForActions, addError, translation, setIsLoading, getRepresentatives]);

  const representativeDeletePartnershipCallback = useCallback(async () => {
    setIsLoading(true);
    try {
      if (representativeForActions) {
        const response = await removePartnershipFromRepresentative(representativeForActions.id, partnershipToRemove);
        switch (response.data.status) {
          case AddItemResult.Success:
            enqueueSnackbar(response.message ? getTranslation(translation, response.message) : translation.text_6507, { variant: 'success' });
            break;
          case AddItemResult.Failure:
            enqueueSnackbar(response.message ? getTranslation(translation, response.message) : translation.text_6508, { variant: 'error' });
            break;

          case AddItemResult.ValidationError:
            enqueueSnackbar(response.message ? getTranslation(translation, response.message) : translation.text_508, { variant: 'error' });
            break;
          default:
            break;
        }
        setConfirmDeletePartnershipDialogTrigger(undefined);
        await getRepresentatives();
      }
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  }, [enqueueSnackbar, representativeForActions, addError, translation, setIsLoading, partnershipToRemove, getRepresentatives]);

  const assignPartnershipsCallback = useCallback(
    async (values, { setSubmitting }) => {
      setIsLoading(true);
      try {
        if (representativeForActions) {
          const response = await assignPartnershipsToRepresentative(representativeForActions.id, values.assignedPartnershipCodes);
          switch (response.data.status) {
            case AddItemResult.Success:
              enqueueSnackbar(response.message ? getTranslation(translation, response.message) : translation.text_6509, { variant: 'success' });
              break;
            case AddItemResult.Failure:
              enqueueSnackbar(response.message ? getTranslation(translation, response.message) : translation.text_6510, { variant: 'error' });
              break;

            case AddItemResult.ValidationError:
              enqueueSnackbar(response.message ? getTranslation(translation, response.message) : translation.text_508, { variant: 'error' });
              break;
            default:
              break;
          }
          setAssignDialogTrigger(undefined);
          await getRepresentatives();
        }
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
      setSubmitting(false);
    },
    [enqueueSnackbar, representativeForActions, addError, translation, setIsLoading, getRepresentatives],
  );

  const confirmDialogRevokeActions = useMemo(
    () => [
      {
        key: '1',
        title: translation.text_28,
        onClick: () => setConfirmRevokeDialogTrigger(undefined),
        isPrimary: false,
      },
      {
        key: '2',
        title: translation.text_6511,
        onClick: representativeRevokeCallback,
        isPrimary: true,
      },
    ],
    [representativeRevokeCallback, translation],
  );

  const confirmDialogLinkActions = useMemo(
    () => [
      {
        key: '1',
        title: translation.text_28,
        onClick: () => setConfirmLinkDialogTrigger(undefined),
        isPrimary: false,
      },
      {
        key: '2',
        title: translation.text_6512,
        onClick: representativeResendLinkCallback,
        isPrimary: true,
      },
    ],
    [representativeResendLinkCallback, translation],
  );

  const confirmDialogDeletePartnershipActions = useMemo(
    () => [
      {
        key: '1',
        title: translation.text_28,
        onClick: () => setConfirmDeletePartnershipDialogTrigger(undefined),
        isPrimary: false,
      },
      {
        key: '2',
        title: translation.text_6513,
        onClick: representativeDeletePartnershipCallback,
        isPrimary: true,
      },
    ],
    [representativeDeletePartnershipCallback, translation],
  );

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="row" spacing={1} alignContent="flex-start" alignItems="center" className={classes.title}>
        <Grid item xs={12} sm={7}>
          <Typography className={classes.title}>{translation.text_6514}</Typography>
        </Grid>
        <Grid item xs={12} sm={5} className={commonClasses.newButtonContainer}>
          <Button variant="contained" color="primary" className={classes.button} onClick={() => setDialogTrigger(Math.random())}>
            {translation.text_6515}
          </Button>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <Grid container direction="column" justify="center" alignItems="center">
          {representativesData.length > 0 ? (
            <>
              <Grid container direction="row" justify="flex-end" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showRevoked}
                      onChange={() => handleShowRevokedRepresentatives(!showRevoked)}
                      icon={<RadioButtonUncheckedOutlined className={classes.radiobutton} />}
                      checkedIcon={<RadioButtonCheckedOutlined className={classes.radiobutton} />}
                      name="showRevoked"
                    />
                  }
                  className={classes.label}
                  label={translation.text_6516}
                />
              </Grid>
              <CustomTable headerCells={renderHeader} rows={renderRows} columnsNumber={columns.length} emptyDataMessage={translation.text_6517} />
            </>
          ) : (
            <>
              <Grid item className={classes.noRepresentativesMessageContainer}>
                {translation.text_6517}
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" className={classes.button} onClick={() => setDialogTrigger(Math.random())}>
                  {translation.text_6515}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>

      <InviteRepresentativeDialog key={dialogTrigger} trigger={dialogTrigger} partnerships={partnerships} onSubmit={inviteNewRepresentative} onCancel={() => setDialogTrigger(undefined)} />

      <AssignPartnershipDialog key={assignDialogTrigger} trigger={assignDialogTrigger} representative={representativeForActions} partnerships={partnerships} onSubmit={assignPartnershipsCallback} onCancel={() => setAssignDialogTrigger(undefined)} />

      <ConfirmationDialog title={translation.text_6518} trigger={confirmRevokeDialogTrigger} actions={confirmDialogRevokeActions} content={translation.text_6519} />

      <ConfirmationDialog title={translation.text_6520} trigger={confirmLinkDialogTrigger} actions={confirmDialogLinkActions} content={translation.text_6521} />

      <ConfirmationDialog title={translation.text_6522} trigger={confirmDeletePartnershipDialogTrigger} actions={confirmDialogDeletePartnershipActions} content={translation.text_6523} />
    </Grid>
  );
};
