import React, { FC } from 'react';
import { ReactComponent as More } from '../../../assets/Common/MoreInCircle.svg';
import { useStyles } from '../styles';
import { CustomIconProps } from '../CustomIconModels';

export const MoreInCircle: FC<CustomIconProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <More className={`${classes.icon} ${className || ''}`} />
  );
};
