import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  titleContainer: {
    color: theme.palette.text.primary,
    marginBottom: 6,

    '@media (max-width: 1025px)': {
      marginTop: 19
    }
  },
  title: {
    textAlign: 'left',
    marginBottom: 0,

    '@media (max-width: 768px)': {
      fontSize: 20
    }
  },
}));
