export interface LinkType {
  linkType: number | string
  linkTypeText: string
  currencies: string[]
}

export const emptyLinkType = () : LinkType => ({
  linkType: '',
  linkTypeText: '',
  currencies: []
});
