import { Broker, Country, Lead, Partnership } from '.';

export interface Partner {
  country?: Country;
  lead?: Lead | null;
  partnerships?: Array<Partnership> | null;
  riskLevel?: number | string;
  id: number | string | null;
  email: string;
  name: string;
  address: string;
  phone?: string;
  paymentAccount: number | string;
  broker: Broker;
  brokerDomainId: string;
  rowVersion?: string | undefined;
  brokerFID?: number;
}

export enum PartnershipRank {
  Normal = 0,
  SubIB = 1,
  MasterIB = 2,
}
