import React, { FC } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { OverflowTip } from 'src/components';
import { getTranslation } from 'src/utils';
import { useStyles } from './styles';
import { LightTooltip } from '../../LightTooltip';
import { InfoIcon } from '../../CustomIcons';

interface Props {
  title: string;
  items: KeyValuePair[];
  translation: Record<string, string>;
}

interface KeyValuePair {
  name: string;
  value: string;
  tooltipText?: string;
}

export const PanelInsights: FC<Props> = ({ title, items, translation }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid item className={classes.title}>
        {title}
      </Grid>
      <Grid container direction="row" alignContent="center" alignItems="center" justify="space-between" className={classes.content}>
        {items.map((item: KeyValuePair, index) => {
          const splittedValue = String(item.value).split(',');

          const actualValue = splittedValue.map((value) => getTranslation(translation, value)).join(', ');

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item xs={items.length === 3 ? 4 : items.length === 4 ? 3 : 2} key={`${item.name}_${actualValue}_${index}`}>
              <Grid item className={classes.nameItem}>
                {item.name}
                {item.tooltipText && (
                  <LightTooltip title={item.tooltipText} arrow placement="right-start">
                    <IconButton size="small" style={{ marginTop: '-10px' }}>
                      <InfoIcon className={classes.infoIcon} />
                    </IconButton>
                  </LightTooltip>
                )}
              </Grid>
              <Grid item className={classes.valueItem}>
                <OverflowTip title={actualValue}>{actualValue}</OverflowTip>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
