import React, { FC } from 'react';
import { useStyles } from '../styles';
import { ReactComponent as Check } from '../../../assets/Common/CheckIcon.svg';

export const CheckIcon: FC = () => {
  const classes = useStyles();
  return (
    <Check className={classes.icon} />
  );
};
