import React, { FC, memo, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { IconButton, Menu, MenuItem, Typography, Divider, Box, Grid } from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import useStyles from './styles';
import { CloseIconRedFilled, MoreInCircle } from '../../../../components';
import { Representative } from '../../RepresentativesModels';
import { useAppData } from '../../../../providers';

interface PartnershipCardProps {
  onRevoke: (representative?: Representative) => void;
  onResend: (representative?: Representative) => void;
  representative: Representative;
}

export const MenuItems: FC<PartnershipCardProps> = memo(({ onRevoke, onResend, representative }) => {
  const classes = useStyles();
  const [isRevoked, setIsRevoked] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    const isRepRevoked = representative.accessRevokedDate !== null;
    setIsRevoked(isRepRevoked);
  }, [setIsRevoked, representative]);

  return (
    <>
      {isRevoked ? (
        <>
          <Grid container direction="column" justify="center" alignItems="center">
            <Box component="div" className={classes.box}>
              {translation.text_6524}
            </Box>
            <IconButton className={classes.disabled} aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
              <MoreInCircle />
            </IconButton>
          </Grid>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            classes={{
              paper: classes.popper,
              list: classes.list,
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            style={{ transform: 'translate(10px, -5px)' }}
          >
            <MenuItem
              className={classes.item}
              onClick={() => {
                onResend(representative);
                handleClose();
              }}
              key={uuid()}
            >
              <IconButton className={classes.iconButton}>
                <LinkIcon className={classes.linkIcon} />
              </IconButton>
              <Typography className={classes.reSendTitle}>{translation.text_6525}</Typography>
            </MenuItem>
            <Divider className={classes.divider} key={uuid()} />
            <MenuItem onClick={handleClose} key={uuid()}>
              <Typography className={classes.closeTitle}>{translation.text_6526}</Typography>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <>
          <IconButton className={classes.menuIcon} aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
            <MoreInCircle />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            classes={{
              paper: classes.popper,
              list: classes.list,
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            style={{ transform: 'translate(10px, -5px)' }}
          >
            <MenuItem
              className={classes.item}
              onClick={() => {
                onRevoke(representative);
                handleClose();
              }}
              key={uuid()}
            >
              <IconButton className={classes.iconButton}>
                <CloseIconRedFilled width={14} />
              </IconButton>
              <Typography className={classes.revokeTitle}>{translation.text_6527}</Typography>
            </MenuItem>
            <Divider className={classes.divider} key={uuid()} />
            <MenuItem onClick={handleClose} key={uuid()}>
              <Typography className={classes.closeTitle}>{translation.text_6526}</Typography>
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
});
