import React, { FC, ReactNode } from 'react';
import { Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useStyles } from '../styles';

interface Props {
  value: any,
  children: ReactNode,
  onChange: (value: any) => void
  className?: string
  border?: any,
  margin?: any,
  minWidth?: any
}

export const SelectControl: FC<Props> = ({
  value, onChange, children, border, margin, minWidth, className
}) => {
  const classes = useStyles();
  return (
    <Select
      value={value}
      IconComponent={ExpandMore}
      onChange={onChange}
      className={`${classes.selectContainer} ${className}`}
      style={{ border, margin, minWidth }}
    >
      {children}
    </Select>
  );
};
