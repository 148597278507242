import { Tooltip, withStyles } from '@material-ui/core';

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.frequent.davyGrey,
    boxShadow: `0 10px 20px 0 ${theme.palette.frequent.boxShadow}`,
    fontSize: 12,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);
