import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  mainTitle: {
    color: theme.palette.frequent.davyGrey,
    fontSize: 26,
    textAlign: 'left',
    fontWeight: 400,

    '@media (max-width: 1024px)': {
      fontSize: 18
    }
  },
  valueTitle: {
    fontSize: 28,
    fontWeight: 700,
    color: theme.palette.frequent.davyGrey,

    '@media (max-width: 1024px)': {
      fontSize: 20
    }
  },
  changeTitle: {
    color: theme.palette.lineGraph.text,
    fontSize: 16,
    textAlign: 'left',

    '@media (max-width: 1024px)': {
      fontSize: 14,
    }
  },
  toDate: {
    '& .MuiOutlinedInput-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  },
  fromDate: {
    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  partnershipTitle: {
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.frequent.green,
    marginBottom: 30,

    '@media (max-width: 1024px)': {
      textAlign: 'center',
      marginBottom: 20,
      fontSize: 14,
    }
  },
  divider: {
    backgroundColor: theme.palette.frequent.chineseWhite,
    margin: '14px 0 16px 0'
  },
  changeTitleIcon: {
    width: 15,
    height: 17,

    '@media (max-width: 1024px)': {
      width: 9,
      height: 11,
    }
  },
  noChangeTitleIcon: {
    height: 10,

    '@media (max-width: 1024px)': {
      height: 8
    }
  },
  emptyMessage: {
    color: theme.palette.lineGraph.text,
    fontStyle: 'italic',
    textAlign: 'left'
  },
  dataRow: {
    justifyContent: 'space-between',

    '@media (max-width: 1410px)': {
      display: 'none'
    }
  },
  mobileDataRow: {
    justifyContent: 'space-between',
    marginBottom: 24,

    '@media (min-width: 1411px)': {
      display: 'none'
    }
  },
  formControl: {
    minWidth: 165,
    flexDirection: 'row',

    '@media (max-width: 1180px)': {
      minWidth: 'unset'
    }
  },
  selectBox: {
    border: `1px solid ${theme.palette.secondary.light}`,
    boxShadow: 'none',
    fontSize: 14,
    margin: 0,

    '&:hover': {
      border: `1px solid ${theme.palette.frequent.nickel}`,
    }
  },
  optionSelector: {
    fontSize: 14,

    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.frequent.selectorOptionGrey
    },
    '&.MuiListItem-root.Mui-selected:hover': {
      backgroundColor: theme.palette.frequent.selectorOptionGrey
    },

    '@media (max-width: 1180px)': {
      fontSize: 12,
      padding: '0 8px'
    },
  },
}));
