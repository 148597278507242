import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
    width: 1000,
  },
  tabs: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.frequent.fossilGrey,
    boxShadow: 'none',

    '& .MuiTab-root': {
      padding: 0,
      backgroundColor: theme.palette.common.white,
      border: `solid 1px ${theme.palette.frequent.abalonGrey}`,
    },

    '& .MuiTab-wrapper': {
      textTransform: 'none',
      padding: 9,
      fontWeight: 'normal'
    },

    '& .Mui-selected': {
      backgroundColor: theme.palette.frequent.cultured,
      textTransform: 'none',
      fontWeight: 'normal'
    },

    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.frequent.cultured,
      height: 0
    }
  },
  clientTab: {
    borderRadius: '15px 0 0 15px',
  },
  accountTab: {
    borderRadius: '0 15px 15px 0',
  },
  cell: {
    verticalAlign: 'top'
  }
}));
