import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useStyles } from '../../../styles';
import { formatNumber } from '../../../../../../utils';

interface Props {
  index: number
  row: any
  columnNames: string[]
  columns: string[]
  currency: string
}

export const MobileTableRow: FC<Props> = ({
  index, row, columnNames, columns, currency
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" style={{ paddingLeft: 17, paddingRight: 17 }}>
      <Box
        component="div"
        className={`${classes.volumeTableItem} 
            ${index === 0 ? classes.volumeTableItem0
          : (index === 1 ? classes.volumeTableItem1
            : (index === 2 ? classes.volumeTableItem2
              : (index === 3 ? classes.volumeTableItem3 : classes.volumeTableItem4)))}`}
      >
        {columnNames.includes(columns[0]) ? row.symbol : row.platform}
      </Box>
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        alignItems="center"
        justify="space-between"
        style={{ marginTop: 17 }}
      >
        <Grid item xs={3}>
          <Grid container direction="column" alignContent="flex-start" alignItems="flex-start">
            <Grid item className={classes.label}>{columnNames[1]}</Grid>
            <Grid item className={classes.value}>{row.lots.toFixed(2).toLocaleString()}</Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container direction="column" alignContent="flex-start" alignItems="flex-start">
            <Grid item className={classes.label}>{columnNames[2]}</Grid>
            <Grid item className={classes.value}>{formatNumber(row.volume, currency)}</Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container direction="column" alignContent="flex-start" alignItems="flex-start">
            <Grid item className={classes.label}>{columnNames[3]}</Grid>
            <Grid item className={classes.value}>{formatNumber(row.commisions, currency)}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
