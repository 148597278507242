import React, { FC, useCallback } from 'react';
import {
  Checkbox, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText
} from '@material-ui/core';
import { CheckBoxOutlined, FiberManualRecord } from '@material-ui/icons';
import { useStyles } from '../../../styles';
import { LineChart, PeriodSelection } from '../../../../../../components';

interface Props {
  selectedPeriod: string
  chartData: any
  currency?: string
  checkboxes: string[]
  selectedCheckboxes: string[]
  colors: any
  translation: any
  handlePeriodSelect: (period: string) => void
  selectCheckboxes: (checkboxes: string[]) => void
}

export const GraphArea: FC<Props> = ({
  selectedPeriod, currency, checkboxes, chartData, handlePeriodSelect, selectedCheckboxes,
  colors, selectCheckboxes, translation
}) => {
  const classes = useStyles();

  const handleChange = useCallback((item: string) => {
    const newValues = selectedCheckboxes.includes(item)
      ? selectedCheckboxes.filter((f) => f !== item)
      : [...selectedCheckboxes, item];

    selectCheckboxes(newValues);
  }, [selectedCheckboxes, selectCheckboxes]);

  return (
    <Grid container direction="column">
      <PeriodSelection selectedPeriod={selectedPeriod} onChange={handlePeriodSelect} translation={translation} />
      <Grid item className={classes.chartSection}>
        {chartData.length > 0 && (
          <LineChart
            key={Math.random()}
            period={selectedPeriod}
            data={chartData}
            currency={currency}
            colors={colors}
            translation={translation}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <List component="div" dense className={classes.flexContainer}>
          {checkboxes.map((item) => (
            <ListItem key={item} button className={classes.checkboxListItem}>
              <ListItemIcon
                className={`${classes.checkboxListIcon} 
                      ${item === translation.text_1600 ? classes.checkBoxListIconCommissions
                  : (item === translation.text_1601 ? classes.checkBoxListIconWithdrawals : classes.checkBoxListIconDeposits)}`}
              >
                <FiberManualRecord className={classes.icon} />
              </ListItemIcon>
              <ListItemText id={item} primary={item} classes={{ primary: classes.checkboxText }} />
              <ListItemSecondaryAction className={classes.action}>
                <Checkbox
                  size="small"
                  edge="end"
                  title={item}
                  disabled={item === translation.text_1600}
                  checked={selectedCheckboxes.includes(item)}
                  classes={{
                    root: classes.checkbox,
                  }}
                  checkedIcon={<CheckBoxOutlined />}
                  onChange={() => handleChange(item)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};
